import { getPointer, RecordValue } from "libs/schema";
import { map, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export type UseTagRecordsResult = [records: RecordValue<"tag">[], meta: { isLoading: boolean }];

export function useTagRecords(tagIds: string[]): UseTagRecordsResult {
  const { recordLoader } = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [recordLoader, tagIds],
    depsKey: tagIds.join(),
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, tagIds]) => {
          return recordLoader.observeGetRecords(tagIds.map((id) => getPointer("tag", id)));
        }),
        map(([records, meta]): UseTagRecordsResult => [records.map((r) => r.record), meta]),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as [],
  {
    isLoading: true,
  },
]) as unknown as UseTagRecordsResult;
