import { Extension, Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const DropHandler = Extension.create({
  name: "dropHandler",
  addOptions: () => ({
    onDrop: undefined as OnDropFn | undefined,
  }),
  addProseMirrorPlugins() {
    if (!this.options.onDrop) {
      throw new Error(`DropHandler: must provide onDrop fn`);
    }

    return [
      DropHandlerPlugin({
        key: new PluginKey(this.name),
        editor: this.editor,
        onDrop: this.options.onDrop,
      }),
    ];
  },
});

export type OnDropFn = (props: { editor: Editor; event: DragEvent; position: number }) => boolean;

function DropHandlerPlugin(props: { key: PluginKey; editor: Editor; onDrop: OnDropFn }) {
  const { key, editor, onDrop } = props;

  return new Plugin({
    key,
    props: {
      handleDrop(editorView, event) {
        console.log("DropHandlerPlugin");

        const pos = editorView.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        });

        const wasHandled = onDrop({ editor, event, position: pos?.pos || 0 });

        console.log("handleDrop", wasHandled);

        if (wasHandled) {
          event.preventDefault();
          event.stopPropagation();
        }

        return wasHandled;
      },
    },
  });
}

// function handleDrop(props: {
//   editor: Editor,
//   event: DragEvent,
//   position: Position,
// }): boolean {
//   var o;
//   if (!onDrop) return !1;
//   if (
//     !(null === (o = event.dataTransfer) || void 0 === o
//       ? void 0
//       : o.files.length)
//   )
//     return !1;
//   const i = editorView.posAtCoords({ left: event.clientX, top: event.clientY });
//   let l = Array.from(event.dataTransfer.files);
//   return (
//     allowedMimeTypes && (l = l.filter((e) => allowedMimeTypes.includes(e.type))),
//     0 !== l.length &&
//       (event.preventDefault(),
//       event.stopPropagation(),
//       onDrop(editor, l, (null == i ? void 0 : i.pos) || 0),
//       !0)
//   );
// }
