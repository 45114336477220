import { ComponentType, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { cx } from "@emotion/css";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import * as MainLayout from "~/page-layouts/main-layout";
import { useOrganizationProfile } from "~/hooks/useOrganizationProfile";
import { useOrganizationGroups } from "~/hooks/useOrganizationGroups";
import { useListPaging } from "~/hooks/useListPaging";
import { PointerWithRecord, RecordValue } from "libs/schema";
import { ContentList, EmptyListMessage, useKBarAwareFocusedEntry$ } from "~/components/content-list/ContentList";
import { GroupEntry, TOrderGroupsBy, onGroupSelectNavigateToGroup } from "~/components/content-list/GroupEntry";
import { ESCAPE_TO_INBOX_COMMAND, tagSubscriptionCommands } from "~/utils/common-commands";
import { Observable, map } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { createFormControl, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { updateUserSettings } from "~/actions/updateUserSettings";

export const ExploreGroupsView: ComponentType<{}> = () => {
  const params = useParams();
  const [organization] = useOrganizationProfile(params.organizationId);
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const [orderBy, setOrderBy] = useState<TOrderGroupsBy>("name");

  const showArchivedGroupsControl = useShowArchivedGroupsControl();

  const showArchivedGroups = useControlState(() => showArchivedGroupsControl.value, [showArchivedGroupsControl]);

  const [groupIds, { fetchMore, isLoading, nextId }] = useOrganizationGroups({
    organizationId: params.organizationId,
    hideArchivedGroups: !showArchivedGroups,
    orderBy: "name",
  });

  const noMoreGroups = !nextId && !isLoading;

  useListPaging({
    fetchMore,
    isLoading,
    isListEnd: noMoreGroups,
    pagingScrollboxRef: scrollboxRef,
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const { setFocusedEntry, focusedEntry$ } = useKBarAwareFocusedEntry$<PointerWithRecord<"tag">>();

  useRegisterExploreGroupsCommands({ focusedEntry$ });

  return (
    <>
      <Helmet>
        <title>Explore Groups | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="flex-col">
        <div className={cx("flex items-center")}>
          <h1 className="text-3xl">Explore Groups</h1>
        </div>

        <MainLayout.HeaderMenu>
          <li className="flex items-center">
            <div className="text-slate-9">
              <small>Order by:</small>
            </div>

            <div className="w-4" />

            <ToggleGroup.Root
              type="single"
              onValueChange={(e) => setOrderBy(e as TOrderGroupsBy)}
              onKeyDown={(e) => {
                const isLeftOrRightArrowKey = e.key === "ArrowLeft" || e.key === "ArrowRight";

                if (!isLeftOrRightArrowKey) return;

                // If we don't do this, then ArrowLeft will focus the sidebar
                e.stopPropagation();
              }}
            >
              {/* <ToggleItem
                value="subscriber-count"
                isSelected={orderBy === "subscriber-count"}
              >
                <small>Subscriber Count</small>
              </ToggleItem>

              <ToggleItem
                value="recently-active"
                isSelected={orderBy === "recently-active"}
              >
                <small>Recently Active</small>
              </ToggleItem> */}

              <ToggleItem value="name" isSelected={orderBy === "name"}>
                <small>Name</small>
              </ToggleItem>
            </ToggleGroup.Root>
          </li>

          <li className="flex items-center">
            <label htmlFor="show-archived-groups" className="text-slate-9 hover:cursor-pointer mx-2">
              <small>Show archived groups:</small>
            </label>

            <CheckboxInput
              id="show-archived-groups"
              control={showArchivedGroupsControl}
              checkedValue={true}
              uncheckedValue={false}
            />
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {groupIds.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<PointerWithRecord<"tag">>
            onEntryFocused={setFocusedEntry}
            onEntryAction={onGroupSelectNavigateToGroup}
            className="mb-20"
            autoFocus
          >
            {groupIds.map((groupId, index) => (
              <GroupEntry key={groupId} groupId={groupId} relativeOrder={index} orderBy={orderBy} />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useShowArchivedGroupsControl() {
  const environment = useClientEnvironment();

  const { settings } = useCurrentUserSettings();

  const showArchivedGroupsControl = useControl(() => createFormControl(!!settings?.show_archived_groups));

  // Update the settings value in response to changes in the control
  useEffect(() => {
    const sub = observable(() => showArchivedGroupsControl.value).subscribe((showArchivedGroups) => {
      updateUserSettings(environment, {
        show_archived_groups: showArchivedGroups,
      });
    });

    return () => sub.unsubscribe();
  }, [showArchivedGroupsControl, environment]);

  // Update the control value in response to changes in the settings.
  const showArchivedGroups = !!settings?.show_archived_groups;
  useEffect(() => {
    showArchivedGroupsControl.setValue(showArchivedGroups);
  }, [showArchivedGroups, showArchivedGroupsControl]);

  return showArchivedGroupsControl;
}

/* -----------------------------------------------------------------------------------------------*/

const ToggleItem: ComponentType<{ value: string; isSelected: boolean }> = (props) => {
  return (
    <ToggleGroup.Item
      value={props.value}
      className={cx(
        "inline-flex items-center border group px-2 py-1",
        "first:rounded-l last:rounded-r",
        "border-r-0 last:border-r",
        "border-slate-9",

        props.isSelected ? "bg-slate-12 text-white" : "hover:bg-slateA-3",
      )}
    >
      {props.children}
    </ToggleGroup.Item>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useRegisterExploreGroupsCommands(props: { focusedEntry$: Observable<PointerWithRecord<"tag"> | null> }) {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands() {
      return props.focusedEntry$.pipe(
        map((focusedEntry) => {
          const commands: ICommandArgs[] = [
            ESCAPE_TO_INBOX_COMMAND,
            ...tagSubscriptionCommands({
              environment,
              tagId: focusedEntry?.id || null,
            }),
          ];

          return commands;
        }),
      );
    },
    deps: [props.focusedEntry$, environment],
  });
}

/* -----------------------------------------------------------------------------------------------*/
