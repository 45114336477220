import { assert } from "ts-decoders";
import * as d from "ts-decoders/decoders";
import { JsonValue } from "type-fest";
import { NonNullableJsonD } from "../decoders";
import { getRecordDecoderBases } from "../getRecordDecoderBases";

const fromDatabaseDecoderBases = getRecordDecoderBases({
  BooleanD: d.numberD().map((i) => !!i),
  DateTimeD: d.stringD(),
  IntegerD: d.integerD(),
  JsonD: d.stringD().map((i) => JSON.parse(i) as JsonValue),
  TextD: d.stringD(),
  UuidD: d.stringD(),
});

export const fromDatabaseDecoders = Object.fromEntries(
  Object.entries(fromDatabaseDecoderBases).map(([table, base]) => [table, d.objectD(base as any)]),
);

/**
 * Functions that map a client record from the expected database input.
 * Will throw an error if passed an unexpected/invalid value.
 */
export const recordFromDatabaseFnMap = Object.fromEntries(
  Object.entries(fromDatabaseDecoders).map(([table, decoder]) => [table, assert(decoder)]),
);

/**
 * Useful to decoding a SQL response that only returns a few columns
 * from a table.
 */
export const fromDatabasePartialDecoders = Object.fromEntries(
  Object.entries(fromDatabaseDecoderBases).map(([table, base]) => [
    table,
    d.objectD(Object.fromEntries(Object.entries(base).map(([prop, decoder]) => [prop, d.undefinableD(decoder)])), {
      removeUndefinedProperties: true,
    }),
  ]),
);

let toDatabaseDecoderBases = getRecordDecoderBases({
  BooleanD: d.booleanD().map((i) => (i ? 1 : 0)),
  DateTimeD: d.stringD(),
  IntegerD: d.integerD(),
  JsonD: NonNullableJsonD.map(JSON.stringify),
  TextD: d.stringD(),
  UuidD: d.stringD(),
});

toDatabaseDecoderBases = {
  ...toDatabaseDecoderBases,
  user_profile: {
    ...toDatabaseDecoderBases.user_profile,
    // The "name" column is a computed column in the database so we remove it
    // when sending a record to the database (`undefined` values are stripped from
    // the record before sending it to the database).
    name: d.constantD(undefined) as any,
  },
};

export const toDatabaseDecoders = Object.fromEntries(
  Object.entries(toDatabaseDecoderBases).map(([table, base]) => [table, d.objectD<any>(base as any)]),
);

/**
 * Functions that map a client record to the expected database input.
 * Will throw an error if passed an unexpected/invalid value.
 */
export const recordToDatabaseFnMap = Object.fromEntries(
  Object.entries(toDatabaseDecoders).map(([table, decoder]) => [table, assert(decoder)]),
);

/**
 * Used for decoding part of a record to the expected database input.
 * Useful in update operations.
 */
export const toDatabasePartialDecoders = Object.fromEntries(
  Object.entries(toDatabaseDecoderBases).map(([table, base]) => [
    table,
    d.objectD(Object.fromEntries(Object.entries(base).map(([prop, decoder]) => [prop, d.undefinableD(decoder)])), {
      removeUndefinedProperties: true,
    }),
  ]),
);

/**
 * Functions that map a client record partial object to the expected
 * database input. Will throw an error if passed an unexpected/invalid value.
 */
export const partialRecordToDatabaseFnMap = Object.fromEntries(
  Object.entries(toDatabasePartialDecoders).map(([table, decoder]) => [table, assert(decoder)]),
);
