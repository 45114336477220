import { FcGoogle } from "react-icons/fc";
import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { ComponentType, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NewUserDialog } from "~/dialogs/user-new/NewUserDialog";
import { LoadingText } from "~/components/LoadingText";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { attemptToGetRedirectLocation } from "~/environment/navigate.service";
import { signInWithSwiftGoogle, signinWithGoogle, signinWithGoogleGetRedirectResult } from "~/actions/signinWithGoogle";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import useIsNativeIOS from "~/hooks/useIsNativeIos";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { getSessionStorage } from "~/environment/KVStore";

/**
 * Shows the login view, but also handles the redirect from Google after the user signs in.
 */
export const LoginView: ComponentType<{}> = () => {
  const environment = useClientEnvironment();
  const [currentUser, { isLoading }] = useCurrentUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const isNativeIOS = useIsNativeIOS();

  const handleExternalSocialAuth = (
    event: CustomEvent<{
      accessToken: string;
      providerId: string;
      email: string;
      signInResult: any;
    }>,
  ) => {
    const { accessToken, providerId, email } = event.detail;
    signInWithSwiftGoogle(environment, { accessToken, providerId, email });
  };

  useCompleteSigninAfterRedirect({
    isCurrentUser: !!currentUser,
    isCurrentUserLoading: isLoading,
  });

  useEffect(() => {
    window.addEventListener("external-social-auth", handleExternalSocialAuth as EventListener);
    return () => window.removeEventListener("external-social-auth", handleExternalSocialAuth as EventListener);
  }, []);

  if (currentUser) {
    return <Navigate to="/inbox" replace />;
  }

  if (isLoading) {
    return (
      <PendingRequestBar>
        <LoadingText />
      </PendingRequestBar>
    );
  }

  const onSocialInAppAuth = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.webkit.messageHandlers.socialAuth.postMessage("google");
  };

  return (
    <div className="h-dynamic-screen w-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Login | Comms</title>
      </Helmet>

      <NewUserDialog />
      <AlertDialog />

      <h1 className="text-4xl font-bold">Login to Comms</h1>

      {isNativeIOS ? (
        <button
          className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
          onClick={async () => {
            onSocialInAppAuth();
          }}
        >
          <FcGoogle /> <span>Login with Google</span>
        </button>
      ) : (
        <button
          className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
          onClick={async () => {
            const redirectTo = attemptToGetRedirectLocation(location);

            if (searchParams.has("invitationToken")) {
              getSessionStorage().setItem("invitationToken", searchParams.get("invitationToken") ?? "");
            }

            await signinWithGoogle(environment, redirectTo);
          }}
        >
          <FcGoogle /> <span>Login with Google</span>
        </button>
      )}
    </div>
  );
};

function useCompleteSigninAfterRedirect(props: { isCurrentUser: boolean; isCurrentUserLoading: boolean }) {
  const { isCurrentUser, isCurrentUserLoading } = props;
  const environment = useClientEnvironment();
  const attemptToGetRedirectResult = !isCurrentUser && !isCurrentUserLoading;

  useEffect(() => {
    if (!attemptToGetRedirectResult) return;
    signinWithGoogleGetRedirectResult(environment);
  }, [attemptToGetRedirectResult, environment]);
}
