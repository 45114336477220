import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

/** Returns an array of organization_controlled_domain domain strings */
export type UseOrganizationControlledDomainDomainsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationControlledDomainDomains(props: {
  organizationId: string | null | undefined;
}): UseOrganizationControlledDomainDomainsResult {
  return useRecordLoader({
    load({ loader, limit, deps: [organizationId] }) {
      if (!organizationId) {
        return loader.createObserveQueryResult<"organization_controlled_domain">();
      }

      return loader.observeGetOrganizationControlledDomains({
        organization_id: organizationId,
        limit,
      });
    },
    deps: [props.organizationId],
    depsKey: String(props.organizationId),
    map: (records) => records.map((r) => r.domain),
  });
}
