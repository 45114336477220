import { switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  observeUsersGroupsWithFolderAncestorRecords,
  ObserveUserGroupsWithFolderAncestorRecordsResult,
} from "~/observables/observeUsersGroupsWithFolderAncestorRecords";
import { useLoadingObservable } from "./useLoadingObservable";

export type UseUsersGroupsWithFolderAncestorRecordsResult = ObserveUserGroupsWithFolderAncestorRecordsResult;

export function useUsersGroupsWithFolderAncestorRecords(props: {
  userId: string;
}): UseUsersGroupsWithFolderAncestorRecordsResult {
  const environment = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, props.userId],
    depsKey: String(props.userId),
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, userId]) => observeUsersGroupsWithFolderAncestorRecords(environment, { userId })),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as [],
  { isLoading: true },
]) as unknown as UseUsersGroupsWithFolderAncestorRecordsResult;
