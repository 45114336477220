import { useMemo } from "react";
import { useRecords } from "./useRecords";

export function useUserProfiles(userIds: string[]) {
  const [profiles, meta] = useRecords(userIds.map((id) => ({ table: "user_profile", id })));

  return useMemo(() => {
    return [profiles.map((p) => p.record), meta] as const;
  }, [profiles, meta]);
}
