import { Observable, combineLatest, map, of, share, switchMap } from "rxjs";
import { IRecipientOption, buildRecipientOption } from "~/components/forms/ThreadRecipients";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { MentionableUser } from "./observeMentionableUserRecords";
import { isNonNullable } from "libs/predicates";
import { observeUsersGroupsWithFolderAncestorRecords } from "./observeUsersGroupsWithFolderAncestorRecords";
import { isTagPrivate } from "libs/schema/predicates";
import { MentionableGroup } from "./observeMentionableGroupRecords";
import { ObserveOptions } from "~/environment/RecordLoader";

export function observeThreadRecipientOptions(
  environment: Pick<ClientEnvironment, "recordLoader" | "subscriptionManager" | "logger">,
  props: {
    currentUserId: string;
    threadId: string;
  },
  options?: ObserveOptions,
): Observable<IRecipientOption[]> {
  const { threadId } = props;
  const { recordLoader } = environment;

  const mentionableUsers$ = recordLoader.observeGetThreadUserParticipants({ thread_id: threadId }, options).pipe(
    switchMap(([threadUserParticipants, { isLoading: areThreadUserParticipantsLoading }]) => {
      if (threadUserParticipants.length === 0) return of([]);

      return combineLatest(
        threadUserParticipants.map((record) => {
          const getRecordOptions = {
            isLoading: areThreadUserParticipantsLoading,
            fetchStrategy: options?.fetchStrategy,
          };

          return combineLatest([
            recordLoader.observeGetRecord(
              {
                table: "user_profile",
                id: record.user_id,
              },
              getRecordOptions,
            ),
            recordLoader.observeGetRecord(
              {
                table: "user_contact_info",
                id: record.user_id,
              },
              getRecordOptions,
            ),
          ]).pipe(
            map(([[profile], [contact]]): MentionableUser | null => {
              if (!profile) return null;

              return {
                type: "user",
                id: profile.id,
                profile,
                contact,
              };
            }),
          );
        }),
      );
    }),
    map((mentionableUsers) => {
      return mentionableUsers.filter(isNonNullable);
    }),
  );

  const mentionableGroups$ = combineLatest([
    observeUsersGroupsWithFolderAncestorRecords(environment, { userId: props.currentUserId }, options),
    recordLoader.observeGetThreadGroupPermissions({ thread_id: threadId }, options),
  ]).pipe(
    map(([[groups], [groupPermissions]]) => {
      return groupPermissions.map(({ group_id }): MentionableGroup | null => {
        const result = groups.find((g) => g.group.id === group_id);

        if (!result) return null;

        return {
          type: "group",
          id: result.group.id,
          record: result.group,
          isPrivate: isTagPrivate(result.group),
          folderPaths: result.folderPaths,
        };
      });
    }),
    map((mentionableUsers) => {
      return mentionableUsers.filter(isNonNullable);
    }),
  );

  return combineLatest([
    recordLoader.observeGetRecord(
      {
        table: "thread",
        id: threadId,
      },
      options,
    ),
    mentionableUsers$,
    mentionableGroups$,
  ]).pipe(
    map(([[thread], mentionableUsers, mentionableGroups]) => {
      if (!thread || thread.type === "EMAIL_BCC") return [];

      return [
        ...mentionableGroups.map((record) => {
          return buildRecipientOption({
            threadType: thread.type as "COMMS" | "EMAIL",
            isThreadPrivate: thread.visibility === "PRIVATE",
            record,
          });
        }),
        ...mentionableUsers.map((record) => {
          return buildRecipientOption({
            threadType: thread.type as "COMMS" | "EMAIL",
            isThreadPrivate: thread.visibility === "PRIVATE",
            record,
          });
        }),
      ];
    }),
    share({ resetOnRefCountZero: true }),
  );
}
