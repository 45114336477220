import {
  ObserveCurrentUserSettingsResult,
  normalizeUserSettings,
  observeCurrentUserSettings,
} from "~/observables/observeCurrentUserSettings";
import { useLoadingObservable } from "./useLoadingObservable";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { FetchStrategy } from "~/environment/QueryCache";
import { useCurrentUserId } from "./useCurrentUserId";
import { useMemo } from "react";

export type UseCurrentUserSettingsResult = ObserveCurrentUserSettingsResult;

export function useCurrentUserSettings(
  props: {
    fetchStrategy?: FetchStrategy;
  } = {},
): UseCurrentUserSettingsResult {
  const environment = useClientEnvironment();
  const currentUserId = useCurrentUserId();

  const initialValue = useMemo((): UseCurrentUserSettingsResult => {
    if (!currentUserId) return DEFAULT_VALUE_FALSE;
    const [settings] = environment.db.getRecord("user_settings", currentUserId);
    if (settings) return normalizeUserSettings([settings, { isLoading: true }]);
    return DEFAULT_VALUE_TRUE;
  }, [currentUserId, environment]);

  return useLoadingObservable({
    initialValue: initialValue,
    deps: [currentUserId, environment, props.fetchStrategy],
    depsKey: String(currentUserId) + props.fetchStrategy,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([userId, environment, fetchStrategy]) => {
          if (!userId) return of({ settings: null, isLoading: false });

          return observeCurrentUserSettings(environment, { userId }, { fetchStrategy });
        }),
      );
    },
  });
}

const DEFAULT_VALUE_FALSE = Object.freeze({ settings: null, isLoading: false });
const DEFAULT_VALUE_TRUE = Object.freeze({ settings: null, isLoading: true });
