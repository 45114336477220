import { FetchStrategy } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";

export function useDoesThreadHaveDraft(props: { threadId: string | null | undefined; fetchStrategy?: FetchStrategy }) {
  const [hasDraft, { isLoading, error }] = useRecordLoader({
    load({ loader, currentUserId, deps: [threadId, fetchStrategy] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"draft">();
      }

      return loader.observeGetDraftsForThread(
        {
          threadId,
          currentUserId,
        },
        { fetchStrategy },
      );
    },
    deps: [props.threadId, props.fetchStrategy],
    depsKey: String(props.threadId) + props.fetchStrategy,
    map: (records) => !!records[0],
  });

  // Remove the metadata related to the "getDraftsForThread" query since "nextId" and the like
  // isn't applicable to "hasDraft".
  return [hasDraft, { isLoading, error }] as const;
}
