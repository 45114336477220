import { ComponentType } from "react";
import { LoadingText } from "~/components/LoadingText";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export const ThreadLoading: ComponentType = () => {
  const { recordLoader } = useClientEnvironment();

  if (recordLoader.options.defaultFetchStrategy.startsWith("cache")) return null;

  return (
    <PendingRequestBar>
      <LoadingText fullscreen={false} />
    </PendingRequestBar>
  );
};
