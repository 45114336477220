import Pino from "pino";

export type Logger = ReturnType<typeof createLogger>;

export function createLogger(options: Pino.LoggerOptions<"notice">, transport?: Pino.DestinationStream) {
  return Pino(
    {
      customLevels: {
        notice: 35,
      },
      formatters: {
        level: (label) => ({ level: label }),
      },
      serializers: {
        err: Pino.stdSerializers.err,
        error: Pino.stdSerializers.err,
        errors: (errors: unknown) => {
          if (!Array.isArray(errors)) return errors;

          return errors.map((error) => (error instanceof Error ? Pino.stdSerializers.err(error) : error));
        },
      },
      ...options,
    },
    transport,
  );
}
