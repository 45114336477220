import { isEqual } from "libs/predicates";
import { startWith } from "libs/rxjs-operators";
import { Observable, distinctUntilChanged, interval, map, merge, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type ObserveBrowserPushNotificationSubscriptionResult = {
  pushSubscription: PushSubscription | null;
  isLoading: boolean;
};

/**
 * Returns an observable for the browser's native PushSubscription object.
 * `null` if this client doesn't have a native push subscription.
 */
export function observeBrowserPushNotificationSubscription(
  environment: Pick<ClientEnvironment, "serviceWorker">,
  options: { isLoading?: boolean } = {},
): Observable<ObserveBrowserPushNotificationSubscriptionResult> {
  return merge(interval(10_000), environment.serviceWorker.pushNotificationSubscriptionChange).pipe(
    startWith(() => null),
    switchMap(() => environment.serviceWorker.getPushNotificationSubscription()),
    map((pushSubscription) => ({
      pushSubscription,
      isLoading: options.isLoading || false,
    })),
    startWith(() => ({ pushSubscription: null, isLoading: true })),
    distinctUntilChanged((a, b) => {
      const x = { ...a, pushSubscription: a.pushSubscription?.toJSON() };
      const y = { ...b, pushSubscription: b.pushSubscription?.toJSON() };
      return isEqual(x, y);
    }),
  );
}
