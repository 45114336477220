import { isNonNullable } from "libs/predicates";
import { RecordPointer } from "libs/schema";
import { delay, filter, firstValueFrom, from, interval, map, race, switchMap, take } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export async function waitForCacheToContainRecord(
  environment: Pick<ClientEnvironment, "db">,
  pointer: RecordPointer,
  options: {
    /**
     * Optional related promise. If provided, the promise returned
     * by this function will wait for the relatedPromise to resolve
     * before returning false.
     */
    relatedPromise?: Promise<void>;
    timeoutMs?: number;
  } = {},
) {
  const { db } = environment;
  const { relatedPromise = Promise.resolve(), timeoutMs = 100 } = options;

  return firstValueFrom(
    race(
      db.observeGetRecord(pointer).pipe(
        map(([record]) => record),
        filter(isNonNullable),
        delay(1),
        map(() => true),
      ),
      from(relatedPromise).pipe(
        switchMap(() => interval(timeoutMs)),
        map(() => false),
      ),
    ).pipe(take(1)),
  );
}
