import { registerSW } from "virtual:pwa-register";
import { BehaviorSubject, distinctUntilChanged, filter, fromEvent, map, Observable, Subject } from "rxjs";
import { PendingUpdates } from "../loading.service";
import { getCurrentUserId } from "../user.service";
import { Logger } from "libs/logger";
import { isNonNullable } from "libs/predicates";
import { Simplify } from "type-fest";
import { UnreachableCaseError } from "libs/errors";
import { oneLine } from "common-tags";
import { checkNotificationSupport } from "~/utils/dom-helpers";
import { MS_IN_MINUTE, MS_IN_SECOND } from "libs/date-helpers";
import { config } from "../config";
import { navigatorIsOnline } from "~/utils/navigatorIsOnline";

/**
 * The `ServiceWorkerService` class is responsible for managing the service worker for the app.
 */

export type ServiceWorkerServiceApi = Simplify<ServiceWorkerService>;

export class ServiceWorkerService {
  /**
   * Note that this is typed as non-nullable for ease of use, but it will be
   * undefined until the `isReady` promise resolves.
   */
  private serviceWorker!: ServiceWorker;
  /**
   * Note that this is typed as non-nullable for ease of use, but it will be
   * undefined until the `isReady` promise resolves.
   */
  private registration!: ServiceWorkerRegistration;

  logger: Logger;

  /**
   * Emits when the user has subscribed/unsubscribed to push notifications.
   * There isn't a native event for this, so we have to manually emit this event.
   */
  pushNotificationSubscriptionChange = new Subject<void>();

  private isReady: Promise<void>;

  isUpdateAvailable$ = isSwUpdateAvailable$.pipe(distinctUntilChanged());

  get isUpdateAvailable() {
    return isSwUpdateAvailable$.getValue();
  }
  set isUpdateAvailable(value: boolean) {
    isSwUpdateAvailable$.next(value);
  }

  constructor(props: { logger: Logger }) {
    this.logger = props.logger.child({ name: "ServiceWorkerService" });

    // If a service worker is installed for the first time, it will not control
    // the page until either (1) the page is reloaded or (2) we tell the service
    // worker to take control of the page. If we tell it to take control of the page,
    // then this `controllerchange` event will emit. When it does so, the
    // `this.serviceWorker` will be `undefined`. If `this.serviceWorker` is defined,
    // it's because this page was already controlled by a service worker and we've
    // just updated it.
    navigator.serviceWorker.addEventListener("controllerchange", async () => {
      const isActivationAfterInstall = !this.serviceWorker;
      if (isActivationAfterInstall) return;

      this.logger.notice(`[controllerchange] service worker was updated`);

      // If the service worker controlling the page changes after being updated,
      // we need to reload the page to ensure all in-memory resources are updated
      // to the latest version.
      await PendingUpdates.whenNoPendingUpdates({
        forcePendingUpdatesToClearAfterMs: MS_IN_SECOND * 4,
      });

      window.location.reload();
    });

    this.isReady = getServiceWorker(this.logger).then(({ serviceWorker, registration }) => {
      this.serviceWorker = serviceWorker;
      this.registration = registration;
    });
  }

  async checkForUpdate() {
    await this.isReady;
    await checkForServiceWorkerUpdate();
  }

  async checkForUpdateAndActivateWhenAvailable() {
    await this.isReady;
    await checkForServiceWorkerUpdateAndActivateWhenAvailable();
  }

  async getPushNotificationSubscription() {
    await this.isReady;
    if (!checkNotificationSupport()) return null;
    return this.registration.pushManager.getSubscription();
  }

  async subscribeToPushNotifications() {
    await this.isReady;
    if (!checkNotificationSupport()) {
      alert(oneLine`
        We're sorry, but your browser doesn't appear to support push notifications.
      `);

      return null;
    }

    const subscribe = async () => {
      const subscription = await this.registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
      });

      this.pushNotificationSubscriptionChange.next();

      return subscription;
    };

    switch (Notification.permission) {
      case "denied": {
        alert(oneLine`
          Your browser has denied push notifications for app.comms.day. You'll need to manually
          allow push notifications for app.comms.day in your browser's settings. In most browsers
          you can accomplish this by pressing the small button located *in the URL bar* immediately
          to the left of "app.comms.day". Again, the button is *in* the URL bar--you might not even 
          realize it's a button. It looks different on different browsers but in Google Chome it 
          looks like a small "i" with a circle around it.
        `);

        return null;
      }
      case "granted": {
        return subscribe();
      }
      case "default": {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") return null;
        return subscribe();
      }
      default: {
        throw new UnreachableCaseError(Notification.permission);
      }
    }
  }

  async unsubscribeFromPushNotifications() {
    await this.isReady;
    if (!checkNotificationSupport()) return true;
    const subscription = await this.getPushNotificationSubscription();
    if (!subscription) return true;
    const result = await subscription.unsubscribe();
    this.pushNotificationSubscriptionChange.next();
    return result;
  }
}

/* -----------------------------------------------------------------------------------------------*/

function getServiceWorker(logger: Logger) {
  return new Promise<{
    serviceWorker: ServiceWorker;
    registration: ServiceWorkerRegistration;
  }>((res) => {
    navigator.serviceWorker.ready.then((registration) => {
      const resolve = () =>
        res({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          serviceWorker: navigator.serviceWorker.controller!,
          registration,
        });

      // Check if the service worker is controlling the page immediately
      if (navigator.serviceWorker.controller) {
        resolve();
      } else {
        logger.debug("awaiting service worker controllerchange");

        const listener = () => {
          resolve();
          navigator.serviceWorker.removeEventListener("controllerchange", listener);
        };

        // Use the 'controllerchange' event to detect when control is claimed
        navigator.serviceWorker.addEventListener("controllerchange", listener);

        if (registration.active) {
          // This might occur if the user force refreshes the page. In this case,
          // the service worker will be ignored on page refresh even though it's already
          // installed. We need to tell the service worker to claim this tab in order
          // for it to take over.
          registration.active.postMessage({ type: "SKIP_WAITING" });
        }
      }
    });
  });
}

/**
 * This function immediately checks for a service worker update, busting the cache.
 */
export async function checkForServiceWorkerUpdate() {
  console.debug("Checking for sw update");

  const registration = await navigator?.serviceWorker?.getRegistration();

  if (!registration) return;
  if (registration.installing) return;
  if ("connection" in navigator && !navigatorIsOnline()) return;

  // From MDM:
  // The update() method of the ServiceWorkerRegistration interface attempts to update the service
  // worker. It fetches the worker's script URL, and if the new worker is not byte-by-byte identical
  // to the current worker, it installs the new worker. The fetch of the worker bypasses any browser
  // caches if the previous fetch occurred over 24 hours ago.
  await registration.update();

  return registration;
}

/* -----------------------------------------------------------------------------------------------*/

let serviceWorkerModuleUrl = "/service-worker.js";

/* -------------------------------------------------------------------------------------------------
 *  checkForServiceWorkerUpdateAndActivateWhenAvailable
 * -------------------------------------------------------------------------------------------------
 */

/**
 * This function immediately checks for a service worker update, busting the cache, and sets a flag
 * that causes the next service worker update to automatically be activated, whenever that
 * update becomes available (even if, at the time this function is called, there isn't an update
 * available).
 */
export async function checkForServiceWorkerUpdateAndActivateWhenAvailable() {
  autoActivateSwOnNextUpdateAvailableEvent = true;

  const registration = await checkForServiceWorkerUpdate();

  // If we found a new service worker while checking for an update, we need to wait for it to
  // finish installing before we can activate it.
  // For more info see https://github.com/levelshealth/comms/pull/1213
  await new Promise<void>((res) => {
    if (!registration?.installing) return res();
    registration.installing.addEventListener("statechange", () => res());
  });

  // If an update was already available, then checking for a new update won't trigger a new
  // `updateavailable` event. In this case we want to manually activate the service worker
  // update.
  await activateServiceWorkerUpdate();
}

/* -------------------------------------------------------------------------------------------------
 *  Register service worker & setup listners
 * -------------------------------------------------------------------------------------------------
 *
 * Service Worker lifecycle
 * - more info https://web.dev/articles/service-worker-lifecycle
 *
 * - install
 *   - The install event is the first event a service worker gets, and it only happens once.
 *   - A promise passed to installEvent.waitUntil() signals the duration and success or
 *     failure of your install.
 *   - A service worker won't receive events like fetch and push until it successfully
 *     finishes installing and becomes "active".
 *   - By default, a page's fetches won't go through a service worker unless the page request
 *     itself went through a service worker. So you'll need to refresh the page to see the
 *     effects of the service worker.
 *   - clients.claim() can override this default, and take control of non-controlled pages.
 *   - If you alter your service worker script the browser considers it a different service
 *     worker, and it'll get its own install event.
 * - activate
 *   - Once your service worker is ready to control clients and handle functional events
 *     like push and sync, you'll get an activate event. But that doesn't mean the page that
 *     called .register() will be controlled.
 *   - You can take control of uncontrolled clients by calling clients.claim() within your
 *     service worker once it's activated.
 * - updating
 *   - The updated service worker is launched alongside the existing one, and gets its own
 *     install event.
 *   - If your new worker has a non-ok status code (for example, 404), fails to parse, throws
 *     an error during execution, or rejects during install, the new worker is thrown away,
 *     but the current one remains active.
 *   - Once successfully installed, the updated worker will wait (status `waiting`) until the
 *     existing worker is controlling zero clients. (Note that clients overlap during a
 *     refresh.)
 *   - self.skipWaiting() prevents the waiting, meaning the service worker activates as soon
 *     as it's finished installing.
 */

// note that we're intentionally defining the service worker event handlers outside of the
// ServiceWorkerService so that they are usable even if the ClientEnvironment fails to
// initialize.
const serviceWorkerEventHandlers = {
  /**
   * As noted above, the install event is the first event a service worker gets, and it only
   * happens once per service worker.
   */
  onInstall: () => {
    console.debug("[ServiceWorker] onInstall");
    activateServiceWorkerUpdate();
  },
  /**
   * "registration" is the process of a tab "loading" a service worker script and getting a
   * handle to it. In reality, if the service worker is already installed for the domain then
   * "loading" it will not download or reinstall it, instead it just returns a handle to the
   * existing service worker. The handle is called the service worker's registration.
   */
  onRegistered: (swUrl: string, registration: ServiceWorkerRegistration | undefined) => {
    console.debug("[ServiceWorker] onRegistered", { swUrl, registration });

    if (serviceWorkerModuleUrl !== swUrl) {
      // This should only happen if we changed the name of the service worker script (which
      // I don't expect we'll ever do) and we forgot to update the serviceWorkerModuleUrl
      // variable. We'd like our referencing the sw script file to work even without this
      // handler being called, so that's something we'd like to fix. But if this mistake is
      // made in the future, here we attempt to limit the damage by setting the variable to
      // the actual service worker url.
      serviceWorkerModuleUrl = swUrl;

      if (config.production) {
        console.error(`[serviceWorkerModuleUrl] was not set correctly. Setting it to ${swUrl}`);
      } else {
        // In development the service worker script is served from the vite dev server, and the
        // url is different than in production. At the moment this is expected.
        console.log(`[serviceWorkerModuleUrl] setting service worker url to ${swUrl}`);
      }
    }

    if (checkForSwUpdateIntervalId) {
      clearInterval(checkForSwUpdateIntervalId);
    }

    checkForSwUpdateIntervalId = setInterval(checkForServiceWorkerUpdate, MS_IN_MINUTE * 5);
  },
  /**
   * Called each time a new update is available
   */
  onUpdateFound: () => {
    console.debug("[ServiceWorker] onUpdateFound");

    isSwUpdateAvailable$.next(true);

    // If the current user is logged out then we should automatically update the
    // service worker immediately.
    if (autoActivateSwOnNextUpdateAvailableEvent || !getCurrentUserId()) {
      activateServiceWorkerUpdate();
    }
  },
} as const;

let checkForSwUpdateIntervalId: ReturnType<typeof setInterval> | undefined;
let autoActivateSwOnNextUpdateAvailableEvent = false;
const isSwUpdateAvailable$ = new BehaviorSubject(false);

/* -----------------------------------------------------------------------------------------------*/

async function activateServiceWorkerUpdate() {
  await PendingUpdates.whenNoPendingUpdates({
    forcePendingUpdatesToClearAfterMs: MS_IN_SECOND * 4,
  });

  await innerActivateServiceWorkerUpdate();

  isSwUpdateAvailable$.next(false);
}

/**
 * Activates an installed service worker update and forces the service worker
 * to immediately take control of the page. Optionally pass `true` to reload
 * the page. If a service worker is being activated for the first time on the
 * page, there is no need to reload the page. If the service worker is being
 * activated and taking control of the page from a previous service worker,
 * then a page reload is necessary.
 */
// note that we're intentionally registering the service worker outside of the
// ServiceWorkerService so that it is registered even if the ClientEnvironment
// fails to initialize.
const innerActivateServiceWorkerUpdate = registerSW({
  onRegisteredSW: serviceWorkerEventHandlers.onRegistered,
  onNeedRefresh: serviceWorkerEventHandlers.onUpdateFound,
  onOfflineReady: serviceWorkerEventHandlers.onInstall,
});

/* -----------------------------------------------------------------------------------------------*/
