import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { runTransaction, withTxLogger } from "./write";
import { op } from "libs/transaction";
import { getPointer } from "libs/schema";
import { toast } from "~/environment/toast-service";
import { ApiInput } from "libs/ApiTypes";

export type InviteUsersParams = ApiInput<"inviteUsers">;

export async function inviteUsers(environment: ClientEnvironment, params: InviteUsersParams) {
  const response = await environment.api.inviteUsers(params);

  if (response.status !== 200) return;

  environment.writeRecordMap(response.body.recordMap);
}

export async function revokeUserInvitation(
  environment: ClientEnvironment,
  props: {
    id: string;
    email_address: string;
  },
) {
  const isActionConfirmed = confirm(`Are you sure you want to revoke the invitation for ${props.email_address}?`);

  if (!isActionConfirmed) return;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "revokeUserInvitation",
    tx: async (transaction) => {
      transaction.operations.push(op.delete(getPointer("organization_user_invitation", props.id)));

      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: "Failed to revoke invitation.",
          });
        } else {
          toast("vanilla", {
            subject: "Invitation revoked.",
          });
        }
      };
    },
  });
}
