import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { FetchStrategy } from "~/environment/RecordLoader";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type GetMentionableUsersResult = {
  userIds: string[];
};

export async function getMentionableUsers(
  environment: Pick<ClientEnvironment, "recordLoader">,
  options?: {
    /** "cache-then-network" is the default */
    fetchStrategy?: FetchStrategy;
  },
): Promise<GetMentionableUsersResult> {
  const currentUserId = getAndAssertCurrentUserId();

  const [currentUsersOrganizations] = await environment.recordLoader.getOrganizationUserMembers(
    {
      user_id: currentUserId,
    },
    options,
  );

  const organizationIds = currentUsersOrganizations.map((r) => r.organization_id);

  const results = await Promise.all(
    organizationIds.map((organization_id) =>
      environment.recordLoader.getOrganizationUserMembers(
        {
          organization_id,
        },
        options,
      ),
    ),
  );

  const userIds = new Set<string>();

  for (const [records] of results) {
    for (const record of records) {
      userIds.add(record.user_id);
    }
  }

  return {
    userIds: Array.from(userIds),
  };
}
