import sqlite3InitModule from "@local/sqlite-wasm-patch";
import { Logger } from "libs/logger";
import { ClientDatabaseAdapter } from "./ClientDatabaseAdapter";
import schema from "libs/schema/client/schema.sql?raw";

// The sqlite3 wasm module needs to be initialized by the browser before
// it can be used. This must only be done once. We eagerly start this
// initialization process when this module is imported.
const modulePromise = sqlite3InitModule({
  print: console.log,
  printErr: console.error,
});

export async function createInMemoryClientDatabaseAdapter(props: { logger: Logger }) {
  const sqlite3 = await modulePromise;
  const sqliteDB = new sqlite3.oo1.DB(":memory:");
  const db = new ClientDatabaseAdapter(sqliteDB, props.logger.child({ name: "InMemoryClientDatabaseAdapter" }));
  db.query({ text: schema });
  return db;
}
