import React, { ComponentType } from "react";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import {
  getCommandFactory,
  markDoneCommand,
  markNotDoneCommand,
  moreThreadActionsCommand,
  replyToThreadCommand,
  setThreadReminderCommand,
  subscribeToThreadCommand,
  unsubscribeFromThreadCommand,
} from "~/utils/common-commands";
import { MdDone, MdOutlineMoreHoriz, MdRemoveDone, MdRssFeed, MdSchedule } from "react-icons/md";
import { CgMailReply } from "react-icons/cg";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useSubscriptionText } from "./ThreadHeader";
import { navigateService } from "~/environment/navigate.service";
import { toast } from "~/environment/toast-service";
import { UnreachableCaseError } from "libs/errors";
import { SwitchCase } from "~/components/SwitchCase";
import { convertThreadViewPrevNextArgsToURL, useThreadViewPrevNextArgs } from "~/environment/thread-prev-next.service";
import { useThread } from "~/hooks/useThread";
import { useDraftIdsForThread } from "~/hooks/useDraftIdsForThread";
import { useNotification } from "~/hooks/useNotification";
import { SetOptional } from "type-fest";
import { useCurrentUserSettings } from "~/hooks/useCurrentUserSettings";
import { UserSettingsDoc } from "libs/schema";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * ActionToolbar
 * -----------------------------------------------------------------------------------------------*/

export const ActionToolbar: ComponentType<{ threadId: string }> = (props) => {
  const [thread] = useThread(props.threadId);
  const prevNextState = useThreadViewPrevNextArgs("thread", props.threadId);
  const { settings } = useCurrentUserSettings();
  const { network } = useClientEnvironment();

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [];

      if (prevNextState) {
        const prevUrl = convertThreadViewPrevNextArgsToURL(prevNextState, "previous");

        if (prevUrl) {
          commands.push(
            navigateToPreviousThreadCommand({
              callback() {
                navigateService(prevUrl, {
                  state: prevNextState.state,
                });
              },
            }),
          );
        } else {
          commands.push(
            navigateToPreviousThreadCommand({
              callback() {
                toast("vanilla", {
                  subject: "This is the first thread in the list.",
                });
              },
            }),
          );
        }

        const nextUrl = convertThreadViewPrevNextArgsToURL(prevNextState, "next");

        if (nextUrl) {
          commands.push(
            navigateToNextThreadCommand({
              callback() {
                navigateService(nextUrl, {
                  state: prevNextState.state,
                });
              },
            }),
          );
        }
      } else {
        commands.push(
          navigateToNextThreadCommand({
            callback() {
              toast("vanilla", {
                subject: "This is the last thread in the list.",
              });
            },
          }),
        );
      }

      if (showMoreThreadActions(settings)) {
        commands.push(moreThreadActionsCommand({}));

        if (settings?.enable_google_calendar) {
          commands.push({
            label: "Block time (Google Calendar)",
            path: ["More actions"],
            callback() {
              const subject = encodeURIComponent(thread?.subject || "");
              const url = new URL(`/threads/${thread?.id}`, location.href);
              const threadUrlEncoded = encodeURIComponent(url.toString());
              const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&details=${threadUrlEncoded}&location=&text=${subject}`;

              window.open(googleCalendarUrl, "_blank");
            },
          });
        }
      }

      return commands;
    },
    deps: [prevNextState],
  });

  if (!thread) return null;

  return (
    <div
      className={cx(
        // shared styles
        "fixed z-[30] bg-white",
        // narrow screen styles
        "sm-max-w:bottom-0 sm-max-w:left-0",
        network.isOnline() ? "pb-0" : "sm-max-w:pb-7",
        "sm-max-w:w-screen sm-max-w:border-t sm-max-w:border-slate-7",
        "sm-max-w:overflow-x-auto",
        // wider screen styles
        "sm-w:top-[5rem] sm-w:overflow-y-auto",
        actionToolbarWrapperCSS,
        "sm-w:ml-6 sm-w:mt-4 sm-w:rounded-lg",
        "sm-w:shadow-lg",
      )}
    >
      <div
        className={cx(
          // shared styles
          "flex w-full ",
          // narrow screen styles
          "sm-max-w:px-2 sm-max-w:py-1",
          "sm-max-w:justify-center",
          // wider screen styles
          "sm-w:flex-col sm-w:p-[1px]",
        )}
      >
        <SwitchCase deps={[thread.id]}>
          {() => {
            switch (thread.type) {
              case "COMMS": {
                return <ThreadActions threadId={props.threadId} />;
              }

              case "EMAIL":
              case "EMAIL_BCC": {
                throw new Error("Not implemented");
                // if (thread.__local.fromSecretThread) {
                //   return <SecretEmailThreadActions />;
                // }

                // return <ThreadActions />;
              }
              default: {
                throw new UnreachableCaseError(thread.type);
              }
            }
          }}
        </SwitchCase>
      </div>
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const ThreadActions: ComponentType<{ threadId: string }> = (props) => {
  const [draftIds] = useDraftIdsForThread(props);
  const [notification] = useNotification({ threadId: props.threadId });
  const prevNextState = useThreadViewPrevNextArgs("thread", props.threadId);
  const { badgeText } = useSubscriptionText(props.threadId);
  const { settings } = useCurrentUserSettings();

  const hasDraft = draftIds.length > 0;

  return (
    <>
      {badgeText === "Everything" ? (
        <Action
          label="Unsubscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            unsubscribeFromThreadCommand.trigger();
          }}
        >
          <MdRssFeed className={cx("sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10", actionBtnIconCSS)} />
        </Action>
      ) : badgeText === "Mentions" ? (
        <Action
          label="Subscribe"
          shortcut="(S)"
          onClick={(e) => {
            e.preventDefault();
            subscribeToThreadCommand.trigger();
          }}
        >
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      ) : (
        <Action label="Loading..." onClick={() => {}}>
          <MdRssFeed className={actionBtnIconCSS} />
        </Action>
      )}

      {notification?.is_done ? (
        <Action
          label="Mark not done"
          shortcut="(Shift+E)"
          onClick={(e) => {
            e.preventDefault();
            markNotDoneCommand.trigger();
          }}
        >
          <MdRemoveDone className={actionBtnIconCSS} />
        </Action>
      ) : (
        <Action
          label="Mark done"
          shortcut="(E)"
          onClick={(e) => {
            e.preventDefault();
            markDoneCommand.trigger();
          }}
        >
          <MdDone className={actionBtnIconCSS} />
        </Action>
      )}

      <Action
        label="Set reminder"
        shortcut="(H)"
        onClick={(e) => {
          e.preventDefault();
          setThreadReminderCommand.trigger();
        }}
      >
        <MdSchedule
          className={cx(
            actionBtnIconCSS,
            notification?.has_reminder && "sm-max-w:text-plum-8 sm-max-w:group-hover:text-plum-10",
          )}
        />
      </Action>

      <Action
        label="Reply"
        shortcut="(R)"
        onClick={(e) => {
          e.preventDefault();
          replyToThreadCommand.trigger();
        }}
      >
        <CgMailReply
          className={cx(hasDraft ? "sm-max-w:text-green-8 sm-max-w:group-hover:text-green-10" : actionBtnIconCSS)}
        />
      </Action>

      {prevNextState?.previousEntry && (
        <Action
          label="Previous"
          shortcut="(K)"
          onClick={() => {
            navigateToPreviousThreadCommand.trigger();
          }}
        >
          <FiChevronUp className={actionBtnIconCSS} />
        </Action>
      )}

      {prevNextState?.nextEntry && (
        <Action
          label="Next"
          shortcut="(J)"
          onClick={() => {
            navigateToNextThreadCommand.trigger();
          }}
        >
          <FiChevronDown className={actionBtnIconCSS} />
        </Action>
      )}

      {showMoreThreadActions(settings) && (
        <Action
          label="More"
          shortcut="(M)"
          onClick={() => {
            moreThreadActionsCommand.trigger();
          }}
        >
          <MdOutlineMoreHoriz className={actionBtnIconCSS} />
        </Action>
      )}
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const Action: ComponentType<{
  label: string;
  shortcut?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = (props) => {
  const tooltip = `${props.label} ${props.shortcut || ""}`.trim();

  return (
    <Tooltip side="right" content={tooltip}>
      <button
        type="button"
        tabIndex={-1}
        className={cx("flex flex-1 items-center px-[0.65rem] h-[60px] rounded-lg", "hover:bg-slate-3 group")}
        onClick={props.onClick}
      >
        <span className={cx("inline-flex items-center justify-center w-10 h-10 text-3xl lg-w:mr-2")}>
          {props.children}
        </span>
        <span className="shrink-0 lg-max-w:hidden">{props.label}</span>
      </button>
    </Tooltip>
  );
};

/* -----------------------------------------------------------------------------------------------*/

const actionToolbarWrapperCSS = css`
  @media (min-width: 580px) {
    max-height: calc(100dvh - 8rem);
  }

  @media print {
    display: none !important;
  }
`;

const actionBtnIconCSS = "text-slate-10 group-hover:text-black";

/* -----------------------------------------------------------------------------------------------*/

const navigateToPreviousThreadCommand = getCommandFactory(
  "NAVIGATE_TO_PREVIOUS_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Previous thread",
    hotkeys: ["k"],
    ...options,
  }),
);

const navigateToNextThreadCommand = getCommandFactory(
  "NAVIGATE_TO_NEXT_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Next thread",
    hotkeys: ["j"],
    ...options,
  }),
);

/**
 * Returns true if we should include more thread actions (button and kbar options)
 */
function showMoreThreadActions(settings: UserSettingsDoc | null) {
  return settings && settings.enable_google_calendar;
}
