import { RefObject, useEffect, useMemo, useState } from "react";
import { unionBy } from "lodash-comms";
import { createFormControl, createFormGroup, IFormControl, IFormGroup, useControl } from "solid-forms-react";
import { observable, useControlState } from "~/components/forms/utils";
import { combineLatest, delay, firstValueFrom, from, map, Observable, pairwise, skip, switchMap, take } from "rxjs";
import { IEmailRecipientOption, IRecipientOption, ThreadRecipients } from "~/components/forms/ThreadRecipients";
import { startWith } from "libs/rxjs-operators";
import { oneLine } from "common-tags";
import { IComposeMessageForm } from "~/components/ComposeMessageContext";
import { useAddNewGroupMentionsAsRecipients, useAddNewUserMentionsAsRecipients } from "~/components/ComposeReplyBase";
import { getLastMessageEntry } from "../utils";
import { cx } from "@emotion/css";
import { MessageType, ThreadVisibility } from "libs/schema";
import { IListRef } from "~/components/list";
import { observeThreadRecipientOptions } from "~/observables/observeThreadRecipientOptions";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

/* -------------------------------------------------------------------------------------------------
 * ReplyDraftHeader
 * -----------------------------------------------------------------------------------------------*/

type THeaderRecipientsControl = IFormGroup<{
  to: IFormControl<IRecipientOption[]>;
  cc: IFormControl<IRecipientOption[]>;
  bcc: IFormControl<IEmailRecipientOption[]>;
}>;

export type IThreadRecipients = {
  to: IRecipientOption[];
  cc: IRecipientOption[];
  bcc: IEmailRecipientOption[];
};

export function ReplyDraftHeader(props: {
  threadId: string;
  threadType: MessageType;
  threadVisibility: ThreadVisibility;
  control: IComposeMessageForm;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  isEditingExistingMessage?: boolean;
}) {
  if (props.threadType === "EMAIL_BCC") {
    throw new Error("ReplyDraftHeader doesn't support EMAIL_BCC");
  }

  const headerRecipientsControl = useControl(() =>
    createFormGroup({
      to: createFormControl<IRecipientOption[]>([]),
      cc: createFormControl<IRecipientOption[]>([]),
      bcc: createFormControl<IEmailRecipientOption[]>([]),
    }),
  );

  const threadRecipients$ = useThreadRecipientsObservable(props.threadId);

  const isSyncInitialized = useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl({
    threadId: props.threadId,
    listRef: props.listRef,
    draftControl: props.control,
    headerRecipientsControl,
    isEditingExistingMessage: !!props.isEditingExistingMessage,
    threadRecipients$,
  });

  usePushDraftControlRecipientUpdatesToHeaderControl({
    threadId: props.threadId,
    draftControl: props.control,
    headerRecipientsControl,
    isSyncInitialized,
    threadRecipients$,
  });

  useAddNewGroupMentionsAsRecipients(
    props.control.controls.body.controls.groupMentions,
    headerRecipientsControl.controls.to,
    isSyncInitialized,
  );

  useAddNewUserMentionsAsRecipients(
    props.control.controls.body.controls.userMentions,
    headerRecipientsControl.controls.to,
    isSyncInitialized,
  );

  const isToInvalid = useControlState(() => !props.control.controls.recipients.controls.to.isValid, [props.control]);

  const isToTouched = useControlState(() => props.control.controls.recipients.controls.to.isTouched, [props.control]);

  const isPrivate = props.threadVisibility === "PRIVATE";

  return (
    <div className="flex flex-col mx-4 py-4 sm-w:mx-8 pb-0">
      <div className="PostSender flex pb-4">
        <strong>
          <span className="text-green-9">{props.isEditingExistingMessage ? "Editing message" : "Draft"}</span>
        </strong>
      </div>

      <div className="flex items-baseline pb-2 border-b border-slate-6">
        <label htmlFor="to" className={cx("mr-2 font-medium", isToTouched && isToInvalid && "text-red-9")}>
          To
        </label>

        <ThreadRecipients
          name="to"
          control={headerRecipientsControl.controls.to}
          threadType={props.threadType}
          isThreadPrivate={isPrivate}
          wrapperClassName="flex-1"
        />
      </div>

      {props.threadType === "EMAIL" && (
        <div className="flex items-baseline py-2 border-b border-slate-6">
          <label htmlFor="cc" className={cx("mr-2 font-medium", isToTouched && isToInvalid && "text-red-9")}>
            Cc
          </label>

          <ThreadRecipients
            name="cc"
            control={headerRecipientsControl.controls.cc}
            threadType={props.threadType}
            isThreadPrivate={isPrivate}
            wrapperClassName="flex-1"
          />
        </div>
      )}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl
 * -----------------------------------------------------------------------------------------------*/

function useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl(args: {
  threadId: string;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  isEditingExistingMessage: boolean;
  draftControl: IComposeMessageForm;
  headerRecipientsControl: THeaderRecipientsControl;
  threadRecipients$: Observable<{
    to: IRecipientOption[];
    cc: IRecipientOption[];
    bcc: IEmailRecipientOption[];
  }>;
}) {
  const { threadId, listRef, draftControl, isEditingExistingMessage, headerRecipientsControl, threadRecipients$ } =
    args;

  // We want to track is this hook has been initialized or not because the
  // process of initialization can cause a race condition to overwrite any
  // others values which are being written to the headerRecipientsControl
  // at the same time (e.g. initialization can overwrite changes made by
  // "useAddNewGroupMentionsAsRecipients" and "useAddNewUserMentionsAsRecipients"
  // hooks if those hooks make changes during initialization).
  const [isInitialized, setIsInitialized] = useState(false);

  const environment = useClientEnvironment();

  useEffect(() => {
    const initializeHeaderRecipientsControlPromise = (async () => {
      const threadRecipients = await firstValueFrom(threadRecipients$);

      headerRecipientsControl.setValue({
        to: unionBy(threadRecipients.to, draftControl.rawValue.recipients.to, (recipient) => recipient.value),
        cc: [],
        bcc: [],
      });
    })();

    const obs = from(initializeHeaderRecipientsControlPromise).pipe(
      switchMap(() =>
        combineLatest([
          threadRecipients$,
          observable(() => draftControl.rawValue.visibility),
          observable(() => headerRecipientsControl.rawValue).pipe(
            // we start with the current value because pairwise() needs two
            // values to be emitted for it to start emitting values itself
            startWith(() => headerRecipientsControl.rawValue),
            pairwise(),
          ),
        ]),
      ),
    );

    const sub = obs.subscribe(
      ([
        currentThreadRecipients,
        visibility,
        // Note that if currentThreadRecipients emits multiple times in a row,
        // then previousHeaderRecipients and currentHeaderRecipients will
        // be the same for each of those emissions (i.e. previousHeaderRecipients
        // won't be equal to the value of header recipients the last time this
        // function was run).
        [previousHeaderRecipients, currentHeaderRecipients],
      ]) => {
        const shouldWeBranch = shouldWeCreateNewBranchedThread({
          currentThreadRecipients,
          previousHeaderRecipients,
          currentHeaderRecipients,
          isEditingExistingMessage: isEditingExistingMessage,
        });

        if (shouldWeBranch) {
          const lastPostEntry = getLastMessageEntry(listRef.current?.entries);

          if (lastPostEntry) {
            alert(`Should convert draft to new branched thread. Not implemented`);
            // convertCurrentDraftToNewBranchedThreadDraft({
            //   recipients: currentHeaderRecipients,
            //   lastPost: lastPostEntry,
            //   control: draftControl,
            // });

            return;
          }
        }

        const newDraftRecipients = {
          to: [] as IRecipientOption[],
          cc: [] as IRecipientOption[],
          bcc: [] as IEmailRecipientOption[],
        };

        const isPrivate = visibility === "PRIVATE";

        currentHeaderRecipients.to.forEach((option) => {
          if (isPrivate && option.type === "group" && !option.isPrivate) {
            return;
          } else if (!isPrivate && option.type === "group" && option.isPrivate) {
            return;
          }

          const isRecipientAThreadRecipient = currentThreadRecipients.to.some((r) => r.value === option.value);

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.to.push({ ...option, emphasize: true });
        });

        currentHeaderRecipients.cc.forEach((option) => {
          const isRecipientAThreadRecipient = currentThreadRecipients.cc.some((r) => r.value === option.value);

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.cc.push({ ...option, emphasize: true });
        });

        currentHeaderRecipients.bcc.forEach((option) => {
          const isRecipientAThreadRecipient = currentThreadRecipients.bcc.some((r) => r.value === option.value);

          if (isRecipientAThreadRecipient) return;

          newDraftRecipients.bcc.push({ ...option, emphasize: true });
        });

        draftControl.patchValue({
          recipients: newDraftRecipients,
        });

        headerRecipientsControl.setValue({
          to: [...currentThreadRecipients.to, ...newDraftRecipients.to],
          cc: [...currentThreadRecipients.cc, ...newDraftRecipients.cc],
          bcc: [...currentThreadRecipients.bcc, ...newDraftRecipients.bcc],
        });
      },
    );

    sub.add(
      obs
        .pipe(
          take(1),
          // I'm not sure why, but setting this value synchronously causes
          //  a bug where adding a new user mention to
          // a message doesn't update the reply draft header. In this scenerio,
          // `observable(() => props.control.controls.body.controls.userMentions)`
          // doesn't emit changes.
          delay(0),
        )
        .subscribe(() => setIsInitialized(true)),
    );

    return () => sub.unsubscribe();
  }, [threadId, draftControl, listRef, isEditingExistingMessage, headerRecipientsControl, threadRecipients$]);

  return isInitialized;
}

// /* -------------------------------------------------------------------------------------------------
//  * observeThreadRecipients
//  * -----------------------------------------------------------------------------------------------*/

// export function observeThreadRecipients(
//   threadId: string,
// ): Observable<IThreadRecipients> {
//   const thread$ = observeThread(threadId).pipe(
//     map((thread) => thread.record),
//     filter(isNonNullable),
//     shareReplay({ refCount: true, bufferSize: 1 }),
//   );

//   const threadProps$ = thread$.pipe(
//     map((thread) =>
//       pick(
//         thread,
//         "id",
//         "type",
//         "lastPost",
//         "permittedChannelIds",
//         "participatingUserIds",
//       ),
//     ),
//     distinctUntilChanged(isEqual),
//     shareReplay({ refCount: true, bufferSize: 1 }),
//   );

//   const recipientOptions$ = combineLatest([
//     thread$.pipe(
//       map((t) => pick(t, "visibility", "type")),
//       distinctUntilChanged(),
//     ),
//     from(import("~/components/forms/ThreadRecipients")),
//   ]).pipe(
//     switchMap(([{ visibility, type }, { observeRecipientOptions }]) =>
//       observeRecipientOptions({
//         threadType: type as "COMMS" | "EMAIL",
//         isThreadPrivate: visibility === "PRIVATE",
//       }),
//     ),
//   );

//   const sharedChannelRecipientOptions$ = USER_ORG_SHARED_CHANNELS$.pipe(
//     map((sharedChannels) =>
//       sharedChannels.map<IGroupRecipientOption>((c) => ({
//         type: "channel",
//         value: c.id,
//         label: c.name,
//         classification: c.classification,
//         channelGroupNames: c.__local.knownChannelGroups.map((g) => g.name),
//         sharedChannel: {
//           organizationName:
//             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//             c.__local.knownChannelGroups[0]!.organizationName,
//           showWalkthrough: false,
//         },
//         isFixed: true,
//       })),
//     ),
//   );

//   const groupedSubscriptions$ = threadProps$.pipe(
//     switchMap((thread) =>
//       observeUsersWhoAreSubscribedToThread({
//         thread,
//         onlyCountTheseSubscriptionPreferences: ["all"],
//         dontIncludeCurrentUser: true,
//       }),
//     ),
//   );

//   const currentUserLowercaseEmail$ = ASSERT_CURRENT_USER$.pipe(
//     map((u) => u.),
//     distinctUntilChanged(),
//   );

//   return combineLatest([
//     currentUserLowercaseEmail$,
//     threadProps$,
//     recipientOptions$,
//     groupedSubscriptions$,
//     sharedChannelRecipientOptions$,
//   ]).pipe(
//     map(
//       ([
//         currentUserLowercaseEmail,
//         thread,
//         recipientOptions,
//         groupedSubscriptions,
//         sharedChannelRecipientOptions,
//       ]) => {
//         switch (thread.lastPost.type) {
//           case "COMMS": {
//             const channelRecipientOptions = thread.permittedChannelIds
//               .map((id) => {
//                 return (
//                   recipientOptions.find((o) => o.value === id) ||
//                   sharedChannelRecipientOptions.find((o) => o.value === id)
//                 );
//               })
//               .filter(isNonNullable);

//             const userRecipientOptions = groupedSubscriptions.knownSubscribers
//               .map(({ id }) =>
//                 recipientOptions.find((option) => option.value === id),
//               )
//               .filter(isNonNullable);

//             return {
//               to: [...channelRecipientOptions, ...userRecipientOptions],
//               cc: [],
//               bcc: [],
//             };
//           }
//           case "EMAIL": {
//             const channelRecipientOptions = thread.permittedChannelIds
//               .map((id) => {
//                 return (
//                   recipientOptions.find((o) => o.value === id) ||
//                   sharedChannelRecipientOptions.find((o) => o.value === id)
//                 );
//               })
//               .filter(isNonNullable);

//             const mapEmailToRecipientOption = (
//               email: EmailAddress,
//             ): IEmailRecipientOption => {
//               if (email.addresses) {
//                 throw new Error("observeThreadRecipients unexpected email");
//               }

//               const option = recipientOptions.find(
//                 (option): option is IUserRecipientOption =>
//                   option.type === "user" && option.email === email.address,
//               );

//               if (option) {
//                 return {
//                   type: "email",
//                   label: option.label,
//                   email: option.email,
//                   value: option.email,
//                 };
//               }

//               return {
//                 type: "email",
//                 label: email.label || email.address,
//                 email: email.address,
//                 value: email.address,
//               };
//             };

//             const to: IRecipientOption[] = channelRecipientOptions;
//             const cc: IRecipientOption[] = [];

//             if (thread.lastPost) {
//               const r = getEmailReplyRecipientsFromLastPost(
//                 thread.lastPost,
//                 currentUserLowercaseEmail,
//               );

//               to.push(...r.to.map(mapEmailToRecipientOption));
//               cc.push(...r.cc.map(mapEmailToRecipientOption));
//             }

//             return {
//               to,
//               cc,
//               bcc: [],
//             };
//           }
//           default: {
//             throw new UnreachableCaseError(thread.lastPost);
//           }
//         }
//       },
//     ),
//     catchNoCurrentUserError(
//       (): IThreadRecipients => ({
//         to: [],
//         cc: [],
//         bcc: [],
//       }),
//     ),
//     shareReplay({ refCount: true, bufferSize: 1 }),
//   );
// }

/* -----------------------------------------------------------------------------------------------*/

function shouldWeCreateNewBranchedThread(args: {
  currentThreadRecipients: IThreadRecipients;
  previousHeaderRecipients: IThreadRecipients;
  currentHeaderRecipients: IThreadRecipients;
  isEditingExistingMessage: boolean;
}) {
  const allPreviousRecipients = [
    ...args.previousHeaderRecipients.to,
    ...args.previousHeaderRecipients.cc,
    ...args.previousHeaderRecipients.bcc,
  ];

  const allCurrentRecipients = [
    ...args.currentHeaderRecipients.to,
    ...args.currentHeaderRecipients.cc,
    ...args.currentHeaderRecipients.bcc,
  ];

  const wasRecipientRemoved = allPreviousRecipients.length > allCurrentRecipients.length;

  if (!wasRecipientRemoved) return false;

  const removedRecipient = allPreviousRecipients.find(
    (prev) => !allCurrentRecipients.some((curr) => curr.value === prev.value),
  );

  const allThreadRecipients = [
    ...args.currentThreadRecipients.to,
    ...args.currentThreadRecipients.cc,
    ...args.currentThreadRecipients.bcc,
  ];

  const attemptedToRemoveThreadRecipient =
    removedRecipient && allThreadRecipients.some((r) => r.value === removedRecipient.value);

  if (!attemptedToRemoveThreadRecipient) return false;

  if (args.isEditingExistingMessage) {
    alert(oneLine`
      You can't remove thread participants when editing a sent message
      (though you can add new participants when editing a message).
    `);

    return false;
  }

  const shouldBranchThread = confirm(oneLine`
    You are attempting to remove a current participant in this thread.
    All responses in a thread must go to all participants, but you can
    create a new thread branching off of this one with different 
    participants. Would you like to do this now?
  `);

  return shouldBranchThread;
}

/* -------------------------------------------------------------------------------------------------
 * convertCurrentDraftToNewBranchedThreadDraft
 * -----------------------------------------------------------------------------------------------*/

// export async function convertCurrentDraftToNewBranchedThreadDraft(args: {
//   recipients: IComposeMessageFormValue["recipients"];
//   lastPost: IPostDoc;
//   control: IComposeMessageForm;
// }) {
//   const { recipients, lastPost, control } = args;

//   if (lastPost.type === "EMAIL_SECRET") {
//     throw new Error(
//       "convertCurrentDraftToNewBranchedThreadDraft: not supported",
//     );
//   }

//   const currentUser = getAndAssertCurrentUser();
//   const newDraftId = uid();
//   const draftForm = control.rawValue;

//   const createNewBranchedDraftPromise = createNewDraft({
//     type: lastPost.type,
//     postId: newDraftId,
//     to: recipients.to.map((r) =>
//       mapRecipientOptionToDraftRecipient(r, lastPost.type),
//     ),
//     cc: recipients.cc.map((r) =>
//       mapRecipientOptionToDraftRecipient(r, lastPost.type),
//     ),
//     bcc: recipients.bcc.map((r) =>
//       mapRecipientOptionToDraftRecipient(r, lastPost.type),
//     ),
//     subject: `Branch: ${lastPost.subject}`,
//     bodyHTML: draftForm.body.content,
//     channelMentions: draftForm.body.channelMentions,
//     userMentions: draftForm.body.userMentions,
//     visibility: draftForm.visibility,
//     branchedFrom: {
//       threadId: lastPost.threadId,
//       postId: lastPost.id,
//       postSentAt: lastPost.sentAt,
//       postScheduledToBeSentAt: lastPost.scheduledToBeSentAt,
//     },
//   });

//   await withPendingRequestBar(
//     waitForCacheToContainDoc(
//       docRef("users", currentUser.id, "unsafeDrafts", newDraftId),
//       createNewBranchedDraftPromise,
//     ),
//   );

//   // When the compose new thread dialog is closed we want to refocus the
//   // correct post.
//   updateSearchParams((searchParams) => searchParams.set("post", lastPost.id), {
//     replace: true,
//   });

//   openComposeNewThreadDialog(newDraftId);

//   deleteDraft({ postId: draftForm.postId });
// }

/* -------------------------------------------------------------------------------------------------
 * usePushDraftControlRecipientUpdatesToHeaderControl
 * -----------------------------------------------------------------------------------------------*/

function usePushDraftControlRecipientUpdatesToHeaderControl(args: {
  threadId: string;
  draftControl: IComposeMessageForm;
  headerRecipientsControl: THeaderRecipientsControl;
  threadRecipients$: Observable<IThreadRecipients>;
  isSyncInitialized: boolean;
}) {
  const { threadId, draftControl, headerRecipientsControl, threadRecipients$, isSyncInitialized } = args;

  useEffect(() => {
    if (!isSyncInitialized) return;

    const sub = combineLatest([threadRecipients$, observable(() => draftControl.rawValue.recipients)])
      .pipe(
        // We initialize the headerRecipientsControl in the
        // useSyncThreadRecipientsToHeaderControlAndPushHeaderUpdatesToDraftControl
        // so doing so again here would be redundant.
        skip(1),
      )
      .subscribe(([threadRecipients, additionalDraftRecipients]) => {
        headerRecipientsControl.setValue({
          to: unionBy(threadRecipients.to, additionalDraftRecipients.to, (recipient) => recipient.value),
          cc: unionBy(threadRecipients.cc, additionalDraftRecipients.cc, (recipient) => recipient.value),
          bcc: unionBy(threadRecipients.bcc, additionalDraftRecipients.bcc, (recipient) => recipient.value),
        });
      });

    return () => sub.unsubscribe();
  }, [threadId, draftControl, headerRecipientsControl, threadRecipients$, isSyncInitialized]);
}

/* -------------------------------------------------------------------------------------------------
 * useThreadRecipientsObservable
 * -----------------------------------------------------------------------------------------------*/

function useThreadRecipientsObservable(threadId: string) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useMemo(() => {
    return observeThreadRecipientOptions(environment, {
      threadId,
      currentUserId,
    }).pipe(
      map((options) => {
        return {
          to: options,
          cc: [] as IRecipientOption[],
          bcc: [] as IEmailRecipientOption[],
        };
      }),
    );
  }, [threadId, currentUserId, environment]);
}
