import { useRecordLoader } from "./useRecordLoader";
import { CURRENT_USER_ID$ } from "~/environment/user.service";
import { switchMap } from "rxjs";

export function useReminderNotifications() {
  return useRecordLoader({
    load({ loader, limit }) {
      return CURRENT_USER_ID$.pipe(
        switchMap((userId) => {
          if (!userId) {
            return loader.createObserveQueryResult<"notification">();
          }

          return loader.observeGetNotifications({
            user_id: userId,
            has_reminder: true,
            orderBy: "remind_at",
            limit,
          });
        }),
      );
    },
    initialLimit: 50,
    limitStep: 50,
  });
}
