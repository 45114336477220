import { FetchStrategy } from "~/environment/RecordLoader";
import { useRecordLoader } from "./useRecordLoader";

export function useThreadPermittedGroupIds(
  props: { threadId: string | null | undefined },
  options: { fetchStrategy?: FetchStrategy } = {},
) {
  return useRecordLoader({
    load({ loader, limit, deps: [threadId, fetchStrategy] }) {
      if (!threadId) {
        return loader.createObserveQueryResult<"thread_group_permission">();
      }

      return loader.observeGetThreadGroupPermissions({ thread_id: threadId, limit }, { fetchStrategy });
    },
    deps: [props.threadId, options.fetchStrategy],
    depsKey: `${props.threadId}` + options.fetchStrategy,
    map: (records) => records.map((r) => r.group_id),
  });
}
