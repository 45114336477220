import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { toast } from "~/environment/toast-service";
import { useOutboxMessagesIds } from "~/state/outbox.state";
import { pluralize } from "~/utils/string-utils";

/**
 * This component shows a toast when there are undelivered messages.
 */
export default function SendingMessageToast() {
  const environment = useClientEnvironment();
  const navigate = useNavigate();
  const outboxMessagesIds = useOutboxMessagesIds();
  const removeToast = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (removeToast.current) {
      removeToast.current();
      removeToast.current = null;
    }

    if (outboxMessagesIds.length > 0) {
      if (!environment.network.isOnline()) {
        // if offline, we show the pending messages in the OfflineBanner instead
        return;
      }

      const r = toast("vanilla", {
        subject: `Sending ${outboxMessagesIds.length} ${pluralize("message", outboxMessagesIds.length)} to server ...`,
        durationMs: Infinity,
        dontDismissOnHover: true,
        onDismiss: () => {},
        onAction: () => navigate("/sent"),
        Action: () => <button className="text-sm">View</button>,
      });
      removeToast.current = r;
    }
  }, [outboxMessagesIds, removeToast, navigate, environment.network]);

  return null;
}
