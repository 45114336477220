import { useObservableEagerState } from "observable-hooks";
import { CURRENT_USER_ID$ } from "~/environment/user.service";

/**
 * **Important!**
 *
 * Most components should get the currentUserId from the AuthGuardContext
 * via useAuthGuardContext. Only use this hook if outside the
 * AuthGuardContext (rare).
 */
export function useCurrentUserId() {
  return useObservableEagerState(CURRENT_USER_ID$);
}
