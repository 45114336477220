import { ComponentType, useEffect, useState } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { createFormControl, useControl } from "solid-forms-react";
import { useControlState } from "~/components/forms/utils";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { combineLatest, interval, map, of, scan, switchMap, takeWhile } from "rxjs";
import { TextInput } from "~/components/forms/TextInput";
import { WINDOW_FOCUSED$, WINDOW_VISIBLE$ } from "~/environment/focus.service";
import { cx } from "@emotion/css";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { closeDialogCommand } from "~/utils/common-commands";
import { Link } from "react-router-dom";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { updateScheduledDelivery } from "~/actions/updateUserSettings";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeCurrentUserSettings } from "~/observables/observeCurrentUserSettings";
import { NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { getCurrentUserSettings } from "~/queries/getCurrentUserSettings";

export type IToggleScheduledDeliveryDialogData = { settings: NormalizedUserSettingsDoc } | undefined;

export type IToggleScheduledDeliveryDialogReturnData = {
  success: boolean;
} | void;

export const ToggleScheduledDeliveryDialogState = new DialogState<
  IToggleScheduledDeliveryDialogData,
  IToggleScheduledDeliveryDialogReturnData
>();

export const ToggleScheduledDeliveryDialog = withModalDialog({
  dialogState: ToggleScheduledDeliveryDialogState,
  useOnDialogContainerRendered() {
    const environment = useClientEnvironment();
    const { currentUserId } = useAuthGuardContext();

    useRegisterCommands({
      commands: () => {
        return observeCurrentUserSettings(environment, {
          userId: currentUserId,
        }).pipe(
          map(({ settings }) => {
            const commands: ICommandArgs[] = [];

            if (!settings) {
              return commands;
            }

            if (settings.enable_scheduled_delivery) {
              const callback = settings.seconds_to_wait_to_disable_scheduled_delivery
                ? () => ToggleScheduledDeliveryDialogState.open()
                : () => updateScheduledDelivery(environment, false);

              commands.push({
                label: "Disable scheduled delivery",
                keywords: ["Enable scheduled delivery"],
                altLabels: ["Toggle scheduled delivery"],
                callback,
              });
            } else {
              commands.push({
                label: "Enable scheduled delivery",
                keywords: ["Disable scheduled delivery"],
                altLabels: ["Toggle scheduled delivery"],
                callback: () => {
                  updateScheduledDelivery(environment, true);
                },
              });
            }

            return commands;
          }),
        );
      },
      deps: [currentUserId, environment],
    });
  },
  async loadData({ environment, data }) {
    if (data?.settings) return data;

    const { settings } = await getCurrentUserSettings(environment);

    return {
      settings,
    };
  },
  Component({ data }) {
    if (!data) {
      throw new Error("Missing required data");
    }

    const { settings } = data;

    if (!settings) {
      alert(`Could not find user settings data. Are you connected to the internet?`);

      ToggleScheduledDeliveryDialogState.close();
      return null;
    }

    const [currentStep, setCurrentStep] = useState(1);
    const [submitAttempt, setSubmitAttempt] = useState(false);

    useRegisterCommands({
      commands: () => {
        return [
          closeDialogCommand({
            callback: () => {
              ToggleScheduledDeliveryDialogState.close();
            },
          }),
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Disable Scheduled Delivery</h2>
        </DialogTitle>

        <form
          // we intentionally don't allow submitting this form
          // with the keyboard
          onSubmit={(e) => {
            e.preventDefault();
            setSubmitAttempt(true);
          }}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          {currentStep === 2 ? (
            <SecondStep initialTimerValue={settings.seconds_to_wait_to_disable_scheduled_delivery} />
          ) : (
            <FirstStep submitAttempt={submitAttempt} onClick={() => setCurrentStep(2)} />
          )}
        </form>
      </>
    );
  },
});

const FirstStep: ComponentType<{
  submitAttempt: boolean;
  onClick: () => void;
}> = (props) => {
  const control = useControl(() => {
    return createFormControl("", {
      validators: (value) => (value.toLowerCase() === firstStepPassword.toLowerCase() ? null : { invalid: true }),
    });
  });

  useEffect(() => {
    if (!props.submitAttempt) return;
    control.markTouched(true);
  }, [props.submitAttempt, control]);

  const hasErrors = useControlState(() => !!control.errors, [control]);

  return (
    <>
      <div className="p-4">
        <p>
          Are you sure you wish to disable scheduled delivery? After doing this, messages will show up in your inbox
          immediately.
        </p>

        <div className="h-4" />

        <p>Type "{firstStepPassword}" then press continue.</p>

        <TextInput name="Type here" control={control} className="mt-4 border border-slate-9 w-full rounded px-2 py-1" />

        {props.submitAttempt && <p className="mt-4 font-bold">You must use your mouse to click "Continue".</p>}
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => ToggleScheduledDeliveryDialogState.close()}
        >
          Cancel
        </button>

        <div className="flex-1" />

        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          disabled={import.meta.env.VITE_FIREBASE_EMULATORS === "true" ? false : hasErrors}
          onClick={props.onClick}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const firstStepPassword = "I would like to disable scheduled delivery";

const SecondStep: ComponentType<{ initialTimerValue: number }> = (props) => {
  const environment = useClientEnvironment();
  const [countdown, setCountdown] = useState<number | "cancelled">(props.initialTimerValue);

  useEffect(() => {
    const sub = combineLatest([WINDOW_VISIBLE$, WINDOW_FOCUSED$])
      .pipe(
        switchMap(([visible, focused]) =>
          !visible || !focused
            ? of("cancelled" as const)
            : interval(1000).pipe(
                scan((acc) => (acc <= 0 ? 0 : acc - 1), props.initialTimerValue),
                takeWhile((value) => value !== 0, true),
              ),
        ),
      )
      .subscribe(setCountdown);

    return () => sub.unsubscribe();
  }, [props.initialTimerValue]);

  return (
    <>
      <div className="p-4">
        <div className="prose">
          <p>
            Please wait for the countdown to finish then press "Disable scheduled delivery", below. You must keep this
            window active or else the timer will reset. Note that you can change the duration of this countdown on the{" "}
            <Link to="/settings">settings page</Link>.
          </p>
        </div>

        <div className="h-4" />

        <button
          type="button"
          disabled={import.meta.env.VITE_FIREBASE_EMULATORS === "true" ? false : countdown !== 0}
          className={cx(
            "text-3xl font-bold text-slate-11 bg-slate-3 border",
            "px-4 py-1 rounded disabled:text-slate-8 active:bg-slate-5",
          )}
          onClick={() => submit(environment)}
        >
          {countdown === "cancelled"
            ? "Cancelled"
            : countdown === 0
              ? `Disable scheduled delivery`
              : `Disable scheduled delivery in ${countdown}s`}
        </button>
      </div>

      <div className="p-4 border-t flex items-center">
        <button
          type="button"
          className={`rounded bg-slate-5 px-2 border 
            border-slate-9 text-sm hover:border-black hover:bg-slate-7`}
          onClick={() => ToggleScheduledDeliveryDialogState.close()}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (environment: ClientEnvironment) => {
    console.log("submitting...");
    ToggleScheduledDeliveryDialogState.close({ success: true });
    await updateScheduledDelivery(environment, false);
  }),
);
