import { isEqual } from "libs/predicates";
import { startWith } from "libs/rxjs-operators";
import { pick } from "lodash-es";
import { useObservable, useObservableState } from "observable-hooks";
import { distinctUntilChanged, interval, map, of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { observeCurrentUserSettings } from "~/observables/observeCurrentUserSettings";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { getLastScheduledDeliveryDatetime, getNextScheduledDeliveryDatetime } from "~/utils/scheduled-delivery";

/** Reevaluate the scheduled delivery window every 10 seconds */
const REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS = 1000 * 20;

export function useLastScheduledDeliveryDatetime() {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  const observable = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(([environment, userId]) => observeCurrentUserSettings(environment, { userId })),
        map(({ settings }) =>
          pick(settings, "enable_scheduled_delivery", "scheduled_days", "scheduled_times", "most_recent_deliver_now"),
        ),
        distinctUntilChanged(isEqual),
        switchMap((settings) => {
          if (!settings.enable_scheduled_delivery) {
            return of(null);
          }

          return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
            startWith(() => null),
            map(() => getLastScheduledDeliveryDatetime(settings)),
          );
        }),
        distinctUntilChanged(isEqual),
      ),
    [environment, currentUserId],
  );

  return useObservableState(observable);
}

export function useNextScheduledDeliveryDatetime() {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  const observable = useObservable(
    (inputs$) =>
      inputs$.pipe(
        switchMap(([environment, userId]) => observeCurrentUserSettings(environment, { userId })),
        map(({ settings }) => pick(settings, "enable_scheduled_delivery", "scheduled_days", "scheduled_times")),
        distinctUntilChanged(isEqual),
        switchMap((settings) => {
          if (!settings.enable_scheduled_delivery) {
            return of(null);
          }

          return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
            startWith(() => null),
            map(() => getNextScheduledDeliveryDatetime(settings)),
          );
        }),
        distinctUntilChanged(isEqual),
      ),
    [environment, currentUserId],
  );

  return useObservableState(observable);
}
