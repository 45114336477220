import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useRecord } from "./useRecord";
import { useMemo } from "react";
import { generateRecordId } from "libs/schema";
import { FetchStrategy } from "~/environment/RecordLoader";

export function useNotification(props: {
  notificationId?: string | null;
  threadId?: string | null;
  fetchStrategy?: FetchStrategy;
}) {
  const { currentUser } = useAuthGuardContext();

  const notificationId = useMemo(() => {
    if (props.notificationId) return props.notificationId;
    if (!props.threadId) return null;

    return generateRecordId("notification", {
      thread_id: props.threadId,
      user_id: currentUser.id,
    });
  }, [props.notificationId, props.threadId, currentUser.id]);

  return useRecord("notification", notificationId, {
    fetchStrategy: props.fetchStrategy,
  });
}
