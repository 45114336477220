import { MentionableGroup } from "~/observables/observeMentionableGroupRecords";
import { getTagFolderAncestorRecords } from "./getTagFolderAncestorRecords";
import { isTagPrivate } from "libs/schema/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { GetOptions } from "~/environment/RecordLoader";

export interface GetMentionableGroupRecordProps {
  id: string;
}

export type GetMentionableGroupRecordResult = [MentionableGroup | null];

export async function getMentionableGroupRecord(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: GetMentionableGroupRecordProps,
  options?: GetOptions,
): Promise<GetMentionableGroupRecordResult> {
  const [[tag], { folderPaths }] = await Promise.all([
    environment.recordLoader.getRecord({ table: "tag", id: props.id }, options),
    getTagFolderAncestorRecords(environment, { tagId: props.id }, options),
  ]);

  if (!tag) return [null];

  return [
    {
      type: "group",
      id: tag.id,
      record: tag,
      isPrivate: isTagPrivate(tag),
      folderPaths,
    },
  ];
}
