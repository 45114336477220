import { ComponentType, memo, useState, useEffect } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { useRegisterCommands } from "~/environment/command.service";
import { SubmitDialogHint } from "../DialogLayout";
import { OutlineButton } from "~/components/OutlineButtons";
import { isEqual } from "libs/predicates";
import { css, cx } from "@emotion/css";
import { navigateService } from "~/environment/navigate.service";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { useInboxSection } from "~/hooks/useInboxSection";
import { entryCSSClasses } from "~/components/content-list/layout";
import { InboxSectionTagRecord, RecordValue } from "libs/schema";
import { deleteInboxSection, reorderInboxSections } from "~/actions/inboxSection";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { Tooltip } from "~/components/Tooltip";

export type IEditInboxSectionsDialogData = undefined;

export type IEditInboxSectionsDialogReturnData = { success: boolean } | void;

export const EditInboxSectionsDialogState = new DialogState<
  IEditInboxSectionsDialogData,
  IEditInboxSectionsDialogReturnData
>();

export const EditInboxSectionsDialog = withModalDialog({
  dialogState: EditInboxSectionsDialogState,
  useOnDialogContainerRendered() {
    useRegisterCommands({
      commands() {
        return [
          {
            label: "Edit inbox sections",
            callback() {
              EditInboxSectionsDialogState.open();
            },
          },
        ];
      },
    });
  },
  async loadData({ environment }) {
    const currentUserId = getAndAssertCurrentUserId();

    const [inboxSectionResults] = await Promise.all([
      environment.recordLoader.getInboxSections({
        currentUserId: currentUserId,
      }),
      // We also preload the inbox subsection
      environment.recordLoader.getInboxSubsections({
        currentUserId: currentUserId,
      }),
    ]);

    return {
      inboxSections: inboxSectionResults,
    };
  },
  Component: ({ data }) => {
    if (!data) {
      throw new Error("No data provided to EditInboxSectionsDialog");
    }

    const [inboxSections] = data.inboxSections;

    const [defaultInboxSection, ...otherInboxSections] = inboxSections as [
      InboxSectionTagRecord,
      ...InboxSectionTagRecord[],
    ];

    const [orderedSections, setOrderedSections] = useState(otherInboxSections);

    usePersistSectionOrderChanges(defaultInboxSection, orderedSections);

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditInboxSectionsDialogState.close();
            },
          },
        ];
      },
    });

    return (
      <>
        <DialogTitle>
          <h2>Edit inbox sections</h2>
        </DialogTitle>

        <div className={DIALOG_CONTENT_WRAPPER_CSS}>
          <div className="m-4 prose">
            <p>
              <em>
                Learn about inbox sections by watching this{" "}
                <a href="https://www.loom.com/share/344b5015d14f433c9d60398cb4976a20" target="_blank" rel="noreferrer">
                  Instructional Loom Video
                </a>
              </em>
            </p>
          </div>

          <div className="m-4">
            <hr className="mb-2" />

            {/* Default inbox section */}
            <div className={inboxSectionEntryCss}>
              <Tooltip content="The default section cannot be moved" side="bottom">
                <div className="cursor-not-allowed">
                  <RxDragHandleHorizontal size={30} className="text-slate-8" />
                </div>
              </Tooltip>

              <div className="font-medium">{defaultInboxSection.name}</div>

              <div className="flex-1" />
            </div>

            {/* Other inbox sections */}
            <DragDropContext
              onDragStart={(start) => {
                addClassToDraggedEntry(start.draggableId);
              }}
              onDragEnd={(result) => {
                removeClassToDraggedEntry(result.draggableId);

                if (!result.destination) {
                  return;
                }

                if (result.destination.index === result.source.index) {
                  return;
                }

                const newOrderedSections = reorder(orderedSections, result.source.index, result.destination.index);

                setOrderedSections(newOrderedSections);
              }}
            >
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {orderedSections.map((section, index) => (
                      <InboxSectionEntry key={section.id} sectionId={section.id} relativeOrder={index} />
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <hr className="mt-2" />
          </div>

          <div className="m-4">
            <OutlineButton
              onClick={() => {
                EditInboxSectionsDialogState.close();
                navigateService("/inbox/new");
              }}
            >
              Add inbox section
            </OutlineButton>
          </div>
        </div>

        <SubmitDialogHint />
      </>
    );
  },
});

function addClassToDraggedEntry(draggableId: string) {
  const draggedEl = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);

  if (!draggedEl) return;

  draggedEl.classList.add("being-dragged");
}

function removeClassToDraggedEntry(draggableId: string) {
  const draggedEl = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`);

  if (!draggedEl) return;

  draggedEl.classList.remove("being-dragged");
}

const reorder = (sections: InboxSectionTagRecord[], startIndex: number, endIndex: number) => {
  const newList = Array.from(sections);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const removed = newList.splice(startIndex, 1)[0]!;
  newList.splice(endIndex, 0, removed);

  return newList;
};

const InboxSectionEntry: ComponentType<{
  sectionId: string;
  relativeOrder: number;
}> = memo((props) => {
  const environment = useClientEnvironment();
  const [inboxSection] = useInboxSection(props.sectionId);

  if (!inboxSection) return null;

  return (
    <Draggable draggableId={inboxSection.id} index={props.relativeOrder}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} className={reactBeautifulDnDCssHack}>
          <div className={inboxSectionEntryCss}>
            <div {...provided.dragHandleProps}>
              <RxDragHandleHorizontal size={30} className="text-slate-8" />
            </div>

            <div className="font-medium">{inboxSection.name}</div>

            <div className="flex-1" />

            <OutlineButton
              onClick={() => {
                EditInboxSectionsDialogState.close();
                navigateService(`/inbox/${inboxSection.id}/edit`);
              }}
            >
              Edit
            </OutlineButton>

            <OutlineButton
              onClick={() => {
                deleteInboxSection(environment, {
                  inboxSectionId: props.sectionId,
                });
              }}
            >
              Delete
            </OutlineButton>
          </div>
        </div>
      )}
    </Draggable>
  );
}, isEqual);

const inboxSectionEntryCss = cx(
  entryCSSClasses,
  "border",
  css`
    padding-left: 0;
    padding-right: 0;
  `,
);

/**
 * React Beautiful DnD has a bug where the positioning CSS won't work for the element
 * being dragged if the element is inside a container with `position: fixed`. Applying
 * this CSS fixes the issue.
 *
 * See https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-1464944428
 */
const reactBeautifulDnDCssHack = css`
  &.being-dragged {
    left: auto !important;
    top: auto !important;
  }
`;

function usePersistSectionOrderChanges(
  defaultInboxSection: InboxSectionTagRecord,
  otherInboxSections: InboxSectionTagRecord[],
) {
  const environment = useClientEnvironment();

  useEffect(() => {
    reorderInboxSections(environment, {
      newOrder: [defaultInboxSection, ...otherInboxSections],
    });
  }, [defaultInboxSection, otherInboxSections, environment]);
}
