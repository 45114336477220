import { useRecordLoader } from "./useRecordLoader";

export function useStarredNotifications() {
  return useRecordLoader({
    load({ loader, limit, currentUserId }) {
      return loader.observeGetNotifications({
        user_id: currentUserId,
        is_starred: true,
        orderBy: "starred_at",
        orderDir: "DESC",
        limit,
      });
    },
    initialLimit: 50,
    limitStep: 50,
  });
}
