import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { debounceTime, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { observeInboxEntries } from "~/observables/observeInboxEntries";
import { RecordValue, getPointer } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { uniq, uniqBy } from "lodash-comms";
import { isEqual } from "libs/predicates";

export type UseInboxGroupsResult = [RecordValue<"tag">[], { isLoading: boolean }];

export function useInboxGroups(inboxSectionId: string | null | undefined): UseInboxGroupsResult {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, inboxSectionId, currentUserId],
    depsKey: String(inboxSectionId) + currentUserId,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([{ recordLoader }, inboxSectionId, userId]) => {
          if (!inboxSectionId) {
            return of<UseInboxGroupsResult>([[], { isLoading: false }]);
          }

          return observeInboxEntries(environment, { userId, inboxSectionId }).pipe(
            debounceTime(200),
            map(([entries, { isLoading }]) => [uniq(entries.map((e) => e.thread_id)), { isLoading }] as const),
            distinctUntilChanged(isEqual),
            switchMap(([threadIds, meta]) => {
              if (!threadIds.length) {
                return of([[] as RecordValue<"thread_group_permission">[], meta] as const);
              }

              return recordLoader.observeGetThreadsGroupPermissions({ threadIds }, meta);
            }),
            switchMap(([permissionsResult, meta]) => {
              const pointers = permissionsResult.map((p) => getPointer("tag", p.group_id));

              return recordLoader.observeGetRecords(pointers, meta);
            }),
            map(([groups, { isLoading }]): UseInboxGroupsResult => {
              const groupsMinusOrgs = groups
                .map(({ record }) => record)
                .filter((record) => !record.data?.is_organization_group);

              const uniqueGroups = uniqBy(groupsMinusOrgs, (r) => r.id);

              return [uniqueGroups, { isLoading }];
            }),
          );
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([] as RecordValue<"tag">[]),
  Object.freeze({ isLoading: true }),
]) as UseInboxGroupsResult;
