import { ComponentType, createContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { usePortalContainerContext } from "~/environment/portal.service";
import { createUseContextHook } from "~/utils/createUseContextHook";

export type TPortalContext = {
  container: HTMLElement;
};

const PortalContext = createContext<TPortalContext | null>(null);

export const usePortalContext = createUseContextHook(PortalContext, "PortalContext");

export const Portal: ComponentType<{}> = (props) => {
  const { container: portalParent } = usePortalContainerContext();

  // Create a div that we'll use as the portal target, it will exist outside the React component tree
  const [container] = useState(() => document.createElement("div"));

  useEffect(() => {
    portalParent.appendChild(container);

    return () => {
      portalParent.removeChild(container);
    };
  }, [container, portalParent]);

  const context = useMemo(() => {
    return { container };
  }, [container]);

  const component = <PortalContext.Provider value={context}>{props.children}</PortalContext.Provider>;

  return createPortal(component, container);
};
