import { ComponentType, useRef } from "react";
import { List, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useParams } from "react-router-dom";
import { NotFound } from "~/components/NotFound";
import { ESCAPE_TO_BACK_COMMAND } from "~/utils/common-commands";
import * as MainLayout from "~/page-layouts/main-layout";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { useOrganizationProfile } from "~/hooks/useOrganizationProfile";
import { useOrganizationUserInvitations } from "~/hooks/useOrganizationUserInvitations";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { PointerWithRecord } from "libs/schema";
import { useUserProfile } from "~/hooks/useUserProfile";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { entryCSSClasses } from "~/components/content-list/layout";
import { useUserContactInfo } from "~/hooks/useUserContactInfo";
import { useListPaging } from "~/hooks/useListPaging";
import { useOrganizationUserIds } from "~/hooks/useOrganizationUserIds";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { Button } from "@mui/material";
import { InviteUsersDialogState } from "~/dialogs/invite-users/InviteUsersDialog";
import { useIsUserOrganizationAdmin } from "~/hooks/useIsUserOrganizationAdmin";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { LabelChip, LabelContainer } from "~/components/LabelChip";
import { useIsUserDeletedFromOrg } from "~/hooks/useIsUserDeletedFromOrg";
import { useOrganizationUserInvitation } from "~/hooks/useOrganizationUserInvitation";
import IconButton from "@mui/material/IconButton";
import { IoMdTrash } from "react-icons/io";
import { revokeUserInvitation } from "~/actions/invitations";
import { reactivateUserFromOrg, removeUserFromOrg } from "~/actions/organization";
import { MdPersonOff } from "react-icons/md";
import { cx } from "@emotion/css";

export const MembersView: ComponentType<{}> = () => {
  const params = useParams();

  const organizationId = params.organizationId;

  const [organization, { isLoading: isOrganizationLoading }] = useOrganizationProfile(organizationId);

  const [memberUserIds, { fetchMore, nextId, isLoading: areMembersLoading }] = useOrganizationUserIds({
    organizationId: organizationId,
    includeSoftDeletes: true,
  });

  const [organizationUserInvites] = useOrganizationUserInvitations({
    organizationId: organizationId,
  });

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);

  const noMoreMembers = !nextId && !areMembersLoading;

  useListPaging({
    fetchMore,
    isLoading: areMembersLoading,
    isListEnd: noMoreMembers,
    pagingScrollboxRef: scrollboxRef,
  });

  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [ESCAPE_TO_BACK_COMMAND];
      return commands;
    },
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!organization],
  });

  if (!organizationId || !organization) {
    if (isOrganizationLoading) {
      return <div>Loading...</div>;
    }

    return <NotFound title="Organization Not Found" />;
  }

  return (
    <>
      <Helmet>
        <title>{organization.name} members | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef}>
        <h1 className="text-3xl">{organization.name} members</h1>
      </MainLayout.Header>

      <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
        {memberUserIds.length === 0 ? (
          <EmptyListMessage text="None." />
        ) : (
          <ContentList className="mb-20" autoFocus>
            <div className="mt-4 ml-10">
              <Button onClick={() => InviteUsersDialogState.open()}>Invite New Member</Button>
            </div>

            {memberUserIds.map((userId, index) => (
              <MemberEntry key={userId} organizationId={organizationId} userId={userId} relativeOrder={index} />
            ))}

            {organizationUserInvites.length > 0 && (
              <>
                <div className="mx-12 mt-8 mb-2 uppercase text-slate-8 font-medium text-sm">Pending Invitations</div>

                {organizationUserInvites.map((inviteId, index) => (
                  <InvitationEntry key={inviteId} organizationInvitationId={inviteId} relativeOrder={index} />
                ))}
              </>
            )}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
};

const MemberEntry: ComponentType<{
  organizationId: string;
  userId: string;
  relativeOrder: number;
}> = (props) => {
  const environment = useClientEnvironment();
  const { currentUser } = useAuthGuardContext();
  const [memberProfile] = useUserProfile(props.userId);
  const [memberContactInfo] = useUserContactInfo(props.userId);
  const [isAdmin] = useIsUserOrganizationAdmin({
    userId: currentUser.id,
    organizationId: currentUser.owner_organization_id,
  });
  const [memberIsAdmin] = useIsUserOrganizationAdmin({
    userId: props.userId,
    organizationId: props.organizationId,
  });
  const [isDeletedFromOrg] = useIsUserDeletedFromOrg({
    userId: props.userId,
    organizationId: props.organizationId,
  });
  const canDeleteUser = isAdmin && !isDeletedFromOrg && currentUser.id !== props.userId;

  const canReactivateUser = isAdmin && isDeletedFromOrg;

  const entryData = useAsPointerWithRecord("user_profile", memberProfile);

  if (!memberProfile || !entryData) return null;

  async function handleDeleteUser(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    await removeUserFromOrg(environment, {
      userId: props.userId,
      organizationId: props.organizationId,
    });
  }

  async function handleReactivateUser(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    e.stopPropagation();

    await reactivateUserFromOrg(environment, {
      userId: props.userId,
      organizationId: props.organizationId,
    });
  }

  return (
    <List.Entry<PointerWithRecord<"user_profile">>
      id={memberProfile.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <div className="w-full flex items-center">
          <span className={`flex-1 ${canReactivateUser ? "!text-slate-7" : null}`}>
            {memberProfile.name}{" "}
            {memberIsAdmin ? (
              <span className="p-1 px-2 border rounded text-xs border-slate-10 text-slate-10 ml-1">Admin</span>
            ) : null}
          </span>

          {memberContactInfo && <span className="flex-[3] text-slate-9">{memberContactInfo.email_address}</span>}

          <LabelContainer>
            {canReactivateUser && (
              <LabelChip
                colorClassName="invisible group-hover:visible group-focus:visible bg-white !px-2 !text-sm !border-slate-8 !text-slate-8 !border-slate-8 z-100"
                onClick={handleReactivateUser}
              >
                Reactivate
              </LabelChip>
            )}

            {canDeleteUser && (
              <LabelChip
                colorClassName="invisible group-hover:visible group-focus:visible !px-2 !border-red-8 !text-sm !text-red-8 hover:pointer hover:!text-red-11 hover:!border-red-11 z-100"
                onClick={handleDeleteUser}
              >
                <div className="flex items-center">
                  <MdPersonOff />
                  <span className="ml-1">Remove</span>
                </div>
              </LabelChip>
            )}
          </LabelContainer>
        </div>
      </div>
    </List.Entry>
  );
};

const InvitationEntry: ComponentType<{
  organizationInvitationId: string;
  relativeOrder: number;
}> = (props) => {
  const environment = useClientEnvironment();
  const [invitation] = useOrganizationUserInvitation(props.organizationInvitationId);
  const [invitedByUser] = useUserProfile(invitation?.creator_user_id);

  const entryData = useAsPointerWithRecord("organization_user_invitation", invitation);

  if (!invitation || !entryData) return null;

  return (
    <List.Entry<PointerWithRecord<"organization_user_invitation">>
      id={invitation.id}
      data={entryData}
      relativeOrder={props.relativeOrder}
    >
      <div className={cx(entryCSSClasses, 'invitation-entry')}>
        <div className="w-full flex items-center justify-between group">
          <div className="flex flex-1">
            <span className="flex-1 invitation-email">{invitation.email_address}</span>

            {invitedByUser && <span className="flex-[4] text-slate-9 ml-5">invited by: {invitedByUser.name}</span>}
          </div>
          <div>
            <IconButton
              aria-label="revoke-invitation"
              onClick={(e) => {
                e.preventDefault();
                revokeUserInvitation(environment, invitation);
              }}
              className="invisible group-hover:visible group-focus:visible"
              size="small"
            >
              <IoMdTrash />
            </IconButton>
          </div>
        </div>
      </div>
    </List.Entry>
  );
};
