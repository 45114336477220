import { ComponentType, useRef } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { slate } from "@radix-ui/colors";
import { Header } from "../Header";
import useConstant from "use-constant";
import { Subject } from "rxjs";
import {
  useAddOrganizationGroupOnRecipientAndVisibilityChanges,
  useAutosaveDraft,
  useRegisterSharedComposeNewMessageCommands,
  useSaveDraftImmediatelyFn,
  useUpdateDraftTypeOnRecipientChanges,
  useUpdateRecipientsOnDraftTypeChanges,
  useUpdateRecipientsOnVisibilityChanges,
  useUpdateVisibilityOnRecipientChanges,
} from "../utils";
import { SetNonNullable } from "libs/type-helpers";
import { useControl } from "solid-forms-react";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { ComposeInfoPanel } from "../ComposeInfoPanel";
import { BranchedThreadDraft } from "./BranchedThreadDraft";
import { createComposeMessageForm, IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { getDraftDataStorageKey } from "~/environment/draft.service";
import dayjs from "dayjs";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { focusDraft } from "~/components/ComposeReplyBase";
import { useRegisterCommands } from "~/environment/command.service";
import { collapseAllMessagesCommand, expandAllMessagesCommand } from "~/utils/common-commands";
import { useSetWebpageBackgroundColor } from "~/hooks/useSetWebpageBackgroundColor";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { ParentThreadTimeline } from "./ParentThreadTimeline";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { mapRecipientOptionToDraftRecipient, mergeDraft } from "~/actions/draft";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { getSessionStorage } from "~/environment/KVStore";

/* -------------------------------------------------------------------------------------------------
 * ComposeBranchedThread
 * -----------------------------------------------------------------------------------------------*/

export const ComposeBranchedThread: ComponentType<{
  initialFormValues: SetNonNullable<IComposeMessageFormValue, "branchedFrom">;
}> = (props) => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const listRef = useRef<IListRef<TThreadTimelineEntry>>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<IRichTextEditorRef>(null);

  const control = useControl(() =>
    createComposeMessageForm(props.initialFormValues, {
      recipientsRequired: true,
    }),
  );

  useUpdateDraftTypeOnRecipientChanges(control);
  useUpdateRecipientsOnDraftTypeChanges(control);
  useUpdateVisibilityOnRecipientChanges(control);
  useUpdateRecipientsOnVisibilityChanges(control);
  useAddOrganizationGroupOnRecipientAndVisibilityChanges({
    control,
    walkthroughNotCompleted: false,
  });

  useSetWebpageBackgroundColor(slate.slate3);

  useAutosaveDraft(control, editorRef);

  const saveDraftImmediatelyFn = useSaveDraftImmediatelyFn(control);

  useRegisterSharedComposeNewMessageCommands({
    control,
    submit,
  });

  const branchedFrom = props.initialFormValues.branchedFrom;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const collapsePostEvents$ = useConstant(() => new Subject<"expand" | "collapse" | string>());

  const loadMoreMessagesButtonFocusEvents$ = useConstant(() => new Subject<void>());

  useRegisterComposeBranchedMessageCommands({
    collapsePostEvents$,
  });

  return (
    <>
      <div className="MainPanel md-w:mr-[280px] flex flex-col">
        <Header ref={headerRef} control={control} isLessonComplete={true} parentThreadId={branchedFrom.threadId} />

        <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
          <ThreadLayout.ContentPanel className="mx-auto flex-1 flex flex-col">
            <ParentThreadTimeline
              parentThreadId={branchedFrom.threadId}
              parentMessageId={branchedFrom.messageId}
              onArrowDownOverflow={() => {
                focusDraft(control, editorRef);
              }}
              loadMoreMessagesButtonFocusEvents={loadMoreMessagesButtonFocusEvents$}
            />

            <BranchedThreadDraft
              ref={editorRef}
              control={control}
              saveDraftFn={saveDraftImmediatelyFn}
              listRef={listRef}
              treatLessonAsCompleted={true}
            />
          </ThreadLayout.ContentPanel>
        </ListScrollbox>
      </div>

      <ComposeInfoPanel control={control} />
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

function useRegisterComposeBranchedMessageCommands(args: {
  collapsePostEvents$: Subject<"expand" | "collapse" | string>;
}) {
  useRegisterCommands({
    commands() {
      return [
        expandAllMessagesCommand({
          callback: () => {
            args.collapsePostEvents$.next("expand");
          },
        }),
        collapseAllMessagesCommand({
          callback: () => {
            args.collapsePostEvents$.next("collapse");
          },
        }),
      ];
    },
    deps: [args.collapsePostEvents$],
  });
}

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    environment: ClientEnvironment,
    values: IComposeMessageFormValue,
    options: { sendImmediately?: boolean } = {},
  ) => {
    console.log("submitting...", values);

    if (values.visibility === null) {
      console.error("Attempted to send message with visibility === null");
      return;
    }

    if (!values.subject) {
      console.error("Attempted to send message with !subject");
      return;
    }

    const branchedFrom = values.branchedFrom || undefined;

    mergeDraft(environment, {
      currentUserId: getAndAssertCurrentUserId(),
      ownerOrganizationId: getAndAssertCurrentUserOwnerOrganizationId(),
      is_edit: values.isEdit,
      is_reply: values.isReply,
      draftId: values.messageId,
      threadId: values.threadId,
      branchedFrom: branchedFrom && {
        messageId: branchedFrom.messageId,
        threadId: branchedFrom.threadId,
        messageTimelineOrder: branchedFrom.messageTimelineOrder,
      },
      type: values.type,
      subject: values.subject,
      visibility: values.visibility,
      bodyHTML: values.body.content,
      groupMentions: values.body.groupMentions,
      userMentions: values.body.userMentions,
      attachments: values.attachments,
      to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
      cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
      bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
      scheduledToBeSentAt: options.sendImmediately ? new Date() : dayjs().add(20_000, "ms").toDate(),
      afterUndo: () => {
        openComposeNewThreadDialog(values.messageId);
      },
    })
      .then(() => console.log("submitted successfully!"))
      .catch(console.error);

    const key = getDraftDataStorageKey(values.messageId);

    getSessionStorage().removeItem(key);
  },
);

/* -----------------------------------------------------------------------------------------------*/
