import { combineLatest, distinctUntilChanged, interval, map, NEVER, Observable, of, switchMap, tap } from "rxjs";
import { observeCurrentUserSettings } from "./observeCurrentUserSettings";
import { isEqual } from "libs/predicates";
import { RecordPointer } from "libs/schema";
import { cacheReplayForTime, startWith } from "libs/rxjs-operators";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getLastScheduledDeliveryDatetime } from "~/utils/scheduled-delivery";
import { ClientRecordLoaderObserveQueryResult, ObserveOptions } from "~/environment/RecordLoader";

/** Reevaluate the scheduled delivery window every 10 seconds */
const REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS = 1000 * 20;

export type ObserveInboxEntriesProps = {
  userId: string;
  inboxSectionId: string;
  startAt?: RecordPointer<"draft"> | RecordPointer<"notification">;
  sortDirection?: "ASC" | "DESC";
  limit?: number;
};

export type ObserveInboxEntriesResult = ClientRecordLoaderObserveQueryResult<"inbox_entry">;

export function observeInboxEntries(
  environment: Pick<ClientEnvironment, "recordLoader" | "db">,
  props: ObserveInboxEntriesProps,
  options?: ObserveOptions,
): Observable<ObserveInboxEntriesResult> {
  const cacheKey =
    props.userId +
    props.inboxSectionId +
    props.startAt?.table +
    props.startAt?.id +
    props.sortDirection +
    props.limit +
    options?.fetchStrategy +
    options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = observeCurrentUserSettings(environment, { userId: props.userId }, options).pipe(
    map(({ settings, isLoading }) => [settings?.inbox_layout || "consolidated-inbox", isLoading] as const),
    distinctUntilChanged(isEqual),
    switchMap(([inboxLayout, isLoading]) => {
      if (inboxLayout === "blocking-inbox") {
        return observeBlockingLayoutInboxEntries(environment, props, {
          fetchStrategy: options?.fetchStrategy,
          isLoading,
        });
      }

      return innerObserveInboxEntries(environment, props, {
        fetchStrategy: options?.fetchStrategy,
        isLoading,
      });
    }),
    tap(([_, { error }]) => {
      if (!error) return;
      // In the case of an error, we don't want to cache the query
      queryCache.delete(cacheKey);
    }),
    cacheReplayForTime({
      // Note that increasing the cache time here is trickier than normal because the cacheKey
      // updates with changes to the limit. I.e. each time we load more records we'd be creating
      // and caching a new observable. Probably ideally we'd only cache the observable with the
      // highest limit.
      timeMs: 100,
      onInit: () => {
        queryCache.set(cacheKey, observable);
      },
      onCleanup: () => {
        queryCache.delete(cacheKey);
      },
    }),
  );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveInboxEntriesResult>>();

export type ObserveBlockingLayoutInboxSubsectionIdResult = [inboxSubsectionId: string | null, { isLoading: boolean }];

export function observeBlockingLayoutInboxSubsectionId(
  environment: Pick<ClientEnvironment, "recordLoader" | "db">,
  props: {
    userId: string;
    inboxSectionId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveBlockingLayoutInboxSubsectionIdResult> {
  return environment.recordLoader
    .observeGetInboxNotificationEntries(
      {
        currentUserId: props.userId,
        inboxSectionId: props.inboxSectionId,
        limit: 1,
      },
      options,
    )
    .pipe(
      map(
        ([records, { isLoading }]): ObserveBlockingLayoutInboxSubsectionIdResult => [
          records[0]?.inbox_subsection_id || null,
          { isLoading },
        ],
      ),
    );
}

function observeBlockingLayoutInboxEntries(
  environment: Pick<ClientEnvironment, "recordLoader" | "db">,
  props: ObserveInboxEntriesProps,
  options?: ObserveOptions,
): Observable<ObserveInboxEntriesResult> {
  const currentInboxSubsectionId = observeBlockingLayoutInboxSubsectionId(environment, props, options).pipe(
    distinctUntilChanged(isEqual),
  );

  return currentInboxSubsectionId.pipe(
    switchMap(([inboxSubsectionId, { isLoading: isInboxSubsectionIdLoading }]) => {
      const observable = !inboxSubsectionId
        ? environment.recordLoader.observeGetInboxDraftEntries(
            { ...props, currentUserId: props.userId },
            {
              fetchStrategy: options?.fetchStrategy,
              isLoading: isInboxSubsectionIdLoading,
            },
          )
        : innerObserveInboxEntries(
            environment,
            { ...props, inboxSubsectionId },
            {
              fetchStrategy: options?.fetchStrategy,
              isLoading: isInboxSubsectionIdLoading,
            },
          );

      return observable;
    }),
  );
}

function innerObserveInboxEntries(
  environment: Pick<ClientEnvironment, "recordLoader" | "db">,
  props: ObserveInboxEntriesProps & { inboxSubsectionId?: string },
  options?: ObserveOptions,
): Observable<ObserveInboxEntriesResult> {
  const {
    userId,
    inboxSectionId,
    // Used for blocking inbox only
    inboxSubsectionId,
    startAt,
    sortDirection,
    limit,
  } = props;

  const { recordLoader, db } = environment;

  return settingsWithLastDeliveryDatetime$(environment, props, options).pipe(
    switchMap(({ settings, lastDeliveryDatetime }) => {
      const isScheduledDeliveryEnabled = !!(settings?.enable_scheduled_delivery && lastDeliveryDatetime);

      const isFocusModeEnabled = !!settings?.enable_focus_mode;

      if (!isScheduledDeliveryEnabled && !isFocusModeEnabled) {
        return recordLoader.observeGetInboxEntries({ ...props, currentUserId: props.userId }, options);
      } else {
        return combineLatest([
          // we fetch records from the cache matching our scheduled delivery
          // and focus mode preferences
          db.observeGetInboxEntries({
            inboxSectionId,
            inboxSubsectionId,
            currentUserId: userId,
            startAt,
            sortDirection,
            limit,
            priorities: !isFocusModeEnabled ? undefined : settings.focus_mode_exceptions,
            lastScheduledDeliveryAt: !isScheduledDeliveryEnabled ? undefined : lastDeliveryDatetime.toISOString(),
          }),
          // we observe the normal inbox entries from the server in order to maintain
          // a subscription to the appropriate records
          recordLoader.observeGetInboxEntries({ ...props, currentUserId: props.userId }, options),
        ]).pipe(map(([[localResults], [_, serverMeta]]): ObserveInboxEntriesResult => [localResults, serverMeta]));
      }
    }),
  );
}

function settingsWithLastDeliveryDatetime$(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    userId: string;
  },
  options?: ObserveOptions,
) {
  return observeCurrentUserSettings(environment, props, options).pipe(
    switchMap(({ settings, isLoading }) => {
      if (isLoading) return NEVER;

      if (!settings?.enable_scheduled_delivery) {
        return of({
          settings,
          lastDeliveryDatetime: null,
        });
      }

      // If we have enableScheduledDelivery turned on, then
      // we need to periodically rerun our
      // applyFocusSettingsToInboxNotifications() mapper function
      // to re-filter notifications with the current time.
      return interval(REEVALUATE_SCHEDULED_DELIVERY_WINDOW_MS).pipe(
        startWith(() => null),
        map(() => {
          const lastDeliveryDatetime = getLastScheduledDeliveryDatetime(settings);

          return {
            settings,
            lastDeliveryDatetime,
          };
        }),
      );
    }),
    distinctUntilChanged(isEqual),
  );
}

// function applyFocusSettingsToInboxNotifications(props: {
//   userSettings: RecordValue<"user_settings"> | null;
//   lastDeliveryDatetime: dayjs.Dayjs | null;
//   notifications: RecordValue<"notification">[];
// }) {
//   const { userSettings, lastDeliveryDatetime, notifications } = props;

//   let filteredNotifications: RecordValue<"notification">[];

//   if (!userSettings?.settings?.enable_focus_mode) {
//     filteredNotifications = notifications.slice();
//   } else if (userSettings.settings.focus_mode_exceptions === undefined) {
//     // We require the user to choose 0 or more exceptions the
//     // first time they enableFocusMode. If this property is undefined
//     // it indicates some kind of a bug so we should just ignore the
//     // setting.
//     filteredNotifications = notifications.slice();
//   } else if (userSettings.settings.focus_mode_exceptions.length === 0) {
//     return [];
//   } else {
//     filteredNotifications = notifications.filter((n) =>
//       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//       userSettings.settings.focus_mode_exceptions!.some((exception) => {
//         if (exception === 300) {
//           return n.priority >= exception;
//         }

//         return n.priority === exception;
//       }),
//     );
//   }

//   if (userSettings?.settings.enable_scheduled_delivery) {
//     if (!lastDeliveryDatetime) {
//       // We require the user to choose 1 or more scheduledDays and
//       // scheduledTimes the first time they enableScheduledDelivery.
//       // If either of these properties are length 0 it indicates
//       // some kind of a bug so we should just ignore the setting.
//       return filteredNotifications;
//     }

//     return filteredNotifications.filter((notification) => {
//       if (notification.priority <= 100) return true;
//       if (notification.done_last_modified_by !== "delivery") return true;
//       if (!notification.oldest_sent_at_value_not_marked_done) {
//         // this should never happen

//         console.error(
//           "Missing oldest_sent_at_value_not_marked_done for notification",
//           notification,
//         );

//         return true;
//       }

//       return !lastDeliveryDatetime.isBefore(
//         notification.oldest_sent_at_value_not_marked_done,
//       );
//     });
//   }

//   return filteredNotifications;
// }
