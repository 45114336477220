import * as P from "bread-n-butter";

export interface Token<Name extends string, Value> {
  type: Name;
  value: Value;
}

export type LogicalOperator<Name extends string> = {
  type: Name;
  value: ParsedToken[];
};

export type ParsedToken = Filters | LogicalOperators | TextToken;

type ParserReturn<Parser> = Parser extends P.Parser<infer Value> ? Value : never;

export type TextToken = Token<"text", string>;

export type PrimativeMap = {
  [Key in keyof typeof primatives]: ParserReturn<(typeof primatives)[Key]>;
};

type Primatives = PrimativeMap[keyof PrimativeMap];

export type FilterMap = {
  [Key in keyof typeof filters]: ParserReturn<(typeof filters)[Key]>;
};

export type Filters = FilterMap[keyof FilterMap];

export type FilterValue<T extends keyof FilterMap> = FilterMap[T]["value"][number];

type LogicalOperators = LogicalOperator<"and()" | "or()" | "not()">;

const primatives = {
  /**
   * We don't allow parenthesis here because parenthesis are used to mark the
   * start/end of the operator fns. We do allow parenthesis in the quotedText
   * parser.
   */
  word: P.match(/[^\s()]+/).map<TextToken>((value) => ({
    type: "text",
    value,
  })),
  wordWithoutComma: P.match(/[^\s(),]+/).map<TextToken>((value) => ({
    type: "text",
    value,
  })),
  quotedText: P.match(/"[^"]*"/).map<Token<"text", string>>((value) => ({
    type: "text",
    value,
  })),
  docId: P.text("<#")
    .next(P.match(/[^>]+/))
    .skip(P.text(">"))
    .map<Token<"DocId", string>>((value) => ({ type: "DocId", value })),
};

function buildFilter<Name extends string>(name: Name, options: { noComma?: boolean } = {}) {
  return P.match(new RegExp(name, "i"))
    .next(
      P.text(" ")
        .repeat()
        .next(options.noComma ? noCommaFilterValue : filterValue)
        .sepBy(P.match(/\s*,\s*/)),
    )
    .map<Token<Name, Primatives[]>>((value) => {
      return { type: name, value };
    });
}

const filterValue = P.choice(primatives.quotedText, primatives.docId, primatives.word);

const noCommaFilterValue = P.choice(primatives.quotedText, primatives.docId, primatives.wordWithoutComma);

const filters = {
  from: buildFilter("from:"),
  to: buildFilter("to:"),
  group: buildFilter("group:"),
  tag: buildFilter("tag:"),
  subject: buildFilter("subject:"),
  body: buildFilter("body:"),
  after: buildFilter("after:"),
  before: buildFilter("before:"),
  mentions: buildFilter("mentions:"),
  participating: buildFilter("participating:"),
  has: buildFilter("has:", { noComma: true }),
  remindAfter: buildFilter("remind-after:"),
  remindBefore: buildFilter("remind-before:"),
  is: buildFilter("is:", { noComma: true }),
  viewer: buildFilter("viewer:"),
  priority: buildFilter("priority:"),
};

const operators = {
  and: P.match(new RegExp("and", "i"))
    .next(P.lazy(() => buildQueryParser({ wrapInParenthesis: true })))
    .map<LogicalOperator<"and()">>((value) => ({ type: "and()", value })),
  or: P.match(new RegExp("or", "i"))
    .next(P.lazy(() => buildQueryParser({ wrapInParenthesis: true })))
    .map<LogicalOperator<"or()">>((value) => ({ type: "or()", value })),
  not: P.match(new RegExp("not", "i"))
    .next(P.lazy(() => buildQueryParser({ wrapInParenthesis: true })))
    .map<LogicalOperator<"not()">>((value) => ({ type: "not()", value })),
};

function buildQueryParser(
  options: {
    wrapInParenthesis?: boolean;
  } = {},
) {
  let parser: P.Parser<ParsedToken[]> = P.choice(
    ...Object.values(filters),
    ...Object.values(operators),
    primatives.word,
  )
    .sepBy(seperator)
    .trim(seperator);

  if (options.wrapInParenthesis) {
    parser = parser.wrap(P.text("("), P.text(")"));
  }

  return parser;
}

const seperator = P.match(/\s*/);

/**
 * If the search query contains a plain text search phrase, the first
 * ParsedToken in the response will be of type "text".
 */
export const searchQueryParser = buildQueryParser().map(mergePlainTextTokens);

/**
 * On error, will return `null`. If you want access to the error information,
 * use the `searchQueryParser` object directly.
 *
 * If the search query contains a plain text search phrase, the first
 * ParsedToken in the response will be of type "text".
 */
export function parseSearchQuery(query: string): ParsedToken[] | null {
  const result = searchQueryParser.parse(query);

  if (result.type === "ParseFail") {
    return null;
  }

  return result.value;
}

/**
 * Our query parser will parse each plain text (i.e. non-keyword) word
 * as a separate token. We want to join all of these plain text tokens
 * with a `" "` seperator into a single token.
 *
 * If the query contains plain text, the plain text token will be the
 * first element of the returned array. If the first element of the
 * returned array isn't a plain text token, then that level of the
 * array doesn't contain any plain text tokens (though logical operator tokens
 * within the array may contain a plain text token nested within them).
 *
 * Example:
 * ```ts
 * const before = [
 *   { type: "to:", value: "sam" },
 *   { type: "text", value: "my" },
 *   { type: "text", value: "search" },
 *   { type: "from:", value: "john" },
 * ];
 *
 * // after pseudo code
 * mergePlainTextTokens(before) == [
 *   { type: "text", value: "my search" },
 *   { type: "to:", value: "sam" },
 *   { type: "from:", value: "john" },
 * ]
 * ```
 */
function mergePlainTextTokens(tokens: ParsedToken[]): ParsedToken[] {
  const normalizedTokens = tokens.reduce(
    (store, curr) => {
      if (curr.type === "text") {
        store.text += curr.value + " ";
      } else if (curr.type === "and()" || curr.type === "or()" || curr.type === "not()") {
        store.filters.push({
          type: curr.type,
          value: mergePlainTextTokens(curr.value),
        });
      } else {
        store.filters.push(curr);
      }

      return store;
    },
    { text: "", filters: [] as ParsedToken[] },
  );

  if (!normalizedTokens.text) {
    return normalizedTokens.filters;
  }

  return [{ type: "text", value: normalizedTokens.text.trim() }, ...normalizedTokens.filters];
}
