import { PointerWithRecord, RecordPointer, RecordTable } from "libs/schema";
import { of, switchMap } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import { useMemo } from "react";
import { useLoadingObservable } from "./useLoadingObservable";
import { usePendingRequestBar } from "./usePendingRequestBar";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ObserveOptions } from "~/environment/RecordLoader";

export type UseRecordsResult<T extends RecordTable> = [PointerWithRecord<T>[], { isLoading: boolean }];

export function useRecords<T extends RecordTable>(pointers: RecordPointer<T>[]): UseRecordsResult<T> {
  const { recordLoader } = useClientEnvironment();

  const pointersKey = pointers
    .map((p) => p.table + p.id)
    .sort()
    .join("");

  const stablePointers = useMemo(() => pointers, [pointersKey]);

  const result = useLoadingObservable({
    initialValue: DEFAULT_VALUE as UseRecordsResult<T>,
    deps: [recordLoader, stablePointers],
    depsKey: pointersKey,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, pointers]) => {
          if (!pointers.length) {
            return of<UseRecordsResult<T>>([[], { isLoading: false }]);
          }

          const options: ObserveOptions = {};

          return recordLoader
            .observeGetRecords(pointers, options)
            .pipe(startWith(() => [[], { isLoading: true }] as UseRecordsResult<T>));
        }),
      );
    },
  });

  usePendingRequestBar(result[1].isLoading);

  return result;
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as unknown as PointerWithRecord[],
  Object.freeze({ isLoading: true }),
]) as UseRecordsResult<any>;
