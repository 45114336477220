import { ApiInput, ApiOutput, ApiResponse, ApiTypes, ErrorResponse } from "libs/ApiTypes";
import { ApiVersionError, AuthenticationError } from "libs/errors";
import { ClientEnvironment } from "./ClientEnvironment";
import { Semaphore } from "libs/semaphore";
import { config } from "./config";
import { ClientMetadata } from "./ClientMetadata";
import { isAbortError } from "libs/predicates";

export type ClientApi = {
  [ApiName in keyof ApiTypes]: ApiInput<ApiName> extends undefined
    ? (args?: ApiInput<ApiName>, options?: ClientApiRequestOptions) => Promise<ApiResponse<ApiOutput<ApiName>>>
    : (args: ApiInput<ApiName>, options?: ClientApiRequestOptions) => Promise<ApiResponse<ApiOutput<ApiName>>>;
};

export type ClientApiRequestOptions = {
  /**
   * Try to send the request to the server regardless of offline status. Useful if
   * the user has manually enabled offline mode and wishes to sign out without waiting
   * for the websocket connection to reconnect.
   */
  ignoreOfflineStatus?: boolean;
  abortSignal?: AbortSignal;
};

export const apiRoot = config.dev.apiServerOrigin ? config.dev.apiServerOrigin + "/api/" : "/api/";

// We can use this semaphore to limit the number of concurrent API requests to 10.
const semaphore = new Semaphore(10);

export function createClientApi(
  environment: Pick<ClientEnvironment, "logger" | "network"> & {
    info: Pick<ClientMetadata, "version" | "datadogSessionId">;
  },
  props: {
    onAuthenticationError: () => Promise<void>;
    onApiVersionError: (error: ApiVersionError | null) => Promise<void>;
  },
) {
  const { info, network } = environment;

  const logger = environment.logger.child({ name: "Api" });

  async function apiRequest<T extends keyof ApiTypes>(
    name: T,
    args: ApiInput<T>,
    options: ClientApiRequestOptions = {},
  ) {
    logger.debug({ name, args }, "apiRequest");

    const response = await (httpRequest({
      url: apiRoot + name,
      environment: { logger, network },
      body: args,
      options,
      credentials: config.dev.apiServerOrigin ? "include" : "same-origin",
      headers: {
        "Accept-Version": `${info.version.api}.${info.version.schema.actual ?? info.version.schema.expected}`,
        "X-DD-Session-Id": info.datadogSessionId || "",
      },
    }) as Promise<ApiResponse<ApiOutput<T>>>);

    logger.debug({ name, args, response }, "apiResponse");

    if (response.status === AuthenticationError.statusCode) {
      await props.onAuthenticationError();
    }

    if (ApiVersionError.isApiVersionError(response)) {
      const error = ApiVersionError.fromResponse(response);
      await props.onApiVersionError(error);
    }

    return response;
  }

  const api = new Proxy(
    {},
    {
      get(_target, key: any) {
        if (key === "then") return undefined;
        if (key === "toJSON") return undefined;

        return (args: any, options: ClientApiRequestOptions = {}) => {
          return semaphore.use(() => apiRequest(key, args, options));
        };
      },
    },
  ) as ClientApi;

  return api;
}

export function formatResponseError(response: ErrorResponse) {
  const { status, body } = response;
  if (body === null) return `${status}: Unknown error.`;
  if (body === undefined) return `${status}: Unknown error.`;
  if (typeof body === "string") return body;
  if (typeof body === "object") {
    if ("message" in body) {
      if (typeof body.message === "string") {
        return body.message;
      }
    }
  }

  return `${status}: ${JSON.stringify(body)}`;
}

export type HttpResponse<Body = any> = { status: 200; body: Body } | { status: number; body?: any };

// Only POST requests for now because this is only used for the API.
export async function httpRequest(props: {
  url: string;
  environment: Pick<ClientEnvironment, "network" | "logger">;
  body?: object;
  headers?: Record<string, string>;
  credentials?: RequestCredentials;
  options?: ClientApiRequestOptions;
}): Promise<HttpResponse> {
  const { url, body: requestBody = null, environment, options = {} } = props;
  const { logger } = environment;
  const { abortSignal, ignoreOfflineStatus } = options;

  if (!ignoreOfflineStatus && !environment.network.isOnline()) {
    logger.debug(`Ignoring request to ${url} because we are offline.`);
    return { status: 0 }; // Offline.
  }

  let response: Response;

  try {
    // Request body cannot be blank if content-type is application/json.
    const headers = requestBody ? { "Content-Type": "application/json", ...props.headers } : props.headers;

    response = await fetch(url, {
      method: "post",
      credentials: props.credentials || "same-origin",
      headers,
      signal: abortSignal,
      body: requestBody === null ? null : JSON.stringify(requestBody),
    });
  } catch (error) {
    if (isAbortError(error)) {
      return { status: -1 };
    }

    logger.debug("httpRequest network error", error);

    // Offline
    return { status: 0 };
  }

  if (response.status === 200) {
    const body = await response.json();
    return { status: 200, body };
  }

  let responseBody: any;
  try {
    responseBody = await response.json();
  } catch (error) {
    logger.warn("Could not parse body of error response.");
  }

  return { status: response.status, body: responseBody };
}
