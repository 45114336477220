import { combineLatest, map, of, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { getPointer } from "libs/schema";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ObserveOptions } from "~/environment/RecordLoader";

export function useIsThreadNotFound(threadId: string | null | undefined) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  return useLoadingObservable({
    initialValue: isNotFoundDefaultValue,
    deps: [environment, threadId, currentUserId],
    depsKey: String(threadId) + currentUserId,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, threadId, currentUserId]) => {
          if (!threadId) {
            return of({
              isThreadNotFound: "not-found" as const,
              isLoading: false,
            });
          }

          const options: ObserveOptions = {};

          return combineLatest([
            environment.recordLoader.observeGetRecord(
              {
                table: "thread",
                id: threadId,
              },
              options,
            ),
            environment.recordLoader.observeGetRecord(
              getPointer("notification", {
                thread_id: threadId,
                user_id: currentUserId,
              }),
              options,
            ),
          ]).pipe(
            map(([[thread, { isLoading: isThreadLoading }], [notification, { isLoading: isNotificationLoading }]]) => ({
              isThreadNotFound: thread
                ? ("found" as const)
                : notification
                  ? ("permission-denied" as const)
                  : ("not-found" as const),
              isLoading: isThreadLoading || isNotificationLoading,
            })),
          );
        }),
      );
    },
  });
}

const isNotFoundDefaultValue = Object.freeze({
  isThreadNotFound: "not-found" as const,
  isLoading: true,
});
