import { createLogger } from "libs/logger";
import { datadogLogs } from "@datadog/browser-logs";
import type { LoggerOptions } from "pino";
import { config } from "./config";
import { omit } from "lodash-comms";

export function createClientLogger(options: LoggerOptions<"notice"> = {}) {
  return createLogger({
    enabled: config.mode !== "test",
    browser: {
      asObject: true,
      disabled: config.mode === "test",
      transmit: {
        send(level, logEvent) {
          if (logEvent.level.value <= 30 || typeof document === "undefined") return;

          try {
            const data = [...logEvent.bindings, ...logEvent.messages];

            const context = data.reduce(
              (store, data) => (typeof data === "object" ? { ...store, ...data } : { ...store, msg: data }),
              {},
            );

            if (level === "trace") level = "debug";
            if (logEvent.level.value === 35) level = "info";
            if (level === "fatal") level = "error";

            datadogLogs.logger.log(
              context.message || context.msg,
              omit(context, "environment"),
              level,
              context.error || context.err,
            );
          } catch (error) {
            console.warn("Failed to send log to Datadog", error);
          }
        },
        level: "notice",
      },
    },
    ...options,
  });
}
