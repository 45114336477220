import { DeferredPromise } from "libs/promise-utils";
import { ClientEnvironment } from "./ClientEnvironment";
import { isInstalledApp, isSharedWorkerSupported } from "~/utils/dom-helpers";
import { isDesktopBrowser } from "./detect-browser.service";
import { MessagePortService } from "./MessagePortService";

export class LeadershipService {
  static disableLeadership() {
    return (isInstalledApp() && !isDesktopBrowser()) || !isSharedWorkerSupported();
  }

  get isLeader() {
    return this.deferredIsLeader.settled;
  }

  get isLeaderPromise() {
    return this.deferredIsLeader.promise;
  }

  /** A deferred promise that is resolved when this tab is promoted to leader */
  private deferredIsLeader = new DeferredPromise<void>();

  constructor(protected env: Pick<ClientEnvironment, "logger" | "clientId">) {
    this.env = {
      ...env,
      logger: env.logger.child({ name: "LeadershipService" }),
    };

    if (LeadershipService.disableLeadership()) {
      this.env.logger.info(`[LeadershipService] promoted to leader`);
      this.deferredIsLeader.resolve();
    } else {
      MessagePortService.acquireContextLock(MessagePortService.uniqueContexts.LEADER).then(() => {
        this.env.logger.info(`[LeadershipService] promoted to leader`);
        this.deferredIsLeader.resolve();
      });
    }
  }
}
