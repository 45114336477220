import { Operation, op } from "libs/transaction";
import { PushSubscriptionJSON, generateRecordId } from "libs/schema";

export type CreateSubscriptionProps = {
  userId: string;
  subscription: PushSubscriptionJSON;
  ownerOrganizationId: string;
};

export function createPushNotificationSubscription(props: CreateSubscriptionProps): Operation[] {
  return [
    op.set("user_push_notification_subscriptions", {
      id: generateRecordId("user_push_notification_subscriptions"),
      user_id: props.userId,
      subscription: props.subscription,
      owner_organization_id: props.ownerOrganizationId,
    }),
  ];
}
