import { useEffect, useState } from "react";
import { isNativeIOS } from "~/utils/pwaBuilder-utils";

export default function useIsNativeIOS() {
  const [isNative, setIsNative] = useState(false);

  useEffect(() => {
    setIsNative(isNativeIOS());
  }, []);

  return isNative;
}
