import { generateRecordId, getPointer, RecordValue, SpecialTagTypeEnum, TagSubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { difference } from "lodash-es";
import { isTagPrivate } from "libs/schema/predicates";
import { renderJsxToString } from "~/utils/renderJsxToString";
import * as ops from "libs/actions";
import { renderGroupName } from "~/utils/groups-utils";

export const createGroup = withPendingRequestBar(
  async (props: {
    environment: ClientEnvironment;
    group: {
      id: string;
      icon: string | null;
      name: string;
      description: string | null;
      /** IDs of the tags which will act as folders for this tag */
      folderIds: string[];
      isPrivate: boolean;
    };
  }) => {
    const { environment } = props;
    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    toast("vanilla", {
      subject: `Creating group...`,
    });

    await runTransaction({
      environment: withTxLogger(environment, { data: props }),
      label: "createGroup",
      tx: async (transaction) => {
        ops.applyOperationsToTransaction(
          transaction,
          ops.group.createGroup({
            creatorUserId: currentUserId,
            isPrivateGroup: props.group.isPrivate,
            icon: props.group.icon,
            name: props.group.name,
            ownerOrganizationId: ownerOrganizationId,
            description: props.group.description,
            groupId: props.group.id,
            nestedInGroupIds: props.group.folderIds,
          }),
          ops.tag.setTagSubscription({
            tagId: props.group.id,
            creatorUserId: currentUserId,
            ownerOrganizationId,
            preference: "all-new",
            userId: currentUserId,
          }),
        );
      },
      undo: async (transaction) => {
        transaction.operations.push(
          op.delete(getPointer("tag", props.group.id)),
          op.delete(
            getPointer("tag_subscription", {
              tag_id: props.group.id,
              user_id: currentUserId,
            }),
          ),
          op.delete(
            getPointer("tag_user_member", {
              tag_id: props.group.id,
              user_id: currentUserId,
            }),
          ),
          op.delete(
            getPointer("tag_group_member", {
              tag_id: props.group.id,
              group_id: ownerOrganizationId,
            }),
          ),
        );

        props.group.folderIds.forEach((folderId) => {
          const pointer = getPointer("tag_folder_member", {
            tag_id: props.group.id,
            folder_id: folderId,
          });

          transaction.operations.push(op.delete(pointer));
        });
      },
    });

    toast("vanilla", {
      subject: `Creating group...Done!`,
    });
  },
);

export const updateTag = withPendingRequestBar(
  async (props: {
    environment: ClientEnvironment;
    tag: {
      id: string;
      icon: string | null;
      name: string;
      description: string | null;
      /** IDs of the tags which will act as folders for this tag */
      folderIds: string[];
    };
  }) => {
    const { environment } = props;
    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    toast("vanilla", {
      subject: `Creating group...`,
    });

    const [[oldTag], [oldFolders]] = await Promise.all([
      environment.recordLoader.getRecord("tag", props.tag.id),
      environment.recordLoader.getTagFolderMembers({
        tag_id: props.tag.id,
      }),
    ]);

    if (!oldTag) {
      toast("vanilla", {
        subject: `Error updating tag.`,
      });

      console.error(`updateTag: Tag with id ${props.tag.id} not found.`);
      return;
    }

    const oldFolderIds = oldFolders.map((folder) => folder.folder_id);
    const newFolderIds = difference(props.tag.folderIds, oldFolderIds);
    const removedFolderIds = difference(oldFolderIds, props.tag.folderIds);

    await runTransaction({
      environment: withTxLogger(environment, { data: props }),
      label: "updateTag",
      tx: async (transaction) => {
        transaction.operations.push(
          op.update(
            { table: "tag", id: props.tag.id },
            {
              name: props.tag.name,
              icon: props.tag.icon,
              description: props.tag.description,
            },
          ),
        );

        removedFolderIds.forEach((folderId) => {
          transaction.operations.push(
            op.delete(
              getPointer("tag_folder_member", {
                tag_id: props.tag.id,
                folder_id: folderId,
              }),
            ),
          );
        });

        newFolderIds.forEach((folderId) => {
          const pointer = getPointer("tag_folder_member", {
            tag_id: props.tag.id,
            folder_id: folderId,
          });

          transaction.operations.push(
            op.set("tag_folder_member", {
              id: pointer.id,
              tag_id: props.tag.id,
              folder_id: folderId,
              creator_user_id: currentUserId,
              owner_organization_id: ownerOrganizationId,
            }),
          );
        });
      },
      undo: async (transaction) => {
        transaction.operations.push(
          op.update(getPointer("tag", props.tag.id), {
            name: oldTag.name,
            description: oldTag.description,
          }),
        );

        newFolderIds.forEach((folderId) => {
          transaction.operations.push(
            op.delete(
              getPointer("tag_folder_member", {
                tag_id: props.tag.id,
                folder_id: folderId,
              }),
            ),
          );
        });

        removedFolderIds.forEach((folderId) => {
          const pointer = getPointer("tag_folder_member", {
            tag_id: props.tag.id,
            folder_id: folderId,
          });

          transaction.operations.push(
            op.set("tag_folder_member", {
              id: pointer.id,
              tag_id: props.tag.id,
              folder_id: folderId,
              creator_user_id: currentUserId,
              owner_organization_id: ownerOrganizationId,
            }),
          );
        });
      },
    });

    toast("vanilla", {
      subject: `Creating group...Done!`,
    });
  },
);

export const subscribeUsersToTag = withPendingRequestBar(
  async (props: {
    environment: ClientEnvironment;
    params: {
      tagId: string;
      userIds: string[];
      subscriptionPreference: TagSubscriptionPreference;
      notifyUsers: boolean;
    };
  }) => {
    const { environment, params } = props;
    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    toast("vanilla", {
      subject: `Subscribing users...`,
    });

    await runTransaction({
      environment: withTxLogger(environment, { data: props }),
      label: "subscribeUsersToTag",
      tx: async (transaction) => {
        const userProfilePointers = params.userIds.map((userId) => getPointer("user_profile", userId));

        const [[tag], [userProfiles]] = await Promise.all([
          environment.recordLoader.getRecord("tag", params.tagId),
          environment.recordLoader.getRecords(userProfilePointers),
        ]);

        if (!tag) {
          toast("vanilla", {
            subject: `Error subscribing users.`,
          });

          console.error("subscribeUsersToTag: Tag not found.");
          return;
        }

        const isPrivate = isTagPrivate(tag);

        params.userIds.forEach((userId) => {
          const pointer = getPointer("tag_subscription", {
            tag_id: params.tagId,
            user_id: userId,
          });

          transaction.operations.push(
            op.upsert(pointer, {
              onCreate: [
                op.set(pointer.table, {
                  id: pointer.id,
                  tag_id: params.tagId,
                  user_id: userId,
                  creator_user_id: currentUserId,
                  preference: params.subscriptionPreference,
                  owner_organization_id: ownerOrganizationId,
                }),
              ],
              onUpdate: [
                {
                  type: "upsert_on_update",
                  where: {
                    preference: { eq: "involved" },
                  },
                  operations: [
                    op.update(pointer, {
                      preference: params.subscriptionPreference,
                    }),
                  ],
                },
              ],
            }),
          );

          if (isPrivate) {
            const pointer = getPointer("tag_user_member", {
              tag_id: params.tagId,
              user_id: userId,
            });

            transaction.operations.push(
              op.upsert(pointer, {
                onCreate: [
                  op.set(pointer.table, {
                    id: pointer.id,
                    tag_id: params.tagId,
                    user_id: userId,
                    creator_user_id: currentUserId,
                    owner_organization_id: ownerOrganizationId,
                  }),
                ],
              }),
            );
          }
        });

        if (params.notifyUsers) {
          const now = new Date();

          params.userIds.forEach((userId) => {
            const userProfile = userProfiles.find((profile) => profile.id === userId)?.record;

            if (!userProfile) {
              console.error("subscribeUsersToTag: User profile not found.", userId);

              return;
            }

            const subjectText = isPrivate
              ? `You've been subscribed to the private "#${tag.name}" group`
              : `You've been subscribed to the "#${tag.name}" group`;

            const bodyHtml = getMessageContentForSubscriptionNotification({
              user: userProfile,
              tag,
            });

            ops.applyOperationsToTransaction(
              transaction,
              ops.draft.sendNewThreadDraft({
                scheduledToBeSentAt: now,
                draft: {
                  id: generateRecordId("draft"),
                  type: "COMMS",
                  to: [
                    {
                      id: generateRecordId("draft_user_recipient_doc", {
                        type: "USER",
                        user_id: userId,
                      }),
                      type: "USER",
                      user_id: userId,
                      is_implicit: false,
                      is_mentioned: true,
                      priority: 300,
                    },
                  ],
                  body_html: bodyHtml,
                  attachments: [],
                  branched_from_message_id: null,
                  branched_from_thread_id: null,
                  is_reply: false,
                  is_edit: false,
                  new_thread_subject: subjectText,
                  new_thread_visibility: "PRIVATE",
                  owner_organization_id: ownerOrganizationId,
                  thread_id: generateRecordId("thread"),
                  user_id: currentUserId,
                },
                branchedFromMessage: undefined,
              }),
            );
          });
        }

        if (params.notifyUsers) {
          toast("vanilla", {
            subject: `Subscribing users...Done!`,
            description: `and notifications sent.`,
          });
        } else {
          toast("vanilla", {
            subject: `Subscribing users...Done!`,
          });
        }
      },
    });
  },
);

export const archiveTag = withPendingRequestBar(async (props: { environment: ClientEnvironment; tagId: string }) => {
  const { environment, tagId } = props;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "archiveTag",
    tx: async (transaction) => {
      transaction.operations.push(
        op.update({ table: "tag", id: tagId }, { archived_at: op.fieldValue.SERVER_TIMESTAMP() }),
      );
    },
  });

  toast("vanilla", {
    subject: `Group archived!`,
  });
});

export const unarchiveTag = withPendingRequestBar(async (props: { environment: ClientEnvironment; tagId: string }) => {
  const { environment, tagId } = props;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "unarchiveTag",
    tx: async (transaction) => {
      transaction.operations.push(op.update({ table: "tag", id: tagId }, { archived_at: null }));
    },
  });

  toast("vanilla", {
    subject: `Group unarchived!`,
  });
});

function getMessageContentForSubscriptionNotification(props: {
  user: RecordValue<"user_profile">;
  tag: RecordValue<"tag">;
}) {
  const { user, tag } = props;

  const isPrivate = isTagPrivate(tag);
  const tagType = tag.type === SpecialTagTypeEnum.GROUP ? "group" : "tag";

  return renderJsxToString(
    <>
      <p>
        Hi{" "}
        <span data-type="mention" data-id={user.id} data-label={user.name} data-subject="user" data-priority="300">
          @{user.name}
        </span>
        ,
      </p>
      <p></p>
      <p>
        I've subscribed you to the{isPrivate ? " private " : " "}
        <a href={`/${tagType}s/${tag.id}`}>{renderGroupName(tag)}</a> {tagType}.
      </p>
    </>,
  );
}
