import { PointerWithRecord } from "libs/schema";
import { RefObject } from "react";
import { map } from "rxjs";
import { triageThread } from "~/actions/notification";
import { IListRef } from "~/components/list";
import { RemindMeDialogState } from "~/dialogs/remind-me";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { GetOptions } from "~/environment/RecordLoader";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import {
  markDoneCommand,
  markNotDoneCommand,
  removeThreadReminderCommand,
  setThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";

export function useRegisterBulkRecordActionCommands(props: {
  priority?: number | { delta: number };
  listRef: RefObject<IListRef<PointerWithRecord<any>>>;
  isListRefSet?: boolean;
}): void {
  const environment = useClientEnvironment();

  useRegisterCommands({
    priority: props.priority,
    commands() {
      const list = props.listRef.current;

      if (!list) return [];

      return list.selectedEntryIds$.pipe(
        map((ids) => {
          const commands: ICommandArgs[] = [];

          const options: GetOptions = {};

          if (ids.size === 0) return commands;

          commands.push({
            label: "Clear selection",
            altLabels: ["Unselect entries"],
            hotkeys: ["Escape"],
            callback() {
              list.deselectAll();
            },
          });

          const selectedEntries = list.entries.filter((entry) => ids.has(entry.id));

          const { threadIds, draftIds } = selectedEntries.reduce(
            (store, entry) => {
              if (entry.data.table === "draft") {
                store.draftIds.push(entry.data.id);
              } else if (entry.data.table === "thread") {
                store.threadIds.push(entry.data.id);
              } else if ("thread_id" in entry.data.record) {
                store.threadIds.push(entry.data.record.thread_id);
              } else {
                environment.logger.warn({ entry }, `[useRegisterStandardBulkEntryActionCommands] unknown entry type`);
              }

              return store;
            },
            { threadIds: [] as string[], draftIds: [] as string[] },
          );

          if (threadIds.length) {
            commands.push(
              markDoneCommand({
                callback() {
                  triageThread(
                    environment,
                    {
                      threadId: threadIds,
                      done: true,
                    },
                    options,
                  );
                },
              }),
              markNotDoneCommand({
                callback() {
                  triageThread(
                    environment,
                    {
                      threadId: threadIds,
                      done: false,
                    },
                    options,
                  );
                },
              }),
              setThreadReminderCommand({
                callback: () => {
                  RemindMeDialogState.open({
                    threadId: threadIds,
                    fetchStrategy: environment.recordLoader.options.defaultFetchStrategy,
                  });
                },
              }),
              removeThreadReminderCommand({
                callback: () => {
                  triageThread(
                    environment,
                    {
                      threadId: threadIds,
                      triagedUntil: null,
                    },
                    options,
                  );
                },
              }),
              starThreadCommand({
                callback: () => {
                  triageThread(
                    environment,
                    {
                      threadId: threadIds,
                      isStarred: true,
                    },
                    options,
                  );
                },
              }),
              unstarThreadCommand({
                callback: () => {
                  triageThread(
                    environment,
                    {
                      threadId: threadIds,
                      isStarred: false,
                    },
                    options,
                  );
                },
              }),
            );
          }

          if (draftIds.length) {
            commands.push();
          }

          return commands;
        }),
      );
    },
    deps: [props.isListRefSet, props.listRef, environment],
  });
}
