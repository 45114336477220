import { useRecordLoader } from "./useRecordLoader";
import { groupBy } from "lodash-es";

export function useSentMessages() {
  return useRecordLoader({
    load({ loader, limit, currentUserId }) {
      return loader.observeGetSentMessages({
        userId: currentUserId,
        limit,
      });
    },
    initialLimit: 50,
    limitStep: 50,
    map: (records) => {
      const distinctMessageIdsByThreadId = Object.values(groupBy(records, "thread_id")).map(
        (records) => records[0]!.id,
      );

      return distinctMessageIdsByThreadId;
    },
  });
}
