import { isEqual } from "libs/predicates";
import { RefObject, useEffect } from "react";
import { combineLatest, switchMap } from "rxjs";
import { IRichTextEditorRef } from "~/components/forms/message-editor";
import { observable } from "~/components/forms/utils";
import { useIsWindowFocused } from "./focus.service";
import { IComposeMessageForm, IComposeMessageFormValue } from "~/components/ComposeMessageContext";
import { getSessionStorage } from "./KVStore";

/**
 * A problem I've experienced using Gmail occurs when opening a draft in
 * two different tabs. If I start editing the draft in one tab the other
 * tab doesn't update with the changes if the draft is also open in that
 * tab. If I close the draft with the changes in the first tab the draft
 * is saved with the state from that tab. If I then close the draft in the
 * second tab the draft is saved with the state from that tab, overwriting
 * the changes made in the first tab (which is very annoying/undesireable).
 *
 * To prevent this from happening in Comms, we quickly sync the draft state
 * between all the tabs. This implementation dates back to when we were
 * using Firestore as our database. Firestore was too slow to sync the draft
 * data between tabs so here we sync the draft data using a special session
 * storage database which, itself, is synced between tabs using a
 * BroadcastChannel. This being said, now that we have a proper in-memory
 * database, as well as persisted database, both of which are much faster than
 * Firestore, we should consider refactoring this to be more generic. We're
 * already syncing optimistic updates between tabs. We might find simply reading
 * from the peristed database is performant enough for syncing draft data.
 */
export function useSyncDraftBetweenTabs(control: IComposeMessageForm, editorRef: RefObject<IRichTextEditorRef>) {
  useEffect(() => {
    // On mount, we check to see if there is local draft data for this
    // draft and update this form with that data if so. After mounting,
    // an effect below will be responsible for handling updates to the
    // draft data in sessionStorage.

    const localDraftData = getSessionStorage().getItem<IComposeMessageFormValue>(
      getDraftDataStorageKey(control.rawValue.messageId),
    );

    if (!localDraftData) return;

    if ("sent" in localDraftData) {
      return;
    } else if (!localDraftData.body.content) {
      return;
    }

    editorRef.current?.editor?.commands.setContent(localDraftData.body.content, true, { preserveWhitespace: "full" });

    if (localDraftData.recipients) {
      control.controls.recipients?.setValue(localDraftData.recipients);
    }

    if (localDraftData.subject) {
      control.controls.subject?.setValue(localDraftData.subject);
    }

    if (localDraftData.visibility) {
      control.controls.visibility?.setValue(localDraftData.visibility);
    }

    if (localDraftData.type) {
      control.controls.type?.setValue(localDraftData.type);
    }

    // onMount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWindowFocused = useIsWindowFocused();

  useEffect(() => {
    if (!isWindowFocused) return;

    const sub = combineLatest([
      observable(() => getDraftDataStorageKey(control.rawValue.messageId)),
      observable(() => control.rawValue.recipients),
      observable(() => control.rawValue.subject),
      observable(() => control.rawValue.visibility),
      observable(() => control.rawValue.type),
      observable(() => control.rawValue.body.content),
    ]).subscribe(([sessionStorageKey]) => {
      getSessionStorage().setItem<IComposeMessageFormValue>(sessionStorageKey, control.rawValue);
    });

    return () => sub.unsubscribe();
    // eslint incorrectly thinks that "props" is a dependency
  }, [control, isWindowFocused]);

  useEffect(() => {
    if (isWindowFocused) return;

    const sub = observable(() => getDraftDataStorageKey(control.rawValue.messageId))
      .pipe(switchMap((sessionStorageKey) => getSessionStorage().getItem$<IComposeMessageFormValue>(sessionStorageKey)))
      .subscribe((value) => {
        if (!value) return;

        if (value.recipients) {
          control.controls.recipients?.setValue(value.recipients);
        }

        if (value.subject) {
          control.controls.subject?.setValue(value.subject);
        }

        if (value.visibility) {
          control.controls.visibility?.setValue(value.visibility);
        }

        if (value.type) {
          control.controls.type?.setValue(value.type);
        }

        const editor = editorRef.current?.editor;

        if (!editor) return;
        if (isEqual(editor.getHTML(), value.body.content)) return;

        editor.commands.setContent(value.body.content, true, {
          preserveWhitespace: "full",
        });
      });

    return () => sub.unsubscribe();
    // eslint incorrectly thinks that "props" is a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control, isWindowFocused]);
}

export function getDraftDataStorageKey(draftId: string) {
  return `DRAFT:${draftId}`;
}
