import { finalizeWithValue, startWith } from "libs/rxjs-operators";
import {
  GetRecordResult,
  GetRecordsResult,
  Query,
  QueryResult,
  SharedQueryApi,
  SharedQueryParams,
} from "libs/database";
import {
  getPointer,
  iterateRecordMap,
  PointerWithRecord,
  RecordMap,
  RecordPointer,
  RecordTable,
  RecordValue,
} from "libs/schema";
import { differenceWith, lowerFirst } from "lodash-comms";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, pairwise, Subject, scan } from "rxjs";
import { Simplify } from "type-fest";
import { SubscriptionQuery } from "./SubscriptionManager";
import { DeferredPromise } from "libs/promise-utils";
import { isEqual } from "libs/predicates";
import { SharedQueryApiKey } from "libs/database";
import { ObserveSharedQueryApiKey, OriginalSharedQueryApiKey } from "libs/database/client";
import { ClientEnvironment } from "./ClientEnvironment";
import { Logger } from "libs/logger";
import { FetchStrategy, QueryCacheDeferredPromise } from "./QueryCache";
import { arrayChange } from "libs/array-utils";

export type { FetchStrategy };

/**
 * The client record loader handles loading records and queries from the local
 * cache/database as well transparently as fetching data from the server, as
 * appropriate. When subscribing to queries, the record loader will also
 * subscribe to query updates from the server.
 */
export type ClientRecordLoaderApi = Simplify<
  GetQueryMethods &
    ObserveQueryMethods & {
      readonly options: ClientRecordLoaderOptions;
      getRecord: GetRecordMethod;
      getRecords: GetRecordsMethod;
      observeGetRecord: ObserveGetRecordMethod;
      observeGetRecords: ObserveGetRecordsMethod;
      createObserveGetResult: CreateObserveGetRecordResultMethod;
      createObserveQueryResult: CreateObserveQueryResultMethod;
      /**
       * Causes all active queries to attempt reloading data using the ApiLoader.
       * If the query data is already cached in the ApiLoader, then that query
       * will be skipped. I.e. consider clearing the ApiLoader cache before
       * calling this method if you want to force a reload.
       */
      reloadActiveQueries: () => void;
    }
>;

export type ClientRecordLoaderOptions = {
  readonly defaultFetchStrategy: FetchStrategy;
};

type GetQueryMethods = { [K in SharedQueryApiKey]: GetQueryMethod<K> };

type ObserveQueryMethods = {
  [K in ObserveSharedQueryApiKey]: ObserveQueryMethod<OriginalSharedQueryApiKey<K>>;
};

type GetRecordMethod = {
  <T extends RecordTable>(
    params: RecordPointer<T>,
    options?: GetOptions,
  ): Promise<ClientRecordLoaderGetRecordResult<T>>;
  <T extends RecordTable>(table: T, id: string, options?: GetOptions): Promise<ClientRecordLoaderGetRecordResult<T>>;
};

type GetRecordsMethod = <T extends RecordTable>(
  pointers: RecordPointer<T>[],
  options?: GetOptions,
) => Promise<ClientRecordLoaderGetRecordsResult<T>>;

type GetQueryMethod<Name extends SharedQueryApiKey> = (
  params: SharedQueryParams<Name>,
  options?: GetOptions,
) => ReturnType<SharedQueryApi[Name]> extends Query<infer T> ? Promise<ClientRecordLoaderQueryResult<T>> : never;

type ObserveGetRecordMethod = <T extends RecordTable>(
  pointer: RecordPointer<T>,
  options?: ObserveOptions,
) => Observable<ClientRecordLoaderObserveGetRecordResult<T>>;

type ObserveGetRecordsMethod = <T extends RecordTable>(
  pointers: RecordPointer<T>[],
  options?: ObserveOptions,
) => Observable<ClientRecordLoaderObserveGetRecordsResult<T>>;

type ObserveQueryMethod<Name extends SharedQueryApiKey> = (
  params: SharedQueryParams<Name>,
  options?: ObserveOptions,
) => ReturnType<SharedQueryApi[Name]> extends Query<infer T>
  ? Observable<ClientRecordLoaderObserveQueryResult<T>>
  : never;

type CreateObserveGetRecordResultMethod = <T extends RecordTable>(
  options?: CreateObserveGetRecordResultOptions<T>,
) => Observable<ClientRecordLoaderObserveGetRecordResult<T>>;

type CreateObserveQueryResultMethod = <T extends RecordTable>(
  options?: CreateObserveQueryResultOptions<T>,
) => Observable<ClientRecordLoaderObserveQueryResult<T>>;

export type ClientRecordLoaderGetRecordResult<T extends RecordTable = RecordTable> = [
  RecordValue<T> | null,
  { error?: unknown },
];

export type ClientRecordLoaderGetRecordsResult<T extends RecordTable = RecordTable> = [
  PointerWithRecord<T>[],
  { error?: unknown },
];

export type ClientRecordLoaderQueryResult<T extends RecordTable = RecordTable> = [
  RecordValue<T>[],
  {
    nextId: string | null;
    limit: number | null;
    recordMap: RecordMap;
    error?: unknown;
  },
];

export type ClientRecordLoaderObserveGetRecordResult<T extends RecordTable = RecordTable> = [
  record: RecordValue<T> | null,
  meta: ClientRecordLoaderObserveGetRecordResultMeta,
];

export type ClientRecordLoaderObserveGetRecordResultMeta = {
  isLoading: boolean;
  error?: unknown;
};

export type ClientRecordLoaderObserveGetRecordsResult<T extends RecordTable = RecordTable> = [
  pointersWithRecord: PointerWithRecord<T>[],
  meta: ClientRecordLoaderObserveGetRecordsResultMeta,
];

export type ClientRecordLoaderObserveGetRecordsResultMeta = {
  isLoading: boolean;
  error?: unknown;
};

export type ClientRecordLoaderObserveQueryResult<T extends RecordTable = RecordTable> = [
  records: RecordValue<T>[],
  meta: ClientRecordLoaderObserveQueryResultMeta,
];

export type ClientRecordLoaderObserveQueryResultMeta = {
  recordMap: RecordMap;
  nextId: string | null;
  limit: number | null;
  isLoading: boolean;
  error?: unknown;
};

export type CreateObserveGetRecordResultOptions<T extends RecordTable> = [
  record?: RecordValue<T> | null,
  meta?: Partial<ClientRecordLoaderObserveGetRecordResultMeta>,
];

export type CreateObserveQueryResultOptions<T extends RecordTable> = [
  records?: RecordValue<T>[],
  meta?: Partial<ClientRecordLoaderObserveQueryResultMeta>,
];

export type GetOptions = {
  fetchStrategy?: FetchStrategy;
  /**
   * Note: this option isn't yet implemented for all RecordLoader
   * methods. Check to make sure it's implemented for the method you care about
   * before relying on it.
   */
  abortSignal?: AbortSignal;
};

export type ObserveOptions = {
  fetchStrategy?: FetchStrategy;
  isLoading?: boolean;
};

/**
 * Creates a new RecordLoader instance.
 */
export function createRecordLoader(
  environment: Pick<ClientEnvironment, "db" | "queryCache" | "subscriptionManager" | "persistedDb" | "logger">,
  options?: { defaultFetchStrategy?: FetchStrategy },
) {
  const recordLoader = new RecordLoader(environment, options);

  const proxy = new Proxy(recordLoader as unknown as ClientRecordLoaderApi, {
    get(_0, prop: keyof ClientRecordLoaderApi) {
      if (prop in recordLoader) {
        return recordLoader[prop as keyof RecordLoader];
      }

      if (prop.startsWith("get")) {
        return async (params: { limit?: number }, options: GetOptions = {}): Promise<ClientRecordLoaderQueryResult> => {
          return recordLoader.getQuery(
            {
              type: prop as SharedQueryApiKey,
              params,
            },
            options,
          );
        };
      }

      if (prop.startsWith("observe")) {
        const methodName = prop as ObserveSharedQueryApiKey;

        const originalMethodName = lowerFirst(prop.replace(/^observe/, "")) as OriginalSharedQueryApiKey<
          typeof methodName
        >;

        return (params: { limit?: number }, options?: ObserveOptions) => {
          return recordLoader.observeQuery(methodName, { type: originalMethodName, params }, options);
        };
      }

      throw new Error(`Unexpected property called on RecordLoader: ${prop}`);
    },
  });

  return proxy;
}

/* -------------------------------------------------------------------------------------------------
 * RecordLoader
 * -------------------------------------------------------------------------------------------------
 * Note that this RecordLoader class is a private implementation detail of the
 * createRecordLoader function and is not exported from this module.
 */

/**
 * This class is instantiated and used by the createRecordLoader function to
 * provide the implementation of the ClientRecordLoaderApi. It's public methods
 * are exposed via the proxy object returned by createRecordLoader. Because of
 * this, it's public methods are implemented using arrow functions so that the
 * RecordLoader is captured as the `this` context when the methods are called.
 */
class RecordLoader {
  logger: Logger;

  readonly options: ClientRecordLoaderOptions;
  private refetchEvents$ = new Subject<void>();

  constructor(
    private env: Pick<ClientEnvironment, "db" | "queryCache" | "subscriptionManager" | "persistedDb" | "logger">,
    options: { defaultFetchStrategy?: FetchStrategy } = {},
  ) {
    this.logger = env.logger.child({ name: "RecordLoader" });

    this.options = {
      ...options,
      defaultFetchStrategy: options.defaultFetchStrategy || "server-first",
    };
  }

  getRecord: GetRecordMethod = async (
    a: RecordTable | RecordPointer,
    b?: string | GetOptions,
    c?: GetOptions,
  ): Promise<ClientRecordLoaderGetRecordResult> => {
    const pointer = typeof a === "string" ? getPointer(a, b as string) : getPointer(a);

    const options = (typeof b === "string" ? c : b) || {};

    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const loader = this.env.queryCache.loadRecord({
      pointer,
      fetchStrategy,
      // Normally, when the subscription for this query is closed that would signal
      // garbage collection for this query. But since we aren't subscribing to this
      // query there's nothing to signal that this query should be garbage collected.
      // Because of this we need to skip caching the query results.
      skipCachingQuery: true,
    });

    const getRecord = (error?: unknown): ClientRecordLoaderGetRecordResult => [
      ...this.env.db.getRecord(pointer),
      { error },
    ];

    try {
      await loader.promise;
      return getRecord();
    } catch (error) {
      return getRecord(error);
    }
  };

  getRecords: GetRecordsMethod = async <T extends RecordTable>(
    pointers: RecordPointer<T>[],
    options: GetOptions = {},
  ) => {
    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const loader = this.env.queryCache.loadRecords({
      pointers,
      fetchStrategy,
      // Normally, when the subscription for this query is closed that would signal
      // garbage collection for this query. But since we aren't subscribing to this
      // query there's nothing to signal that this query should be garbage collected.
      // Because of this we need to skip caching the query results.
      skipCachingQuery: true,
    });

    const getRecords = (error?: unknown): ClientRecordLoaderGetRecordsResult<T> => [
      ...this.env.db.getRecords(pointers),
      { error },
    ];

    try {
      await loader.promise;
      return getRecords();
    } catch (error) {
      return getRecords(error);
    }
  };

  getQuery = async <T extends SharedQueryApiKey>(
    query: {
      type: T;
      params: SharedQueryParams<T>;
    },
    options: GetOptions = {},
  ): Promise<ClientRecordLoaderQueryResult> => {
    const { limit: originalLimit = null } = query.params as { limit?: number };

    if (originalLimit) {
      // We overfetch by one so that we can determine if there are
      // more records to fetch.
      query = {
        ...query,
        params: { ...query.params, limit: originalLimit + 1 },
      };
    }

    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const loader = this.env.queryCache.loadQuery({
      ...query,
      fetchStrategy,
      // Normally, when the subscription for this query is closed that would signal
      // garbage collection for this query. But since we aren't subscribing to this
      // query there's nothing to signal that this query should be garbage collected.
      // Because of this we need to skip caching the query results.
      skipCachingQuery: true,
    });

    const runQuery = (error?: unknown): ClientRecordLoaderQueryResult => {
      const queryResult: QueryResult<any> = this.env.db[query.type](query.params as any);

      const [totalRecords, { recordMap }] = queryResult;

      const records = originalLimit ? totalRecords.slice(0, originalLimit) : totalRecords;

      const nextId = totalRecords.length > records.length ? totalRecords.at(-1)?.id || null : null;

      return [
        records,
        {
          nextId,
          limit: originalLimit,
          recordMap,
          error,
        },
      ];
    };

    try {
      await loader.promise;
      return runQuery();
    } catch (error) {
      return runQuery(error);
    }
  };

  observeGetRecord: ObserveGetRecordMethod = <T extends RecordTable>(
    pointer: RecordPointer<T>,
    options: ObserveOptions = {},
  ): Observable<ClientRecordLoaderObserveGetRecordResult<T>> => {
    const subscribeToQueryProps = this.env.subscriptionManager.getSubscribeToQueryProps({
      type: "getRecord",
      params: pointer as RecordPointer,
    });

    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const getQueryPromise = () => this.env.queryCache.loadRecord({ pointer, fetchStrategy });

    return new Observable((subscriber) => {
      const queryPromise = getQueryPromise();

      const pubsubUnsubscribeFns = this.env.subscriptionManager.subscribeToQuery(subscribeToQueryProps);

      const isLoading$ = new BehaviorSubject(true);
      const error$ = new BehaviorSubject<unknown | undefined>(undefined);

      this.initializeIsLoadingAndErrorObservables({
        isLoading$,
        error$,
        queryPromise,
        forceIsLoading: options.isLoading,
      });

      const record$ = this.env.db.observeGetRecord(pointer).pipe(
        catchError((error) => {
          error$.next(error);
          return of<GetRecordResult<any>>([null]);
        }),
      );

      const query$ = combineLatest([record$, isLoading$, error$]).pipe(
        map(([[record], isLoading, error]) => [record, { isLoading, error }]),
      );

      const refetchSubscription = this.refetchEvents$.subscribe(() => {
        isLoading$.next(true);
        const queryPromise = getQueryPromise();

        this.initializeIsLoadingAndErrorObservables({
          isLoading$,
          error$,
          queryPromise,
          forceIsLoading: options.isLoading,
        });
      });

      const querySubscription = query$.subscribe(subscriber as any);

      return () => {
        pubsubUnsubscribeFns.forEach(([_, fn]) => fn());
        querySubscription.unsubscribe();
        refetchSubscription.unsubscribe();
      };
    });
  };

  observeGetRecords: ObserveGetRecordsMethod = <T extends RecordTable>(
    pointers: RecordPointer<T>[],
    options: ObserveOptions = {},
  ): Observable<ClientRecordLoaderObserveGetRecordsResult<T>> => {
    if (pointers.length === 0) {
      return of([[], { isLoading: false }]);
    }

    const subscriptionProps = pointers.map((pointer) =>
      this.env.subscriptionManager.getSubscribeToQueryProps({
        type: "getRecord",
        params: pointer as RecordPointer,
      }),
    );

    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const getQueryPromise = () => this.env.queryCache.loadRecords({ pointers, fetchStrategy });

    return new Observable((subscriber) => {
      const queryPromise = getQueryPromise();

      const pubsubUnsubscribeFns = subscriptionProps.flatMap((props) =>
        this.env.subscriptionManager.subscribeToQuery(props),
      );

      const isLoading$ = new BehaviorSubject(true);
      const error$ = new BehaviorSubject<unknown | undefined>(undefined);

      this.initializeIsLoadingAndErrorObservables({
        isLoading$,
        error$,
        queryPromise,
        forceIsLoading: options.isLoading,
      });

      const record$ = this.env.db.observeGetRecords(pointers).pipe(
        catchError((error) => {
          error$.next(error);
          return of<GetRecordsResult<any>>([[]]);
        }),
      );

      const query$ = combineLatest([record$, isLoading$, error$]).pipe(
        map(([[records], isLoading, error]) => [records, { isLoading, error }]),
      );

      const refetchSubscription = this.refetchEvents$.subscribe(() => {
        isLoading$.next(true);
        const queryPromise = getQueryPromise();

        this.initializeIsLoadingAndErrorObservables({
          isLoading$,
          error$,
          queryPromise,
          forceIsLoading: options.isLoading,
        });
      });

      const querySubscription = query$.subscribe(subscriber as any);

      return () => {
        pubsubUnsubscribeFns.forEach(([_, fn]) => fn());
        querySubscription.unsubscribe();
        refetchSubscription.unsubscribe();
      };
    });
  };

  observeQuery = <T extends SharedQueryApiKey>(
    methodName: ObserveSharedQueryApiKey,
    query: {
      type: T;
      params: SharedQueryParams<T>;
    },
    options: ObserveOptions = {},
  ): Observable<ClientRecordLoaderObserveQueryResult> => {
    const { limit: originalLimit = null } = query.params as { limit?: number };

    if (originalLimit) {
      // We overfetch by one so that we can determine if there are
      // more records to fetch.
      query = {
        ...query,
        params: { ...query.params, limit: originalLimit + 1 },
      };
    }

    const fetchStrategy = options.fetchStrategy || this.options.defaultFetchStrategy;

    const getQueryPromise = () => this.env.queryCache.loadQuery({ ...query, fetchStrategy });

    return new Observable<ClientRecordLoaderObserveQueryResult<any>>((subscriber) => {
      const queryPromise = getQueryPromise();

      const isLoading$ = new BehaviorSubject(true);
      const error$ = new BehaviorSubject<unknown | undefined>(undefined);

      this.initializeIsLoadingAndErrorObservables({
        isLoading$,
        error$,
        queryPromise,
        forceIsLoading: options.isLoading,
      });

      const observable = (this.env.db[methodName](query.params as any) as Observable<QueryResult<any>>).pipe(
        catchError((error): Observable<QueryResult<any>> => {
          error$.next(error);
          return of<QueryResult<any>>([[], { recordMap: {} }]);
        }),
      );

      const manageQuerySubscriptionKeysSub = this.subscribeToQuerySubscriptionKeys({
        query: query as SubscriptionQuery,
        queryObservable: observable,
        fetchStrategy,
      });

      const query$ = combineLatest([observable, isLoading$, error$]).pipe(
        map(([[queryRecords, { recordMap }], isLoading, error]) => {
          const records = originalLimit ? queryRecords.slice(0, originalLimit) : queryRecords;

          const nextId = queryRecords.length > records.length ? queryRecords.at(-1)?.id || null : null;

          return [
            records,
            {
              nextId,
              limit: originalLimit,
              recordMap,
              isLoading,
              error,
            },
          ] as ClientRecordLoaderObserveQueryResult;
        }),
      );

      // Important that this comes after the individualRecordsSubscription
      // so that, by the time this query emits we've already subscribed
      // to all of the individual records.
      const querySubscription = query$.subscribe(subscriber);

      const refetchSubscription = this.refetchEvents$.subscribe(() => {
        isLoading$.next(true);
        const queryPromise = getQueryPromise();

        this.initializeIsLoadingAndErrorObservables({
          isLoading$,
          error$,
          queryPromise,
          forceIsLoading: options.isLoading,
        });
      });

      return () => {
        manageQuerySubscriptionKeysSub.unsubscribe();
        querySubscription.unsubscribe();
        refetchSubscription.unsubscribe();
      };
    });
  };

  createObserveGetResult: CreateObserveGetRecordResultMethod = (options = []) => {
    const [record = null, { isLoading = false } = {}] = options;
    return of([record, { isLoading }]);
  };

  createObserveQueryResult: CreateObserveQueryResultMethod = (options = []) => {
    const [records = [], { recordMap = {}, nextId = null, limit = null, isLoading = false } = {}] = options;

    return of([records, { recordMap, nextId, limit, isLoading }]);
  };

  reloadActiveQueries = () => {
    this.refetchEvents$.next();
  };

  private initializeIsLoadingAndErrorObservables(props: {
    isLoading$: BehaviorSubject<boolean>;
    error$: BehaviorSubject<unknown | undefined>;
    queryPromise: DeferredPromise<any, any>;
    forceIsLoading?: boolean;
  }) {
    const { isLoading$, error$, queryPromise, forceIsLoading } = props;

    if (forceIsLoading) {
      isLoading$.next(true);
    } else if (queryPromise.settled) {
      if (queryPromise.error) {
        error$.next(queryPromise.error);
      }

      isLoading$.next(false);
    } else {
      queryPromise.promise
        .then(() => isLoading$.next(false))
        .catch((error) => {
          error$.next(error);
          isLoading$.next(false);
        });
    }
  }

  private subscribeToQuerySubscriptionKeys(props: {
    query: SubscriptionQuery;
    queryObservable: Observable<QueryResult<any>>;
    fetchStrategy: FetchStrategy;
  }) {
    const { query, queryObservable, fetchStrategy } = props;

    // While we can determine the subscriptionKeys for most queries just based off
    // the query params, some queries are more complex. Those queries require the
    // query results to fully construct an array of all the subscriptionKeys
    // necessary for subscribing to the query. As a consequence, we get the
    // `initialSubscriptionProps` (which contain the subscriptionKeys) once before
    // running the query and then we need to get the subscriptionProps again whenever
    // the query results update. We then need to diff the subscriptionKeys to determine
    // which subscriptionKeys we need to unsubscribe from and which subscriptionKeys
    // we need to subscribe to.

    const initialSubscriptionProps = this.env.subscriptionManager.getSubscribeToQueryProps(query);

    // The queryKey is created from the query params and will not change
    const queryKey = initialSubscriptionProps.queryKey;

    const pubsubUnsubscribeFns = this.env.subscriptionManager.subscribeToQuery(initialSubscriptionProps);

    const subscriptionKeyToUnsubscribeFnMap: Record<string, () => void> = {};

    pubsubUnsubscribeFns.forEach(([subscriptionKey, unsubscribeFn]) => {
      subscriptionKeyToUnsubscribeFnMap[subscriptionKey] = unsubscribeFn;
    });

    const manageQuerySubscriptionKeysSub = queryObservable
      .pipe(
        map(([_, { recordMap }]) => {
          const queryProps = this.env.subscriptionManager.getSubscribeToQueryProps({
            ...query,
            resultRecordMap: recordMap,
          });

          return {
            subscriptionKeys: queryProps.subscriptionKeys,
            resultPointers: Array.from(iterateRecordMap(recordMap)).map(getPointer),
          };
        }),
        startWith(() => ({
          subscriptionKeys: initialSubscriptionProps.subscriptionKeys,
          resultPointers: [] as RecordPointer[],
        })),
        pairwise(),
        scan((store: Record<string, () => void>, [prev, next]) => {
          const { added: addedSubscriptionKeys, removed: removedSubscriptionKeys } = arrayChange(
            next.subscriptionKeys,
            prev.subscriptionKeys,
          );

          for (const subscriptionKey of removedSubscriptionKeys) {
            // The subscriptionKeyToQueryCacheKeyMap maps subscriptionKeys to the
            // query's that depend on those subscriptions. In order for a query to
            // be considered "subscribed", all the subscriptionKeys required by that
            // query must be subscribed to. So if we unsubscribe from a subscriptionKey
            // and the subscriptionManager thinks that that key is required by this
            // query, then the subscriptionManager will think that this query is no
            // longer subscribed to.
            //
            // In this case, the subscriptionKeys we are deleting are keys which are
            // no longer required by this query. So we need to tell the subscription
            // manager that this key is no longer associated with this query.
            this.env.subscriptionManager.subscriptionKeyToQueryCacheKeyMap.get(subscriptionKey)?.delete(queryKey);

            // We want to unsubscribe from the subscription. If this is the last
            // subscriber to this subscription, the subscriptionManager will perform
            // cleanup for this subscription. Because of this, it's important that
            // we've already updated the subscriptionKeyToQueryCacheKeyMap to deassociate
            // this query from this subscription.
            const unsubscribe = store[subscriptionKey];
            unsubscribe?.();
            delete store[subscriptionKey];
          }

          const unsubscribeFns = this.env.subscriptionManager.subscribeToQuery({
            queryKey,
            subscriptionKeys: addedSubscriptionKeys,
          });

          for (const [subscriptionKey, unsubscribeFn] of unsubscribeFns) {
            store[subscriptionKey] = unsubscribeFn;
          }

          const addedPointers = differenceWith(next.resultPointers, prev.resultPointers, isEqual);

          for (const pointer of addedPointers) {
            // A side effect of this query is that we've subscribed to all the records
            // returned by this query. As an optimization, if we try to fetch these
            // records again we want the queryCache to know that these records are cached.
            // We don't need to worry about updating the queryCache when the records
            // are unsubscribed since the subscriptionManager will handle doing that for
            // us.
            const loader: QueryCacheDeferredPromise = new DeferredPromise();

            loader.resolve();

            this.env.queryCache.setCachedPromiseIfFresher("getRecord", pointer, loader, fetchStrategy);
          }

          return store;
        }, subscriptionKeyToUnsubscribeFnMap),
        finalizeWithValue((store) => {
          Object.values(store).forEach((sub) => sub());
        }),
      )
      .subscribe();

    return manageQuerySubscriptionKeysSub;
  }
}
