import { ComponentType } from "react";
import { Outlet } from "react-router-dom";
import { withAuthGuard } from "~/route-guards/withAuthGuard";
import { Sidebar } from "./Sidebar";
import { SidebarLayoutContext, useSidebarLayoutContext, withProvideSidebarLayoutContext } from "./context";
import { PageDialogRenderer } from "~/page-dialogs/PageDialogRenderer";
import { KBarDialog } from "~/dialogs/kbar";
import { withNewCommandContext } from "~/environment/command.service";
import { DarkMode } from "~/components/DarkMode";
import {
  useRegisterComposeNewMessageCommands,
  useRegisterGeneralNavigationCommands,
  useRegisterGroupCommands,
  useRegisterOrganizationCommands,
  useRegisterSettingsCommands,
  useRegisterSidebarLayoutCommands,
  useRegisterToggleOfflineModeCommands,
  useRegisterUndoRedoCommands,
  useRegisterUserProfileCommands,
} from "./commands";
import { OpenComposeMessageService } from "~/page-dialogs/page-dialog-state";
import { RemindMeDialog } from "~/dialogs/remind-me";
import { ToggleScheduledDeliveryDialog } from "~/dialogs/toggle-scheduled-delivery/ToggleScheduledDeliveryDialog";
import { DeliverMessagesNowDialog } from "~/dialogs/deliver-messages-now/DeliverMessagesNowDialog";
import { HelpDialog } from "~/dialogs/help/HelpDialog";
import { EditGroupDialog } from "~/dialogs/group-edit/EditGroupDialog";
import { TagInviteDialog } from "~/dialogs/tag-invite/TagInviteDialog";
import { EditInboxSectionsDialog } from "~/dialogs/edit-inbox-sections/EditInboxSectionsDialog";
import { AddGroupToThreadDialog } from "~/dialogs/thread-add-group/AddGroupToThreadDialog";
import { withPaymentGuard } from "~/route-guards/withPaymentGuard";
import FeedbackButton from "./FeedbackButton";
import { InviteUsersDialog } from "~/dialogs/invite-users/InviteUsersDialog";
import { withOrgMemberGuard } from "~/route-guards/withOrgMemberGuard";
import SendingMessageToast from "./SendingMessageToast";
import { AlertDialog } from "~/dialogs/alert/AlertDialog";
import { PWAInstallDialog } from "~/dialogs/pwa-install/PWAInstallDialog";
import BetaBanner from "./BetaBanner";

export const SidebarLayout: ComponentType<{}> = withAuthGuard(
  withPaymentGuard(
    withOrgMemberGuard(
      withNewCommandContext(
        withProvideSidebarLayoutContext(() => {
          const context = useSidebarLayoutContext();
          const mode = context.useSidebarMode();

          return (
            <SidebarLayoutContext.Provider value={context}>
              <RegisterSidebarLayoutCommands />
              <BetaBanner />
              <Sidebar mode={mode} />
              <main className={mode === "push" ? "ml-[256px]" : ""}>
                <PageDialogRenderer>
                  <Outlet />
                </PageDialogRenderer>
              </main>
              {/* 
              *tl;dr;*
              - Order these dialog components in the order you
                would like their kbar commands to appear.

              Many of these Dialog components also register kbar
              commands. At the moment, we don't use many weights
              when ordering kbar commands. If two commands match
              the current search term equally well, they'll 
              appear in the order they were registered. As such,
              the order of these dialogs can affects the order
              commands are displayed in via the kbar. E.g. the
              EditChannelGroupDialog and the EditChannelDialog both
              register commands beginning with "create channel".
              We choose to render EditChannelDialog first so that
              its command is displayed first in this edge case.
              In the future, we should have a better system for
              ordering commands and this order here won't matter.
            */}
              <HelpDialog />
              <KBarDialog />
              <RemindMeDialog />
              <ToggleScheduledDeliveryDialog />
              <DeliverMessagesNowDialog />
              <InviteUsersDialog />
              <EditGroupDialog />
              <TagInviteDialog />
              <EditInboxSectionsDialog />
              <AlertDialog />
              <AddGroupToThreadDialog />
              <PWAInstallDialog />
              <OpenComposeMessageService />
              {/* 
              <PendingUpdatesSpinner />
              <OrganizationInviteDialog />
              */}
              <DarkMode />
              <FeedbackButton />
              <SendingMessageToast />
            </SidebarLayoutContext.Provider>
          );
        }),
      ),
    ),
  ),
);

export const RegisterSidebarLayoutCommands: ComponentType<{}> = () => {
  useRegisterSidebarLayoutCommands();
  useRegisterGeneralNavigationCommands();
  useRegisterComposeNewMessageCommands();
  useRegisterGroupCommands();
  useRegisterOrganizationCommands();
  useRegisterSettingsCommands();
  useRegisterUserProfileCommands();
  useRegisterToggleOfflineModeCommands();
  useRegisterUndoRedoCommands();

  return null;
};
