import { UseRecordLoaderResult, useRecordLoader } from "./useRecordLoader";

export type UseOrganizationUserIdsResult = UseRecordLoaderResult<string[]>;

export function useOrganizationUserIds(props: {
  organizationId: string | null | undefined;
  includeSoftDeletes?: boolean;
}): UseOrganizationUserIdsResult {
  return useRecordLoader({
    load({ loader, limit, currentUserId, deps: [organizationId] }) {
      if (!organizationId) {
        return loader.createObserveQueryResult<"user_profile">();
      }

      return loader.observeGetOrganizationUsers({
        currentUserId,
        organizationId: organizationId,
        includeSoftDeletes: props.includeSoftDeletes,
        limit,
      });
    },
    deps: [props.organizationId, props.includeSoftDeletes],
    depsKey: String(props.organizationId) + props.includeSoftDeletes,
    initialLimit: 25,
    limitStep: 25,
    map: (records) => records.map((r) => r.id),
  });
}
