import { unsendDraft } from "~/actions/draft";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { getCurrentRouterLocation, openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { focusReply } from "../utils";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";

export const unsendAndFocusDraft = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (props: { environment: ClientEnvironment; draftId: string; isReply: boolean }) => {
    const { environment, draftId, isReply } = props;
    const draftLocation = getCurrentRouterLocation();

    await unsendDraft(environment, { draftId });

    if (isReply) {
      focusReply(draftId, draftLocation);
    } else {
      openComposeNewThreadDialog(draftId);
    }
  }),
);
