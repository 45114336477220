import { RecordPointer, RecordTable, RecordValue, getPointer } from "libs/schema";
import { distinctUntilChanged, switchMap } from "rxjs";
import { useLoadingObservable } from "./useLoadingObservable";
import { usePendingRequestBar } from "./usePendingRequestBar";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useMemo } from "react";
import { ClientRecordLoaderObserveGetRecordResultMeta, FetchStrategy } from "~/environment/RecordLoader";
import { isEqual } from "libs/predicates";

export type UseRecordResult<T extends RecordTable> = [RecordValue<T> | null, UseRecordResultMeta];

export type UseRecordResultMeta = ClientRecordLoaderObserveGetRecordResultMeta;

const DEFAULT_VALUE_TRUE = Object.freeze([null, Object.freeze({ isLoading: true })]);

const DEFAULT_VALUE_FALSE = Object.freeze([null, Object.freeze({ isLoading: false })]);

export function useRecord<T extends RecordTable>(
  table: T,
  id?: string | null,
  options?: { fetchStrategy?: FetchStrategy },
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  pointer?: RecordPointer<T> | null,
  options?: { fetchStrategy?: FetchStrategy },
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | { fetchStrategy?: FetchStrategy },
  c?: { fetchStrategy?: FetchStrategy },
) {
  const { db, recordLoader } = useClientEnvironment();
  const pointer = getPointerFromInput(a, b);
  const options = getOptionsFromInput(b, c);
  const pointerKey = (pointer && pointer.table + pointer.id) || "";

  const depsKey = pointerKey + options?.fetchStrategy;

  const initialValue = useMemo((): UseRecordResult<T> => {
    if (!pointer) return DEFAULT_VALUE_FALSE as UseRecordResult<T>;
    const [record] = db.getRecord(pointer);
    if (record) return [record, { isLoading: true }];
    return DEFAULT_VALUE_TRUE as UseRecordResult<T>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depsKey, db]);

  const result = useLoadingObservable({
    initialValue,
    deps: [recordLoader, pointer, options?.fetchStrategy],
    depsKey,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([recordLoader, pointer, fetchStrategy]) => {
          return pointer
            ? recordLoader.observeGetRecord(pointer, { fetchStrategy })
            : recordLoader.createObserveGetResult<T>();
        }),
        distinctUntilChanged(isEqual),
      );
    },
  });

  usePendingRequestBar(result[1].isLoading);

  return result;
}

function getPointerFromInput<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | { fetchStrategy?: FetchStrategy },
): RecordPointer<T> | undefined {
  if (!a) return;
  if (typeof a === "string") {
    return typeof b === "string" ? getPointer(a, b) : undefined;
  }

  return a;
}

function getOptionsFromInput(
  a?: string | null | { fetchStrategy?: FetchStrategy },
  b?: { fetchStrategy?: FetchStrategy },
): { fetchStrategy?: FetchStrategy } | undefined {
  if (typeof a === "object" && a) return a;
  if (b) return b;
}
