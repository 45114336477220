import { create } from "zustand";

/**
 * Maintains the list of unsent messages (i.e. messages that have not been sent to the server).
 */
const useOutboxState = create<{
  messagesIds: string[];
  actions: {
    addMessageId: (messageId: string) => void;
    removeMessageId: (messageId: string) => void;
  };
}>((set) => ({
  messagesIds: [],
  actions: {
    addMessageId: (messageId) => {
      set((state) => ({
        messagesIds: [...state.messagesIds, messageId],
      }));
    },
    removeMessageId: (messageId) => {
      set((state) => ({
        messagesIds: state.messagesIds.filter((id) => id !== messageId),
      }));
    },
  },
}));

export const useOutboxMessagesIds = () => useOutboxState((state) => state.messagesIds);
export const useOutboxActions = () => useOutboxState((state) => state.actions);
export const getOutboxActions = () => useOutboxState.getState().actions;
