import { shareReplay, distinctUntilChanged, map, interval } from "rxjs";
import { startWith } from "libs/rxjs-operators";
import Cookies from "js-cookie";
import { PendingUpdates, setIsLoading } from "./loading.service";
import { firebaseSignOut, isEmulatingAuth } from "~/firebase";
import { apiRoot } from "./api";
import { onlyCallFnOnceWhilePreviousCallIsPending, wait } from "libs/promise-utils";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "./config";

/* -------------------------------------------------------------------------------------------------
 * AuthService
 * -------------------------------------------------------------------------------------------------
 */

export class AuthService {
  currentUserId$ = CURRENT_USER_ID$;
  getCurrentUserId = getCurrentUserId;
  getAndAssertCurrentUserId = getAndAssertCurrentUserId;
  getCurrentUserOwnerOrganizationId = getCurrentUserOwnerOrganizationId;
  getAndAssertCurrentUserOwnerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId;
  signout = signout;
}

export function getCurrentUserId(): string | null {
  return Cookies.get("userId") || null;
}

export function getAndAssertCurrentUserId(): string {
  const currentUserId = getCurrentUserId();

  if (!currentUserId) {
    throw new Error("Expected current user to be signed in but they are not.");
  }

  return currentUserId;
}

export function getCurrentUserOwnerOrganizationId(): string | null {
  return Cookies.get("userOwnerOrganizationId") || null;
}

export function getAndAssertCurrentUserOwnerOrganizationId(): string {
  const ownerOrganizationId = getCurrentUserOwnerOrganizationId();

  if (!ownerOrganizationId) {
    throw new Error("Expected current user to be signed in but they are not.");
  }

  return ownerOrganizationId;
}

export const CURRENT_USER_ID$ = interval(1000).pipe(
  startWith(() => null),
  map(getCurrentUserId),
  distinctUntilChanged(),
  shareReplay(1),
);

export const signout = onlyCallFnOnceWhilePreviousCallIsPending(
  async (
    options: {
      /**
       * Normally when attempting to close the current tab we'll show the user a warning if there
       * are any pending transactions. Pass `true` to this function to force signout without
       * showing the warning.
       */
      force?: boolean;
    } = {},
  ) => {
    if (isEmulatingAuth()) {
      await setIsLoading(logoutRequest());
    } else {
      await setIsLoading(Promise.all([firebaseSignOut(), logoutRequest()]));
    }

    // If we're running the client locally but connecting to a production server
    // then we need to manually remove these cookies which we set on localhost but
    // which the server won't be able to remove.
    Cookies.remove("userId");
    Cookies.remove("userOwnerOrganizationId");

    datadogRum.clearUser();

    if (options.force) {
      PendingUpdates.clearAll();
    }

    // Note that we navigate using location.href so that the current memory is cleared.
    // Important as a security precaution.
    window.location.href = "/login";

    // Wait a few seconds to give time for the page to reload
    await wait(3000);
  },
);

/* -----------------------------------------------------------------------------------------------*/

// Note that this is a *rare* exception where we aren't using the api service to make an
// api request! We're making an exception here because we potentially want the ability to
// logout before the API service has been initialized. In general, we want all requests to
// go through the API service.
async function logoutRequest() {
  const response = await fetch(apiRoot + "logout", {
    method: "post",
    credentials: config.dev.apiServerOrigin ? "include" : "same-origin",
    body: null,
  });

  if (!response.ok) {
    throw new Error("Failed to log out");
  }
}
