import { MentionFrequencyMapDoc, RecordPointer, RecordTable, RecordValue } from "libs/schema";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { FetchStrategy } from "~/environment/RecordLoader";

export type GetRecordOptions = {
  /** "cache-then-network" is the default */
  fetchStrategy?: FetchStrategy;
};

export async function getRecord<T extends RecordTable>(
  environment: Pick<ClientEnvironment, "recordLoader">,
  pointer: RecordPointer<T>,
  options: {
    /** "cache-then-network" is the default */
    fetchStrategy?: FetchStrategy;
  } = {},
) {
  const { recordLoader } = environment;

  return recordLoader.getRecord(pointer, options);
}

export async function getRecords<T extends RecordTable>(props: {
  environment: Pick<ClientEnvironment, "recordLoader">;
  pointers: RecordPointer<T>[];
  /** "cache-then-network" is the default */
  fetchStrategy?: FetchStrategy;
}) {
  const { recordLoader } = props.environment;

  const results = await Promise.all(props.pointers.map((pointer) => recordLoader.getRecord(pointer, props)));

  const records: RecordValue<T>[] = [];

  for (const [result] of results) {
    if (result) records.push(result);
  }

  return [records] as const;
}

export async function getCurrentUserMentionFrequency(
  environment: Pick<ClientEnvironment, "recordLoader">,
  currentUserId: string,
  options?: GetRecordOptions,
): Promise<[MentionFrequencyMapDoc | null]> {
  const { recordLoader } = environment;
  const [record] = await recordLoader.getRecord({ table: "user_settings", id: currentUserId }, options);

  return [record?.mention_frequency || null];
}
