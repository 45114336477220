import { oneLine } from "common-tags";
import { ComponentType, useEffect, useState } from "react";
import useIsNativeIOS from "~/hooks/useIsNativeIos";
import useIsPWA from "~/hooks/useIsPWA";
import UAParser from "ua-parser-js";

export function detectBrowserEngine() {
  const parser = new UAParser();
  return { engine: parser.getEngine(), deviceType: parser.getDevice().type };
}

export function isDesktopBrowser() {
  const parser = new UAParser();
  // Device type will be `undefined` for desktop browsers
  return !parser.getDevice().type;
}

export function isPWASupported() {
  const { engine } = detectBrowserEngine();
  const isSupportedBrowser = engine.name === "Blink" || engine.name === "WebKit";
  return isSupportedBrowser;
}

export const UnsupportedBrowserWarningBanner: ComponentType<{}> = () => {
  const isNativeApp = useIsNativeIOS();
  const isPWA = useIsPWA();
  const [isSupportedBrowser, setIsSupportedBrowser] = useState<boolean | null>(null);

  const [hideWarning, setHideWarning] = useState<boolean>(sessionStorage.getItem(SESSION_STORAGE_KEY) === "true");

  useEffect(() => {
    const { engine } = detectBrowserEngine();
    setIsSupportedBrowser(engine.name === "Blink" || engine.name === "WebKit");
  }, []);

  useEffect(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, String(hideWarning));
  }, [hideWarning]);

  if (isNativeApp) return null;
  if (isPWA) return null;
  if (isSupportedBrowser === null) return null;
  if (isSupportedBrowser) return null;
  if (hideWarning) return null;

  return (
    <button className={warningBannerCSS} type="button" onClick={() => setHideWarning(true)}>
      <span>
        WARNING&nbsp;- Google Chrome and mobile Safari are currently the only supported browsers.{" "}
        <strong>
          <em>Tap to dismiss.</em>
        </strong>
      </span>
    </button>
  );
};

const warningBannerCSS = oneLine`
  py-2 px-12 flex justify-center text-center font-medium bg-orange-10 text-white text-sm fixed top-0 w-screen z-[2000]
`;

const SESSION_STORAGE_KEY = "hideUnsupportedBrowserWarningBanner";
