import { getAndAssertCurrentUserId } from "~/environment/user.service";

import { GetRecordOptions } from "./getRecord";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { defaultUserSettings, NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";

export async function getCurrentUserSettings(
  environment: Pick<ClientEnvironment, "recordLoader">,
  options?: GetRecordOptions,
): Promise<{
  settings: NormalizedUserSettingsDoc | null;
}> {
  const currentUserId = getAndAssertCurrentUserId();

  const [userSettings] = await environment.recordLoader.getRecord("user_settings", currentUserId, options);

  const settings = userSettings?.settings;

  if (!settings) return { settings: null };

  const hasValidScheduledDeliverySettings =
    settings &&
    settings.scheduled_days &&
    settings.scheduled_times &&
    settings.scheduled_days.length > 0 &&
    settings.scheduled_times.length > 0;

  if (!hasValidScheduledDeliverySettings) {
    return { settings: defaultUserSettings() };
  }

  return {
    settings: {
      ...defaultUserSettings(),
      ...settings,
    },
  };
}
