import "./polyfills";
import "./setup";
import { StrictMode } from "react";
import { render } from "react-dom";
import "tailwindcss/tailwind.css";
import App from "./App";
import "./index.scss";
import { beforeInstallPrompt$ } from "./utils/beforeInstallPrompt";

// See
// https://github.com/khmyznikov/pwa-install?tab=readme-ov-file#async-mode
beforeInstallPrompt$.subscribe();

// if (isAnEmulatorActive) {
//   // Add "Emulator Mode" warning banner

//   const div = document.createElement("div");

//   if (import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL) {
//     div.innerText = "EMULATOR MODE";
//   } else {
//     div.innerText = "EMULATOR MODE (except AUTH)";
//   }

//   div.classList.add(
//     ..."fixed w-screen bottom-0 flex justify-center".split(" "),
//     ..."p-2 text-white font-bold".split(" "),
//     ..."hover:opacity-0 pointer-events-auto".split(" "),
//   );

//   div.style.zIndex = "9999";

//   div.style.backgroundColor = "rgba(255,0,0, .5)";

//   // *sigh* for some reason the typescript compiler is complaining that
//   // .append() only accepts strings... when it clearly doesn't...
//   document.body.append(div as any);
// }

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root"),
);
