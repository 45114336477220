import { getPointer, generateRecordId, ThreadVisibility, GroupTagRecord } from "libs/schema";
import { op } from "libs/transaction";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { UnreachableCaseError } from "libs/errors";
import { isTagPrivate } from "libs/schema/predicates";
import * as ops from "libs/actions";
import { FetchStrategy } from "~/environment/QueryCache";
import { GetOptions } from "~/environment/RecordLoader";

export const addGroupToThread = withPendingRequestBar(
  async (
    environment: ClientEnvironment,
    props: {
      threadId: string;
      groupId: string;
    },
  ) => {
    const { threadId, groupId } = props;

    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    toast("vanilla", {
      subject: `Adding group to thread...`,
    });

    const [[thread], [group]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getRecord("tag", props.groupId),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: "Thread not found",
      });

      return;
    } else if (!group) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: "Group not found.",
      });

      return;
    } else if (thread.visibility === "PRIVATE" ? !isTagPrivate(group) : isTagPrivate(group)) {
      toast("vanilla", {
        subject: `Error adding group to thread.`,
        description: `Cannot add ${
          thread.visibility === "PRIVATE" ? "shared" : "private"
        } to ${thread.visibility.toLowerCase()} thread.`,
      });

      return;
    }

    const pointer = getPointer("thread_group_permission", {
      thread_id: threadId,
      group_id: groupId,
    });

    await runTransaction({
      environment: withTxLogger(environment, { data: props }),
      label: "addGroupToThread",
      tx: async (transaction) => {
        transaction.operations.push(
          op.upsert(pointer, {
            onCreate: [
              op.set(pointer, {
                id: pointer.id,
                thread_id: threadId,
                group_id: groupId,
                creator_user_id: currentUserId,
                owner_organization_id: ownerOrganizationId,
                start_at: thread.first_message_timeline_order,
                thread_sent_at: thread.first_message_sent_at,
              }),
            ],
          }),
        );
      },
      undo: async (transaction) => {
        transaction.onServerResponse = ({ error }) => {
          if (error) {
            toast("vanilla", {
              subject: `Error removing group from thread.`,
            });

            return;
          }

          toast("vanilla", {
            subject: `Group removed from thread.`,
          });
        };

        transaction.operations.push(op.delete(pointer.table, pointer));
      },
    });

    toast("vanilla", {
      subject: `Adding group to thread...Done!`,
    });
  },
);

export const removeGroupFromThread = withPendingRequestBar(
  async (
    environment: ClientEnvironment,
    props: {
      threadId: string;
      groupId: string;
    },
  ) => {
    const { threadId, groupId } = props;

    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    toast("vanilla", {
      subject: `Removing group from thread...`,
    });

    const [[thread], [group]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getRecord("tag", props.groupId),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Thread not found",
      });

      return;
    } else if (!group) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Group not found. This could be because you don't have permission to access this group.",
      });

      return;
    } else if ((group as GroupTagRecord).data?.is_organization_group) {
      toast("vanilla", {
        subject: `Error removing group from thread.`,
        description: "Cannot manually remove an organization group. Instead, change the thread visibility.",
      });

      return;
    }

    const pointer = getPointer("thread_group_permission", {
      thread_id: threadId,
      group_id: groupId,
    });

    await runTransaction({
      environment: withTxLogger(environment, { data: props }),
      label: "removeGroupFromThread",
      tx: async (transaction) => {
        transaction.operations.push(op.delete(pointer.table, pointer));
      },
      undo: async (transaction) => {
        transaction.onServerResponse = ({ error }) => {
          if (error) {
            toast("vanilla", {
              subject: `Error restoring group to thread.`,
            });

            return;
          }

          toast("vanilla", {
            subject: `Group restored to thread.`,
          });
        };

        transaction.operations.push(
          op.upsert(pointer, {
            onCreate: [
              op.set(pointer, {
                id: pointer.id,
                thread_id: threadId,
                group_id: groupId,
                creator_user_id: currentUserId,
                owner_organization_id: ownerOrganizationId,
                start_at: thread.first_message_timeline_order,
                thread_sent_at: thread.first_message_sent_at,
              }),
            ],
          }),
        );
      },
    });

    toast("vanilla", {
      subject: `Removing group from thread...Done!`,
    });
  },
);

export const updateThreadVisibility = withPendingRequestBar(
  async (props: { environment: ClientEnvironment; threadId: string; visibility: ThreadVisibility }) => {
    const { environment, threadId, visibility } = props;

    const currentUserId = getAndAssertCurrentUserId();
    const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

    if (!environment.network.isOnline()) {
      toast("vanilla", {
        subject: "Not available offline",
        description: `
        Cannot change thread visibility while offline.
      `,
      });

      return;
    }

    toast("vanilla", {
      subject: `Making thread ${visibility}...`,
    });

    const [[thread], [groupPermissions]] = await Promise.all([
      environment.recordLoader.getRecord("thread", props.threadId),
      environment.recordLoader.getThreadGroupPermissions({
        thread_id: threadId,
      }),
    ]);

    if (!thread) {
      toast("vanilla", {
        subject: `Error making thread ${visibility}.`,
        description: "Thread not found",
      });

      return;
    }

    switch (visibility) {
      case "PRIVATE": {
        await runTransaction({
          environment: withTxLogger(environment, { data: props }),
          label: "updateThreadVisibility PRIVATE",
          tx: async (transaction) => {
            transaction.operations.push(
              op.update({ table: "thread", id: threadId }, { visibility }),
              ...groupPermissions.map((permission) => {
                return op.delete("thread_group_permission", permission);
              }),
            );
          },
        });

        break;
      }
      case "SHARED": {
        await runTransaction({
          environment: withTxLogger(environment, { data: props }),
          label: "updateThreadVisibility SHARED",
          tx: async (transaction) => {
            transaction.operations.push(
              op.update({ table: "thread", id: threadId }, { visibility }),
              ...groupPermissions.map((permission) => {
                return op.delete("thread_group_permission", permission);
              }),
              op.set("thread_group_permission", {
                id: generateRecordId("thread_group_permission", {
                  thread_id: threadId,
                  group_id: ownerOrganizationId,
                }),
                thread_id: threadId,
                group_id: ownerOrganizationId,
                creator_user_id: currentUserId,
                owner_organization_id: ownerOrganizationId,
                start_at: thread.first_message_timeline_order,
                thread_sent_at: thread.first_message_sent_at,
              }),
            );
          },
        });

        break;
      }
      default: {
        throw new UnreachableCaseError(visibility);
      }
    }

    toast("vanilla", {
      subject: `Making thread ${visibility}...Done!`,
    });
  },
);

export async function markThreadSeen(
  environment: ClientEnvironment,
  props: {
    threadId: string;
    seen_to_timeline_id: string;
    seen_to_timeline_order: string;
  },
) {
  const currentUserId = getAndAssertCurrentUserId();
  const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "markThreadSeen",
    tx: async (transaction) => {
      transaction.operations.push(
        op.set("thread_seen_receipt", {
          id: generateRecordId("thread_seen_receipt", {
            thread_id: props.threadId,
            user_id: currentUserId,
          }),
          thread_id: props.threadId,
          user_id: currentUserId,
          seen_to_timeline_id: props.seen_to_timeline_id,
          seen_to_timeline_order: props.seen_to_timeline_order,
          owner_organization_id: ownerOrganizationId,
        }),
      );
    },
  });
}

export async function markThreadResolved(
  environment: ClientEnvironment,
  props: {
    threadId: string;
    resolvedByMessageId: string;
  },
  options?: GetOptions,
) {
  const currentUserId = getAndAssertCurrentUserId();
  const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();
  const fetchOptions = {
    fetchStrategy: "cache-first" satisfies FetchStrategy as FetchStrategy,
    ...options,
  };

  const [[threadTag], [message]] = await Promise.all([
    environment.recordLoader.getRecord(
      "thread_tag",
      generateRecordId("thread_tag", {
        thread_id: props.threadId,
        tag_id: generateRecordId("singleton_tag", { name: "resolved" }),
      }),
      fetchOptions,
    ),
    environment.recordLoader.getRecord("message", props.resolvedByMessageId, fetchOptions),
  ]);

  if (!message) return;
  if (message.sent_at > new Date().toISOString()) {
    toast("vanilla", {
      subject: `Cannot mark as resolved`,
      description: `
        The message that resolves the thread has not been sent yet.
        Try again after it has been sent.
      `,
    });

    return;
  }

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "markThreadResolved",
    tx: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: `Error marking thread resolved.`,
          });

          return;
        }

        toast("vanilla", {
          subject: `Thread resolved.`,
        });
      };

      ops.applyOperationsToTransaction(
        transaction,
        ops.thread.markThreadResolved({
          threadId: props.threadId,
          resolvedByMessageId: props.resolvedByMessageId,
          markedResolvedByUserId: currentUserId,
          ownerOrganizationId: ownerOrganizationId,
        }),
      );
    },
    undo: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: `Error undoing marking thread resolved.`,
          });

          return;
        }

        toast("vanilla", {
          subject: `Thread resolved undone.`,
        });
      };

      if (threadTag?.data?.message_id) {
        ops.applyOperationsToTransaction(
          transaction,
          ops.thread.markThreadResolved({
            threadId: threadTag.thread_id,
            resolvedByMessageId: threadTag.data.message_id as string,
            markedResolvedByUserId: threadTag.creator_user_id,
            ownerOrganizationId: threadTag.owner_organization_id,
          }),
        );
      } else {
        ops.applyOperationsToTransaction(
          transaction,
          ops.thread.markThreadNotResolved({
            threadId: props.threadId,
          }),
        );
      }
    },
  });
}

export async function markThreadNotResolved(
  environment: ClientEnvironment,
  props: {
    threadId: string;
  },
  options?: GetOptions,
) {
  const [threadTag] = await environment.recordLoader.getRecord(
    "thread_tag",
    generateRecordId("thread_tag", {
      thread_id: props.threadId,
      tag_id: generateRecordId("singleton_tag", { name: "resolved" }),
    }),
    { ...options, fetchStrategy: "cache-first" },
  );

  await runTransaction({
    environment,
    label: "markThreadNotResolved",
    tx: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: `Error marking thread not resolved.`,
          });

          return;
        }

        toast("vanilla", {
          subject: `Thread marked not resolved.`,
        });
      };

      ops.applyOperationsToTransaction(
        transaction,
        ops.thread.markThreadNotResolved({
          threadId: props.threadId,
        }),
      );
    },
    undo: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: `Undo error.`,
          });

          return;
        }

        toast("vanilla", {
          subject: `Thread not resolved undone.`,
        });
      };

      if (threadTag?.data?.message_id) {
        ops.applyOperationsToTransaction(
          transaction,
          ops.thread.markThreadResolved({
            threadId: threadTag.thread_id,
            resolvedByMessageId: threadTag.data.message_id as string,
            markedResolvedByUserId: threadTag.creator_user_id,
            ownerOrganizationId: threadTag.owner_organization_id,
          }),
        );
      }
    },
  });
}
