import { useCallback, useEffect, useRef } from "react";
import { usePortalContainerContext } from "~/environment/portal.service";
import { useAsRef } from "./useAsRef";

/**
 * Dynamically adds and removes a hidden file input to the dom to allow
 * for programmatic access to the browser's file picker.
 */
export function useFileInputElement(props: { multiple?: boolean; onFilesChange?: (files: FileList | null) => void }) {
  const inputRef = useRef<HTMLInputElement>();
  const { container } = usePortalContainerContext();
  const onChangeRef = useAsRef(props.onFilesChange);

  useEffect(() => {
    const inputEl = document.createElement("input");
    inputEl.type = "file";
    inputEl.multiple = props.multiple ?? false;
    inputEl.style.display = "none";

    inputEl.onchange = () => {
      const onChange = onChangeRef.current;
      if (!onChange) return;
      onChange(inputEl.files);
    };

    container.appendChild(inputEl);

    inputRef.current = inputEl;

    return () => {
      inputEl.remove();
    };
  }, [container, onChangeRef, props.multiple]);

  const openFilePicker = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.click();
  }, []);

  return { openFilePicker };
}
