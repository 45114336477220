import { of } from "rxjs";
import { observeInboxEntries } from "../observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useRecordLoader } from "./useRecordLoader";
import { ClientRecordLoaderObserveQueryResult } from "~/environment/RecordLoader";

export function useInboxEntries(props: { inboxSectionId?: string | null; initialLimit?: number }) {
  const environment = useClientEnvironment();

  return useRecordLoader({
    deps: [environment, props.inboxSectionId],
    depsKey: String(props.inboxSectionId),
    load({ deps: [environment, inboxSectionId], limit, currentUserId }) {
      if (!inboxSectionId) {
        return of<ClientRecordLoaderObserveQueryResult<"inbox_entry">>([
          [],
          {
            isLoading: false,
            limit: limit || null,
            nextId: null,
            recordMap: {},
          },
        ]);
      }

      return observeInboxEntries(environment, {
        userId: currentUserId,
        inboxSectionId,
        limit: limit || undefined,
      });
    },
    initialLimit: props.initialLimit || 25,
    limitStep: 50,
  });
}
