import { getCurrentUserId } from "./user.service";
import { ClientEnvironment } from "./ClientEnvironment";
import { MessagePortService, Request } from "./MessagePortService";
import { LocalForagePersistedKVStore, PersistedKVStore } from "./KVStore";
import { filter, skip } from "rxjs";
import { PersistedDatabaseWorkerApi } from "./persisted-database-worker/PersistedDatabaseWorkerApi";
import { DeferredPromise } from "libs/promise-utils";

declare module "./MessagePortService" {
  interface MessageTypeMap {
    PERSISTED_DB_ACTIVATE: {
      request: Message<"PERSISTED_DB_ACTIVATE", { clearOnInit?: boolean }>;
      response: Message<"PERSISTED_DB_ACTIVATE", null>;
    };
    PERSISTED_DB_QUERY: {
      request: Message<"PERSISTED_DB_QUERY", { prop: string; args: any[] }>;
      response: Message<"PERSISTED_DB_QUERY", unknown>;
    };
    PERSISTED_DB_OBSERVE_QUERY: {
      request: Message<"PERSISTED_DB_OBSERVE_QUERY", { prop: string; args: any[] }>;
      response: Message<"PERSISTED_DB_OBSERVE_QUERY", unknown>;
    };
    PERSISTED_DB_PORT: {
      request: Message<"PERSISTED_DB_PORT", null>;
      response: Message<"PERSISTED_DB_PORT", { port: MessagePort }>;
    };
  }
}

export class PersistedDatabaseServiceProvider {
  private static broadcastChannel = new BroadcastChannel("persisted-database");

  static create(
    env: Pick<ClientEnvironment, "logger" | "auth" | "clientId" | "leader" | "sharedWorker" | "serviceWorker">,
  ) {
    const provider = new PersistedDatabaseServiceProvider(env);
    provider.activate();
    const service = provider.createService();
    return service;
  }

  static async setLastDatabaseUserId(userId: string | null) {
    if (userId) {
      await kvStore.setItem("lastDatabaseUserId", userId);
    } else {
      await kvStore.removeItem("lastDatabaseUserId");
    }
  }

  /**
   * The database will be cleared and reinstalled from scratch the next time an instance
   * of the PersistedDatabaseServiceProvider is promoted to leader. The PersistedDatabaseServiceProvider
   * _leader tab_ will also react to this action by telling all tabs to reload the page.
   * This reload will cause a new leader tab to be elected which will cause the persisted database schema to
   * be updated. It's necessary that all tabs reload because some running services (e.g. the SyncService)
   * might expect the persisted database cache to have certain records available which it will no longer have
   * after the reinstall.
   *
   * Note that reloading all tabs does require that the leader tab be initialized properly. If a bug has prevented
   * the leader tab from properly initializing, the behavior might be unexpected.
   */
  static reloadAllTabsAndReinstallCommsDatabase() {
    return setShouldMigrateCommsSchema(true);
  }

  private worker!: Worker;
  private connection: MessagePortService;
  private isDatabaseOpened = new DeferredPromise<void>();

  constructor(
    protected env: Pick<
      ClientEnvironment,
      "logger" | "auth" | "clientId" | "leader" | "sharedWorker" | "serviceWorker"
    >,
  ) {
    this.connection = new MessagePortService({
      serviceName: "MainThreadPersistedDatabaseMessagePortService",
      senderId: env.clientId,
      logger: env.logger,
      defaultRetryOnDisconnect: false,
      getRecipient() {
        return this.recipients.get(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER);
      },
    });

    PersistedDatabaseServiceProvider.broadcastChannel.onmessage = (event) => {
      if (event.data === "RELOAD_ALL_TABS") {
        location.reload();
      }
    };

    // Note that these events may be emitted as a result of changes in other tabs.
    kvStore
      .getItem$<boolean>("MIGRATE_COMMS_SCHEMA")
      // The curent value will be emitted immediately on subscription. We're only interested in changes
      // so we skip the first value.
      .pipe(skip(1))
      .subscribe(async (value) => {
        if (!value) return;
        if (!this.env.leader.isLeader) return;

        this.env.logger.info("[PersistedDatabaseService] MIGRATE_COMMS_SCHEMA change");

        try {
          // This will check for a service worker update and, if available, install and activate
          // the update. This will cause all pages to reload with the latest version of the client,
          // if successful. It's very important we do this *before* attempting to reinstall the Comms
          // database so that we have the latest version of the db schema.
          await this.env.serviceWorker.checkForUpdateAndActivateWhenAvailable();
        } catch (error) {
          this.env.logger.error({ error }, "[PersistedDatabaseService] checkForUpdateAndActivateIfAvailable");
          await this.env.auth.signout();
        }

        // In case there isn't a service worker update available, we reload all tabs.
        // It's necessary that all tabs reload because some running services (e.g. the SyncService)
        // might expect the persisted database cache to have certain records available which it
        // will no longer have after the reinstall.
        PersistedDatabaseServiceProvider.broadcastChannel.postMessage("RELOAD_ALL_TABS");
        // Posting the message to the broadcast channel will notify other tabs but will not notify this
        // tab. We need to manually reload this tab as well.
        location.reload();
      });

    this.env.sharedWorker.connection.requests$
      .pipe(filter((m) => m.type.startsWith("PERSISTED_DB")))
      .subscribe(async (message) => {
        if (!this.env.leader.isLeader) {
          return this.env.sharedWorker.connection.sendError(
            message,
            new Error("Persisted database query sent to non-leader tab"),
          );
        }

        try {
          await this.handleRequest(message);
        } catch (error) {
          this.env.sharedWorker.connection.sendError(message, error);
        }
      });
  }

  private async onGetPort(request: Request<"PERSISTED_DB_PORT">) {
    const response = await this.connection.sendRequest(request.type, request);
    this.env.sharedWorker.connection.sendResponse(request, { data: response, transfer: [response.port] });
  }

  private async handleRequest(request: Request) {
    if (!this.isDatabaseOpened.settled) {
      await this.isDatabaseOpened.promise;
    }

    switch (request.type) {
      case "PERSISTED_DB_PORT": {
        return this.onGetPort(request);
      }
      default: {
        this.env.sharedWorker.connection.sendError(request, new Error("Only PERSISTED_DB_PORT messages are supported"));
      }
    }
  }

  private createService() {
    return new Proxy({} as PersistedDatabaseWorkerApi, {
      get: (_, prop: string) => {
        if (prop === "then") return undefined;
        if (prop === "toJSON") {
          return () => Promise.reject(new Error("Method toJSON called on PersistedDatabaseService"));
        }

        return (...args: any[]) => {
          if (prop.startsWith("observe")) {
            return this.sendObserveQuery({ prop, args });
          }

          return this.sendGetQuery({ prop, args });
        };
      },
    });
  }

  activate() {
    this.env.leader.isLeaderPromise
      .then(() => this.openDatabase())
      .catch((error) => {
        // We're not showing this alert, even though we'd like to, because it will freeze this tab.
        // If this code block was triggered after we attempted to reload all open tabs, that would
        // mean that every tab is initializing and this one happened to be elected the leader. By
        // showing this alert, other tabs would be requesting access to this tab (because this tab
        // is the leader tab) but would be blocked until the alert is dismissed. This would cause
        // the other tabs to fail to initialize. So instead we're just silently reloading every tab.
        //
        // alert(oneLine`
        //   An error occurred while opening the local Comms database. We're going to try
        //   clearing the local database and reloading Comms to see if that fixes the issue. If
        //   you continue to see this message, please reach out to team@comms.day.
        // `);

        // Because the error that was thrown might have been created in the worker thread and then
        // serialized and passed to this thread, we cannot rely on the error object to be an instance
        // of Error. We need to check what type of error it is by looking at the properties.
        const isSqliteError =
          error &&
          typeof error === "object" &&
          "name" in error &&
          "message" in error &&
          typeof error.name === "string" &&
          error.name.includes("SQLite3Error");

        this.env.logger.error(
          { error, isSqliteError },
          `[PersistedDatabaseService] Error opening database after promoting to leader`,
        );

        if (isSqliteError) {
          // Note that we also attempt to catch and handle errors inside the persisted database worker
          // during the init process. When successful, we can handle the error more gracefully within the
          // worker thread.
          PersistedDatabaseServiceProvider.reloadAllTabsAndReinstallCommsDatabase();
        }
      });

    this.connection.activate();
  }

  private sendGetQuery(props: Request<"PERSISTED_DB_QUERY">["data"]) {
    return this.env.sharedWorker.connection.sendRequest(
      "PERSISTED_DB_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }

  private sendObserveQuery(props: Request<"PERSISTED_DB_OBSERVE_QUERY">["data"]) {
    return this.env.sharedWorker.connection.observeRequest(
      "PERSISTED_DB_OBSERVE_QUERY",
      {
        to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
        data: props,
      },
      { retryOnDisconnect: true },
    );
  }

  /**
   * Opens the database if it hasn't been opened yet. This method is idempotent.
   */
  private async openDatabase() {
    await navigator.locks.request(`open-persisted-db`, { ifAvailable: true }, async (lock) => {
      if (!lock) return;

      // This code is necessary as part of the migration of the PersistedDatabaseService to the SharedWorker.
      // The SharedWorker doesn't support localStorage so we've moved storage to localForage and
      // indexedDB. This code migrates the old data from localStorage to localForage.
      // This can be removed after the migration is complete.
      // John - 9/9/24
      if (typeof localStorage !== "undefined") {
        const oldKVStore = new PersistedKVStore({ namespace: "PersistedDatabaseServiceProvider" });

        const migrateKey = async (key: string) => {
          const value = oldKVStore.getItem<string>(key);

          if (value) {
            await kvStore.setItem(key, value);
            oldKVStore.removeItem(key);
          }
        };

        try {
          await migrateKey("lastDatabaseUserId");
          await migrateKey("MIGRATE_COMMS_SCHEMA");
        } catch (error) {
          this.env.logger.error(
            { error },
            "[PersistedDatabaseService] [openDatabase] Failed to migrate persisted KV store to localForage",
          );
        }
      }

      const currentUserId = getCurrentUserId();
      const lastDatabaseUserId = await getLastDatabaseUserId();

      // We don't do client-side authorization of queries so if the the database was last
      // used by another user it probably has records in it which the current user doesn't have
      // permission to access. Because of this, we need to clear the offline database on user changes.
      const wasDatabaseLastUsedByAnotherUser = !!lastDatabaseUserId && lastDatabaseUserId !== currentUserId;

      const clearOnInit = wasDatabaseLastUsedByAnotherUser || (await getShouldMigrateCommsSchema());

      this.worker = new Worker(new URL("./persisted-database-worker/start", import.meta.url), {
        name: "PersistedDatabaseWorker",
        type: "module",
      });

      this.connection.onConnect(MessagePortService.uniqueContexts.PERSISTED_DB_WORKER, this.worker);

      await this.activateDatabaseWorker({ clearOnInit });

      if (clearOnInit) {
        await setShouldMigrateCommsSchema(false);
      }

      await PersistedDatabaseServiceProvider.setLastDatabaseUserId(currentUserId);

      this.isDatabaseOpened.resolve();
    });
  }

  private activateDatabaseWorker(props: Request<"PERSISTED_DB_ACTIVATE">["data"]) {
    return this.connection.sendRequest("PERSISTED_DB_ACTIVATE", {
      to: MessagePortService.uniqueContexts.PERSISTED_DB_WORKER,
      data: props,
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/

const kvStore = new LocalForagePersistedKVStore({ namespace: "PersistedDatabaseServiceProvider" });

function getLastDatabaseUserId() {
  return kvStore.getItem<string>("lastDatabaseUserId");
}

async function getShouldMigrateCommsSchema() {
  return !!(await kvStore.getItem("MIGRATE_COMMS_SCHEMA"));
}

/**
 * The constructor observes changes to this value and reacts. Note that the constructor
 * will see changes to this value from other tabs as well (which is desired).
 */
async function setShouldMigrateCommsSchema(value: boolean) {
  if (value) {
    await kvStore.setItem("MIGRATE_COMMS_SCHEMA", value);
  } else {
    await kvStore.removeItem("MIGRATE_COMMS_SCHEMA");
  }
}

/* -----------------------------------------------------------------------------------------------*/
