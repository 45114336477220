import { isNonNullable } from "libs/predicates";
import { MentionableGroup } from "~/observables/observeMentionableGroupRecords";
import { GetRecordOptions } from "./getRecord";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { RecordValue } from "libs/schema";
import { isTagPrivate } from "libs/schema/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export interface GetMentionableGroupRecordsProps extends GetRecordOptions {}

export type GetMentionableGroupRecordsResult = {
  groups: MentionableGroup[];
};

export async function getMentionableGroupRecords(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: GetMentionableGroupRecordsProps = {},
): Promise<GetMentionableGroupRecordsResult> {
  const currentUserId = getAndAssertCurrentUserId();
  const { recordLoader } = environment;

  const [[groups], [folderMembers]] = await Promise.all([
    recordLoader.getGroupsUserHasAccessTo({ currentUserId }, { fetchStrategy: props.fetchStrategy }),
    recordLoader.getTagFolderMembersUserHasAccessTo({ currentUserId }, { fetchStrategy: props.fetchStrategy }),
  ]);

  return {
    groups: groups.map((group) => {
      return {
        type: "group",
        id: group.id,
        record: group,
        isPrivate: isTagPrivate(group),
        folderPaths: folderPathsForTag(group.id, folderMembers).map((paths) =>
          paths.map((folderId) => groups.find((group) => group.id === folderId)).filter(isNonNullable),
        ),
      };
    }),
  };
}

function folderPathsForTag(tagId: string, folders: RecordValue<"tag_folder_member">[]): string[][] {
  return folders
    .filter((folder) => folder.tag_id === tagId)
    .flatMap((folder) => {
      const ancestorPaths = folderPathsForTag(folder.folder_id, folders);

      if (ancestorPaths.length === 0) return [[folder.folder_id]];

      return ancestorPaths.map((paths) => [folder.folder_id, ...paths]);
    });
}
