import { useEffect } from "react";

export function useConsoleLog(type: "log" | "debug" | "warn" | "error" | boolean, ...values: any[]): void;
export function useConsoleLog(...values: any[]): void;
export function useConsoleLog(type: any, ...values: any[]) {
  useEffect(() => {
    switch (type) {
      case "debug": {
        console.debug(...values);
        break;
      }
      case "warn": {
        console.warn(...values);
        break;
      }
      case "error": {
        console.error(...values);
        break;
      }
      case false: {
        break;
      }
      case true:
      case "log": {
        console.log(...values);
        break;
      }
      default: {
        console.log(type, ...values);
        break;
      }
    }
  }, [type, ...values]);
}
