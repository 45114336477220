import { ComponentType, ReactElement, memo } from "react";
import { IListOnEntryActionEvent, List } from "../list";
import { isEqual } from "libs/predicates";
import { navigateToEntry } from "./ContentList";
import {
  EntryActions,
  entryCSSClasses,
  EntryTimestamp,
  OtherCommandEntryAction,
  PrivateEntryIcon,
  Recipients,
  StarredEntryIcon,
  Summary,
  useShouldShowChannelLabels,
} from "./layout";
import { NotificationTimestamp } from "./NotificationEntry";
import { isModKeyActive } from "~/environment/command.service";
import { openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { Avatar } from "../Avatar";
import { useMessage } from "~/hooks/useMessage";
import { useThread } from "~/hooks/useThread";
import { PointerWithRecord } from "libs/schema";
import { useNotification } from "~/hooks/useNotification";
import { LabelContainer, PermittedGroupChips } from "../LabelChip";
import { useMessageRecipientNames } from "~/hooks/useMessageRecipientNames";
import { useMessageSender } from "~/hooks/useMessageSender";
import { useAsPointerWithRecord } from "~/hooks/useAsPointerWithRecord";
import { Tooltip } from "../Tooltip";
import { MdScheduleSend } from "react-icons/md";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useOutboxMessagesIds } from "~/state/outbox.state";
import { cx } from "@emotion/css";

export function onMessageSelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<{
  table: "message";
  id: string;
  record: { thread_id: string };
}>) {
  const url = `/threads/${entry.record.thread_id}?message=${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url);
  }
}

export const MessageEntry: ComponentType<{
  messageId: string;
  showRecipientNames?: boolean;
  relativeOrder: number;
  overrideSubject?: string | ReactElement;
  overrideBody?: string | ReactElement;
}> = memo(({ messageId, showRecipientNames = false, relativeOrder, overrideSubject, overrideBody }) => {
  const shouldShowChannelLabels = useShouldShowChannelLabels();
  const [message] = useMessage(messageId);
  const [thread] = useThread(message?.thread_id);
  const [notification] = useNotification({ threadId: message?.thread_id });
  const sender = useMessageSender(messageId);
  const entryData = useAsPointerWithRecord("message", message);
  const environment = useClientEnvironment();
  const outboxMessagesIds = useOutboxMessagesIds();

  if (!message || !thread || !entryData) return null;

  const isPrivateThread = thread.visibility === "PRIVATE";
  const senderLabel = sender?.label || (sender.isLoading ? "loading..." : "unknown");
  const isSent = !outboxMessagesIds.includes(messageId);

  return (
    <List.Entry<PointerWithRecord<"message">> id={messageId} data={entryData} relativeOrder={relativeOrder}>
      <div role="listitem" className={cx(entryCSSClasses, "MessageEntry")}>
        <div className="pr-2 sm-w:pr-3">
          <Avatar label={senderLabel} photoURL={sender?.photoUrl} width="30px" />
        </div>

        <div className="flex flex-col md-w:flex-row flex-1 min-w-0">
          <Recipients
            nonTruncatedSuffix={
              <>
                {isPrivateThread && <PrivateEntryIcon />}
                {notification?.is_starred && <StarredEntryIcon />}
              </>
            }
          >
            {showRecipientNames ? (
              <div className="truncate">
                <RecipientNames messageId={messageId} />
              </div>
            ) : (
              <>
                <span className="mr-3 truncate">{senderLabel}</span>
              </>
            )}
          </Recipients>

          <Summary
            subject={overrideSubject ?? message.subject}
            formatAsReply={message.is_reply && message.type === "COMMS"}
            details={isPrivateThread ? "private message" : (overrideBody ?? message.body_text)}
          />

          <LabelContainer>
            {shouldShowChannelLabels && <PermittedGroupChips threadId={message.thread_id} />}
          </LabelContainer>
        </div>

        {!isSent ? (
          <Tooltip
            side="bottom"
            content={
              <span>
                {environment.network.isOnline() ? `Sending message ...` : `Waiting for network to send this message`}
              </span>
            }
          >
            <span>
              <MdScheduleSend className="text-slate-9" />
            </span>
          </Tooltip>
        ) : null}

        <EntryActions
          defaultComponent={
            notification?.has_reminder ? (
              <NotificationTimestamp notification={notification} sentAt={message.sent_at} />
            ) : (
              <EntryTimestamp datetime={message.sent_at} />
            )
          }
        >
          <OtherCommandEntryAction />
        </EntryActions>
      </div>
    </List.Entry>
  );
}, isEqual);

const RecipientNames: ComponentType<{ messageId: string }> = (props) => {
  const [names, { isLoading }] = useMessageRecipientNames(props.messageId);

  return <>{names.join(", ") || (isLoading ? "loading..." : "unknown")}</>;
};
