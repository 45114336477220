import { getPointer } from "libs/schema";
import { op } from "libs/transaction";
import { runTransaction, withTxLogger } from "./write";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getAndAssertCurrentUserId } from "~/environment/user.service";

export async function toggleMessageReaction(
  environment: ClientEnvironment,
  props: {
    messageId: string;
    reactionId: string;
  },
) {
  const { recordLoader } = environment;
  const currentUserId = getAndAssertCurrentUserId();

  const pointer = getPointer("message_reactions", props.messageId);

  const [reactionBeforeUpdate] = await recordLoader.getRecord(pointer);

  if (!reactionBeforeUpdate) {
    console.warn(`toggleMessageReaction: Could not find message_reactions with id ${props.messageId}`);

    return;
  }

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "toggleMessageReaction",
    tx: async (transaction) => {
      const [reaction] = await recordLoader.getRecord(pointer);

      let usersReactions = reaction?.reactions?.[currentUserId] || [];

      if (usersReactions.includes(props.reactionId)) {
        usersReactions = usersReactions.filter((r) => r !== props.reactionId);
      } else {
        usersReactions.push(props.reactionId);
      }

      transaction.operations.push(op.set(pointer, ["reactions", currentUserId], usersReactions));
    },
    undo: async (transaction) => {
      const usersReactions = reactionBeforeUpdate?.reactions?.[currentUserId] || [];

      transaction.operations.push(op.set(pointer, ["reactions", currentUserId], usersReactions));
    },
  });
}

export async function setMessageReactions(
  environment: ClientEnvironment,
  props: { messageId: string; reactions: string[] },
) {
  const { recordLoader } = environment;
  const currentUserId = getAndAssertCurrentUserId();

  const pointer = getPointer("message_reactions", props.messageId);

  const [reactionBeforeUpdate] = await recordLoader.getRecord(pointer);

  if (!reactionBeforeUpdate) {
    console.warn(`setMessageReactions: Could not find message_reactions with id ${props.messageId}`);

    return;
  }

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "setMessageReactions",
    tx: async (transaction) => {
      transaction.operations.push(op.set(pointer, ["reactions", currentUserId], props.reactions));
    },
    undo: async (transaction) => {
      const usersReactions = reactionBeforeUpdate?.reactions?.[currentUserId] || [];

      transaction.operations.push(op.set(pointer, ["reactions", currentUserId], usersReactions));
    },
  });
}
