import { ClientEnvironment } from "./ClientEnvironment";
import { config } from "./config";
import { UndoRedoStack } from "./UndoRedoStack";
import { startWith } from "libs/rxjs-operators";
import { pairwise } from "rxjs";
import { ServiceWorkerService } from "./service-worker/ServiceWorkerService";
import { initializeFocusService } from "./focus.service";
import { isInstalledApp } from "~/utils/dom-helpers";
import { Logger } from "libs/logger";
import { PendingUpdates } from "./loading.service";
import { detectBrowserEngine, isDesktopBrowser } from "./detect-browser.service";
import { handleApiVersionError } from "~/utils/handleApiVersionError";
import { AuthService, signout } from "./user.service";
import { createEnvironmentBase } from "./createEnvironmentBase";
import { SharedWorkerService } from "./SharedWorkerService";
import { PersistedDatabaseServiceProvider } from "./PersistedDatabaseService";
import { LeadershipService } from "./LeadershipService";
import { PersistedDatabaseWorkerApi } from "./persisted-database-worker/PersistedDatabaseWorkerApi";
import { MessagePortService } from "./MessagePortService";
import { SyncServiceProvider } from "./shared-worker/SyncService";
import { getClientId } from "~/utils/getClientId";
import { KBarState } from "~/dialogs/kbar";

export async function createEnvironment(props: { logger: Logger; envLogger: Pick<Logger, "debug"> }) {
  const { logger, envLogger } = props;

  envLogger.debug({ config }, "Creating environment...");

  const auth = new AuthService();
  envLogger.debug({ auth }, "Created AuthService");

  const clientId = await getClientId();
  envLogger.debug({ clientId }, "Got clientId");

  // We acquire a context lock on our own clientId. Other tabs can watch this lock
  // to learn when this tab is closed.
  await MessagePortService.acquireContextLock(clientId);
  envLogger.debug({ clientId }, "Acquired clientId context lock");

  const isPersistedDbSupported = isDesktopBrowser() || isInstalledApp();

  // Note that a side-effect of importing the ServiceWorkerService module is that we also
  // register the service worker and setup event listeners on it.
  const serviceWorker = new ServiceWorkerService({ logger });
  envLogger.debug({ serviceWorker }, "Created ServiceWorkerService");

  const leader = new LeadershipService({ logger, clientId });
  envLogger.debug({ leader }, "Created LeadershipService");

  const sharedWorker = await SharedWorkerService.create({ logger, clientId, leader }, { isPersistedDbSupported });
  envLogger.debug({ sharedWorker }, "Created SharedWorkerService");

  let persistedDb = null as PersistedDatabaseWorkerApi | null;
  let schemaVersion = null as number | null;

  if (isPersistedDbSupported) {
    persistedDb = PersistedDatabaseServiceProvider.create({
      logger,
      auth,
      sharedWorker,
      clientId,
      leader,
      serviceWorker,
    });

    envLogger.debug({ persistedDb }, "Created PersistedDatabaseService");

    // We want to activate the shared worker after the services which depend on it are activated.
    // Delaying activation causes pending messages sent through the sharedWorker to be buffered
    // and allows all the services which depend on it to be ready to receive messages.
    await sharedWorker.activate();
    envLogger.debug("Activated shared worker");

    schemaVersion = await persistedDb.getSchemaVersion();
    envLogger.debug({ schemaVersion }, "persistedDb.getSchemaVersion()");

    if (schemaVersion === -1) {
      alert("Error while initializing the offline cache. Please reach out to team@comms.day for help.");
      throw new Error("Error while initializing the offline cache. Please reach out to team@comms.day for help.");
    }
  } else {
    await sharedWorker.activate();
    envLogger.debug("Activated shared worker");

    const { deviceType } = detectBrowserEngine();
    envLogger.debug({ deviceType }, "Skipping PersistedDatabaseService");
  }

  const environmentBase = await createEnvironmentBase({
    logger,
    envLogger,
    auth,
    persistedDb,
    clientId,
    subscribeToAlwaysAvailableRecordsFetchStrategy: "cache",
    onApiAuthenticationError: () => {
      return signout({ force: true });
    },
    onApiVersionError: (error) => {
      return handleApiVersionError(environment, error);
    },
  });

  const undoRedo = new UndoRedoStack();
  envLogger.debug({ undoRedo }, "Created UndoRedoStack");

  const environment: ClientEnvironment = {
    ...environmentBase,
    auth,
    clientId,
    leader,
    logger,
    persistedDb,
    serviceWorker,
    sharedWorker,
    undoRedo,
  };

  initializeFocusService(logger);

  // Log user ID changes and handle logout events.
  auth.currentUserId$.pipe(startWith(auth.getCurrentUserId), pairwise()).subscribe(([prevUserId, currentUserId]) => {
    logger.info({ currentUserId }, `CURRENT_USER_ID`);

    environment.info.currentUserId = currentUserId;
    sharedWorker.onAuthChange();

    if (prevUserId && !currentUserId) {
      // If the user logs out on another tab, we want to reload this tab to reset the environment.
      // We clear pending updates so that we don't warn the user about unsaved changes (which might
      // prevent the page from being reloaded).
      PendingUpdates.clearAll();
      window.location.href = "/login";
    }
  });

  KBarState.init();

  // This exists to facilitate e2e testing.
  // See the e2e Page dialect's `waitForSyncServiceInitialSyncToComplete` method for how this is used.
  if (config.mode === "test") {
    (environment as any).isInitialSyncComplete$ = SyncServiceProvider.isInitialSyncComplete$;
  }

  envLogger.debug({ environment }, "Created environment");

  return environment;
}
