import { ComponentType, useEffect, useRef } from "react";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { ICommandArgs, useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { ISearchEditorRef, SearchEditor } from "~/components/forms/search-editor";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { TextInput } from "~/components/forms/TextInput";
import { firstValueFrom } from "rxjs";
import { mapPlainTextSearchQueryToHTMLQuery } from "../search/useSearch/useGetSearchQueryFromURL";
import { OutlineButton } from "~/components/OutlineButtons";
import { ParsedToken } from "libs/searchQueryParser";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";

export interface IEditInboxSubsectionSubmitValue {
  id: string | null;
  inboxSectionId: string;
  name: string;
  description: string;
  search: {
    queryText: string;
    queryHTML: string;
  };
}

interface SubsectionProps {
  id: string;
  name: string;
  description?: string | null;
  data: {
    query: string;
  };
}

export const EditInboxSubsection: ComponentType<{
  inboxSectionId: string;
  subsection?: SubsectionProps;
  onCancel?: () => void;
  onDelete?: () => void;
  onSubmit?: (value: IEditInboxSubsectionSubmitValue) => void;
}> = withNewCommandContext((props) => {
  const isNewPage = !props.subsection;

  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const editorRef = useSearchEditorRef({ subsection: props.subsection });

  const subsectionControl = useControl(() => {
    return createFormGroup({
      id: createFormControl<string | null>(props.subsection?.id || null),
      inboxSectionId: createFormControl(props.inboxSectionId),
      name: createFormControl(props.subsection?.name || "", {
        required: true,
      }),
      description: createFormControl(props.subsection?.description || ""),
      search: createFormGroup({
        queryText: createFormControl(""),
        queryHTML: createFormControl(""),
      }),
    });
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Submit",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            if (!subsectionControl.isValid) {
              subsectionControl.children.markTouched(true);
              return;
            }

            props.onSubmit?.(subsectionControl.rawValue);
          },
        },
      ];

      return commands;
    },
    deps: [subsectionControl],
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  return (
    <>
      <span className="text-lg">{isNewPage ? "Add Subsection" : "Edit Subsection"}</span>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 text-slate-9">Name</h4>

        <TextInput control={subsectionControl.controls.name} name="name" placeholder="text" className="text-lg" />
      </div>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 text-slate-9">Description</h4>

        <TextInput
          control={subsectionControl.controls.description}
          name="description"
          placeholder="text"
          className="text-lg"
        />
      </div>

      <div className="flex mt-4">
        <h4 className="text-lg mr-3 mt-2 text-slate-9">Query</h4>

        <SearchEditor editorRef={editorRef} control={subsectionControl.controls.search} />
      </div>

      <div className="flex mt-4">
        <OutlineButton onClick={props.onCancel}>Cancel</OutlineButton>

        <div className="w-4" />

        <OutlineButton
          onClick={() => {
            if (!subsectionControl.isValid) {
              subsectionControl.children.markTouched(true);
              return;
            }

            console.log("subsectionControl.rawValue", subsectionControl.rawValue);

            props.onSubmit?.(subsectionControl.rawValue);
          }}
        >
          Save
        </OutlineButton>

        <div className="w-4" />

        {!isNewPage && props.onDelete && (
          <OutlineButton
            onClick={() => {
              const confirmed = confirm("Are you sure you want to delete this subsection?");

              if (!confirmed) return;

              props.onDelete?.();
            }}
          >
            Delete
          </OutlineButton>
        )}
      </div>
    </>
  );
});

function useSearchEditorRef(props: { subsection: SubsectionProps | undefined }) {
  const { subsection } = props;
  const environment = useClientEnvironment();
  const editorRef = useRef<ISearchEditorRef>(null);

  // Initialize the query editor with the initial value of this subsection's
  // query.
  useEffect(() => {
    if (!editorRef.current) return;
    if (!subsection) return;

    let isMounted = true;

    Promise.all([
      mapPlainTextSearchQueryToHTMLQuery(environment, subsection.data.query),
      firstValueFrom(editorRef.current.onCreate$),
    ]).then(([{ queryAsHTML }, editor]) => {
      if (!editorRef.current) return;
      if (queryAsHTML === null) return;
      if (!isMounted) return;

      editor.commands.setContent(queryAsHTML, true, {
        preserveWhitespace: true,
      });
    });

    return () => {
      isMounted = false;
    };
  }, [subsection === undefined, environment]);

  return editorRef;
}
