import { useRecordLoader } from "./useRecordLoader";
import { CURRENT_USER_ID$ } from "~/environment/user.service";
import { switchMap } from "rxjs";
import { FetchStrategy } from "~/environment/QueryCache";

export function useDoneNotifications(props: { fetchStrategy?: FetchStrategy } = {}) {
  return useRecordLoader({
    deps: [props.fetchStrategy],
    depsKey: String(props.fetchStrategy),
    load({ loader, limit, deps: [fetchStrategy] }) {
      const options = { fetchStrategy };

      return CURRENT_USER_ID$.pipe(
        switchMap((userId) => {
          if (!userId) {
            return loader.createObserveQueryResult<"notification">();
          }

          return loader.observeGetNotifications(
            {
              user_id: userId,
              is_done: true,
              orderBy: "done_at",
              orderDir: "DESC",
              limit,
            },
            options,
          );
        }),
      );
    },
    initialLimit: 50,
    limitStep: 50,
    map: (records) => records.map((r) => r.id),
  });
}
