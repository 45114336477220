import { ComponentType, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import * as MainLayout from "~/page-layouts/main-layout";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { isEqual } from "libs/predicates";
import { useMatch, useParams } from "react-router-dom";
import { OutlineButton } from "~/components/OutlineButtons";
import { EditInboxSubsection, IEditInboxSubsectionSubmitValue } from "./EditInboxSubsection";
import { cx } from "@emotion/css";
import { IFormControl, createFormControl, useControl } from "solid-forms-react";
import { Tooltip } from "~/components/Tooltip";
import { removeOneFromArray } from "libs/array-utils";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { oneLine } from "common-tags";
import { useInboxSection } from "~/hooks/useInboxSection";
import { TextInput } from "~/components/forms/TextInput";
import { RedirectToDefaultInboxSection } from "./utils";
import {
  InboxSectionTagRecord,
  InboxSubsectionTagRecord,
  RecordValue,
  SpecialTagTypeEnum,
  generateRecordId,
} from "libs/schema";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { navigateService } from "~/environment/navigate.service";
import { createInboxSection, deleteInboxSection, updateInboxSection } from "~/actions/inboxSection";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { CreateRecord } from "libs/transaction";
import { EmptyListMessage } from "~/components/content-list/ContentList";
import { ParsedToken, parseSearchQuery } from "libs/searchQueryParser";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { isUuid } from "libs/uuid";
import { entryCSSClasses } from "~/components/content-list/layout";
import { toast } from "~/environment/toast-service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { isInboxSectionTagRecord } from "libs/schema/predicates";
import produce from "immer";

export const EditInboxSectionView: ComponentType<{}> = () => {
  const environment = useClientEnvironment();
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const isNewPage = useIsNewSectionPage();
  const inboxSectionId = useInboxSectionId(isNewPage);
  const [inboxSection] = useInboxSection(inboxSectionId);

  const {
    areSubsectionsLoading,
    orderedSubsections,
    addSubsection,
    removeSubsection,
    reorderSubsections,
    currentEditingSubsectionId,
    setCurrentlyEditingSubsectionId,
  } = useOrderedSubsections(inboxSectionId);

  const sectionNameControl = useSectionNameControl(inboxSection);

  useRegisterEditInboxSectionCommands({
    inboxSectionId,
    inboxSection,
    orderedSubsections,
    sectionNameControl,
  });

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  if (!isNewPage && !inboxSection) {
    return null;
  } else if (areSubsectionsLoading) {
    return <EmptyListMessage text="Loading..." loading={true} />;
  }

  if (!inboxSectionId) {
    return <EmptyListMessage text="Invalid URL" />;
  }

  if (inboxSection?.data.order === 0) {
    // Cannot currently edit the default inbox section
    return <RedirectToDefaultInboxSection />;
  }

  const title = isNewPage ? "Add inbox section" : "Edit inbox section";

  return (
    <>
      <Helmet>
        <title>{title} | Comms</title>
      </Helmet>

      <MainLayout.Header ref={headerRef} className="flex-col">
        <h1 className="text-3xl">{title}</h1>

        <div className="flex mt-4">
          <h4 className="text-lg mr-3">Section name:</h4>

          <TextInput control={sectionNameControl} name="name" placeholder="text" className="text-lg" />
        </div>
      </MainLayout.Header>

      <hr />

      <DragDropContext onDragEnd={reorderSubsections}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {orderedSubsections.map((subsection, index) =>
                currentEditingSubsectionId === subsection.id ? (
                  <EditInboxSubsectionEntry
                    key={subsection.id}
                    subsection={subsection}
                    subsections={orderedSubsections}
                    setCurrentEditingSubsectionId={setCurrentlyEditingSubsectionId}
                    addSubsection={addSubsection}
                    removeSubsection={removeSubsection}
                  />
                ) : (
                  <InboxSubsectionEntry
                    key={subsection.id}
                    subsection={subsection}
                    relativeOrder={index}
                    canEdit={currentEditingSubsectionId === null}
                    onEdit={() => setCurrentlyEditingSubsectionId(subsection.id)}
                  />
                ),
              )}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="mb-4" />

      {currentEditingSubsectionId === null && (
        <>
          <div className={entryCSSClasses}>
            <OutlineButton onClick={() => setCurrentlyEditingSubsectionId("new")}>Add subsection</OutlineButton>
          </div>

          <div className={cx(entryCSSClasses, orderedSubsections.length === 0 && "text-slate-9 cursor-not-allowed")}>
            <Tooltip side="bottom" content={orderedSubsections.length === 0 ? "Must have at least one subsection" : ""}>
              <OutlineButton
                onClick={() => {
                  if (!sectionNameControl.isValid || orderedSubsections.length === 0) {
                    sectionNameControl.markTouched(true);
                    return;
                  }

                  submit(environment, {
                    isNew: !inboxSection,
                    id: inboxSectionId,
                    name: sectionNameControl.value,
                    order: inboxSection?.data.order || null,
                    subsections: orderedSubsections,
                  });
                }}
                disabled={orderedSubsections.length === 0}
              >
                Save
              </OutlineButton>
            </Tooltip>
          </div>
        </>
      )}

      {currentEditingSubsectionId === "new" && (
        <div className="px-4 sm-w:px-8 md-w:px-12">
          <div className="flex-1 border p-2">
            <EditInboxSubsection
              inboxSectionId={inboxSectionId}
              onCancel={() => setCurrentlyEditingSubsectionId(null)}
              onSubmit={addSubsection}
            />
          </div>
        </div>
      )}

      <div className="mb-20" />
    </>
  );
};

function useInboxSectionId(isNewPage: boolean) {
  const params = useParams<{ inboxSectionId?: string }>();

  return useMemo(() => {
    if (isNewPage) {
      return generateRecordId("tag");
    }

    if (!isUuid(params.inboxSectionId || "")) {
      return null;
    }

    return params.inboxSectionId;
  }, [params.inboxSectionId, isNewPage]);
}

function useRegisterEditInboxSectionCommands(props: {
  inboxSectionId: string | null | undefined;
  inboxSection: InboxSectionTagRecord | null;
  orderedSubsections: CreateRecord<InboxSubsectionTagRecord>[];
  sectionNameControl: IFormControl<string>;
}) {
  const { inboxSectionId, inboxSection, orderedSubsections, sectionNameControl } = props;
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Close dialog",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback() {
            navigateService(-1);
          },
        },
      ];

      if (inboxSectionId) {
        commands.push({
          label: "Submit",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            if (!sectionNameControl.isValid || orderedSubsections.length === 0) {
              sectionNameControl.markTouched(true);
              return;
            }

            submit(environment, {
              isNew: !inboxSection,
              id: inboxSectionId,
              name: sectionNameControl.value,
              order: inboxSection?.data.order || null,
              subsections: orderedSubsections,
            });
          },
        });
      }

      if (inboxSection) {
        commands.push({
          label: "Delete inbox section",
          callback: () =>
            deleteInboxSection(environment, {
              inboxSectionId: inboxSection.id,
            }),
        });
      }

      return commands;
    },
    deps: [inboxSectionId, inboxSection, orderedSubsections, sectionNameControl, environment],
  });
}

function useSectionNameControl(inboxSection: InboxSectionTagRecord | null) {
  const sectionNameControl = useControl(() => {
    return createFormControl("", {
      required: true,
      validators: (rawValue) => (rawValue.trim() ? null : { required: true }),
    });
  });

  useEffect(() => {
    if (!inboxSection) return;
    sectionNameControl.setValue(inboxSection.name);
  }, [inboxSection?.name, sectionNameControl]);

  return sectionNameControl;
}

function useIsNewSectionPage() {
  return !!useMatch("/inbox/new");
}

function useOrderedSubsections(inboxSectionId?: string | null) {
  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();
  const [areSubsectionsLoading, setAreSubsectionsLoading] = useState(true);
  const [orderedSubsections, setOrderedSubsections] = useState<CreateRecord<InboxSubsectionTagRecord>[]>([]);

  const isNewPage = useIsNewSectionPage();

  const [currentEditingSubsectionId, setCurrentlyEditingSubsectionId] = useState<string | null>(
    isNewPage ? "new" : null,
  );

  useEffect(() => {
    if (!inboxSectionId || isNewPage) {
      setAreSubsectionsLoading(false);
      return;
    }

    let isMounted = true;

    (async () => {
      const [inboxSubsections] = await environment.recordLoader.getInboxSubsections({
        currentUserId: currentUserId,
        inboxSectionId,
      });

      console.log("inboxSubsections", inboxSubsections);

      if (!isMounted) return;

      setOrderedSubsections(inboxSubsections as unknown as CreateRecord<InboxSubsectionTagRecord>[]);

      setAreSubsectionsLoading(false);
    })();

    return () => {
      isMounted = false;
    };
  }, [inboxSectionId, currentUserId, environment]);

  const addSubsection = useCallback(
    (value: IEditInboxSubsectionSubmitValue) => {
      const parsedQuery = parseSearchQuery(value.search.queryText);

      if (!parsedQuery) {
        alert("Invalid search query");
        return;
      }

      if (hasFulltextFilter(parsedQuery)) {
        return;
      }

      setCurrentlyEditingSubsectionId(null);
      setOrderedSubsections((prev) => [
        ...prev,
        {
          id: generateRecordId("tag"),
          type: SpecialTagTypeEnum.INBOX_SUBSECTION,
          name: value.name,
          icon: null,
          description: value.description,
          data: {
            user_id: currentUserId,
            inbox_section_id: value.inboxSectionId,
            order: prev.length,
            query: value.search.queryText,
            parsed_query: parsedQuery,
          },
          owner_organization_id: getAndAssertCurrentUserOwnerOrganizationId(),
          archived_at: null,
        },
      ]);
    },
    [currentUserId, setCurrentlyEditingSubsectionId, setOrderedSubsections],
  );

  const removeSubsection = useCallback(
    (subsectionId: string) => {
      setCurrentlyEditingSubsectionId(null);
      setOrderedSubsections((prev) => {
        const newOrderedSubsections = removeOneFromArray(prev, (el) => el.id === subsectionId);

        return newOrderedSubsections;
      });
    },
    [setCurrentlyEditingSubsectionId, setOrderedSubsections],
  );

  const reorderSubsections = useCallback(
    (dropResult: DropResult) => {
      if (!dropResult.destination) {
        return;
      }

      const sourceIndex = dropResult.source.index;
      const destinationIndex = dropResult.destination.index;

      if (destinationIndex === sourceIndex) {
        return;
      }

      setOrderedSubsections((prev) => {
        const newOrderedSubsections = reorder(prev, sourceIndex, destinationIndex);

        return newOrderedSubsections;
      });
    },
    [setOrderedSubsections],
  );

  return {
    areSubsectionsLoading,
    orderedSubsections,
    /**
     * - `null` if not editing a subsection
     * - `new` if creating a new subsection
     * - else the ID of the subsection being edited
     */
    currentEditingSubsectionId,
    addSubsection,
    removeSubsection,
    reorderSubsections,
    setCurrentlyEditingSubsectionId,
  };
}

const reorder = (subsections: CreateRecord<InboxSubsectionTagRecord>[], startIndex: number, endIndex: number) => {
  const newList = Array.from(subsections);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const removed = newList.splice(startIndex, 1)[0]!;
  newList.splice(endIndex, 0, removed);

  return newList;
};

const EditInboxSubsectionEntry: ComponentType<{
  subsection: CreateRecord<InboxSubsectionTagRecord>;
  subsections: CreateRecord<InboxSubsectionTagRecord>[];
  setCurrentEditingSubsectionId: (subsectionId: string | null) => void;
  addSubsection: (value: IEditInboxSubsectionSubmitValue) => void;
  removeSubsection: (subsectionId: string) => void;
}> = (props) => {
  const { subsection, addSubsection, removeSubsection, setCurrentEditingSubsectionId } = props;

  return (
    <div className="px-4 sm-w:px-8 md-w:px-12">
      <div className="flex-1 border p-2">
        <EditInboxSubsection
          inboxSectionId={subsection.data.inbox_section_id}
          subsection={subsection}
          onCancel={() => setCurrentEditingSubsectionId(null)}
          onDelete={() => removeSubsection(subsection.id)}
          onSubmit={addSubsection}
        />
      </div>
    </div>
  );
};

const hasFulltextFilter = (parsedQuery: ParsedToken[]) => {
  const isThereAFullTextSearchComponent = parsedQuery.some((token) => token.type === "text");

  if (isThereAFullTextSearchComponent) {
    alert(oneLine`
      It looks like this inbox section attempts to filter messages on
      plain text. Unfortunately, inbox sections don't currently 
      support searching all of a message's content. You can achieve similar
      functionality by using a body:"some text" and/or subject:"some text"
      filter. Please edit your inbox sections and save again.
    `);

    return true;
  }

  return false;
};

const InboxSubsectionEntry: ComponentType<{
  subsection: CreateRecord<InboxSubsectionTagRecord>;
  relativeOrder: number;
  canEdit: boolean;
  onEdit: () => void;
}> = memo((props) => {
  return (
    <Draggable draggableId={props.subsection.id} index={props.relativeOrder} isDragDisabled={!props.canEdit}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className={entryCSSClasses}>
            <div {...provided.dragHandleProps}>
              <RxDragHandleHorizontal size={30} className="text-slate-8" />
            </div>

            <div className="font-medium">{props.subsection.name}</div>

            {props.subsection.description && <div className="text-slate-10">{props.subsection.description}</div>}

            <div className="flex-1" />

            {props.canEdit && <OutlineButton onClick={props.onEdit}>Edit</OutlineButton>}
          </div>
        </div>
      )}
    </Draggable>
  );
}, isEqual);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(
    async (
      environment: ClientEnvironment,
      values: {
        isNew: boolean;
        id: string;
        name: string;
        order: number | null;
        subsections: CreateRecord<InboxSubsectionTagRecord>[];
      },
    ) => {
      console.log("submitting...", values);

      const currentUserId = getAndAssertCurrentUserId();

      const [inboxSections] = await environment.recordLoader.getInboxSections({
        currentUserId: currentUserId,
      });

      const lastInboxSection = inboxSections.at(-1);

      if (!isInboxSectionTagRecord(lastInboxSection)) {
        throw new Error("EditInboxSectionView submit: lastInboxSection is null");
      }

      toast("vanilla", {
        subject: "Saving inbox section...",
      });

      if (values.isNew) {
        createInboxSection(environment, {
          inboxSection: {
            id: values.id,
            type: SpecialTagTypeEnum.INBOX_SECTION,
            name: values.name,
            description: null,
            icon: null,
            data: {
              user_id: currentUserId,
              order: lastInboxSection.data.order + 1,
              is_reindexing: true,
            },
            owner_organization_id: getAndAssertCurrentUserOwnerOrganizationId(),
            archived_at: null,
          },
          inboxSubsections: values.subsections.map((subsection, index) =>
            produce(subsection, (draft) => {
              draft.data.order = index;
            }),
          ),
        });
      } else {
        if (values.order === null) {
          throw new Error("EditInboxSectionView submit: values.order is null");
        }

        updateInboxSection(environment, {
          inboxSection: {
            id: values.id,
            type: SpecialTagTypeEnum.INBOX_SECTION,
            name: values.name,
            description: null,
            icon: null,
            data: {
              user_id: currentUserId,
              order: values.order,
              is_reindexing: true,
            },
            owner_organization_id: getAndAssertCurrentUserOwnerOrganizationId(),
            archived_at: null,
          },
          inboxSubsections: values.subsections.map((subsection, index) =>
            produce(subsection, (draft) => {
              draft.data.order = index;
            }),
          ),
        });
      }

      console.log("submitted successfully!");

      toast("vanilla", {
        subject: values.isNew ? "Inbox section created." : "Inbox section updated.",
      });

      navigateService(`/inbox/${values.id}`);
    },
  ),
);
