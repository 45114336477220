import { ComponentType, Suspense, useRef } from "react";
import { useParams } from "react-router";
import { useForceSidebarIntoMode } from "~/page-layouts/sidebar-layout";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { ListScrollbox } from "~/components/list";
import * as ThreadLayout from "~/page-layouts/thread-layout";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { ICommandArgs, useRegisterCommands } from "~/environment/command.service";
import { slate } from "@radix-ui/colors";
import { useIsOnline } from "~/hooks/useIsOnline";
import { closeViewCommand } from "~/utils/common-commands";
import { useSetWebpageBackgroundColor } from "~/hooks/useSetWebpageBackgroundColor";
import { LoadingText } from "~/components/LoadingText";
import { ThreadTimeline } from "./ThreadTimeline";
import { closeThreadView } from "./utils";
import { ThreadHeader } from "./ThreadHeader";
import { ActionToolbar } from "./ActionToolbar";
import { useThread } from "~/hooks/useThread";
import { useIsThreadNotFound } from "~/hooks/useIsThreadNotFound";
import { deriveUUID, isUuid } from "libs/uuid";
import { Navigate } from "react-router-dom";
import { usePreloadThreadData } from "~/hooks/usePreloadThreadData";
import { withOfflineFirstIfSynced } from "~/components/withOfflineFirstIfSynced";
import { triageThread } from "~/actions/notification";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { navigateService } from "~/environment/navigate.service";
import { EmptyListMessage } from "~/components/content-list/ContentList";
import { useNotification } from "~/hooks/useNotification";
import { ThreadLoading } from "./ThreadLoading";

/* -------------------------------------------------------------------------------------------------
 * ThreadView
 * -----------------------------------------------------------------------------------------------*/

export const ThreadView: ComponentType = withOfflineFirstIfSynced({
  useIsOfflineFirst: () => {
    const params = useParams();
    const [notification] = useNotification({ threadId: params.threadId, fetchStrategy: "cache" });
    return !!notification && !notification.is_done;
  },
  Component: () => {
    const environment = useClientEnvironment();
    const params = useParams();
    const scrollboxRef = useRef<HTMLElement>(document.body);
    const headerRef = useRef<HTMLElement>(null);
    const isAppOnline = useIsOnline();
    const threadId = params.threadId;

    useSetWebpageBackgroundColor(slate.slate3);

    useRegisterThreadViewCommands();

    useForceSidebarIntoMode("over");

    const { isThreadNotFound } = useIsThreadNotFound(threadId);
    const { isThreadLoading } = usePreloadThreadData({ threadId });

    useTopScrollShadow({
      scrollboxRef,
      targetRef: headerRef,
      deps: [isThreadLoading, isThreadNotFound],
    });

    if (!threadId) {
      return <NotFound title="Thread Not Found" />;
    }

    /**
     * If a user has lost permission to this thread, we mark it as done and redirect them to the Inbox.
     * TODO: this logic should be moved to the server as soon as the visibility or permissions change.
     * See: https://github.com/levelshealth/comms/pull/1297
     */
    if (!isThreadLoading && isThreadNotFound === "permission-denied") {
      environment.logger.warn({ threadId }, "User lost permission to thread, marking as done");
      triageThread(environment, {
        threadId,
        done: true,
        noToast: true,
        noUndo: true,
      });
      navigateService("/inbox");
      return <EmptyListMessage text="Loading..." loading={true} />;
    }

    // If a user is attempting to nagivate to a link for the old version of Comms,
    // redirect them to the correct url.
    if (threadId && !isUuid(threadId)) {
      return <Navigate to={`/threads/${deriveUUID(threadId)}`} replace />;
    }

    if (isThreadNotFound !== "found" && !isThreadLoading) {
      if (isThreadNotFound === "permission-denied") {
        return <NotFound title="Permission denied" />;
      }

      if (!isAppOnline) {
        return <NotFound title="App Offline" />;
      }

      return <NotFound title="Thread Not Found" />;
    }

    return (
      <Suspense fallback={<ThreadLoading />}>
        {/* <RedirectToDraftDialogIfOnlyDraft threadId={threadId} /> */}

        <div className="MainPanel">
          <ThreadViewHeader threadId={threadId} headerRef={headerRef} />

          <div className="flex">
            <ThreadLayout.ActionPanel>
              <ActionToolbar threadId={threadId} />
            </ThreadLayout.ActionPanel>

            <ThreadLayout.ContentPanel>
              {isThreadLoading ? (
                <ThreadLoading />
              ) : (
                <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
                  <ThreadTimeline threadId={threadId} />
                </ListScrollbox>
              )}
            </ThreadLayout.ContentPanel>

            <div className="flex-1" />
          </div>
        </div>
      </Suspense>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

const ThreadViewHeader: ComponentType<{
  threadId: string;
  headerRef: React.RefObject<HTMLElement>;
}> = (props) => {
  const [thread] = useThread(props.threadId);

  return (
    <>
      <Helmet>
        <title>{thread?.subject || ""} | Thread | Comms</title>
      </Helmet>

      <ThreadLayout.Header ref={props.headerRef} theme={thread?.visibility === "PRIVATE" ? "dark" : "light"}>
        <ThreadLayout.ActionPanel />

        <ThreadLayout.ContentPanel>
          <ThreadHeader threadId={props.threadId} />
        </ThreadLayout.ContentPanel>

        <div className="flex-1" />
      </ThreadLayout.Header>
    </>
  );
};

/* -----------------------------------------------------------------------------------------------*/

/**
 * These commands will be available to someone on the thread
 * view even if the thread hasn't loaded.
 */
function useRegisterThreadViewCommands() {
  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        closeViewCommand({
          callback() {
            closeThreadView();
          },
        }),
      ];

      return commands;
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

// const RedirectToDraftDialogIfOnlyDraft: ComponentType<{
//   threadId: string;
// }> = (props) => {
//   const [draft] = useDraftForThread(props.threadId);
//   const draftId = draft?.id;

//   useEffect(() => {
//     if (!draftId) return;

//     if (navigationHistory.index === 0) {
//       openComposeNewThreadDialog(draftId, {
//         andNavigateToPathname: "/index",
//       });
//     } else {
//       openComposeNewThreadDialog(draftId, {
//         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//         andNavigateToPathname: navigationHistory.current(-1)!.pathname,
//       });
//     }
//   }, [draftId]);

//   return null;
// };

/* -----------------------------------------------------------------------------------------------*/
