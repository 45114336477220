import { map, of, switchMap } from "rxjs";
import { CURRENT_USER_ID$ } from "~/environment/user.service";
import {
  ObserveBlockingLayoutInboxSubsectionIdResult,
  observeBlockingLayoutInboxSubsectionId,
} from "~/observables/observeInboxEntries";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useLoadingObservable } from "./useLoadingObservable";
import { InboxSubsectionTagRecord } from "libs/schema";
import { ClientRecordLoaderObserveGetRecordResultMeta, FetchStrategy } from "~/environment/RecordLoader";

export type UseBlockingLayoutInboxSubsectionResult = [
  InboxSubsectionTagRecord | null,
  ClientRecordLoaderObserveGetRecordResultMeta,
];

export function useBlockingLayoutInboxSubsection(props: {
  inboxSectionId?: string | null;
  fetchStrategy?: FetchStrategy;
}): UseBlockingLayoutInboxSubsectionResult {
  const environment = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, props.inboxSectionId, props.fetchStrategy],
    depsKey: String(props.inboxSectionId) + props.fetchStrategy,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, inboxSectionId, fetchStrategy]) => {
          const options = { fetchStrategy: fetchStrategy };

          return CURRENT_USER_ID$.pipe(
            switchMap((userId) => {
              if (!userId || !inboxSectionId) {
                return of<ObserveBlockingLayoutInboxSubsectionIdResult>([null, { isLoading: false }]);
              }

              return observeBlockingLayoutInboxSubsectionId(
                environment,
                {
                  userId,
                  inboxSectionId,
                },
                options,
              );
            }),
            switchMap(([inboxSubsectionId, { isLoading: isInboxSubsectionIdLoading }]) =>
              !inboxSubsectionId
                ? of<UseBlockingLayoutInboxSubsectionResult>([null, { isLoading: isInboxSubsectionIdLoading }])
                : environment.recordLoader
                    .observeGetRecord(
                      {
                        table: "tag",
                        id: inboxSubsectionId,
                      },
                      {
                        fetchStrategy: options.fetchStrategy,
                        isLoading: isInboxSubsectionIdLoading,
                      },
                    )
                    .pipe(
                      map(
                        ([inboxSubsection, meta]): UseBlockingLayoutInboxSubsectionResult => [
                          inboxSubsection as InboxSubsectionTagRecord | null,
                          meta,
                        ],
                      ),
                    ),
            ),
          );
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  null,
  {
    isLoading: true as boolean,
  },
]) as UseBlockingLayoutInboxSubsectionResult;
