import { AuthService } from "~/environment/user.service";
import { GetRecordOptions } from "./getRecord";
import {
  RecordMap,
  RecordPointer,
  assignToRecordMap,
  createRecordMapFromPointersWithRecords,
  getMapRecord,
  getMapRecords,
  getPointer,
} from "libs/schema";
import { UnreachableCaseError } from "libs/errors";
import { ClientRecordLoaderApi } from "~/environment/RecordLoader";

export interface GetAllThreadViewDataProps extends GetRecordOptions {
  threadId: string;
  getParentThreads?: "none" | "first" | "all";
}

export type GetAllThreadViewDataResult = {
  recordMap: RecordMap;
};

export type GetAllThreadViewDataRecordLoader = Pick<
  ClientRecordLoaderApi,
  | "getRecords"
  | "getThreadTags"
  | "getThreadGroupPermissions"
  | "getThreadUserParticipants"
  | "getThreadUserPermissions"
  | "getThreadTimelineEntries"
  | "getDraftsForThread"
  | "getThreadSubscriptions"
>;

export async function getAllThreadViewData(
  environment: {
    auth: AuthService;
    recordLoader: GetAllThreadViewDataRecordLoader;
  },
  props: GetAllThreadViewDataProps,
): Promise<GetAllThreadViewDataResult> {
  const { recordLoader, auth } = environment;
  const { threadId, getParentThreads = "none", fetchStrategy } = props;
  const fetchOptions = { fetchStrategy };
  const currentUserId = auth.getAndAssertCurrentUserId();

  const pointers = [
    getPointer("thread", threadId),
    getPointer("notification", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
    getPointer("thread_read_receipt", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
    getPointer("thread_seen_receipt", {
      thread_id: threadId,
      user_id: currentUserId,
    }),
  ];

  const queries = await Promise.all([
    recordLoader.getRecords(pointers, fetchOptions).then(([pointersWithRecords]) => {
      const recordMap = createRecordMapFromPointersWithRecords(pointersWithRecords);

      return [undefined, { recordMap }] as const;
    }),
    recordLoader.getThreadTags({ thread_id: threadId }, fetchOptions),
    recordLoader.getThreadGroupPermissions({ thread_id: threadId }, fetchOptions),
    recordLoader.getThreadUserParticipants({ thread_id: threadId }, fetchOptions),
    recordLoader.getThreadUserPermissions({ thread_id: threadId }, fetchOptions),
    recordLoader.getThreadTimelineEntries({ thread_id: threadId }, fetchOptions),
    recordLoader.getDraftsForThread({ threadId, currentUserId: currentUserId }, fetchOptions),
    recordLoader.getThreadSubscriptions({ thread_id: threadId }, fetchOptions),
  ]);

  const recordMaps = queries.map(([, { recordMap }]) => recordMap) as [RecordMap, ...RecordMap[]];

  const recordMap: RecordMap = {};

  assignToRecordMap(recordMap, ...recordMaps);

  const threadTags = getMapRecords(recordMap, "thread_tag");
  const groupPermissions = getMapRecords(recordMap, "thread_group_permission");
  const userPermissions = getMapRecords(recordMap, "thread_user_permission");
  const userParticipants = getMapRecords(recordMap, "thread_user_participant");
  const threadSubscriptions = getMapRecords(recordMap, "thread_subscription");
  const timelineEntries = getMapRecords(recordMap, "thread_timeline");

  const additionalPointers = [
    ...threadTags.map((r) => getPointer("tag", r.tag_id)),
    ...threadTags.map((r) =>
      getPointer("tag_subscription", {
        tag_id: r.tag_id,
        user_id: currentUserId,
      }),
    ),
    ...groupPermissions.map((r) => getPointer("tag", r.group_id)),
    ...groupPermissions.map((r) =>
      getPointer("tag_subscription", {
        tag_id: r.group_id,
        user_id: currentUserId,
      }),
    ),
    ...userPermissions.map((r) => getPointer("user_profile", r.user_id)),
    ...userParticipants.map((r) => getPointer("user_profile", r.user_id)),
    ...threadSubscriptions.map((r) => getPointer("user_profile", r.user_id)),
    ...timelineEntries.flatMap((r): RecordPointer[] => {
      switch (r.type) {
        case "BRANCHED_DRAFT": {
          return [getPointer("draft", r.entry_id)];
        }
        case "BRANCHED_THREAD": {
          return [getPointer("thread", r.entry_id)];
        }
        case "MESSAGE": {
          return [getPointer("message", r.entry_id), getPointer("message_reactions", r.entry_id)];
        }
        default: {
          throw new UnreachableCaseError(r.type);
        }
      }
    }),
  ];

  const [additionalPointerWithRecords] = await recordLoader.getRecords(additionalPointers, fetchOptions);

  assignToRecordMap(recordMap, additionalPointerWithRecords);

  const thread = getMapRecord(recordMap, {
    table: "thread",
    id: threadId,
  });

  if (thread?.branched_from_thread_id) {
    switch (getParentThreads) {
      case "none": {
        break;
      }
      case "first":
      case "all": {
        const { recordMap: parentRecordMap } = await getAllThreadViewData(environment, {
          threadId: thread.branched_from_thread_id,
          getParentThreads: getParentThreads === "first" ? "none" : "all",
          fetchStrategy,
        });

        assignToRecordMap(recordMap, parentRecordMap);
        break;
      }
      default: {
        throw new UnreachableCaseError(getParentThreads);
      }
    }
  }

  return { recordMap };
}
