import { UnreachableCaseError } from "libs/errors";
import {
  DraftAttachmentDoc,
  DraftRecipientDoc,
  DraftGroupRecipientDoc,
  DraftUserRecipientDoc,
  generateRecordId,
  getMapRecords,
  getPointer,
  RecordValue,
  ThreadVisibility,
  ThreadTimelineTypeEnum,
  createRecordMapFromPointersWithRecords,
} from "libs/schema";
import { applyOperation, getOperationPointers, op, Transaction } from "libs/transaction";
import { IEditorMention } from "~/components/forms/message-editor";
import { IRecipientOption } from "~/components/forms/ThreadRecipients";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import * as ops from "libs/actions";
import { promiseAllKeyed } from "libs/promise-utils";
import { isEqual } from "libs/predicates";
import { cloneDeep, isString, uniqBy, uniqWith } from "lodash-es";
import { ClientRecordLoaderApi, FetchStrategy, GetOptions } from "~/environment/RecordLoader";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getOutboxActions } from "~/state/outbox.state";

export type DraftRecipient = { type: IRecipientOption["type"]; id: string };

export function mapRecipientOptionToDraftRecipient(option: IRecipientOption): DraftRecipient {
  return { type: option.type, id: option.value };
}

/**
 * Updates a draft. If the draft is ready to be sent (scheduled_to_be_sent_at is set), it will be sent.
 */
export async function mergeDraft(
  environment: ClientEnvironment,
  props: MergeDraftProps & {
    scheduledToBeSentAt?: Date;
    shouldResendNotifications?: boolean;
    afterUndo?: () => void;
    noToast?: boolean;
  },
  options?: GetOptions,
) {
  const { recordLoader } = environment;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "mergeDraft",
    tx: async (transaction) => {
      const { logger } = withTxLogger(environment, {
        transaction,
        data: props,
      });

      applyMergeDraftToTx(transaction, props);

      if (!props.scheduledToBeSentAt) return;

      // store the message as an undelivered messages as we write it locally
      transaction.onOptimisticWrite = async () => {
        getOutboxActions().addMessageId(props.draftId);
      };

      // remove the message from the undelivered messages when we get a response from the server
      transaction.onServerResponse = () => {
        getOutboxActions().removeMessageId(props.draftId);
      };

      const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);

      const [pointersWithRecord] = await recordLoader.getRecords(pointers, options);

      const beforeRecordMap = createRecordMapFromPointersWithRecords(pointersWithRecord);

      const afterRecordMap = cloneDeep(beforeRecordMap);

      const currentTimestamp = new Date().toISOString();

      // Apply the mutations.
      for (const operation of transaction.operations) {
        applyOperation({
          recordMap: afterRecordMap,
          operation,
          authorId: transaction.authorId,
          currentTimestamp,
        });
      }

      const [draft] = getMapRecords(afterRecordMap, "draft");

      if (!draft) {
        logger.error(`[mergeDraft] ${props.draftId} not found after merge.`);
        throw new Error(`[mergeDraft] draft not found.`);
      }

      if (draft.is_edit) {
        logger.notice(`[mergeDraft] [edit] sending draft ${draft.id} ...`);

        const [message] = await recordLoader.getRecord(getPointer("message", draft.id), options);

        if (!message) {
          logger.error(`[mergeDraft] [edit] message not find ${draft.id}`);
          alert(`Count not find message to edit`);
          throw new Error(`[mergeDraft] [edit] message not found.`);
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.applyEditsToMessage({
            draft,
            message,
            shouldResendNotifications: !!props.shouldResendNotifications,
          }),
        );
      } else if (draft.is_reply) {
        logger.notice(`[mergeDraft] [reply] sending draft ${draft.id} ...`);

        const [thread] = await recordLoader.getRecord(getPointer("thread", draft.thread_id), options);

        if (!thread) {
          logger.error(`[mergeDraft] [reply] thread not found ${draft.thread_id}`);

          throw new Error(`[mergeDraft] [reply] thread not found.`);
        }

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.sendDraftReply({
            scheduledToBeSentAt: props.scheduledToBeSentAt,
            draft,
            thread,
          }),
        );
      } else {
        logger.notice(`[mergeDraft] [new] sending draft ${draft.id} ...`);

        const branchedFromMessage = draft.branched_from_message_id
          ? await recordLoader
              .getRecord(getPointer("message", draft.branched_from_message_id), options)
              .then(([m]) => m)
          : undefined;

        ops.applyOperationsToTransaction(
          transaction,
          ops.draft.sendNewThreadDraft({
            scheduledToBeSentAt: props.scheduledToBeSentAt,
            draft,
            branchedFromMessage: branchedFromMessage || undefined,
          }),
        );
      }

      const branchedDraftTimelineEntry = draft.branched_from_thread_id
        ? await recordLoader
            .getRecord(
              getPointer("thread_timeline", {
                entry_id: draft.id,
                thread_id: draft.branched_from_thread_id,
              }),
              options,
            )
            .then(([m]) => m)
        : undefined;

      applyDeleteDraftToTx(transaction, {
        draft,
        branchedDraftTimelineEntry,
      });
    },
    // We don't bother constructing an "undo" transaction for a simple draft
    // update. This is because "undoing" draft content changes will be handled
    // natively by the compose message editor. Recipient changes will need to
    // be manually undone by the user, but that's easy enough. We only support
    // "undoing" sending a draft.
    undo:
      !props.scheduledToBeSentAt || props.shouldResendNotifications !== undefined
        ? undefined
        : async (transaction) => {
            const { logger } = withTxLogger(environment, {
              transaction,
              data: props,
            });

            transaction.onServerResponse = ({ error }) => {
              if (error) {
                if (!props.noToast) {
                  toast("vanilla", {
                    subject: "Failed to unsend draft",
                    description: "There was an error.",
                  });
                }

                return;
              }

              if (!props.noToast) {
                toast("vanilla", {
                  subject: "Draft unsent",
                });
              }
            };

            const loadedData = await loadApplyUnsendDraftToTxData({
              recordLoader,
              draftId: props.draftId,
              currentUserId: props.currentUserId,
            });

            if (isString(loadedData)) {
              toast("vanilla", {
                subject: "Cannot undo sending message",
                description: loadedData,
              });

              logger.info(`[undo mergeDraft] cannot undo sending ${props.draftId}`);

              return;
            }

            logger.notice(`[undo mergeDraft] unsending ${props.draftId}`);

            applyUnsendDraftToTx(transaction, loadedData);
          },
  });
}

/* -------------------------------------------------------------------------------------------------
 *  unsendDraft
 * -------------------------------------------------------------------------------------------------
 */

export async function unsendDraft(environment: ClientEnvironment, props: { draftId: string }) {
  const { recordLoader } = environment;
  const currentUserId = getAndAssertCurrentUserId();

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "unsendDraft",
    tx: async (transaction) => {
      const { logger } = withTxLogger(environment, {
        transaction,
        data: props,
      });

      const loadedData = await loadApplyUnsendDraftToTxData({
        recordLoader,
        draftId: props.draftId,
        currentUserId,
      });

      if (isString(loadedData)) {
        logger.error(loadedData);
        return;
      }

      applyUnsendDraftToTx(transaction, loadedData);
    },
  });
}

/* -------------------------------------------------------------------------------------------------
 *  deleteDraft
 * -------------------------------------------------------------------------------------------------
 */

export async function deleteDraft(
  environment: ClientEnvironment,
  props: {
    currentUserId: string;
    draftId: string;
    afterUndo?: () => void;
  },
  options?: GetOptions,
) {
  const { recordLoader } = environment;

  toast("vanilla", {
    subject: "Deleting draft...",
  });

  const draftQuery = recordLoader.getRecord({ table: "draft", id: props.draftId }, options).then(([m]) => m);

  const beforeUpdateData = await promiseAllKeyed({
    draft: draftQuery,
  });

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "deleteDraft",
    tx: async (transaction) => {
      const { logger } = withTxLogger(environment, {
        transaction,
        data: props,
      });

      logger.notice(`[deleteDraft] deleting draft ${props.draftId} ...`);

      const loadedData = await loadApplyDeleteDraftToTxData(
        {
          recordLoader,
          draftId: props.draftId,
        },
        options,
      );

      if (isString(loadedData)) {
        logger.error(loadedData);
        return;
      }

      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: "Failed to delete draft.",
          });
        } else {
          toast("vanilla", {
            subject: "Draft deleted.",
          });
        }
      };

      applyDeleteDraftToTx(transaction, loadedData);
    },
    undo: async (transaction) => {
      const { logger } = withTxLogger(environment, {
        transaction,
        data: props,
      });

      const beforeUpdateBranchedMessageQuery = beforeUpdateData.draft?.branched_from_message_id
        ? recordLoader
            .getRecord(
              {
                table: "message",
                id: beforeUpdateData.draft.branched_from_message_id,
              },
              options,
            )
            .then(([m]) => m)
        : undefined;

      const [[draft], beforeUpdateBranchedMessage] = await Promise.all([
        recordLoader.getRecord({ table: "draft", id: props.draftId }, options),
        beforeUpdateBranchedMessageQuery,
      ]);

      if (draft) {
        logger.warn(`[undo deleteDraft] cannot undo delete draft ${props.draftId}, id already exists.`);
        return;
      }

      const { draft: beforeDraft } = beforeUpdateData;

      if (!beforeDraft) {
        logger.warn(`[undo deleteDraft] cannot undo delete draft ${props.draftId}, already deleted`);

        return;
      } else if (beforeDraft.branched_from_message_id && !beforeUpdateBranchedMessage) {
        logger.error(`[undo deleteDraft] cannot undo delete draft ${props.draftId}, missing branched from`);

        return;
      }

      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: "Failed to restore draft.",
            description: "There was an error.",
          });

          return;
        }

        props.afterUndo?.();

        toast("vanilla", {
          subject: "Draft restored.",
        });
      };

      applyMergeDraftToTx(transaction, {
        draftId: beforeDraft.id,
        type: beforeDraft.type,
        currentUserId: props.currentUserId,
        ownerOrganizationId: beforeDraft.owner_organization_id,
        threadId: beforeDraft.thread_id,
        is_reply: beforeDraft.is_reply,
        is_edit: beforeDraft.is_edit,
        branchedFrom:
          beforeDraft.branched_from_message_id && beforeDraft.branched_from_thread_id && beforeUpdateBranchedMessage
            ? {
                threadId: beforeDraft.branched_from_thread_id,
                messageId: beforeDraft.branched_from_message_id,
                messageTimelineOrder: beforeUpdateBranchedMessage.timeline_order,
              }
            : null,
        subject: beforeDraft.new_thread_subject,
        to: beforeDraft.to.map<DraftRecipient>((r) => {
          switch (r.type) {
            case "GROUP": {
              return { type: "group", id: r.group_id };
            }
            case "USER": {
              return { type: "user", id: r.user_id };
            }
            default: {
              throw new UnreachableCaseError(r);
            }
          }
        }),
        cc: [],
        bcc: [],
        bodyHTML: beforeDraft.body_html,
        visibility: beforeDraft.new_thread_visibility,
        groupMentions: beforeDraft.to
          .filter((r): r is DraftGroupRecipientDoc => r.is_mentioned && r.type === "GROUP")
          .map(
            (r): IEditorMention => ({
              type: "group",
              priority: r.priority,
              id: r.group_id,
            }),
          ),
        userMentions: beforeDraft.to
          .filter((r): r is DraftUserRecipientDoc => r.is_mentioned && r.type === "USER")
          .map(
            (r): IEditorMention => ({
              type: "user",
              priority: r.priority,
              id: r.user_id,
            }),
          ),
        attachments: beforeDraft.attachments,
      });

      logger.notice(`[draft] ${props.draftId} restored`, {
        draftId: props.draftId,
      });
    },
  });
}

/* -----------------------------------------------------------------------------------------------*/

export interface MergeDraftProps {
  type: "COMMS" | "EMAIL";
  draftId: string;
  currentUserId: string;
  ownerOrganizationId: string;
  is_reply: boolean;
  is_edit: boolean;
  threadId: string;
  branchedFrom?: null | {
    threadId: string;
    messageId: string;
    messageTimelineOrder: string;
  };
  subject?: string | null;
  bodyHTML?: string;
  to?: DraftRecipient[];
  cc?: DraftRecipient[];
  bcc?: DraftRecipient[];
  visibility?: ThreadVisibility | null;
  userMentions?: IEditorMention[];
  groupMentions?: IEditorMention[];
  attachments?: DraftAttachmentDoc[];
}

function applyMergeDraftToTx(transaction: Transaction, props: MergeDraftProps) {
  switch (props.type) {
    case "COMMS": {
      if (props.cc && props.cc.length > 0) {
        alert("Draft cc recipients not supported yet.");
        throw new Error("applyDraftUpdateToTx: Not implemented");
      } else if (props.bcc && props.bcc.length > 0) {
        alert("Draft bcc recipients not supported yet.");
        throw new Error("applyDraftUpdateToTx: Not implemented");
      }

      const { userMentions = [], groupMentions = [], to = [] } = props;

      // Note that drafts for replies only add recipients that are *new* to the thread to the `to`
      // field. If someone is mentioned in a reply who is already participating in the thread, they
      // will not be added to the `to` field. Because of this, we need to ensure that all mentioned
      // users and groups are included as recipients when constructing the message.
      const toRecipientOptions = uniqBy(
        [
          ...userMentions.map((r) => ({ ...r, is_mentioned: true })),
          ...groupMentions.map((r) => ({ ...r, is_mentioned: true })),
          ...to.map((r) => ({ ...r, is_mentioned: false, priority: 300 })),
        ],
        (r) => r.id,
      );

      const toRecipients = toRecipientOptions.map((recipient) => {
        switch (recipient.type) {
          case "user": {
            return {
              id: generateRecordId("draft_user_recipient_doc", {
                type: "USER",
                user_id: recipient.id,
              }),
              type: "USER",
              user_id: recipient.id,
              priority: recipient.priority,
              is_implicit: false,
              is_mentioned: recipient.is_mentioned,
            } satisfies DraftUserRecipientDoc;
          }
          case "group": {
            return {
              id: generateRecordId("draft_group_recipient_doc", {
                type: "GROUP",
                group_id: recipient.id,
              }),
              type: "GROUP",
              group_id: recipient.id,
              priority: recipient.priority,
              is_implicit: false,
              is_mentioned: recipient.is_mentioned,
            } satisfies DraftGroupRecipientDoc;
          }
          case "email": {
            throw new Error("Email drafts are not yet supported.");
          }
          default: {
            throw new UnreachableCaseError(recipient.type);
          }
        }
      });

      ops.applyOperationsToTransaction(
        transaction,
        ops.draft.mergeDraft({
          draftId: props.draftId,
          userId: props.currentUserId,
          type: props.type,
          is_edit: props.is_edit,
          is_reply: props.is_reply,
          ownerOrganizationId: props.ownerOrganizationId,
          threadId: props.threadId,
          attachments: props.attachments,
          bodyHTML: props.bodyHTML,
          branchedFrom: props.branchedFrom,
          subject: props.subject,
          visibility: props.visibility,
          to: toRecipients,
        }),
      );

      break;
    }
    case "EMAIL": {
      throw new Error("Email drafts are not yet supported.");
    }
    default: {
      throw new UnreachableCaseError(props.type);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

interface LoadedUnsendDraftData {
  message: RecordValue<"message">;
  thread: RecordValue<"thread"> | null;
  branchedFromMessage: RecordValue<"message"> | null;
  loadedDeleteMessageData: ApplyDeleteMessageProps | null;
  loadedDeleteThreadData: ApplyDeleteThreadProps | null;
}

function applyUnsendDraftToTx(transaction: Transaction, props: LoadedUnsendDraftData) {
  const { message, thread, branchedFromMessage, loadedDeleteMessageData, loadedDeleteThreadData } = props;

  const to = message.to.map<DraftRecipientDoc>((recipient) => {
    switch (recipient.type) {
      case "GROUP": {
        return {
          id: generateRecordId("draft_group_recipient_doc", {
            type: "GROUP",
            group_id: recipient.group_id,
          }),
          type: "GROUP",
          group_id: recipient.group_id,
          priority: recipient.priority,
          is_implicit: recipient.is_implicit,
          is_mentioned: recipient.is_mentioned,
        };
      }
      case "USER": {
        return {
          id: generateRecordId("draft_user_recipient_doc", {
            type: "USER",
            user_id: recipient.user_id,
          }),
          type: "USER",
          user_id: recipient.user_id,
          priority: recipient.priority,
          is_implicit: recipient.is_implicit,
          is_mentioned: recipient.is_mentioned,
        };
      }
      default: {
        throw new UnreachableCaseError(recipient);
      }
    }
  });

  if (message.is_reply) {
    if (!loadedDeleteMessageData) {
      throw new Error(`applyUnsendDraftToTx: !loadedDeleteMessageData`);
    }

    transaction.operations.push(
      op.set("draft", {
        id: message.id,
        body_html: message.body_html,
        attachments: message.attachments,
        branched_from_message_id: null,
        branched_from_thread_id: null,
        is_reply: true,
        is_edit: false,
        new_thread_subject: null,
        new_thread_visibility: null,
        owner_organization_id: message.owner_organization_id,
        thread_id: message.thread_id,
        type: message.type as "COMMS" | "EMAIL",
        user_id: message.sender_user_id!,
        to,
      }),
    );

    applyDeleteMessageToTx(transaction, loadedDeleteMessageData!);
  } else {
    if (!thread) {
      throw new Error(`applyUnsendDraftToTx: !thread`);
    } else if (!loadedDeleteThreadData) {
      throw new Error(`applyUnsendDraftToTx: !loadedDeleteThreadData`);
    }

    transaction.operations.push(
      op.set("draft", {
        id: message.id,
        body_html: message.body_html,
        attachments: message.attachments,
        branched_from_message_id: thread.branched_from_message_id,
        branched_from_thread_id: thread.branched_from_thread_id,
        is_reply: false,
        is_edit: false,
        new_thread_subject: thread.subject,
        new_thread_visibility: thread.visibility,
        owner_organization_id: message.owner_organization_id,
        thread_id: message.thread_id,
        type: message.type as "COMMS" | "EMAIL",
        user_id: message.sender_user_id!,
        to,
      }),
    );

    if (branchedFromMessage) {
      transaction.operations.push(
        op.set("thread_timeline", {
          id: generateRecordId("thread_timeline", {
            entry_id: message.id,
            thread_id: branchedFromMessage.thread_id,
          }),
          entry_id: message.id,
          thread_id: branchedFromMessage.thread_id,
          type: ThreadTimelineTypeEnum.BRANCHED_DRAFT,
          data: null,
          order: op.fieldValue.BRANCHED_DRAFT_TIMELINE_ORDER({
            branchedFromMessageId: branchedFromMessage.id,
            draftCreatedAt: op.fieldValue.SERVER_TIMESTAMP(),
          }),
          creator_user_id: message.sender_user_id!,
          owner_organization_id: message.owner_organization_id,
        }),
      );
    }

    applyDeleteThreadToTx(transaction, loadedDeleteThreadData);
  }
}

async function loadApplyUnsendDraftToTxData(props: {
  recordLoader: ClientRecordLoaderApi;
  draftId: string;
  currentUserId: string;
}): Promise<LoadedUnsendDraftData | string> {
  const { recordLoader, draftId } = props;

  const [message] = await recordLoader.getRecord({
    table: "message",
    id: draftId,
  });

  if (!message) {
    return `Message not found.`;
  } else if (message.sent_at <= new Date().toISOString()) {
    return `Message already sent.`;
  } else if (message.is_delivered) {
    return `Message already delivered.`;
  } else if (message.sender_user_id !== props.currentUserId) {
    return `Message not sent by current user.`;
  } else if (message.type === "EMAIL_BCC") {
    return `Message is a BCC message.`;
  }

  let thread: RecordValue<"thread"> | null = null;
  let branchedFromMessage: RecordValue<"message"> | null = null;
  let loadedDeleteMessageData: ApplyDeleteMessageProps | null = null;
  let loadedDeleteThreadData: ApplyDeleteThreadProps | null = null;

  if (message.is_reply) {
    const loadedDeleteMessageDataOrErrorString = await loadApplyDeleteMessageToTxData({
      recordLoader,
      messageId: message.id,
      currentUserId: props.currentUserId,
    });

    if (isString(loadedDeleteMessageDataOrErrorString)) {
      return loadedDeleteMessageDataOrErrorString;
    }

    loadedDeleteMessageData = loadedDeleteMessageDataOrErrorString;
  } else {
    const [[threadTemp], loadedDeleteThreadDataOrErrorString] = await Promise.all([
      recordLoader.getRecord({ table: "thread", id: message.thread_id }),
      loadApplyDeleteThreadToTxData({
        recordLoader,
        threadId: message.thread_id,
      }),
    ]);

    if (isString(loadedDeleteThreadDataOrErrorString)) {
      return loadedDeleteThreadDataOrErrorString;
    }

    if (!threadTemp) {
      return `Thread with ID ${message.thread_id} not found.`;
    }

    thread = threadTemp;
    loadedDeleteThreadData = loadedDeleteThreadDataOrErrorString;

    if (thread.branched_from_message_id) {
      const [branchedFromMessageTemp] = await recordLoader.getRecord("message", thread.branched_from_message_id);

      branchedFromMessage = branchedFromMessageTemp;
    }
  }

  return {
    message,
    thread,
    branchedFromMessage,
    loadedDeleteMessageData,
    loadedDeleteThreadData,
  };
}

/* -----------------------------------------------------------------------------------------------*/

interface ApplyDeleteMessageProps {
  message: { id: string };
  threadTimelineEntry: { id: string };
}

function applyDeleteMessageToTx(transaction: Transaction, props: ApplyDeleteMessageProps) {
  const { message, threadTimelineEntry } = props;

  transaction.operations.push(op.delete("message", message), op.delete("thread_timeline", threadTimelineEntry));
}

async function loadApplyDeleteMessageToTxData(props: {
  recordLoader: ClientRecordLoaderApi;
  messageId: string;
  currentUserId: string;
}) {
  const { recordLoader, messageId, currentUserId } = props;

  const [message] = await recordLoader.getRecord({
    table: "message",
    id: messageId,
  });

  if (!message) {
    return `Message with ID ${messageId} not found.`;
  } else if (message.sent_at <= new Date().toISOString()) {
    return `Message with ID ${messageId} already sent.`;
  } else if (message.is_delivered) {
    return `Message with ID ${messageId} already delivered.`;
  } else if (message.sender_user_id !== currentUserId) {
    return `Message with ID ${messageId} not sent by current user.`;
  }

  const [threadTimelineEntry] = await recordLoader.getRecord(
    getPointer("thread_timeline", {
      thread_id: message.thread_id,
      entry_id: messageId,
    }),
  );

  if (!threadTimelineEntry) {
    return `Thread timeline entry with ID ${messageId} not found.`;
  }

  return {
    message,
    threadTimelineEntry,
  } satisfies ApplyDeleteMessageProps;
}

/* -----------------------------------------------------------------------------------------------*/

interface ApplyDeleteThreadProps {
  thread: { id: string };
}

function applyDeleteThreadToTx(transaction: Transaction, props: ApplyDeleteThreadProps) {
  const { thread } = props;

  transaction.operations.push(op.delete("thread", thread));
}

async function loadApplyDeleteThreadToTxData(props: {
  recordLoader: ClientRecordLoaderApi;
  threadId: string;
}): Promise<ApplyDeleteThreadProps | string> {
  const { recordLoader, threadId } = props;

  const [thread] = await recordLoader.getRecord({
    table: "thread",
    id: threadId,
  });

  if (!thread) {
    return `Thread with ID ${threadId} not found.`;
  }

  return {
    thread,
  };
}

/* -----------------------------------------------------------------------------------------------*/

interface ApplyDeleteDraftProps {
  draft: { id: string };
  branchedDraftTimelineEntry?: { id: string } | null;
}

function applyDeleteDraftToTx(transaction: Transaction, props: ApplyDeleteDraftProps) {
  const { draft } = props;

  transaction.operations.push(op.delete("draft", draft));

  if (props.branchedDraftTimelineEntry) {
    transaction.operations.push(op.delete("thread_timeline", props.branchedDraftTimelineEntry));
  }
}

async function loadApplyDeleteDraftToTxData(
  props: {
    recordLoader: ClientRecordLoaderApi;
    draftId: string;
  },
  options?: GetOptions,
): Promise<ApplyDeleteDraftProps | string> {
  const { recordLoader, draftId } = props;

  const [[draft]] = await Promise.all([
    recordLoader.getRecord(
      {
        table: "draft",
        id: draftId,
      },
      options,
    ),
  ]);

  if (!draft) {
    return `Draft with ID ${draftId} not found.`;
  }

  let branchedDraftTimelineEntry: RecordValue<"thread_timeline"> | undefined;

  if (draft.branched_from_thread_id) {
    const [record] = await recordLoader.getRecord(
      getPointer("thread_timeline", {
        entry_id: draftId,
        thread_id: draft.branched_from_thread_id,
      }),
      options,
    );

    if (record) {
      branchedDraftTimelineEntry = record;
    }
  }

  return {
    draft: draft,
    branchedDraftTimelineEntry,
  };
}
