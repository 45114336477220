import { Observable, map } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { defaultUserSettings, NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { ClientRecordLoaderObserveGetRecordResult, ObserveOptions } from "~/environment/RecordLoader";

export type ObserveCurrentUserSettingsResult = {
  settings: NormalizedUserSettingsDoc | null;
  isLoading: boolean;
};

/**
 * This can return null if the user is currently offline.
 */
export function observeCurrentUserSettings(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    userId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveCurrentUserSettingsResult> {
  const cacheKey = props.userId + options?.fetchStrategy + options?.isLoading;

  const cachedQuery = queryCache.get(cacheKey);

  if (cachedQuery) return cachedQuery;

  const observable = environment.recordLoader
    .observeGetRecord(
      {
        table: "user_settings",
        id: props.userId,
      },
      options,
    )
    .pipe(
      map(normalizeUserSettings),
      cacheReplayForTime({
        timeMs: 100,
        onInit: () => {
          queryCache.set(cacheKey, observable);
        },
        onCleanup: () => {
          queryCache.delete(cacheKey);
        },
      }),
    );

  return observable;
}

const queryCache = new Map<string, Observable<ObserveCurrentUserSettingsResult>>();

/* -----------------------------------------------------------------------------------------------*/

export function normalizeUserSettings([
  record,
  { isLoading },
]: ClientRecordLoaderObserveGetRecordResult<"user_settings">): ObserveCurrentUserSettingsResult {
  if (!record) return { settings: null, isLoading };

  const settings = record?.settings;

  const hasValidScheduledDeliverySettings = settings && Object.keys(settings).length > 0;

  if (!hasValidScheduledDeliverySettings) {
    return { settings: defaultUserSettings(), isLoading };
  }

  return {
    settings: {
      ...defaultUserSettings(),
      ...settings,
    },
    isLoading,
  };
}

/* -----------------------------------------------------------------------------------------------*/
