import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  ObserveGroupFoldersUserIsSubscribedToResult,
  observeGroupFoldersUserIsSubscribedTo,
} from "~/observables/observeGroupFoldersUserIsSubscribedTo";
import { useLoadingObservable } from "./useLoadingObservable";
import { FetchStrategy } from "~/environment/RecordLoader";

export type UseGroupFoldersUserIsSubscribedToResult = ObserveGroupFoldersUserIsSubscribedToResult;

export function useGroupFoldersUserIsSubscribedTo(props: {
  userId: string | null | undefined;
  fetchStrategy?: FetchStrategy;
}): UseGroupFoldersUserIsSubscribedToResult {
  const environment = useClientEnvironment();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [environment, props.userId, props.fetchStrategy],
    depsKey: String(props.userId) + props.fetchStrategy,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([environment, userId, fetchStrategy]) =>
          !userId
            ? of<UseGroupFoldersUserIsSubscribedToResult>([{}, { isLoading: false }])
            : observeGroupFoldersUserIsSubscribedTo(environment, { userId, includeArchived: true }, { fetchStrategy }),
        ),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  Object.freeze({}),
  { isLoading: true },
]) as unknown as UseGroupFoldersUserIsSubscribedToResult;
