import { MS_IN_SECOND } from "libs/date-helpers";
import { ApiVersionError } from "libs/errors";
import { onlyCallFnOnceWhilePreviousCallIsPending, wait } from "libs/promise-utils";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { PersistedDatabaseServiceProvider } from "~/environment/PersistedDatabaseService";
import { signout } from "~/environment/user.service";

export const handleApiVersionError = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: Pick<ClientEnvironment, "serviceWorker" | "info" | "logger">, error: ApiVersionError | null) => {
    const { serviceWorker, info, logger } = environment;

    logger.notice(
      {
        server: {
          apiVersion: error?.apiVersion ?? null,
          schemaVersion: error?.schemaVersion ?? null,
        },
        client: {
          apiVersion: info.version.api,
          schemaVersion: info.version.schema.actual,
        },
      },
      "[handleApiVersionError] api update available",
    );

    // We'd like to alert the current user that a new version of the app is available
    // and we're going to sign them out and reload the page.
    // Unfortunately, if the current tab is the persisted database leader and we show
    // an alert, javascript execution will be paused until the user interacts with the
    // alert. This will prevent the persisted database leader from responding to
    // messages from other tabs, potentially causing errors. So for the time being we
    // simply install the new update without notifying the user.
    //
    // alert(oneLine`
    //   A new version of the app is available.
    //   You will be signed out and the page will reload.
    // `);

    // Schema version will be null if the persisted database has not been initialized.
    const schemaVersion = info.version.schema.actual;

    if (error && schemaVersion !== null && error.schemaVersion > schemaVersion) {
      try {
        // This will tell Comms to reset the persisted database the next time the app is loaded.
        await PersistedDatabaseServiceProvider.reloadAllTabsAndReinstallCommsDatabase();
      } catch (error) {
        logger.error({ error }, "[handleApiVersionError] reloadAllTabsAndReinstallCommsDatabase");
        await forceSignout();
      }
    }

    try {
      // This will check for a service worker update and, if available, install and activate
      // the update. This will cause the page to reload with the latest version of the client
      // if successful.
      await serviceWorker.checkForUpdateAndActivateWhenAvailable();

      // Allow some time for the service worker to update
      await wait(MS_IN_SECOND * 5);
    } catch (error) {
      logger.error({ error }, "[handleApiVersionError] checkForUpdateAndActivateIfAvailable");

      // Theoretically, the above steps are all that should be needed to update the client
      // to the latest version of the API. If we fail to install the latest service worker
      // update for some reason though, we fallback to signing out.
      await forceSignout();
    }

    if (!error) {
      // If we were unable to parse the error from the server, we fallback to signing out after attempting
      // to update the service worker. A side effect of signing out is that the persisted database will be
      // cleared and reinstalled.
      await forceSignout();
    }

    // An API version error should always result in a page reload. Either because the service worker needs
    // to install an update or because the sqlite database needs to be reinstalled.
    // If we don't reload the page within a reasonable amount of time, we assume something has gone
    // wrong and sign the user out.
    await wait(MS_IN_SECOND * 15);

    logger.fatal({ error }, "[handleApiVersionError] forcing signout after failed reload");

    await forceSignout();
  },
);

/**
 * Will not resolve until the user has been signed out.
 */
async function forceSignout() {
  await signout({ force: true });
  // We delay resolving to give time for the page to reload
  await wait(MS_IN_SECOND * 5);
}
