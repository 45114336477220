import {
  ComponentType,
  ForwardedRef,
  RefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { IListRef, focusLastListEntry, useListScrollboxContext } from "~/components/list";
import { focusReply, getLastMessageEntry } from "../utils";
import { ICommandArgs, useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { IEditorMention, IRichTextEditorRef } from "~/components/forms/message-editor";
import { isEqual } from "libs/predicates";
import {
  IComposeMessageForm,
  IComposeMessageFormValue,
  createComposeMessageForm,
  getSaveDraftFns,
  sendMessageCommand,
  useAutosaveDraft,
  usePromptToRemoveRecipientOnMentionRemoval,
} from "~/components/ComposeMessageContext";
import { useAsync } from "react-use";
import { createRecipientOptionFromPointer } from "~/components/forms/ThreadRecipients";
import {
  DraftGroupRecipientDoc,
  DraftUserRecipientDoc,
  MessageType,
  ThreadVisibility,
  generateRecordId,
  getMentionFrequencyKey,
} from "libs/schema";
import { isNonNullable } from "libs/predicates";
import { IFormControl, createFormControl, useControl } from "solid-forms-react";
import { useThreadVisibility } from "~/hooks/useThreadVisibility";
import { useComposedRefs } from "~/hooks/useComposedRefs";
import {
  AttachFileButton,
  ComposeMessageReplyBase,
  DeleteDraftButton,
  SendDraftButton,
} from "~/components/ComposeReplyBase";
import { observeFocusWithin, setScrollTop } from "~/utils/dom-helpers";
import { ReplyDraftHeader } from "./ReplyDraftHeader";
import { OutlineButton } from "~/components/OutlineButtons";
import { Tooltip } from "~/components/Tooltip";
import { MergeDraftProps, deleteDraft, mapRecipientOptionToDraftRecipient, mergeDraft } from "~/actions/draft";
import { PendingUpdates } from "~/environment/loading.service";
import { getSelectionAsHTMLString } from "../getSelectedPostHTMLString";
import { addAttachmentCommand, deleteDraftCommand, replyToThreadCommand } from "~/utils/common-commands";
import { getCurrentRouterLocation, updateSearchParams } from "~/environment/navigate.service";
import { getDraftDataStorageKey } from "~/environment/draft.service";
import { htmlToText } from "libs/htmlToText";
import { handleSubmit } from "~/components/forms/utils";
import dayjs from "dayjs";
import { combineLatest, filter, from, map, merge } from "rxjs";
import { waitForCacheToContainRecord } from "~/utils/database-utils";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { cx } from "@emotion/css";
import { getCurrentUserSettings } from "~/queries/getCurrentUserSettings";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { Editor } from "@tiptap/core";
import { SwitchInput } from "~/components/forms/SwitchInput";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { addAndUploadAttachment } from "~/components/forms/message-editor/uploads";
import { useFileInputElement } from "~/hooks/useFileInputElement";
import { UnreachableCaseError } from "libs/errors";
import { GetOptions } from "~/environment/RecordLoader";
import { KBarState } from "~/dialogs/kbar";
import { startWith } from "libs/rxjs-operators";
import { getSessionStorage } from "~/environment/KVStore";

export interface TComposeReplyProps {
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRefStore: Map<string, IRichTextEditorRef>;
  draftId: string;
  isEditingExistingMessage?: boolean;
  threadId: string;
  threadType: MessageType;
  threadVisibility: ThreadVisibility;
  threadSubject: string;
  isFirstDraft: boolean;
  focusOnInit: boolean;
}

export const ComposeThreadReply = memo(
  withNewCommandContext({
    forwardRef: true,
    Component: forwardRef((props: TComposeReplyProps, ref: ForwardedRef<IRichTextEditorRef>) => {
      const control = useReplyFormControl(props);

      if (!control) return null;

      return <ComposeReplyForm ref={ref} control={control} {...props} />;
    }),
  }),
  isEqual,
);

const ComposeReplyForm = forwardRef<
  IRichTextEditorRef,
  {
    listRef: RefObject<IListRef<TThreadTimelineEntry>>;
    editorRefStore: Map<string, IRichTextEditorRef>;
    control: IComposeMessageForm;
    draftId: string;
    isEditingExistingMessage?: boolean;
    threadId: string;
    threadType: MessageType;
    threadVisibility: ThreadVisibility;
    threadSubject: string;
    isFirstDraft: boolean;
    focusOnInit: boolean;
  }
>((props, ref) => {
  const { control, editorRefStore } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<IRichTextEditorRef>(null);
  const { scrollboxRef } = useListScrollboxContext();

  const resendNotificationsControl = useControl(() => {
    if (!props.isEditingExistingMessage) return;
    return createFormControl(false);
  }, [props.isEditingExistingMessage]);

  const composedEditorRefs = useComposedRefs(ref, editorRef, (value) => {
    if (value) {
      editorRefStore.set(props.draftId, value);
    } else {
      editorRefStore.delete(props.draftId);
    }
  });

  useAutosaveDraft({
    control,
    saveDraft: saveReplyDraft,
    cancelSaveDraft: cancelSaveReplyDraft,
    editorRef,
  });

  const saveDraftImmediatelyFn = useSaveDraftImmediatelyFn(control);

  useEditorMentionWeightAdjustments({ threadId: props.threadId, editorRef });

  const { convertDraftToBranchedReply } = useRegisterComposeReplyCommands({
    threadId: props.threadId,
    threadSubject: props.threadSubject,
    threadVisibility: props.threadVisibility,
    control,
    resendNotificationsControl,
    editorRef,
    listRef: props.listRef,
    wrapperRef,
    isFirstDraft: props.isFirstDraft,
  });

  usePromptToRemoveRecipientOnMentionRemoval(control);

  const onEditorStartOverflow = useCallback(() => {
    if (props.isEditingExistingMessage) return;
    focusLastListEntry(props.listRef);
  }, [props.isEditingExistingMessage, props.listRef]);

  const onEditorEndOverflow = useCallback(() => {
    if (props.isEditingExistingMessage) return;
    if (!scrollboxRef.current) return;
    setScrollTop(scrollboxRef.current, (value) => value + 100);
  }, [props.isEditingExistingMessage, scrollboxRef]);

  return (
    <>
      <ComposeMessageReplyBase
        ref={composedEditorRefs}
        control={control}
        saveDraftFn={saveDraftImmediatelyFn}
        header={
          <ReplyDraftHeader
            control={control}
            listRef={props.listRef}
            threadId={props.threadId}
            threadType={props.threadType}
            threadVisibility={props.threadVisibility}
            isEditingExistingMessage={props.isEditingExistingMessage}
          />
        }
        draftActions={
          <DraftActions
            isEditingExistingMessage={props.isEditingExistingMessage}
            resendNotificationsControl={resendNotificationsControl}
            convertDraftToBranchedReply={convertDraftToBranchedReply}
          />
        }
        formRef={formRef}
        focusOnInit={props.focusOnInit}
        wrapperRef={wrapperRef}
        onEditorStartOverflow={onEditorStartOverflow}
        onEditorEndOverflow={onEditorEndOverflow}
        className={cx("mt-4", props.isFirstDraft && "IsFirstDraft")}
      />

      {/* <ReplyRecipientInfo thread={props.thread} /> */}
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

const DraftActions: ComponentType<{
  isEditingExistingMessage?: boolean;
  resendNotificationsControl?: IFormControl<boolean>;
  convertDraftToBranchedReply: () => Promise<void>;
}> = (props) => {
  return (
    <>
      <SendDraftButton label={props.isEditingExistingMessage ? "Save edits" : "Send"} />

      {!props.isEditingExistingMessage && (
        <Tooltip side="bottom" content="Convert to branched Reply">
          <OutlineButton
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              props.convertDraftToBranchedReply();
            }}
          >
            <small>Branch Reply</small>
          </OutlineButton>
        </Tooltip>
      )}

      {props.isEditingExistingMessage && props.resendNotificationsControl && (
        <div className="flex items-center">
          <div className="w-2" />

          <SwitchInput id="resend-notifications-checkbox" control={props.resendNotificationsControl} />

          <label htmlFor="resend-notifications-checkbox" className="ml-2">
            <small className="font-medium">Resend notifications?</small>
          </label>
        </div>
      )}

      <div className="flex-1" />

      <AttachFileButton />

      <DeleteDraftButton label={props.isEditingExistingMessage ? "Discard edits" : "Delete draft"} />
    </>
  );
};

/* -------------------------------------------------------------------------------------------------
 * useReplyFormControl
 * -----------------------------------------------------------------------------------------------*/

function useReplyFormControl(props: { draftId: string; threadId: string }): IComposeMessageForm | undefined {
  const { draftId, threadId } = props;

  const environment = useClientEnvironment();

  const initialFormValues = useAsync(
    () => getInitialFormValues(environment, { threadId, draftId }),
    [draftId, threadId, environment],
  );

  const control = useControl(() => {
    if (!initialFormValues.value) return;
    const control = createComposeMessageForm(initialFormValues.value);
    control.controls.subject.markDisabled(true);
    return control;
  }, [initialFormValues.value]);

  const { visibility } = useThreadVisibility(threadId);

  // respond to thread visibility changes
  useEffect(() => {
    if (!control) return;

    control.patchValue({ visibility });
  }, [control, visibility]);

  return control;
}

/* -----------------------------------------------------------------------------------------------*/

async function getInitialFormValues(
  environment: Pick<ClientEnvironment, "recordLoader">,
  props: {
    threadId: string;
    draftId: string;
  },
  options?: GetOptions,
): Promise<IComposeMessageFormValue | null> {
  const { draftId, threadId } = props;

  const [[thread], [draft]] = await Promise.all([
    environment.recordLoader.getRecord("thread", threadId, options),
    environment.recordLoader.getRecord("draft", draftId, options),
  ]);

  if (!thread || !draft) return null;

  const isPrivate = thread.visibility === "PRIVATE";

  const recipientOptions = await Promise.all(
    draft.to.map((r) => {
      switch (r.type) {
        case "GROUP": {
          return createRecipientOptionFromPointer(
            environment,
            {
              pointer: { table: "tag", id: r.group_id },
              isThreadPrivate: isPrivate,
            },
            options,
          );
        }
        case "USER": {
          return createRecipientOptionFromPointer(
            environment,
            {
              pointer: { table: "user_profile", id: r.user_id },
              isThreadPrivate: isPrivate,
            },
            options,
          );
        }
        default: {
          throw new UnreachableCaseError(r);
        }
      }
    }),
  );

  const userMentions = draft.to
    .filter((r): r is DraftUserRecipientDoc => r.is_mentioned && r.type === "USER")
    .map((r): IEditorMention => {
      return {
        type: "user",
        id: r.user_id,
        priority: r.priority,
      };
    });

  const groupMentions = draft.to
    .filter((r): r is DraftGroupRecipientDoc => r.is_mentioned && r.type === "GROUP")
    .map((recipient): IEditorMention => {
      return {
        type: "group",
        id: recipient.group_id,
        priority: recipient.priority,
      };
    });

  return {
    type: draft.type,
    messageId: draft.id,
    threadId: draft.thread_id,
    isEdit: draft.is_edit,
    isReply: draft.is_reply,
    branchedFrom: null,
    visibility: thread.visibility,
    recipients: {
      to: recipientOptions.filter(isNonNullable),
      cc: [],
      bcc: [],
    },
    // subject is not used when composing reply
    subject: "",
    attachments: draft.attachments,
    body: {
      content: draft.body_html,
      groupMentions,
      userMentions,
    },
  };
}

/* -------------------------------------------------------------------------------------------------
 * saveReplyDraft, cancelSaveReplyDraft
 * -----------------------------------------------------------------------------------------------*/

const [saveReplyDraft, cancelSaveReplyDraft] = getSaveDraftFns(function (environment, values) {
  this.pendingDebouncePostId = null;

  return mergeDraft(environment, {
    currentUserId: getAndAssertCurrentUserId(),
    ownerOrganizationId: getAndAssertCurrentUserOwnerOrganizationId(),
    is_edit: values.isEdit,
    is_reply: values.isReply,
    draftId: values.messageId,
    threadId: values.threadId,
    type: values.type,
    to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
    cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
    bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
    userMentions: values.body.userMentions,
    groupMentions: values.body.groupMentions,
    attachments: values.attachments,
    bodyHTML: values.body.content,
  })
    .catch((e) => {
      console.error("mergeDraft", e);
      throw e;
    })
    .finally(() => {
      PendingUpdates.remove(values.messageId);
    });
});

function useSaveDraftImmediatelyFn(control: IComposeMessageForm) {
  const environment = useClientEnvironment();

  return useCallback(() => {
    return saveReplyDraft(environment, control.rawValue, { immediate: true });
  }, [control, environment]);
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterComposeReplyCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterComposeReplyCommands(props: {
  threadId: string;
  threadVisibility: ThreadVisibility;
  threadSubject: string;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRef: RefObject<IRichTextEditorRef>;
  wrapperRef: RefObject<HTMLDivElement>;
  // formRef: RefObject<HTMLFormElement>;
  control: IComposeMessageForm;
  resendNotificationsControl?: IFormControl<boolean>;
  isFirstDraft: boolean;
}) {
  const {
    control,
    resendNotificationsControl,
    threadId,
    listRef,
    editorRef,
    wrapperRef,
    isFirstDraft,
    threadSubject,
    threadVisibility,
  } = props;

  const environment = useClientEnvironment();
  const { currentUserId } = useAuthGuardContext();

  const convertDraftToBranchedReply = useMemo(() => {
    return onlyCallFnOnceWhilePreviousCallIsPending(async () => {
      if (!listRef.current) return;

      const branchedFromMessage = getLastMessageEntry(listRef.current.entries);

      if (!branchedFromMessage) {
        alert(`Couldn't find a message to branch from. This is a bug, please reach out to team@comms.day.`);

        return;
      }

      const {
        messageId,
        body: { content },
      } = control.rawValue;

      const newDraftId = generateRecordId("draft");
      const threadId = generateRecordId("thread");

      const mergeDraftProps: MergeDraftProps = {
        type: "COMMS",
        draftId: newDraftId,
        threadId,
        currentUserId,
        ownerOrganizationId: getAndAssertCurrentUserOwnerOrganizationId(),
        is_reply: false,
        is_edit: false,
        visibility: threadVisibility,
        subject: `Branch: ${threadSubject}`,
        bodyHTML: content,
        branchedFrom: {
          threadId: branchedFromMessage.record.thread_id,
          messageId: branchedFromMessage.id,
          messageTimelineOrder: branchedFromMessage.record.timeline_order,
        },
      };

      deleteDraft(environment, {
        draftId: messageId,
        currentUserId,
      });

      const createNewDraftPromise = mergeDraft(environment, mergeDraftProps);

      await waitForCacheToContainRecord(
        environment,
        { table: "draft", id: mergeDraftProps.draftId },
        { relatedPromise: createNewDraftPromise },
      );

      // When the compose new thread dialog is closed we want to refocus the
      // correct message.
      updateSearchParams((searchParams) => searchParams.set("message", branchedFromMessage.id), { replace: true });

      openComposeNewThreadDialog(mergeDraftProps.draftId);
    });
  }, [threadVisibility, threadSubject, currentUserId, listRef, environment, control]);

  const { openFilePicker } = useFileInputElement({
    multiple: true,
    onFilesChange(files) {
      addAndUploadAttachment(environment, {
        control,
        files,
      });
    },
  });

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [];

      if (isFirstDraft) {
        commands.push(
          replyToThreadCommand({
            label: `Focus reply`,
            keywords: ["Reply"],
            callback: () => {
              const selectedHTML = getSelectionAsHTMLString();

              const editor = editorRef.current?.editor;

              if (!selectedHTML || !editor) {
                editorRef.current?.focus("end", { scrollIntoView: true });
                return;
              }

              const prefix = editor.isEmpty ? "" : "<p></p>";
              const replyText = `${prefix}<blockquote>${selectedHTML}</blockquote><p></p><p></p>`;

              editor.chain().focus("end").insertContent(replyText).focus("end", { scrollIntoView: true }).run();
            },
          }),
        );
      }

      return commands;
    },
    deps: [editorRef, isFirstDraft],
  });

  useRegisterCommands({
    commands() {
      if (!wrapperRef.current) return [];

      // When the kbar is opened, focus will shift await from the draft. We want
      // to know, right before the kbar was opened, was the draft focused? That
      // will determine what draft commands we show in the kbar.
      const isKBarOpen$ = merge(
        KBarState.beforeOpen$.pipe(map(() => true)),
        KBarState.afterClose$.pipe(map(() => false)),
      ).pipe(startWith(() => KBarState.isOpen()));

      const isDraftFocused$ = combineLatest([observeFocusWithin(wrapperRef.current), isKBarOpen$]).pipe(
        filter(([_, isKBarOpen]) => !isKBarOpen),
        map(([isFocused]) => isFocused),
      );

      return isDraftFocused$.pipe(
        map((isFocused) => {
          if (!isFocused) return [];

          const commands: ICommandArgs[] = [];

          commands.push(
            deleteDraftCommand({
              triggerHotkeysWhenInputFocused: true,
              callback: () => {
                const value = control.rawValue;

                cancelSaveReplyDraft(value.messageId);
                const draftLocation = getCurrentRouterLocation();

                deleteDraft(environment, {
                  draftId: value.messageId,
                  currentUserId,
                  afterUndo: () => {
                    focusReply(value.messageId, draftLocation);
                  },
                });

                getSessionStorage().removeItem(getDraftDataStorageKey(value.messageId));
              },
            }),
            addAttachmentCommand({
              callback: (e) => {
                e?.preventDefault();
                openFilePicker();
              },
            }),
          );

          if (resendNotificationsControl) {
            commands.push(
              sendMessageCommand({
                label: "Save edits",
                keywords: ["Send reply", "Send reply immediately", "Submit reply"],
                hotkeys: ["$mod+Enter"],
                callback: async (e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleSubmit({
                    environment,
                    control,
                    submit: (environment, values) =>
                      submit({
                        environment,
                        values,
                        listRef: listRef,
                        shouldResendNotifications: resendNotificationsControl.value,
                      }),
                  });
                },
              }),
              {
                label: "Discard edits",
                hotkeys: ["Escape"],
                triggerHotkeysWhenInputFocused: true,
                showInKBar: false,
                callback: () => {
                  const isSure = confirm(`Are you sure you want to discard edits?`);

                  if (!isSure) return;

                  deleteDraftCommand.trigger();
                },
              },
            );
          } else {
            commands.push(
              {
                label: "Blur/cancel reply",
                hotkeys: ["Escape"],
                triggerHotkeysWhenInputFocused: true,
                showInKBar: false,
                callback: () => {
                  const value = control.rawValue;

                  // While we could simply use a `div` and `innerText` here,
                  // the draft service already depends on `htmlToText()` and
                  // reusing it provides more consistent HTML to text conversion
                  const textContent = htmlToText(value.body.content);

                  const isDraftEmpty = textContent.trim().length === 0;

                  focusLastListEntry(listRef);

                  if (isDraftEmpty) {
                    cancelSaveReplyDraft(value.messageId);
                    deleteDraft(environment, {
                      draftId: value.messageId,
                      currentUserId,
                    });

                    getSessionStorage().removeItem(getDraftDataStorageKey(value.messageId));
                  }
                },
              },
              sendMessageCommand({
                label: "Send reply",
                keywords: ["Submit reply"],
                hotkeys: ["$mod+Enter"],
                callback: async (e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleSubmit({
                    environment,
                    control,
                    submit: (environment, values) =>
                      submit({
                        environment,
                        values,
                        listRef: listRef,
                      }),
                  });
                },
              }),
              {
                label: "Send reply immediately",
                keywords: ["Send immediately", "Submit reply immediately"],
                callback: async (e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleSubmit({
                    environment,
                    control,
                    submit: (environment, values) =>
                      submit({
                        environment,
                        values,
                        listRef: listRef,
                        sendImmediately: true,
                      }),
                  });
                },
              },
            );
          }

          return commands;
        }),
      );
    },
    deps: [control, threadId, openFilePicker, listRef, wrapperRef, currentUserId, environment],
  });

  return {
    convertDraftToBranchedReply,
  };
}

/* -----------------------------------------------------------------------------------------------*/

async function submit(props: {
  environment: ClientEnvironment;
  values: IComposeMessageFormValue;
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  sendImmediately?: boolean;
  shouldResendNotifications?: boolean;
  noToast?: boolean;
}) {
  const { values, listRef, sendImmediately: sendImmediatelyOption, noToast, environment } = props;

  console.log("submitting...", values);

  cancelSaveReplyDraft(values.messageId);

  const { settings } = await getCurrentUserSettings(environment);

  const sendImmediately = sendImmediatelyOption || !settings?.seconds_for_undoing_sent_message;

  const undoDurationSeconds = sendImmediately ? 0 : settings.seconds_for_undoing_sent_message! + 10;

  const draftLocation = getCurrentRouterLocation();

  // We want the new post form to close immediately without
  // waiting for this promise to resolve.
  // See `createNewDraftReply` jsdoc.
  const promise = mergeDraft(environment, {
    currentUserId: getAndAssertCurrentUserId(),
    ownerOrganizationId: getAndAssertCurrentUserOwnerOrganizationId(),
    is_edit: values.isEdit,
    is_reply: values.isReply,
    type: values.type,
    draftId: values.messageId,
    threadId: values.threadId,
    bodyHTML: values.body.content,
    to: values.recipients.to.map(mapRecipientOptionToDraftRecipient),
    cc: values.recipients.cc.map(mapRecipientOptionToDraftRecipient),
    bcc: values.recipients.bcc.map(mapRecipientOptionToDraftRecipient),
    userMentions: values.body.userMentions,
    groupMentions: values.body.groupMentions,
    attachments: values.attachments,
    scheduledToBeSentAt: sendImmediately ? new Date() : dayjs().add(undoDurationSeconds, "seconds").toDate(),
    afterUndo: () => {
      focusReply(values.messageId, draftLocation);
    },
    shouldResendNotifications: props.shouldResendNotifications,
    noToast,
  })
    .then(() => console.log("submitted successfully!"))
    .catch(console.error);

  const success = await waitForCacheToContainRecord(
    environment,
    { table: "message", id: values.messageId },
    { relatedPromise: promise },
  );

  if (success) {
    if (props.shouldResendNotifications !== undefined) {
      listRef.current?.focus(values.messageId);
    } else {
      focusLastListEntry(listRef);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

/**
 * This hook observes the users participating in the current thread and
 * weights them more heavily when determining the autocomplete `@mention`
 * suggestion order.
 */
function useEditorMentionWeightAdjustments(props: {
  threadId: string;
  editorRef: RefObject<IRichTextEditorRef | null>;
}) {
  const { threadId, editorRef } = props;
  const { recordLoader } = useClientEnvironment();

  useEffect(() => {
    if (!editorRef.current) {
      console.log("no editor ref");
      return;
    }

    const editorObservable = from(
      new Promise<Editor>((res, rej) => {
        if (!editorRef.current) {
          rej("no editor ref");
        } else {
          editorRef.current.onCreate.push(res);
        }
      }),
    );

    const sub = combineLatest([
      editorObservable,
      recordLoader.observeGetThreadUserParticipants({
        thread_id: threadId,
      }),
    ]).subscribe(([editor, [participants]]) => {
      if (!editor.storage.mention) {
        editor.storage.mention = {};
      }

      editor.storage.mention.mentionWeightAdjustments = {};

      for (const participant of participants) {
        const key = getMentionFrequencyKey({
          table: "user_profile",
          id: participant.user_id,
        });

        editor.storage.mention.mentionWeightAdjustments[key] = 0.05;
      }
    });

    return () => sub.unsubscribe();
  }, [threadId, editorRef, recordLoader]);
}

/* -----------------------------------------------------------------------------------------------*/
