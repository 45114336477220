import { Transaction } from "libs/transaction";
import { Simplify } from "type-fest";

export interface UndoRedoTransaction {
  undo: (transaction: Transaction) => Promise<void>;
  redo: (transaction: Transaction) => Promise<void>;
}

export type UndoRedoStackApi = Simplify<UndoRedoStack>;

export class UndoRedoStack {
  running = false;
  undoStack: UndoRedoTransaction[] = [];
  redoStack: UndoRedoTransaction[] = [];
}
