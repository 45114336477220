import { useObservable, useObservableState } from "observable-hooks";
import { useEffect, useRef } from "react";
import { usePrevious } from "react-use";
import { Observable } from "rxjs";

/**
 * The "observable-hooks" library updates observable deps inside a useEffect hook
 * which has the undesirable side effect that the observable always updates a frame
 * after the deps update. For this reason, observables that return an object containg
 * an `isLoading: boolean` value update their isLoading value a frame late, which can
 * cause challenges. This hook wraps the "observable-hooks" functions and returns
 * an observable which has the isLoading value update syncronously when the dependencies
 * update. You must provide a `depsKey` string value that is a stable key which only
 * updates when the deps update.
 */
export function useLoadingObservable<
  Result extends { isLoading: boolean } | [any, { isLoading: boolean }],
  Deps extends readonly any[],
>(props: {
  fn: (inputs$: Observable<[...Deps]>) => Observable<Result>;
  depsKey: string;
  deps: [...Deps];
  initialValue: Result;
}): Result {
  const isFirstRender = useIsFirstRender();

  const prevDepsKey = usePrevious(props.depsKey);

  const observable = useObservable(props.fn, props.deps);

  const result = useObservableState(observable, props.initialValue);

  // Immediately after the deps change, we show the default initial value. This will
  // always be true for the first render, and that's fine.
  if (prevDepsKey !== props.depsKey) {
    return props.initialValue;
  }

  if (Array.isArray(result)) {
    const [value, meta] = result;

    return [
      value,
      {
        ...meta,
        // If it's the first render, then we don't care what the prevDepsKey value is
        isLoading: isFirstRender ? meta.isLoading : meta.isLoading || prevDepsKey !== props.depsKey,
      },
    ] as Result;
  }

  return {
    ...result,
    // If it's the first render, then we don't care what the prevDepsKey value is
    isLoading: isFirstRender ? result.isLoading : result.isLoading || prevDepsKey !== props.depsKey,
  };
}

function useIsFirstRender(): boolean {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
}
