import { isEqual } from "libs/predicates";
import { TransactionConflictError } from "libs/errors";
import { RecordMap, getPointer, setMapRecord } from "libs/schema";
import { applyTransaction, getOperationPointers, op, Transaction } from "libs/transaction";
import { uniqWith } from "lodash-comms";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { toast } from "~/environment/toast-service";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { pick } from "lodash-comms";

/**
 * Runs the provided async transaction function and handles updating
 * the undoRedo stack.
 */
export async function runTransaction(props: {
  environment: Pick<ClientEnvironment, "undoRedo" | "db" | "transactionQueue" | "writeRecordMap" | "logger">;
  tx: (transaction: Transaction) => Promise<void>;
  /** Only used for logging purposes */
  label?: string;
  undo?: (transaction: Transaction) => Promise<void>;
  debounce?: {
    type: string;
    ms: number;
  };
}): Promise<void> {
  const { environment, tx, undo, label } = props;
  const { undoRedo } = environment;

  if (undo && !undoRedo.running) {
    undoRedo.redoStack = [];

    const undoFn = label
      ? (tx: Transaction) => {
          tx.label = `undo ${label}`;
          return undo(tx);
        }
      : undo;

    undoRedo.undoStack.push({ undo: undoFn, redo: tx });
  }

  await innerRunTransaction({ environment, fn: tx, label });
}

export async function undo(
  environment: Pick<ClientEnvironment, "undoRedo" | "db" | "transactionQueue" | "writeRecordMap" | "logger">,
) {
  const { undoRedo } = environment;

  if (undoRedo.running) {
    toast("vanilla", {
      subject: "Still processing previous undo/redo...",
    });

    return;
  }

  const undoRedoTransaction = undoRedo.undoStack.pop();

  if (!undoRedoTransaction) {
    toast("vanilla", {
      subject: "Nothing to undo",
    });

    return;
  }

  const { undo } = undoRedoTransaction;

  try {
    undoRedo.running = true;
    await innerRunTransaction({ environment, fn: undo });
  } catch (e) {
    undoRedo.undoStack.push(undoRedoTransaction);
    throw e;
  } finally {
    undoRedo.running = false;
  }

  undoRedo.redoStack.push(undoRedoTransaction);
}

export async function redo(
  environment: Pick<ClientEnvironment, "undoRedo" | "db" | "transactionQueue" | "writeRecordMap" | "logger">,
) {
  const { undoRedo } = environment;

  if (undoRedo.running) {
    toast("vanilla", {
      subject: "Still processing previous undo/redo...",
    });

    return;
  }

  const undoRedoTransaction = undoRedo.redoStack.pop();

  if (!undoRedoTransaction) {
    toast("vanilla", {
      subject: "Nothing to redo",
    });

    return;
  }

  const { redo } = undoRedoTransaction;

  try {
    undoRedo.running = true;
    await innerRunTransaction({ environment, fn: redo });
  } catch (e) {
    undoRedo.redoStack.push(undoRedoTransaction);
    throw e;
  } finally {
    undoRedo.running = false;
  }

  undoRedo.undoStack.push(undoRedoTransaction);
}

async function innerRunTransaction(props: {
  environment: Pick<ClientEnvironment, "undoRedo" | "db" | "transactionQueue" | "writeRecordMap" | "logger">;
  fn: (tx: Transaction) => Promise<void>;
  label?: string;
  attempt?: number;
}): Promise<void> {
  const { environment, fn, label, attempt = 1 } = props;

  if (attempt > 5) {
    throw new Error(`runTransaction: transaction failed 5 times`);
  }

  const currentUserId = getAndAssertCurrentUserId();
  const transaction = op.transaction({
    authorId: currentUserId,
    operations: [],
    label,
  });

  try {
    await fn(transaction);
  } catch (error) {
    environment.logger.error({ error, transaction }, `[${transaction.label}] error`);

    throw error;
  }

  try {
    await write({ environment, transaction });
  } catch (e) {
    // At time of writing, the only TranscationConflictError that runTransaction
    // might catch comes from optimisticWrites. Transcation conflicts thrown by
    // the server will automatically be retried by the TranscationQueue service.
    if (e instanceof TransactionConflictError) {
      return innerRunTransaction({
        environment,
        fn,
        label,
        attempt: attempt + 1,
      });
    }

    throw e;
  }
}

// We're intentionally not using an async function so that the
// optimisticWrite happens syncronously.
function write(props: {
  environment: Pick<ClientEnvironment, "transactionQueue" | "db" | "writeRecordMap" | "logger">;
  transaction: Transaction;
}) {
  const { environment, transaction } = props;
  const { transactionQueue, logger } = environment;
  const { onOptimisticWrite, onServerResponse } = transaction;

  // In case there are any errors with the transaction, we start by
  // applying the optimistic write to the local databases. Note that
  // the write is applied to the in-memory database synchronously, so if
  // there are any errors they should be thrown synchronously. The write
  // is then applied to the persisted database async but we don't await the
  // results as a small performance boost. This catch statement will only
  // catch errors with the write to the persisted database.
  // An error while writing to the peristed db but not in-memory db would
  // be surprising.
  optimisticWrite(environment, transaction).catch((error) => {
    logger.error({ ...transaction, error }, `[${transaction.label}] error applying optimistic write to persisted db`);
  });

  onOptimisticWrite?.();

  const promise = transactionQueue.enqueue(transaction).then(
    () => {
      logger.notice(transaction, `[${transaction.label}] successfully sent to server`);
    },
    (error) => {
      logger.error(
        { ...transaction, error },
        `[${transaction.label}] error sending to server or writing back to persisted db`,
      );
    },
  );

  if (onServerResponse) {
    return promise.then(
      () => onServerResponse({}),
      (error) => onServerResponse({ error }),
    );
  } else {
    return promise;
  }
}

// Should be a sync function. See comments below.
function optimisticWrite(
  environment: Pick<ClientEnvironment, "db" | "writeRecordMap" | "logger">,
  transaction: Transaction,
) {
  const { db, writeRecordMap } = environment;

  // Get cached records for this write.
  const pointers = uniqWith(transaction.operations.flatMap(getOperationPointers), isEqual);

  const recordMap: RecordMap = {};
  for (const pointer of pointers) {
    let [record] = db.getRecord(pointer);

    if (record) {
      setMapRecord(recordMap, pointer, record);
    }

    const deletedPointer = getPointer("deleted_row", {
      row_table: pointer.table,
      row_id: pointer.id,
    });

    [record] = db.getRecord(deletedPointer);

    if (record) {
      setMapRecord(recordMap, deletedPointer, record);
    }
  }

  const currentTimestamp = new Date().toISOString();

  // Optimistic update.
  applyTransaction({
    recordMap,
    transaction,
    currentTimestamp,
  });

  environment.logger.debug({ recordMap, transaction }, "optimisticWrite");

  // Note that writeRecordMap itself is not an async function but it returns a promise.
  // It synchronously updates the in memory cache and async updates the persisted database.
  // Similarly, we want this optimistic write to be synchronous on the in-memory database
  // so we don't want to make this an async function.
  return writeRecordMap(recordMap);
}

// function invertTransaction(
//   environment: ClientEnvironment,
//   transaction: Transaction,
// ) {
//   const { db } = environment;

//   // Get cached records for this write.
//   const pointers = uniqWith(
//     transaction.operations.flatMap(getOperationPointers),
//     isEqual,
//   );

//   const recordMap: RecordMap = {};
//   for (const pointer of pointers) {
//     const record = db.syncGetRecord(pointer);
//     if (!record) continue;
//     setMapRecord(recordMap, pointer, record);
//   }

//   const undoOperations = compact(
//     transaction.operations.map((op) =>
//       invertOperation({ recordMap, operation: op }),
//     ),
//   );

//   const canUndoAllOperations =
//     undoOperations.length === transaction.operations.length;

//   if (!canUndoAllOperations) return;

//   const undoTransaction: Transaction = {
//     txId: window.crypto.randomUUID(),
//     authorId: transaction.authorId,
//     operations: undoOperations,
//     onUndo: transaction.onUndo,
//   };

//   return undoTransaction;
// }

export function withTxLogger<T extends Pick<ClientEnvironment, "logger">>(
  environment: T,
  props?: {
    transaction?: Transaction;
    data?: Record<string, any> | Record<string, any>[];
  },
): T {
  const data = Array.isArray(props?.data) ? props?.data.reduce(Object.assign, {}) : props?.data;

  return {
    ...environment,
    logger: environment.logger.child({
      ...data,
      transaction: pick(props?.transaction, ["txId", "label"]),
    }),
  };
}
