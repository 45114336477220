import { NavigateOptions, type createBrowserRouter, matchPath } from "react-router-dom";
import { filter, fromEvent, Subject } from "rxjs";
import { isEqual } from "libs/predicates";
import { RouterState } from "@remix-run/router";
import { isNonNullObject } from "libs/predicates";
import { Location } from "react-router-dom";

export interface ILocation<T = unknown> {
  key: string;
  hash: string;
  search: string;
  pathname: string;
  state: T;
}

export function getCurrentRouterLocation(): ILocation {
  // When navigating to Comms in a new tab, both key (i.e.
  // `history.state.key`) and state in the returned object
  // will be undefined.
  return {
    key: history.state.key,
    hash: location.hash,
    search: location.search,
    pathname: location.pathname,
    state: history.state.usr,
  };
}

class NavigationHistory {
  /** our current location in the stack */
  index = 0;

  constructor(public stack: ILocation[]) {}

  get size() {
    return this.stack.length;
  }

  current(): ILocation;
  current(offset?: number): ILocation | undefined;
  current(offset = 0) {
    return this.stack[this.index + (offset as number)];
  }

  findIndex(location: ILocation) {
    return this.stack.findIndex((loc) => isEqual(loc, location));
  }
}

export const navigationHistory = new NavigationHistory([]);

export const _NAVIGATION_EVENTS = new Subject<PopStateNavigation | AfterReactRouterNavigation>();

/** Observable of browser navigation events. */
export const NAVIGATION_EVENTS = _NAVIGATION_EVENTS.asObservable();

/**
 * Emitted in response to a user pressing the "back" or "forward"
 * buttons in their browser. Call `stopImmediatePropagation()` on
 * the event to prevent ReactRouter from seeing it.
 */
export class PopStateNavigation {
  constructor(
    public type: "BACKWARD" | "FORWARD",
    public event: PopStateEvent,
    public location: ILocation,
  ) {}
}

export class AfterReactRouterNavigation {
  constructor(
    public state: RouterState,
    public location: ILocation,
  ) {}
}

fromEvent<PopStateEvent>(window, "popstate").subscribe((e) => {
  const location = getCurrentRouterLocation();

  const index = navigationHistory.findIndex(location);

  const type = index < navigationHistory.index ? "BACKWARD" : "FORWARD";

  _NAVIGATION_EVENTS.next(new PopStateNavigation(type, e, location));
});

// Here we register a history event listener before passing the history
// object to ReactRouter. This ensures that this callback is triggered
// before react router navigates.
// See https://github.com/remix-run/react-router/issues/8617#issuecomment-1021623058
NAVIGATION_EVENTS.pipe(
  filter((event): event is AfterReactRouterNavigation => event instanceof AfterReactRouterNavigation),
).subscribe((event) => {
  console.debug("navigationHistory", navigationHistory);

  if (import.meta.env.MODE === "development") {
    const { state } = event.location;

    const isValid = state === undefined || state === null || typeof state === "object";

    if (!isValid) {
      console.error(`Location state must be undefined or an object`, state);
      alert(`Location state must be undefined or an object`);
    }
  }

  if (event.state.historyAction === "POP") {
    const index = navigationHistory.stack.findIndex((loc) => isEqual(loc, event.location));

    if (index >= 0) {
      navigationHistory.index = index;
      return;
    }

    console.debug("Unable to find browser history location. Resetting history service...", event);

    // index < 0
    // This indicates the user has gone "back" to before they
    // started this session on the Comms site or "forward" to
    // after they left this session on the comms site.
    // In either case, we don't know where they are in the
    // history stack (they might have jumped forward/backward many
    // entries) so we need to reset our history.
    navigationHistory.index = 0;
    navigationHistory.stack = [{ ...event.location }];
    return;
  }

  navigationHistory.stack = navigationHistory.stack.slice(0, navigationHistory.index + 1);

  if (event.state.historyAction === "REPLACE") {
    navigationHistory.stack.pop();
    navigationHistory.stack.push({ ...event.location });
  } else if (event.state.historyAction === "PUSH") {
    navigationHistory.index += 1;
    navigationHistory.stack.push({ ...event.location });
  } else {
    if (import.meta.env.MODE !== "development") return;
    alert(`Unexpected browser history action "${event.state.historyAction}"`);
  }

  if (import.meta.env.VITE_FIREBASE_EMULATORS !== "true") {
    // Firebase analytics doesn't support the emulators yet
    // logEvent(analytics, "page_view", {
    //   page_path: event.location.pathname,
    // });
  }
});

export type Router = ReturnType<typeof createBrowserRouter>;

export let router: Router;

export function _setRouter(input: Router) {
  if (router) return;
  router = input;

  // We reset our navigationHistory stack after the router has been
  // initialized. Before the router has been intialized,
  // getCurrentRouterLocation()'s `key` and `state` props will be
  // undefined.
  navigationHistory.stack = [{ ...getCurrentRouterLocation() }];

  router.subscribe((state) => {
    _NAVIGATION_EVENTS.next(new AfterReactRouterNavigation(state, state.location));
  });
}

export type INavigateServiceOptions<
  T extends { [key: string]: unknown } | null | undefined = { [key: string]: unknown } | null | undefined,
> = Omit<NavigateOptions, "state"> & {
  state?: T;
};

/**
 * Allows navigation outside of React's context. The "to" argument must be a
 * full route path. Relative routing is not supported.
 *
 * navigateService typing attempts to enforce a convention in Comms for the
 * location#state property: the state property should always be equal to
 * an object if it is not undefined and that object should be a dictionary
 * of nested states. If a component wants to save data to location#state,
 * it should namespace that data in location#state using the name of the
 * component. For example, the SearchView component would save data in
 * location#state as { ...otherStateData, SearchView: whatever }.
 */
export function navigateService(
  to: string | Partial<ILocation> | number,
  options?: INavigateServiceOptions<{ [key: string]: unknown } | null>,
) {
  if (typeof to === "string" && !to.startsWith("/")) {
    const msg = "The navigate service doesn't support relative routing. Routes must begin with '/'";

    alert(msg);
    console.error(msg);
  } else if (typeof to === "number") {
    return router.navigate(to);
  }

  return router.navigate(to, options);
}

/**
 * Navigate's "back" if "back" is still known to be inside
 * of Comms, else navigates to the inbox.
 */
export function navigateBackOrToInbox() {
  if (navigationHistory.index === 0) {
    return navigateService("/inbox");
  }

  return router.navigate(-1);
}

/**
 * Returns the pathname for the most recently visited inbox page, or "/inbox" if
 * no inbox page has been visited.
 */
export function getMostRecentInboxPagePathname() {
  return (
    navigationHistory.stack.findLast(
      (loc) => matchPath("/inbox", loc.pathname) || matchPath("/inbox/:inboxSectionId", loc.pathname),
    )?.pathname || "/inbox"
  );
}

/**
 * Navigates backwards to the most recent route which matches
 * the provided comparer function.
 *
 * E.g. if on route `/threads/fdalkj32409jfafasfdf`, you may
 * wish to navigate to the most recent previous page which
 * did not start with `/threads/`.
 */
export function navigateBackToMostRecentRouteMatching(comparer: (loc: ILocation) => boolean) {
  const pastNavHistory = navigationHistory.stack.slice(
    0,
    // We might have already navigated "back" so it's possible
    // for index to not be the last entry.
    navigationHistory.index + 1,
  );

  const loc = pastNavHistory.findLast(comparer);

  if (!loc) {
    return navigateBackOrToInbox();
  }

  const index = pastNavHistory.indexOf(loc);

  const diff = -(pastNavHistory.length - 1 - index);

  return navigateService(diff);
}

export function getMostRecentRouteMatching(comparer: (loc: ILocation) => boolean) {
  const pastNavHistory = navigationHistory.stack.slice(
    0,
    // We might have already navigated "back" so it's possible
    // for index to not be the last entry.
    navigationHistory.index + 1,
  );

  return pastNavHistory.findLast(comparer);
}

/**
 * Comms expects the location state to always be a dictionary object.
 * If the location state isn't a non-null object this will return `{}`.
 */

export function getLocationState<T extends Record<string, any>>(location?: ILocation): T;
export function getLocationState<T>(name: string, location?: ILocation): T | undefined;
export function getLocationState<T>(a?: ILocation | string, b?: ILocation): T {
  const location = b ? b : typeof a === "object" ? a : getCurrentRouterLocation();

  if (!isNonNullObject(location.state)) return {} as T;

  const key = typeof a === "string" ? a : null;

  if (key) return location.state[key] as T;

  return location.state as T;
}

export function updateSearchParams(
  updateFn: (searchParams: URLSearchParams) => void,
  options: {
    replace?: boolean;
    /**
     * If replace is `true` and state is `undefined` then the current
     * location state will remain unchanged.
     */
    state?: unknown;
  } = {},
) {
  const url = new URL(location.href);

  updateFn(url.searchParams);

  if (options.replace) {
    const state = options.state === undefined ? history.state : options.state === null ? undefined : options.state;

    // It's necessary to just replace the "search" value. Attempting
    // to pass the full URL clears the URL's "search" prop.
    router.navigate(
      { ...location, search: url.search },
      {
        replace: true,
        state,
      },
    );
  } else {
    router.navigate({ ...location, search: url.search }, { state: options.state });
  }
}

/**
 * If the user was attempting to navigate to a protected page in
 * our app, we might redirect them to another page but save the
 * location they were attempting to navigate to in
 * `location.state.from`.
 */
export function attemptToGetRedirectLocation({ state }: Location): ILocation | undefined {
  if (typeof state !== "object" || !state) return;

  const { from } = state as { from?: Location };

  return from;
}

export function openLinkInNewTabOrWindow(url: string | URL) {
  // If we don't use the "noopener" feature, then the new page will
  // share a rendering process with the current page in Google Chrome.
  // This can quickly lead to jank and high memory usage.
  window.open(new URL(url, location.origin), "_blank", "noopener");
}

/**
 * Updates the current navigation history state by merging in the provided
 * state object. The state object should be a dictionary of nested states,
 * where the keys are namespaces for the state data. Note that this function
 * will replace the current location state with the updated location state.
 */
export function updateNavigationHistoryState(state: { [namespace: string]: unknown }) {
  const location = getCurrentRouterLocation();

  return navigateService(location, {
    replace: true,
    state: {
      ...getLocationState(),
      ...state,
    },
  });
}
