import { pick } from "lodash-comms";
import {
  JoinTable,
  NonJoinTable,
  RecordTable,
  RecordValue,
  SingletonTagRecord,
  TableHasIdEqualToOtherTableId,
  TablePKey,
  tablePKeys,
  tablesWithIdEqualToOtherTableId,
} from "./schema";
import { deriveUUID, uuid } from "libs/uuid";
import { throwUnreachableCaseError } from "../errors";

// generateRecordId can also be used to generate IDs for certain types of
// documents embedded within jsonb columns. This type contains mappings for
// the props required to generate the IDs of these additional document types.
type AdditionalGeneratorIds = {
  // We don't simply use the group ID as the ID for draft_group_recipient_docs
  // because there's no requirement that group's and users don't have overlapping
  // IDs and we want each recipient to have a unique ID within a given draft.
  draft_group_recipient_doc: {
    type: "GROUP";
    group_id: string;
  };
  draft_user_recipient_doc: {
    type: "USER";
    user_id: string;
  };
  // we don't simply use the user ID as the ID for message_user_recipient_doc
  // because there's no requirement that group's and users don't have overlapping
  // IDs and we want each recipient to have a unique ID within a given draft.
  message_user_recipient_doc: {
    type: "USER";
    user_id: string;
  };
  message_group_recipient_doc: {
    type: "GROUP";
    group_id: string;
  };
  singleton_tag: {
    name: Lowercase<SingletonTagRecord["name"]>;
  };
};

/** Generates a new ID for a record */
export function generateRecordId<Table extends JoinTable>(
  table: Table,
  record: Pick<RecordValue<Table>, TablePKey<Table>>,
): string;
export function generateRecordId<Table extends Exclude<NonJoinTable, TableHasIdEqualToOtherTableId>>(
  table: Table,
): string;
export function generateRecordId<Table extends keyof AdditionalGeneratorIds>(
  table: Table,
  record: AdditionalGeneratorIds[Table],
): string;
export function generateRecordId<Table extends RecordTable>(
  table: Table,
  record?: Table extends JoinTable ? Pick<RecordValue<Table>, TablePKey<Table>> : never,
): string {
  if (invalidTablesForGenerateRecordId.includes(table)) {
    throw new Error(`generateRecordId: invalid table "${table}"`);
  }

  if (record) {
    const doesTableHaveCaseInsensitiveColumns = table in caseInsensitiveTableColumns;

    type AdditionalTable = keyof typeof additionalGeneratorPKeys;

    const pickedPKeys =
      table in tablePKeys
        ? pick(record, tablePKeys[table])
        : table in additionalGeneratorPKeys
          ? pick(record, additionalGeneratorPKeys[table as AdditionalTable])
          : throwUnreachableCaseError(table as never);

    if (!doesTableHaveCaseInsensitiveColumns) {
      return deriveUUID(...Object.values(pickedPKeys).map(String));
    }

    type Table = keyof typeof caseInsensitiveTableColumns;

    const caseInsensitiveColumns = caseInsensitiveTableColumns[table as Table];

    const values = Object.entries(pickedPKeys).map(([key, value]) => {
      const stringValue = String(value);

      type Column = keyof typeof caseInsensitiveColumns;

      return caseInsensitiveColumns[key as Column] ? stringValue.toLowerCase() : stringValue;
    });

    return deriveUUID(...values);
  }

  return uuid();
}

const invalidTablesForGenerateRecordId = Object.keys(tablesWithIdEqualToOtherTableId);

const caseInsensitiveTableColumns = {
  organization_user_invitation: {
    email_address: true,
  },
  organization_controlled_domain: {
    domain: true,
  },
} satisfies {
  [Table in JoinTable]?: {
    [Column in keyof Pick<RecordValue<Table>, TablePKey<Table>>]?: boolean;
  };
};

const additionalGeneratorPKeys = {
  draft_group_recipient_doc: ["type", "group_id"],
  draft_user_recipient_doc: ["type", "user_id"],
  message_user_recipient_doc: ["type", "user_id"],
  message_group_recipient_doc: ["type", "group_id"],
  singleton_tag: ["name"],
} as const;
