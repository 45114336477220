import { op, Transaction, CreateRecord } from "libs/transaction";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import {
  generateRecordId,
  getPointer,
  InboxSectionTagRecord,
  InboxSubsectionTagRecord,
  RecordPointer,
} from "libs/schema";
import { findMatchingSubsection } from "libs/searchQueryMatcher";
import { compact, memoize } from "lodash-comms";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import { withPendingUpdate } from "~/environment/loading.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { RecordLoaderApi } from "libs/database";
import { GetOptions } from "~/environment/RecordLoader";
import * as ops from "libs/actions";

/* -------------------------------------------------------------------------------------------------
 *  triageThread
 * -------------------------------------------------------------------------------------------------
 */

export type TriageThreadProps = {
  threadId: string | string[];
  done?: boolean;
  triagedUntil?: Date | null;
  isStarred?: boolean;
  noToast?: boolean;
  noUndo?: boolean;
};

/**
 * Triages a thread by updating the associated inbox notification.
 * If no inbox notification currently exists for this thread, one will
 * be created using the last post in the thread. When marking the
 * thread as "done", we will also record that the user has read up
 * to the current last post in the thread.
 */
export const triageThread = memoize(
  withPendingUpdate(async (environment: ClientEnvironment, props: TriageThreadProps, options?: GetOptions) => {
    try {
      const currentUserId = getAndAssertCurrentUserId();
      const { recordLoader } = environment;

      const threadIds = Array.isArray(props.threadId) ? props.threadId : [props.threadId];

      if (!threadIds.length) return;

      const [notificationsBeforeUpdate] = await recordLoader.getRecords(
        threadIds.map((threadId) =>
          getPointer("notification", {
            thread_id: threadId,
            user_id: currentUserId,
          }),
        ),
        options,
      );

      return runTransaction({
        environment: withTxLogger(environment, { data: props }),
        label: "triageThread",
        tx: async (transaction) => {
          for (const threadId of threadIds) {
            await applyTriageThreadToTx(
              environment,
              {
                params: { ...props, threadId },
                transaction,
              },
              options,
            );
          }

          if (!props.noToast) {
            transaction.onServerResponse = ({ error }) => {
              triageThreadToast({ params: props, error });
            };
          }
        },
        undo: props.noUndo
          ? undefined
          : async (transaction) => {
              for (const threadId of threadIds) {
                const notificationBeforeUpdate = notificationsBeforeUpdate.find(
                  (n) => n.record.thread_id === threadId,
                )?.record;

                const done = props.done === undefined ? undefined : !!notificationBeforeUpdate?.is_done;

                const isStarred = props.isStarred === undefined ? undefined : !!notificationBeforeUpdate?.is_starred;

                const triagedUntil =
                  props.triagedUntil === undefined
                    ? undefined
                    : (notificationBeforeUpdate?.remind_at &&
                        new Date(Date.parse(notificationBeforeUpdate.remind_at))) ||
                      null;

                const params = {
                  threadId,
                  done,
                  isStarred,
                  triagedUntil,
                };

                await applyTriageThreadToTx(
                  environment,
                  {
                    params,
                    transaction,
                  },
                  options,
                );
              }

              if (!props.noToast) {
                transaction.onServerResponse = ({ error }) => {
                  triageThreadToast({ params: props, error });
                };
              }
            },
      });
    } finally {
      triageThread.cache.delete(JSON.stringify(props));
    }
  }),
  (_, props) => JSON.stringify(props),
);

/* -----------------------------------------------------------------------------------------------*/

function triageThreadToast(props: { params: TriageThreadProps; error?: unknown }) {
  const { params, error } = props;

  const count = Array.isArray(params.threadId) ? params.threadId.length : 1;

  if (typeof params.triagedUntil !== "undefined") {
    if (error) {
      toast("vanilla", {
        subject: params.triagedUntil
          ? `Error setting ${count} reminder${count === 1 ? "" : "s"}.`
          : `Error removing ${count} reminder${count === 1 ? "" : "s"}.`,
        durationMs: 5000,
      });
    } else {
      toast("vanilla", {
        subject: params.triagedUntil
          ? `${count} reminder${count === 1 ? "" : "s"} set.`
          : `${count} reminder${count === 1 ? "" : "s"} removed.`,
        durationMs: 5000,
      });
    }
  } else if (typeof params.isStarred !== "undefined") {
    if (error) {
      toast("vanilla", {
        subject: params.isStarred
          ? `Error starring ${count} thread${count === 1 ? "" : "s"}.`
          : `Error unstarring ${count} thread${count === 1 ? "" : "s"}.`,
        durationMs: 5000,
      });
    } else {
      toast("vanilla", {
        subject: params.isStarred
          ? `Starred ${count} thread${count === 1 ? "" : "s"}.`
          : `Unstarred ${count} thread${count === 1 ? "" : "s"}.`,
        durationMs: 5000,
      });
    }
  } else {
    if (error) {
      toast("vanilla", {
        subject: params.done
          ? `Error marking ${count} thread${count === 1 ? "" : "s"} done.`
          : `Error marking ${count} thread${count === 1 ? "" : "s"} not done.`,
        durationMs: 5000,
      });
    } else {
      toast("vanilla", {
        subject: params.done
          ? `${count} thread${count === 1 ? "" : "s"} marked done.`
          : `${count} thread${count === 1 ? "" : "s"} marked not done.`,
        durationMs: 5000,
      });
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 *  applyTriageThreadToTx
 * -------------------------------------------------------------------------------------------------
 */

async function applyTriageThreadToTx(
  environment: Pick<ClientEnvironment, "recordLoader" | "logger" | "undoRedo" | "db" | "transactionQueue">,
  props: {
    params: CreateNotificationProps;
    transaction: Transaction;
  },
  options?: GetOptions,
) {
  const { params, transaction } = props;

  if (params.done === undefined && params.triagedUntil === undefined && params.isStarred === undefined) {
    console.warn("Provided an empty update to updateThreadNotification()");
    return;
  }

  const currentUserId = getAndAssertCurrentUserId();
  const { recordLoader } = environment;

  const [[notification], [thread], [[lastMessage]]] = await Promise.all([
    recordLoader.getRecord(
      getPointer("notification", {
        thread_id: params.threadId,
        user_id: currentUserId,
      }),
      options,
    ),
    recordLoader.getRecord("thread", params.threadId, options),
    recordLoader.getLastMessageInThread(
      { threadId: params.threadId },
      { fetchStrategy: "cache" }, // See usage note below for reason for this.
    ),
  ]);

  if (!notification) {
    await applyCreateNotificationToTx(environment, { params, transaction }, options);
  } else {
    ops.applyOperationsToTransaction(
      transaction,
      ops.notification.triageNotification({
        notification,
        thread,
        lastMessage: lastMessage ?? null,
        isDone: params.done,
        remindAt: params.triagedUntil,
        isStarred: params.isStarred,
        currentTimestamp: new Date().toISOString(),
      }),
    );
  }
}

/* -------------------------------------------------------------------------------------------------
 *  applyCreateNotificationToTx
 * -------------------------------------------------------------------------------------------------
 */

interface CreateNotificationProps {
  threadId: string;
  done?: boolean;
  triagedUntil?: Date | null;
  isStarred?: boolean;
}

async function applyCreateNotificationToTx(
  environment: Pick<ClientEnvironment, "recordLoader" | "logger" | "undoRedo" | "db" | "transactionQueue">,
  props: {
    params: CreateNotificationProps;
    transaction: Transaction;
  },
  options?: GetOptions,
) {
  const currentUserId = getAndAssertCurrentUserId();
  const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();
  const { params, transaction } = props;
  const { recordLoader } = environment;

  const [[thread], [_inboxSections], [_inboxSubsections]] = await Promise.all([
    recordLoader.getRecord("thread", params.threadId, options),
    recordLoader.getInboxSections({ currentUserId }, options),
    recordLoader.getInboxSubsections({ currentUserId }, options),
  ]);

  const inboxSections = _inboxSections as InboxSectionTagRecord[];
  const inboxSubsections = _inboxSubsections as InboxSubsectionTagRecord[];

  if (!thread) {
    throw new Error(`[applyCreateNotificationToTx] Thread not found: ${params.threadId}`);
  }

  if (thread?.type === "EMAIL_BCC") {
    console.error(
      "Attempted to triage bcc thread. Instead triage the canonical thread " +
        "that this bcc thread is associated with.",
      thread,
    );

    throw new Error(`Attempted to triage bcc thread`);
  }

  const isDone = params.done ?? true;
  const now = new Date().toISOString();

  const notification: CreateRecord<"notification"> = {
    id: generateRecordId("notification", {
      thread_id: thread.id,
      user_id: currentUserId,
    }),
    user_id: currentUserId,
    thread_id: thread.id,
    thread_type: thread.type,
    message_id: thread.last_message_id,
    tag_ids: [],
    done_at: isDone ? now : null,
    is_done: isDone,
    done_last_modified_by: "user",
    oldest_message_not_marked_done_message_id: isDone ? null : thread.last_message_id,
    oldest_message_not_marked_done_sent_at: isDone ? null : thread.last_message_sent_at,
    has_reminder: !!params.triagedUntil,
    is_starred: !!params.isStarred,
    owner_organization_id: ownerOrganizationId,
    priority: 200,
    remind_at: params.triagedUntil?.toISOString() || null,
    sent_at: thread.last_message_sent_at,
    starred_at: params.isStarred ? now : null,
    is_delivered: true,
    delivered_at: now,
  };

  // Since this notification hasn't been persisted yet, we need to intercept getRecord attempts
  // to fetch it and return the notification that we're about to create.
  const loader = new Proxy(recordLoader as unknown as RecordLoaderApi, {
    get(target, p, receiver) {
      if (p === "getRecord") {
        return async (a: RecordPointer | string, b?: string) => {
          const pointer = (typeof a === "string" ? { table: a, id: b! } : a) as RecordPointer;

          if (pointer.table === "notification" && pointer.id === notification.id) {
            return [notification];
          }

          return recordLoader.getRecord(pointer, options);
        };
      } else if (typeof p === "string" && p.startsWith("get")) {
        return async (...args: any[]) => {
          return (recordLoader as any)[p](...args, options);
        };
      }

      return Reflect.get(target, p, receiver);
    },
  });

  const inboxSectionMatches = compact(
    await Promise.all(
      inboxSections.map((inboxSection) => {
        return findMatchingSubsection({
          currentUserId: notification.user_id,
          messageId: notification.message_id,
          threadId: notification.thread_id,
          loader,
          inboxSection,
          inboxSubsections: inboxSubsections.filter(
            (subsection) => subsection.data.inbox_section_id === inboxSection.id,
          ),
          logger: environment.logger,
        });
      }),
    ),
  );

  if (inboxSectionMatches.length === 0) {
    alert(`
      Please let team@comms.day know that something went wrong when attempting to create
      a notification.
    `);

    environment.logger.error(
      { notification, thread, inboxSections, inboxSubsections },
      `[applyCreateNotificationToTx] No inbox section matches found for notification ${notification.id}`,
    );

    return;
  }

  for (const { section, subsection } of inboxSectionMatches) {
    notification.tag_ids.push(section.id, subsection.id);
  }

  transaction.operations.push(op.set("notification", notification));

  if (
    notification.is_done &&
    // setting a reminder shouldn't mark a thread as "read"
    !notification.has_reminder
  ) {
    transaction.operations.push(
      op.set("thread_read_receipt", {
        id: generateRecordId("thread_read_receipt", {
          thread_id: notification.id,
          user_id: currentUserId,
        }),
        owner_organization_id: ownerOrganizationId,
        thread_id: notification.id,
        user_id: currentUserId,
        read_to_timeline_id: thread.last_message_id,
        read_to_timeline_order: thread.last_message_timeline_order,
      }),

      op.set("thread_seen_receipt", {
        id: generateRecordId("thread_seen_receipt", {
          thread_id: notification.id,
          user_id: currentUserId,
        }),
        owner_organization_id: ownerOrganizationId,
        thread_id: notification.id,
        user_id: currentUserId,
        seen_to_timeline_id: thread.last_message_id,
        seen_to_timeline_order: thread.last_message_timeline_order,
      }),
    );
  }
}

/* -----------------------------------------------------------------------------------------------*/
