import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { FetchStrategy } from "~/environment/RecordLoader";

export type GetTagFolderAncestorIdPathsResult = {
  /**
   * Returns an array of folderId arrays. Each folderId array represents a
   * possible path from the root folder to the current folder.
   */
  folderPaths: string[][];
};

export function getTagFolderAncestorIdPaths(props: {
  environment: Pick<ClientEnvironment, "recordLoader">;
  tagId: string;
  maxDepth?: number;
  /** "cache-then-network" is the default */
  fetchStrategy?: FetchStrategy;
}) {
  const { tagId, maxDepth = Infinity, fetchStrategy, environment } = props;

  return getTagFolderAncestorsInner({
    environment,
    tagId,
    currentDepth: 0,
    maxDepth,
    fetchStrategy,
  });
}

async function getTagFolderAncestorsInner(props: {
  environment: Pick<ClientEnvironment, "recordLoader">;
  tagId: string;
  currentDepth: number;
  maxDepth: number;
  fetchStrategy?: FetchStrategy;
}): Promise<GetTagFolderAncestorIdPathsResult> {
  const { tagId, currentDepth, maxDepth, fetchStrategy, environment } = props;
  const { recordLoader } = environment;

  const [parentFolders] = await recordLoader.getTagFolderMembers({ tag_id: tagId }, { fetchStrategy });

  const nextDepth = currentDepth + 1;
  const isComplete = parentFolders.length === 0 || nextDepth === maxDepth;

  if (isComplete) {
    return {
      folderPaths: parentFolders.map(({ folder_id }) => [folder_id]),
    };
  }

  const ancestors = await Promise.all(
    parentFolders.map(({ folder_id }) =>
      getTagFolderAncestorsInner({
        environment,
        tagId: folder_id,
        currentDepth: nextDepth,
        maxDepth,
      }),
    ),
  );

  return {
    folderPaths: parentFolders.flatMap(({ folder_id }, index) => {
      const ancestor = ancestors[index]!;

      if (ancestor.folderPaths.length === 0) {
        return [[folder_id]];
      }

      return ancestor.folderPaths.map((folderIds) => {
        return [folder_id, ...folderIds];
      });
    }),
  };
}
