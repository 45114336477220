import type { ReactNode } from "react";
import { distinctUntilChanged, fromEvent, share, Subject } from "rxjs";
import { numberComparer } from "libs/comparers";
import { groupBy } from "lodash-comms";
import { updatableBehaviorSubject } from "libs/updatableBehaviorSubject";
import { UnreachableCaseError } from "libs/errors";
import { BsOption } from "react-icons/bs";
import { FiCommand } from "react-icons/fi";
import { ImCtrl } from "react-icons/im";
import { createKeybindingsHandler } from "libs/tinykeys";
import { isEqual } from "libs/predicates";
import { Logger } from "libs/logger";

export interface ICommandArgs {
  id?: string;

  /** The default label/name of the command in the UI */
  label: string | ReactNode;

  /**
   * Keywords associated with the default label that will
   * be used to create a search index for this command.
   * Keywords are not visible in the UI. They only affect
   * filtering/searching for commands.
   */
  keywords?: string[];

  /**
   * An array of labels for this command. If the user is
   * viewing the command bar and hasn't typed anything into
   * the search bar, then only the default "label" prop
   * will be rendered and these alternate labels will be ignored.
   *
   * If the user has typed something into the command bar, then
   * these alternate labels will be combined with the default label
   * and only the best match will be rendered in the command list.
   */
  altLabels?: Array<
    | string
    | {
        /** The way this label should be rendered in the UI */
        render: string | ReactNode;

        /**
         * Keywords that will be used to create a search index
         * for this command. The "render" value will be added to
         * this list if it is a string.
         */
        keywords: string[];
      }
  >;

  /** Currently unused */
  icon?: ReactNode | null;
  /**
   * Optionally provide an array of strings to namespace
   * this command under. The hotkey(s) associated with
   * this command will only be active when this path is
   * active in the kbar.
   *
   * Provide the special value `["global"]` to make this
   * command available at all paths.
   */
  path?: string[];
  /**
   * An array of hotkey shortcuts that the user can use
   * to activate this command. If multiple hotkeys are
   * provided for a single command, in general to UI
   * will only provide the first option to users as a
   * hint (though both options will work).
   *
   * e.g. ["g i", "Shift+E"]
   */
  hotkeys?: string[];
  triggerHotkeysWhenInputFocused?: boolean;
  showInKBar?: boolean;
  closeKBarOnSelect?: boolean;
  /**
   * By default, `event.preventDefault()` will be called on the
   * event which triggered the callback. Return `false` from the
   * callback to stop this from happening.
   */
  callback: CommandCallback;
}

export type CommandCallback = (
  /**
   * A KeyboardEvent is only provided when the command is
   * triggered by a hotkey/shortcut.
   */
  event?: KeyboardEvent,
) => boolean | void | Promise<void>;

export type TUpdateStrategy = "merge" | "replace";

export interface ICommandConfig {
  id: string;
  priority: number;
  commands: IActiveCommandMap;
}

export interface IActiveCommandMap {
  [commandId: string]: ICommand;
}

/** Normalized command. A command that has been processed. */
export type ICommand = Required<ICommandArgs> & { priority: number };

export class CommandContext {
  id: string;
  updateStrategy: TUpdateStrategy;
  /**
   * This acts as the default priority for the `configsCommands`.
   * May be overridden on an per-command basis.
   */
  defaultPriority: number;
  configs = new Map<string, ICommandConfig>();
  /**
   * The commands for this context's `configs` property, merged.
   */
  configsCommands: ICommand[] = [];
  /**
   * The `configsCommands` and childContexts `configsCommands`,
   * merged.
   */
  activeCommands: ICommand[] = [];
  /**
   * Will be "replace" if either this context's updateStrategy
   * is "replace" or there is a childContext with an
   * updateStrategy of "replace".
   */
  activeCommandsUpdateStrategy: TUpdateStrategy = "merge";
  childContexts = new Map<string, CommandContext>();
  parentContext: CommandContext | null;

  constructor(args: {
    id: string;
    updateStrategy: TUpdateStrategy;
    defaultPriority: number;
    parentContext: CommandContext | null;
  }) {
    this.id = args.id;
    this.updateStrategy = args.updateStrategy;
    this.defaultPriority = args.defaultPriority;
    this.parentContext = args.parentContext;
  }

  mergeConfig(config: ICommandConfig) {
    this.configs.set(config.id, config);
    this.updateConfigsCommands();
    this.updateActiveCommands();
  }

  removeConfig(id: string) {
    this.configs.delete(id);
    this.updateConfigsCommands();
    this.updateActiveCommands();
  }

  mergeChildContext(context: CommandContext) {
    this.childContexts.set(context.id, context);
    context.parentContext = this;
    this.updateActiveCommands();
  }

  removeChildContext(id: string) {
    const child = this.childContexts.get(id);

    if (!child) return;

    child.parentContext = null;
    this.childContexts.delete(id);
    this.updateActiveCommands();
  }

  updateActiveCommands() {
    const children = Array.from(this.childContexts.values());

    const child = this.getChildContextWithReplaceUpdateStrategy();

    const contexts = child ? [child] : children;

    let wipActiveCommands: ICommand[];

    if (child) {
      this.activeCommandsUpdateStrategy = "replace";
      wipActiveCommands = contexts.flatMap((context) => context.activeCommands);
    } else if (this.updateStrategy === "replace") {
      this.activeCommandsUpdateStrategy = "replace";

      wipActiveCommands = [...this.configsCommands, ...contexts.flatMap((context) => context.activeCommands)];
    } else {
      this.activeCommandsUpdateStrategy = "merge";

      wipActiveCommands = [...this.configsCommands, ...contexts.flatMap((context) => context.activeCommands)];
    }

    const groupedDictionary = groupBy(wipActiveCommands, (item) => item.id);

    wipActiveCommands = Object.values(groupedDictionary).map((items) => {
      if (items.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return items[0]!;
      }

      // groupBy creates a dictionary with properties that contain at least one value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return items.sort((a, b) => numberComparer(b.priority, a.priority)).at(0)!;
    });

    this.activeCommands = wipActiveCommands;

    this.parentContext?.updateActiveCommands();
  }

  private updateConfigsCommands() {
    const activeCommandMap = Array.from(this.configs.values())
      .sort((a, b) => numberComparer(a.priority, b.priority))
      .reduce((store, curr) => {
        return { ...store, ...curr.commands };
      }, {} as IActiveCommandMap);

    this.configsCommands = Object.values(activeCommandMap);
  }

  private getChildContextWithReplaceUpdateStrategy() {
    const [childA, childB] = Array.from(this.childContexts.values())
      .filter((context) => context.updateStrategy === "replace" || context.activeCommandsUpdateStrategy === "replace")
      .sort((a, b) => b.defaultPriority - a.defaultPriority);

    if (childA && childB && childA.defaultPriority === childB.defaultPriority) {
      throw new Error(`
        withNewCommandContext: A component cannot have two 
        children which both use updateStrategy: "replace" and have the same
        priority.
      `);
    }

    return childA;
  }
}

/* -------------------------------------------------------------------------------------------------
 * ACTIVE_COMMANDS$
 * -----------------------------------------------------------------------------------------------*/

const _ACTIVE_COMMANDS$ = updatableBehaviorSubject([] as ICommand[]);

export function getActiveCommands() {
  return _ACTIVE_COMMANDS$.getValue();
}

export function setActiveCommands(commands: ICommand[]) {
  return _ACTIVE_COMMANDS$.next(commands);
}

export const ACTIVE_COMMANDS$ = _ACTIVE_COMMANDS$.pipe(distinctUntilChanged(isEqual));

export function getCommandById(id: string) {
  return _ACTIVE_COMMANDS$.getValue().find((cmd) => cmd.id === id);
}

export function callCommandById(id: string) {
  return getCommandById(id)?.callback();
}

/* -------------------------------------------------------------------------------------------------
 * ACTIVE_PATH$
 * -----------------------------------------------------------------------------------------------*/

export const ACTIVE_PATH$ = updatableBehaviorSubject<string[]>([]);

/* -------------------------------------------------------------------------------------------------
 * COMMAND_EVENTS$
 * -----------------------------------------------------------------------------------------------*/

export interface ICommandEvent {
  type: "hotkey" | "kbar";
  command: ICommand;
}

/**
 * Observable that emits with an ICommandEvent object whenever the user
 * executes a command.
 */
export const COMMAND_EVENTS$ = new Subject<ICommandEvent>();

/** Handler for all hotkeys */

let commandEventHandler: EventListener = () => {};

/** Handler for hotkeys which can be triggered while an input has focus */

let inputCommandEventHandler: EventListener = () => {};

/** A variable that can be used to disable hotkey listeners for the Command service */
let isCommandServiceHotkeyListenerDisabled = false;

/**
 * Use this to globally enable/disable hotkey listeners for the Command service.
 */
export function disableCommandServiceHotkeyListener(value: boolean) {
  isCommandServiceHotkeyListenerDisabled = value;
}

/* -------------------------------------------------------------------------------------------------
 * utils
 * -----------------------------------------------------------------------------------------------*/

/**
 * On Apple devices the platform modifier key is
 * the command key and on other devices it is the
 * control key.
 */
export const PLATFORM_MODIFIER_KEY =
  typeof navigator === "object" && /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? ({ name: "Command", shortName: "Cmd", symbol: FiCommand } as const)
    : ({ name: "Control", shortName: "Ctrl", symbol: ImCtrl } as const);

/**
 * On Apple devices the "Alt" key is generally referred
 * to as "Option" whereas on other devices it is refered
 * to as "Alt".
 */
export const PLATFORM_ALT_KEY =
  typeof navigator === "object" && /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? ({ name: "Option", shortName: "Opt", symbol: BsOption } as const)
    : ({ name: "Alt", shortName: "Alt", symbol: BsOption } as const);

export function isModKeyActive(event: KeyboardEvent | MouseEvent) {
  const key = PLATFORM_MODIFIER_KEY.name;

  switch (key) {
    case "Command": {
      return event.metaKey;
    }
    case "Control": {
      return event.ctrlKey;
    }
    default: {
      throw new UnreachableCaseError(key);
    }
  }
}

export function normalizeCommand(command: ICommandArgs, priority: number): ICommand {
  const id = command.id || command.label;

  if (typeof id !== "string") {
    throw new Error(`Commands with non-string labels are required to provide an ID`);
  }

  return {
    priority,
    path: [],
    hotkeys: [],
    keywords: [],
    showInKBar: true,
    closeKBarOnSelect: true,
    triggerHotkeysWhenInputFocused: false,
    icon: null,
    altLabels: [],
    ...command,
    id,
  };
}

/**
 * When provided an array of active commands, this function will rebuild
 * the commandEventHandler and inputCommandEventHandler functions.
 */
export function reindexActiveHotkeyCommands(props: { logger: Logger; activeCommands: ICommand[] }) {
  const { activeCommands, logger } = props;
  const activePath = ACTIVE_PATH$.getValue();

  const activeHotkeyCommands = activeCommands
    .filter((command) => {
      if (command.hotkeys.length === 0) return false;
      if (isEqual(command.path, ["global"] as const)) return true;
      if (command.path.length !== activePath.length) return false;

      return command.path.every((segment, i) => activePath[i] === segment);
    })
    .sort((a, b) => numberComparer(a.priority, b.priority));

  if (import.meta.env.MODE === "development") {
    const hotkeysSet = new Map<string, ICommandArgs>();

    for (const command of activeHotkeyCommands) {
      for (const hotkey of command.hotkeys) {
        if (!hotkeysSet.has(hotkey)) {
          hotkeysSet.set(hotkey, command);
          continue;
        }

        logger.debug(
          { command, hotkeys: hotkeysSet.get(hotkey) },
          `Two commands were registered using the same hotkey: "${hotkey}". ` +
            `This can be perfectly valid and expected; it can also be unexpected. ` +
            `To avoid this, you can use the "priority" option in useRegisterCommands()`,
        );
      }
    }
  }

  const keyBindingMap = Object.fromEntries(
    activeHotkeyCommands.flatMap((command) => mapCommandToKeyBinding(command, logger)),
  );

  const inputKeyBindingMap = Object.fromEntries(
    activeHotkeyCommands
      .filter((command) => command.triggerHotkeysWhenInputFocused)
      .flatMap((command) => mapCommandToKeyBinding(command, logger)),
  );

  logger.debug({ keyBindingMap, inputKeyBindingMap }, "command keyBindingMap changed");

  logger.debug(
    {
      activeHotkeyCommands: Object.fromEntries(activeHotkeyCommands.map((c) => [c.id, c])),
    },
    "active commands changed",
  );

  commandEventHandler = createKeybindingsHandler(keyBindingMap);
  inputCommandEventHandler = createKeybindingsHandler(inputKeyBindingMap);
}

export const WINDOW_EVENTS$ = fromEvent<KeyboardEvent>(window, "keydown").pipe(share());

export function commandServiceHandleKeyDown(event: KeyboardEvent) {
  if (isCommandServiceHotkeyListenerDisabled) return;

  const target = event.target as HTMLElement | null;
  const tagName = target?.tagName;

  if (tagName === "INPUT" || tagName === "SELECT" || tagName === "TEXTAREA" || target?.isContentEditable) {
    inputCommandEventHandler(event);
    return;
  }

  commandEventHandler(event);
}

function mapCommandToKeyBinding(command: ICommand, logger: Logger) {
  const processedEventStore = new WeakSet<KeyboardEvent>();

  return command.hotkeys.map((trigger) => [
    trigger,
    (event: KeyboardEvent) => {
      // A single command can have multiple hotkey triggers.
      // Sometimes, we'll have similar variations of a trigger
      // targeting different browsers. This can cause some
      // browsers to trigger multiple times depending on how
      // they interpret the trigger (different browsers interpret
      // KeyboardEvents differently). Here we guard against that
      // possibility by using a WeakSet to make sure we only
      // call a given callback once per event.
      //
      // Additionally, it's possible that some application logic
      // manually imports and calls `handleKeyDown` with an event
      // before it reaches the window. In this case, it's possible
      // that the same event might be processed by this service
      // multiple times.
      if (processedEventStore.has(event)) return;

      processedEventStore.add(event);

      if (import.meta.env.MODE === "development") {
        logger.info({ trigger, event, command }, "hotkey triggered");
      } else if (import.meta.env.MODE === "production") {
        logger.debug({ trigger, event, command }, "hotkey triggered");
      }

      COMMAND_EVENTS$.next({ type: "hotkey", command });

      const result = command.callback(event);

      if (result === false) return;

      // At least one command does need this prevent default:
      // when you press "c" to open the compose post modal,
      // if we don't prevent default then the "to" field will
      // have a "c" in it on open.
      event.preventDefault();
    },
  ]);
}
