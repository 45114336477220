import { useRecordLoader } from "./useRecordLoader";

export function useDrafts() {
  return useRecordLoader({
    load({ loader, currentUserId, limit }) {
      return loader.observeGetDrafts({
        currentUserId,
        limit,
      });
    },
    initialLimit: 50,
    limitStep: 50,
    map: (records) => records.map((r) => r.id),
  });
}
