import { startWith } from "libs/rxjs-operators";
import { RefObject, useEffect } from "react";
import { filter, fromEvent, throttleTime } from "rxjs";
import { getMaxScrollTop, getScrollTop } from "~/utils/dom-helpers";
import { useAsRef } from "./useAsRef";

/**
 * This hook aids with implementing paging functionality
 * in other hooks which are returning firestore data. This
 * hook receives a ref for the dom element where scrolling will
 * take place as well as a callback function which will be
 * called when the user scrolls to the bottom of the scrolling
 * element.
 */
export function useListPaging(props: {
  isLoading: boolean;
  fetchMore: () => void;
  isListEnd: boolean;
  /**
   * Loads an initial chunk of threads and then loads
   * more when the user scrolls to the bottom of the
   * element associated with this scrollboxRef.
   */
  pagingScrollboxRef: RefObject<HTMLElement>;
}) {
  const { fetchMore, pagingScrollboxRef, isLoading, isListEnd } = props;

  const isLoadingRef = useAsRef(isLoading);
  const fetchMoreRef = useAsRef(fetchMore);
  const isListEndRef = useAsRef(isListEnd);

  useEffect(() => {
    if (isListEnd) return;

    const scrollboxEl = pagingScrollboxRef.current;

    if (!scrollboxEl) {
      console.warn("useListPaging: pagingScrollboxRef is null");
      return;
    }

    const sub = fromEvent(scrollboxEl === document.body ? window : scrollboxEl, "scroll")
      .pipe(
        startWith(() => null),
        throttleTime(100, undefined, { leading: false, trailing: true }),
        filter(() => {
          if (isLoadingRef.current) return false;
          // When loading ends, isListEnd might turn `true` at the same time that isLoading
          // turns `false`. Because this is happening in an effect which occurs after rendering
          // the component (i.e. after the `isLoading` variable is updated), it's possible for
          // isLoadingRef.current to reflect the latest state while `isListEnd` reflects stale
          // state. This can cause this observable to trigger when it shouldn't unless we guard
          // against the possibility using this ref.
          if (isListEndRef.current) return false;

          const targetPxFromBottom = 400;

          const maxScrollHeight = getMaxScrollTop(scrollboxEl);

          const targetHeight = maxScrollHeight - targetPxFromBottom;

          const isTargetHeightReached = getScrollTop(scrollboxEl) > targetHeight;

          return isTargetHeightReached;
        }),
        throttleTime(1500, undefined, { leading: true, trailing: true }),
      )
      .subscribe(() => {
        fetchMoreRef.current();
      });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMoreRef, pagingScrollboxRef, isLoadingRef, isListEnd]);
}
