import { css, cx } from "@emotion/css";
import { ComponentType, memo, ReactNode, useEffect, useMemo, useState } from "react";
import { Portal } from "@radix-ui/react-portal";
import { usePortalContainerContext } from "../portal.service";
import { LOUD_HINT_EVENTS$, pauseQuietHintTimeout, QUIET_HINT_STATE$, resumeQuietHintTimeout } from "./hint.state";
import { slateDarkA } from "@radix-ui/colors";
import { ImArrowLeft, ImArrowRight, ImArrowUp, ImArrowDown } from "react-icons/im";
import { PLATFORM_ALT_KEY, PLATFORM_MODIFIER_KEY } from "../command.service";
import { isEqual } from "libs/predicates";
import { Tooltip } from "~/components/Tooltip";
import { useObservableEagerState } from "observable-hooks";

export const HintViewport: ComponentType<{}> = () => {
  const { container } = usePortalContainerContext();

  return (
    <Portal container={container}>
      <div id="quiet-hint-container" className="fixed bottom-8 left-8 z-[900]">
        <QuietHint />
      </div>

      <LoudHint />
    </Portal>
  );
};

const hintCSS = cx(
  "flex py-3 px-5 my-4 rounded-full",
  "items-center outline-none",
  "bg-levels-greenBold",
  css`
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 50%),
      0 4px 6px -4px rgb(0 0 0 / 30%);

    & {
      .verb {
        color: black;
      }

      kbd {
        background-color: ${slateDarkA.slateA11};
      }
    }
  `,
);

const QuietHint: ComponentType<{}> = () => {
  const quietHint = useObservableEagerState(QUIET_HINT_STATE$);

  if (!quietHint) return null;

  return (
    <div className={hintCSS} onMouseEnter={pauseQuietHintTimeout} onMouseLeave={resumeQuietHintTimeout}>
      {quietHint.subject && <strong className="mr-1">{quietHint.subject}</strong>}
      <span className="inline-flex">{quietHint.content}</span>
    </div>
  );
};

let loudHintTimeoutId: number;

const LoudHint: ComponentType<{}> = () => {
  const [loudHint, setLoudHint] = useState<string | null | ReactNode>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const sub = LOUD_HINT_EVENTS$.subscribe((hint) => {
      if (loudHintTimeoutId) {
        clearTimeout(loudHintTimeoutId);
      }

      loudHintTimeoutId = setTimeout(() => setLoudHint(null), 1500) as unknown as number;

      setLoudHint(hint.content);

      setIsVisible(true);
      setTimeout(() => setIsVisible(false), 1000);
    });

    return () => sub.unsubscribe();
  }, []);

  if (!loudHint) return null;

  return (
    <div
      id="loud-hint-container"
      className={cx(
        "flex justify-center items-center ",
        "fixed w-screen h-screen top-0 left-0 z-[8000] pointer-events-none",
        "bg-black text-white p-10 md-w:p-20",
        isVisible ? "opacity-80" : "transition opacity-0 duration-300",
      )}
    >
      {typeof loudHint === "string" ? <h1 className="text-6xl text-center leading-tight">{loudHint}</h1> : loudHint}
    </div>
  );
};

export const ShortcutHint: ComponentType<{
  hint: string;
}> = ({ hint }) => {
  return (
    <>
      Try pressing{" "}
      <span className="inline-flex items-center mx-2">
        <ShortcutHintContents hint={hint} />
      </span>{" "}
      to perform this action instead.
    </>
  );
};

export const ShortcutHintContents: ComponentType<{
  hint: string;
  adverbClassName?: string;
  keyClassName?: string;
}> = memo((props) => {
  const { hint } = props;

  const keys = useMemo(() => {
    const _keys: string[] = [];

    hint.split(" ").forEach((word, indexA) => {
      if (indexA > 0) {
        _keys.push("then");
      }

      word.split("+").forEach((key) => {
        // if (indexB > 0) {
        //   _keys.push("+");
        // }

        _keys.push(key);
      });
    });

    return _keys;
  }, [hint]);

  return (
    <>
      {keys.map((key, index) => {
        switch (key) {
          case "then":
          case "+": {
            return (
              <small key={index} className={cx("verb mx-1", props.adverbClassName)}>
                {key}
              </small>
            );
          }
          default: {
            return (
              <kbd
                key={index}
                className={cx(
                  "flex rounded uppercase font-bold",
                  "justify-center items-center text-xs",
                  "mx-[2px]",
                  "py-[2px] px-[6px] min-h-[20px]",
                  props.keyClassName,
                )}
              >
                {humanizeKey(key)}
              </kbd>
            );
          }
        }
      })}
    </>
  );
}, isEqual);

function humanizeKey(key: string) {
  switch (key) {
    case "ArrowLeft": {
      return <ImArrowLeft />;
    }
    case "ArrowRight": {
      return <ImArrowRight />;
    }
    case "ArrowUp": {
      return <ImArrowUp />;
    }
    case "ArrowDown": {
      return <ImArrowDown />;
    }
    case "$mod": {
      return (
        <Tooltip side="bottom" content={PLATFORM_MODIFIER_KEY.name}>
          {/* The Tooltip can't wrap an SVG element so we add a span */}
          <span>
            <PLATFORM_MODIFIER_KEY.symbol />
          </span>
        </Tooltip>
      );
    }
    case "Alt": {
      return (
        <Tooltip side="bottom" content={PLATFORM_ALT_KEY.name}>
          {/* The Tooltip can't wrap an SVG element so we add a span */}
          <span>
            <PLATFORM_ALT_KEY.symbol className="stroke-1" />
          </span>
        </Tooltip>
      );
    }
    default: {
      return key;
    }
  }
}
