import { getCurrentUserId, getCurrentUserOwnerOrganizationId } from "./user.service";
import { Simplify } from "type-fest";
import { ClientEnvironment } from "./ClientEnvironment";
import { datadogLogs } from "@datadog/browser-logs";
import { MessagePortService } from "./MessagePortService";
import { LeadershipService } from "./LeadershipService";
import { isSharedWorkerSupported } from "~/utils/dom-helpers";

declare module "./MessagePortService" {
  interface MessageTypeMap {
    SHARED_WORKER_CONNECT: {
      request: Message<
        "SHARED_WORKER_CONNECT",
        {
          currentUserId: string | null;
          ownerOrganizationId: string | null;
          datadogSessionId: string | null;
          disableLeadership: boolean;
        }
      >;
      response: Message<"SHARED_WORKER_CONNECT", null>;
    };
    SHARED_WORKER_IS_PERSISTED_DB_ACTIVE: {
      request: Message<"SHARED_WORKER_IS_PERSISTED_DB_ACTIVE", null>;
      response: Message<"SHARED_WORKER_IS_PERSISTED_DB_ACTIVE", null>;
    };
  }
}

/**
 * The `ServiceWorkerService` class is responsible for managing the service worker for the app.
 */

export type SharedWorkerServiceApi = Simplify<SharedWorkerService>;

export class SharedWorkerService {
  static async create(
    env: Pick<ClientEnvironment, "logger" | "clientId" | "leader">,
    props: { isPersistedDbSupported: boolean },
  ) {
    const service = new SharedWorkerService(env, props);
    await service.init();
    return service;
  }

  connection: MessagePortService;

  private worker!: SharedWorker | Worker;

  constructor(
    env: Pick<
      ClientEnvironment,
      | "logger"
      | "clientId"
      // Note that the leader service is not used *however* it's important that we attempt to acquire a lock
      // on leadership before initializing the shared worker. This way the SharedWorkerLeadershipService can
      // look up who holds the leadership lock during initialization. A good way to enforce this order is to
      // make this service depend on the leader service.
      | "leader"
    >,
    private props: { isPersistedDbSupported: boolean },
  ) {
    this.connection = new MessagePortService({
      serviceName: "SharedWorkerService",
      senderId: env.clientId,
      logger: env.logger,
      defaultRetryOnDisconnect: true,
      getRecipient() {
        return this.recipients.get(MessagePortService.uniqueContexts.SHARED_WORKER);
      },
    });
  }

  onAuthChange() {
    return this.sendAuthChange();
  }

  async init() {
    if (isSharedWorkerSupported()) {
      this.worker = new SharedWorker(new URL("./shared-worker", import.meta.url), {
        // We store whether the persistedDb should be created in the worker name.
        // Vite requires passing vite-ignore if the worker name is dynamic. Not sure why, but this is
        // vite's suggested workaround.
        /* @vite-ignore */
        name: `CommsSharedWorker-${this.props.isPersistedDbSupported ? "PersistedDb" : "NoPersistedDb"}`,
        type: "module",
      });

      this.connection.onConnect(MessagePortService.uniqueContexts.SHARED_WORKER, this.worker.port);
    } else {
      this.worker = new Worker(new URL("./shared-worker", import.meta.url), {
        // We store whether the persistedDb should be created in the worker name.
        // Vite requires passing vite-ignore if the worker name is dynamic. Not sure why, but this is
        // vite's suggested workaround.
        /* @vite-ignore */
        name: `CommsFakeSharedWorker-${this.props.isPersistedDbSupported ? "PersistedDb" : "NoPersistedDb"}`,
        type: "module",
      });

      this.connection.onConnect(MessagePortService.uniqueContexts.SHARED_WORKER, this.worker);
    }

    await this.sendSharedWorkerConnect();
  }

  async activate() {
    this.connection.activate();
  }

  private sendAuthChange() {
    return this.connection.sendRequest("AUTH_CHANGE", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: {
        currentUserId: getCurrentUserId(),
        ownerOrganizationId: getCurrentUserOwnerOrganizationId(),
      },
    });
  }

  private sendSharedWorkerConnect() {
    return this.connection.sendRequest("SHARED_WORKER_CONNECT", {
      to: MessagePortService.uniqueContexts.SHARED_WORKER,
      data: {
        currentUserId: getCurrentUserId(),
        ownerOrganizationId: getCurrentUserOwnerOrganizationId(),
        datadogSessionId: datadogLogs.getInternalContext()?.session_id ?? null,
        disableLeadership: LeadershipService.disableLeadership(),
      },
    });
  }
}

/* -----------------------------------------------------------------------------------------------*/
