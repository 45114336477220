import { op } from "libs/transaction";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { navigateService } from "~/environment/navigate.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { runTransaction, withTxLogger } from "./write";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export const toggleFocusMode = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (environment: ClientEnvironment, enableFocusMode: boolean) => {
    const currentUserId = getAndAssertCurrentUserId();

    await runTransaction({
      environment: withTxLogger(environment, { data: { enableFocusMode } }),
      label: "toggleFocusMode",
      tx: async (transaction) => {
        const [settingsRec] = await environment.recordLoader.getRecord({
          table: "user_settings",
          id: currentUserId,
        });

        const hasTheUserEverEnabledFocusModeBefore = !!settingsRec?.settings.focus_mode_exceptions;

        if (hasTheUserEverEnabledFocusModeBefore) {
          transaction.operations.push(
            op.set({ table: "user_settings", id: currentUserId }, ["settings", "enable_focus_mode"], enableFocusMode),
          );
        } else {
          // If the user has never enabled focus mode before,
          // we take them to the focus mode section of the
          // settings so that they can learn more about it
          // and do any initial setup that's necessary.
          navigateService("/settings#focus-mode");
        }
      },
    });
  }),
);
