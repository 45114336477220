import { withNewCommandContext } from "~/environment/command.service";
import { RefObject, useEffect, useRef, useState } from "react";
import { IListRef, ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { useIsOnline } from "~/hooks/useIsOnline";
import * as MainLayout from "~/page-layouts/main-layout";
import { ContentList, EmptyListMessage } from "~/components/content-list/ContentList";
import { ISearchEditorRef, SearchEditor } from "~/components/forms/search-editor";
import { PointerWithRecord } from "libs/schema";
import { MessageEntry, onMessageSelectNavigateToThread } from "~/components/content-list/MessageEntry";
import { EndOfListMsg } from "~/components/EndOfListMsg";
import { useAndInitializeSearch } from "./useSearch";
import { usePerformSearch, useRegisterSearchViewCommands } from "./useRegisterSearchViewCommands";
import { useListPaging } from "~/hooks/useListPaging";
import { filterSuggestions } from "~/components/forms/search-editor/suggest/SuggestionsDropdownProvider";
import { buildFilterSuggestionString, getTextSelectionRange } from "~/components/forms/search-editor/suggest/utils";
import _ from "lodash";
import { useTips } from "./useTips";

/* -------------------------------------------------------------------------------------------------
 * SearchView
 * -----------------------------------------------------------------------------------------------*/

export const SearchView = withNewCommandContext({
  priority: { delta: 2 },
  Component: () => {
    const resultsListRef = useRef<IListRef<PointerWithRecord<"message">>>(null);
    const resultsScrollboxRef = useRef<HTMLElement>(document.body);
    const headerRef = useRef<HTMLElement>(null);
    const editorRef = useRef<ISearchEditorRef>(null);

    const isAppOnline = useIsOnline();

    const { searchControl, queryAsPlainText, onlySearchSeenPostsControl } = useAndInitializeSearch({
      editorRef,
    });

    const { search, fetchMore, searchResults, isEndOfSearch, isSearchPending } = usePerformSearch({
      searchControl,
      resultsListRef,
      onlySearchSeenPostsControl,
    });

    useFocusSearchInputIfNoResults({
      editorRef,
      queryAsPlainText,
      areSearchResults: searchResults.messages.length > 0,
      isSearchPending,
    });

    useRegisterSearchViewCommands({
      editorRef,
      searchControl,
      search,
      onlySearchSeenPostsControl,
    });

    useTopScrollShadow({
      scrollboxRef: resultsScrollboxRef,
      targetRef: headerRef,
    });

    useListPaging({
      isLoading: isSearchPending,
      fetchMore,
      isListEnd: isEndOfSearch,
      pagingScrollboxRef: resultsScrollboxRef,
    });

    const { tip, nextTip } = useTips();

    return (
      <>
        <Helmet>
          <title>Search | Comms</title>
        </Helmet>

        <div className="MainPanel">
          <MainLayout.Header ref={headerRef} className="MainHeader pt-4">
            <h1 className="text-3xl mr-3 mt-2 font-medium">Search</h1>
          </MainLayout.Header>

          <div className="flex justify-center px-4 md-w:px-12">
            <div className="flex flex-col w-full">
              <SearchEditor editorRef={editorRef} control={searchControl} />
            </div>
          </div>

          <div className="flex flex-wrap mx-4 md-w:mx-12 mt-5">
            {filterSuggestions.map((o, index) => (
              <div
                key={index}
                className="bg-slate-2 py-1 mb-2 px-3 mr-2 rounded text-sm text-slate-11 border border-slate-5 cursor-pointer hover:bg-slate-3 hover:border-slate-4"
                title={o.description}
                onClick={() => {
                  const editor = editorRef.current?.editor;
                  if (!editor) return;

                  editor.commands.insertContent(buildFilterSuggestionString(o));

                  editorRef.current?.editor?.view.focus();
                  if (o.hint && o.selectHint) {
                    const range = getTextSelectionRange(o, editor);
                    editorRef.current?.editor?.commands.setTextSelection(range);
                  }
                }}
              >
                {o.name}
              </div>
            ))}
          </div>

          {searchResults.messages.length === 0 && !isSearchPending && (
            <>
              {tip && (
                <div className="flex align-center justify-center my-6 md-w:mt-12 text-slate-10">
                  <div className="w-screen max-w-[600px] mx-4 md-w:mx-12 py-3 px-5 bg-slate-1 rounded">
                    <div className="text-base mb-4">{tip.title}</div>
                    <div className="text-sm mb-2">{tip.description}</div>
                    <div className="text-center mt-3">
                      <code>{tip?.example}</code>
                    </div>
                    <div className="flex justify-end mt-4">
                      <button className="px-4 py-1 hover:bg-slate-4 text-sm text-slate-10 rounded" onClick={nextTip}>
                        Next Tip
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <ListScrollbox isBodyElement offsetHeaderEl={headerRef} onlyOffsetHeaderElIfSticky>
            {!isAppOnline ? (
              <EmptyListMessage text="Currently offline. Go online to search." />
            ) : !queryAsPlainText || (searchResults.messages.length === 0 && isSearchPending) ? (
              <div />
            ) : searchResults.messages.length === 0 ? (
              <EmptyListMessage text={`No results for "${queryAsPlainText}"`} />
            ) : (
              <>
                {searchResults.messages.length > 0 && (
                  <div className="px-4 md-w:px-12 text-sm text-slate-9 mt-6 mb-2">
                    {searchResults.estimatedTotalHits} results in {searchResults.processingTimeMs}ms
                  </div>
                )}
                <ContentList
                  ref={resultsListRef}
                  onEntryAction={onMessageSelectNavigateToThread}
                  onArrowUpOverflow={(e) => {
                    e.preventDefault();
                    editorRef.current?.focus("all");
                  }}
                  className="mb-20"
                  autoFocus
                >
                  {!!searchResults.messages &&
                    searchResults.messages.map(
                      (message, index) =>
                        !!message && (
                          <MessageEntry
                            key={message.id}
                            messageId={message.id}
                            relativeOrder={index}
                            overrideSubject={<span dangerouslySetInnerHTML={{ __html: message.subject }} />}
                            overrideBody={<span dangerouslySetInnerHTML={{ __html: message.body }} />}
                          />
                        ),
                    )}

                  <EndOfListMsg isEnd={isEndOfSearch} />
                </ContentList>
              </>
            )}
          </ListScrollbox>
        </div>
      </>
    );
  },
});

function useFocusSearchInputIfNoResults(props: {
  editorRef: RefObject<ISearchEditorRef>;
  isSearchPending: boolean;
  queryAsPlainText: string | null;
  areSearchResults: boolean;
}) {
  useEffect(() => {
    if (props.isSearchPending) return;
    if (props.areSearchResults) return;
    props.editorRef.current?.onCreate$.subscribe((observer) => {
      observer.view.focus();
      props.editorRef.current?.focus("end");
    });
    props.editorRef.current?.focus("all");
  }, [
    props.editorRef,
    // We wish to refocus the input every time the query changes
    props.queryAsPlainText,
    props.areSearchResults,
    props.isSearchPending,
  ]);
}
