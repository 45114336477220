/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Except, Simplify, IterableElement } from "type-fest";

export type SetNonNullable<BaseType, Keys extends keyof BaseType> = Simplify<
  // Pick just the keys that are optional from the base type.
  // Note, if we just use `Except<BaseType, Keys>` instead of
  // `Pick<BaseType, keyof Except<BaseType, Keys>>` then we lose jsdoc
  // comments on the type.
  Pick<BaseType, keyof Except<BaseType, Keys>> &
    // Pick the keys that should be required from the base type and make them required.
    NonNullableProps<Pick<BaseType, Keys>>
>;

type NonNullableProps<T> = { [P in keyof T]: NonNullable<T[P]> };

export type ExcludeFromArray<A extends any[], B> = Exclude<IterableElement<A>, B>[];

export type AsyncifyMethods<T> = {
  [K in keyof T]: T[K] extends (...args: infer A) => infer R
    ? R extends Promise<any>
      ? T[K]
      : (...args: A) => Promise<R>
    : never;
};

/**
 * This is just a helper type to ensure that interface `T`
 * implements all the keys of interface `I`.
 */
export type ImplementsInterface<I extends {}, T extends Record<keyof I, unknown>> = T;

/**
 * This function exists purely for typescript typing purposes
 * during development. It is a noop that simply returns its value.
 * It's a stricter (i.e. more type save) version of `as` that
 * doesn't caste the provided value to the provided type but
 * rather checks to ensure the provided value matches the provided
 * type.
 */
export function checkValueMatchesType<T>(value: T) {
  return value;
}
