import { RecordValue } from "libs/schema";
import { op } from "libs/transaction";
import { runTransaction, withTxLogger } from "./write";
import { toast } from "~/environment/toast-service";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getAndAssertCurrentUserId } from "~/environment/user.service";

export function updateUserSettings(environment: ClientEnvironment, settings: RecordValue<"user_settings">["settings"]) {
  const currentUserId = getAndAssertCurrentUserId();

  return runTransaction({
    environment: withTxLogger(environment, { data: settings }),
    label: "updateUserSettings",
    tx: async (transaction) => {
      transaction.operations.push(op.update({ table: "user_settings", id: currentUserId }, "settings", settings));
    },
  });
}

export async function updateScheduledDelivery(environment: ClientEnvironment, enableScheduledDelivery: boolean) {
  const { recordLoader } = environment;
  const currentUserId = getAndAssertCurrentUserId();
  const [settingsRecord] = await recordLoader.getRecord("user_settings", currentUserId);

  const settings = settingsRecord?.settings;

  const hasTheUserEverEnabledScheduledDeliveryBefore =
    !!settings?.scheduled_days &&
    !!settings.scheduled_times &&
    settings.scheduled_days.length > 0 &&
    settings.scheduled_times.length > 0;

  if (hasTheUserEverEnabledScheduledDeliveryBefore) {
    updateUserSettings(environment, {
      enable_scheduled_delivery: enableScheduledDelivery,
    });

    toast("vanilla", {
      subject: `Scheduled delivery ${enableScheduledDelivery ? "enabled" : "disabled"}`,
    });
  } else {
    updateUserSettings(environment, {
      enable_scheduled_delivery: true,
    });
  }
}
