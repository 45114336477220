import { useObservableEagerState } from "observable-hooks";
import { map } from "rxjs";
import useConstant from "use-constant";
import { observeSyncProgress } from "~/observables/observeSyncProgress";

export function useSyncProgress() {
  const observable = useConstant(() => {
    const formatter = new Intl.NumberFormat();

    return observeSyncProgress().pipe(
      map((message) => {
        return {
          ...message,
          text: `${formatter.format(message.current)} / ${message.total ? formatter.format(message.total) : "?"}`,
        };
      }),
    );
  });

  return useObservableEagerState(observable);
}
