import { forwardRef } from "react";
import { DialogState, DialogTitle, DIALOG_CONTENT_WRAPPER_CSS, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import {
  AutocompleteSelect,
  getFuzzyFilteringFn,
  IOption,
  onChangeMultiHandler,
  TAutocompleteSelectRef,
  useAutocompleteMenuPositioning,
} from "~/components/forms/AutocompleteSelect";
import { createFormControl, createFormGroup, IFormControl, useControl } from "solid-forms-react";
import { handleSubmit, useControlState } from "~/components/forms/utils";
import { toast } from "~/environment/toast-service";
import { useRegisterCommands } from "~/environment/command.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { cx } from "@emotion/css";
import { isNonNullable } from "libs/predicates";
import * as DialogLayout from "~/dialogs/DialogLayout";
import { dayOfWeekComparer, stringComparer } from "libs/comparers";
import { useUnmount } from "react-use";
import { updateUserSettings } from "~/actions/updateUserSettings";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { NormalizedUserSettingsDoc } from "libs/constants/defaultUserSettings";
import { getCurrentUserSettings } from "~/queries/getCurrentUserSettings";

export type IEditScheduledDeliveryData = { settings: NormalizedUserSettingsDoc } | undefined;

export type IEditScheduledDeliveryReturnData = { success: boolean } | void;

export const EditScheduledDeliveryState = new DialogState<
  IEditScheduledDeliveryData,
  IEditScheduledDeliveryReturnData
>();

export const EditScheduledDeliveryDialog = withModalDialog({
  dialogState: EditScheduledDeliveryState,
  async loadData({ environment, data }) {
    if (data?.settings) return data;

    const { settings } = await getCurrentUserSettings(environment);

    return {
      settings,
    };
  },
  Component: (props) => {
    const { data } = props;

    if (!data) {
      throw new Error("EditScheduledDeliveryDialog: data required");
    }

    const { settings } = data;

    if (!settings) {
      alert(`Could not find user settings data. Are you connected to the internet?`);

      EditScheduledDeliveryState.close();
      return null;
    }

    const control = useControl(() => controlFactory(settings));
    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditScheduledDeliveryState.close();
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => handleSubmit({ control, environment, submit }),
          },
        ];
      },
    });

    // If the user disables scheduled delivery while the dialog is open,
    // the dialog will be unmounted but the state hasn't changed. Here we
    // update the state.
    useUnmount(() => {
      if (!EditScheduledDeliveryState.isOpen()) return;
      EditScheduledDeliveryState.close();
    });

    const [deliveryDaysAutocompleteRef, deliveryDaysAutocompletePortalEl, deliveryDaysAutocompletePortalJSX] =
      useAutocompleteMenuPositioning<IOption<string>, true>();

    const [deliveryTimesAutocompleteRef, deliveryTimesAutocompletePortalEl, deliveryTimesAutocompletePortalJSX] =
      useAutocompleteMenuPositioning<IOption<string>, true>();

    return (
      <>
        <DialogTitle>
          <h2>Edit scheduled delivery times</h2>
        </DialogTitle>

        <form
          onSubmit={(e) => {
            // We require users to submit the form with their mouse.
            e.preventDefault();
            e.stopPropagation();
          }}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <div className="px-4">
            <ScheduledDeliveryDays
              ref={deliveryDaysAutocompleteRef}
              control={control.controls.scheduledDays}
              autocompletePortalEl={deliveryDaysAutocompletePortalEl}
            />

            <ScheduledDeliveryTimes
              ref={deliveryTimesAutocompleteRef}
              control={control.controls.scheduledTimes}
              autocompletePortalEl={deliveryTimesAutocompletePortalEl}
            />
          </div>

          <DialogLayout.DialogFooter>
            <div className="flex-1" />

            <DialogLayout.DialogSubmitButton onClick={() => handleSubmit({ control, environment, submit })} />
          </DialogLayout.DialogFooter>
        </form>

        {deliveryDaysAutocompletePortalJSX}
        {deliveryTimesAutocompletePortalJSX}

        <DialogLayout.SubmitDialogHint />
      </>
    );
  },
});

/* -----------------------------------------------------------------------------------------------*/

type TForm = ReturnType<typeof controlFactory>;

function controlFactory(settings: NormalizedUserSettingsDoc) {
  const scheduledDays = settings.scheduled_days
    .map((day) => SCHEDULED_DELIVERY_DAYS_OPTIONS.find((o) => o.value === day))
    .filter(isNonNullable);

  const scheduledTimes = settings.scheduled_times
    .map((times) => SCHEDULED_DELIVERY_TIMES_OPTIONS.find((o) => o.value === times))
    .filter(isNonNullable);

  return createFormGroup({
    scheduledDays: createFormControl<IOption<string>[]>(scheduledDays, {
      validators: [required],
    }),
    scheduledTimes: createFormControl<IOption<string>[]>(scheduledTimes, {
      validators: [required],
    }),
  });
}

function required(value: IOption<string>[]) {
  return value.length > 0 ? null : { required: "Required." };
}

/* -----------------------------------------------------------------------------------------------*/

const ScheduledDeliveryDays = forwardRef<
  TAutocompleteSelectRef<IOption<string>, true>,
  {
    control: IFormControl<IOption<string>[]>;
    autocompletePortalEl?: HTMLDivElement | null;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.rawValue, [props.control]);

  const hasErrors = useControlState(() => !!props.control.errors, [props.control]);

  return (
    <div className="flex flex-col my-4">
      <label htmlFor="delivery-days">What days of the week should we deliver messages?</label>

      <AutocompleteSelect
        ref={ref}
        multiple
        name="delivery-days"
        placeholder="Select days..."
        loadOptions={getFuzzyFilteringFn(SCHEDULED_DELIVERY_DAYS_OPTIONS)}
        value={value}
        classNames={cx(
          "px-2 my-2 border focus-within:border-blue-9 rounded",
          hasErrors ? "border-red-10" : "border-slate-8",
        )}
        onBlur={() => props.control.markTouched(true)}
        onChange={onChangeMultiHandler(props.control)}
        menuPortalTarget={props.autocompletePortalEl}
      />

      {hasErrors && (
        <div className="text-red-10 font-medium">
          In order to enable scheduled delivery, you must choose at least one delivery day.
        </div>
      )}
    </div>
  );
});

const SCHEDULED_DELIVERY_DAYS_OPTIONS: IOption<string>[] = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

/* -----------------------------------------------------------------------------------------------*/

const ScheduledDeliveryTimes = forwardRef<
  TAutocompleteSelectRef<IOption<string>, true>,
  {
    control: IFormControl<IOption<string>[]>;
    autocompletePortalEl?: HTMLDivElement | null;
  }
>((props, ref) => {
  const value = useControlState(() => props.control.rawValue, [props.control]);

  const hasErrors = useControlState(() => !!props.control.errors, [props.control]);

  return (
    <div className="flex flex-col my-4">
      <label htmlFor="delivery-times">What hours of the day should we deliver messages?</label>

      <AutocompleteSelect
        ref={ref}
        multiple
        name="delivery-times"
        placeholder="Select times..."
        loadOptions={getFuzzyFilteringFn(SCHEDULED_DELIVERY_TIMES_OPTIONS)}
        value={value}
        classNames={cx(
          "px-2 my-2 border focus-within:border-blue-9 rounded",
          hasErrors ? "border-red-10" : "border-slate-8",
        )}
        onBlur={() => props.control.markTouched(true)}
        onChange={onChangeMultiHandler(props.control)}
        menuPortalTarget={props.autocompletePortalEl}
        menuPlacement="auto"
      />

      {hasErrors && (
        <div className="text-red-10 font-medium">
          In order to enable scheduled delivery, you must choose at least one delivery time.
        </div>
      )}
    </div>
  );
});

export const SCHEDULED_DELIVERY_TIMES_OPTIONS: IOption<string>[] = [
  { label: "12 am", value: "00:00" },
  { label: "12:30 am", value: "00:30" },
  { label: "1 am", value: "01:00" },
  { label: "1:30 am", value: "01:30" },
  { label: "2 am", value: "02:00" },
  { label: "2:30 am", value: "02:30" },
  { label: "3 am", value: "03:00" },
  { label: "3:30 am", value: "03:30" },
  { label: "4 am", value: "04:00" },
  { label: "4:30 am", value: "04:30" },
  { label: "5 am", value: "05:00" },
  { label: "5:30 am", value: "05:30" },
  { label: "6 am", value: "06:00" },
  { label: "6:30 am", value: "06:30" },
  { label: "7 am", value: "07:00" },
  { label: "7:30 am", value: "07:30" },
  { label: "8 am", value: "08:00" },
  { label: "8:30 am", value: "08:30" },
  { label: "9 am", value: "09:00" },
  { label: "9:30 am", value: "09:30" },
  { label: "10 am", value: "10:00" },
  { label: "10:30 am", value: "10:30" },
  { label: "11 am", value: "11:00" },
  { label: "11:30 am", value: "11:30" },
  { label: "12 pm", value: "12:00" },
  { label: "12:30 pm", value: "12:30" },
  { label: "1 pm", value: "13:00" },
  { label: "1:30 pm", value: "13:30" },
  { label: "2 pm", value: "14:00" },
  { label: "2:30 pm", value: "14:30" },
  { label: "3 pm", value: "15:00" },
  { label: "3:30 pm", value: "15:30" },
  { label: "4 pm", value: "16:00" },
  { label: "4:30 pm", value: "16:30" },
  { label: "5 pm", value: "17:00" },
  { label: "5:30 pm", value: "17:30" },
  { label: "6 pm", value: "18:00" },
  { label: "6:30 pm", value: "18:30" },
  { label: "7 pm", value: "19:00" },
  { label: "7:30 pm", value: "19:30" },
  { label: "8 pm", value: "20:00" },
  { label: "8:30 pm", value: "20:30" },
  { label: "9 pm", value: "21:00" },
  { label: "9:30 pm", value: "21:30" },
  { label: "10 pm", value: "22:00" },
  { label: "10:30 pm", value: "22:30" },
  { label: "11 pm", value: "23:00" },
  { label: "11:30 pm", value: "23:30" },
];

/* -----------------------------------------------------------------------------------------------*/

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (environment: ClientEnvironment, values: TForm["rawValue"]) => {
    console.log("submitting...", values);

    EditScheduledDeliveryState.close({ success: true });

    toast("vanilla", {
      subject: "Updating scheduled delivery settings...",
    });

    await updateUserSettings(environment, {
      scheduled_days: values.scheduledDays.map((o) => o.value).sort(dayOfWeekComparer),
      scheduled_times: values.scheduledTimes.map((o) => o.value).sort(stringComparer),
    });

    console.log("submitted successfully!");

    toast("vanilla", {
      subject: "Saved.",
    });
  }),
);

/* -----------------------------------------------------------------------------------------------*/
