import { useLoadingObservable } from "./useLoadingObservable";
import { of, switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  ObserveCurrentUserMentionFrequencyResult,
  observeCurrentUserMentionFrequency,
} from "~/observables/observeCurrentUserMentionFrequency";
import { FetchStrategy } from "~/environment/QueryCache";
import { useCurrentUserId } from "./useCurrentUserId";

export function useCurrentUserMentionFrequency(
  props: {
    fetchStrategy?: FetchStrategy;
  } = {},
) {
  const environment = useClientEnvironment();
  const currentUserId = useCurrentUserId();

  return useLoadingObservable({
    initialValue: DEFAULT_VALUE,
    deps: [currentUserId, environment, props.fetchStrategy],
    depsKey: String(currentUserId) + props.fetchStrategy,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([userId, environment, fetchStrategy]) => {
          if (!userId) {
            return of<ObserveCurrentUserMentionFrequencyResult>([null, { isLoading: false }]);
          }

          return observeCurrentUserMentionFrequency(environment, { currentUserId: userId }, { fetchStrategy });
        }),
      );
    },
  });
}

const DEFAULT_VALUE = Object.freeze([
  null,
  Object.freeze({ isLoading: true }),
]) as ObserveCurrentUserMentionFrequencyResult;
