import { Navigate, useParams } from "react-router-dom";
import { NotFound } from "./components/NotFound";
import { SidebarLayout } from "~/page-layouts/sidebar-layout";
import { Suspense } from "react";
import { LoadingText } from "./components/LoadingText";
import { createBrowserRouter } from "react-router-dom";
import { _setRouter } from "./environment/navigate.service";
import { LoginView } from "./pages/login/LoginView";
import { SignupView } from "./pages/signup/SignupView";
import { TagView } from "./pages/tag/TagView";
import { InboxView } from "./pages/inbox/InboxView";
import { ThreadView } from "./pages/thread/ThreadView";
import { SentView } from "./pages/sent/SentView";
import { DraftsView } from "./pages/drafts/DraftsView";
import { RemindersView } from "./pages/reminders/RemindersView";
import { StarredView } from "./pages/starred/StarredView";
import { DoneView } from "./pages/done/DoneView";
import { SettingsView } from "./pages/settings/SettingsView";
import { SearchView } from "./pages/search/SearchView";
import { ExploreGroupsView } from "./pages/organization/explore-groups/ExploreGroupsView";
import { TagSubscribersView } from "./pages/tag/TagSubscribersView";
import { MembersView as OrganizationMembersView } from "./pages/organization/MembersView";
import { deriveUUID } from "libs/uuid";
import { EditInboxSectionView } from "./pages/inbox/EditInboxSectionView";
import { PlansView } from "~/pages/plans";
import { ReferralsView } from "~/pages/referrals/ReferralsView";
import { DeactivatedView } from "~/pages/deactivated";

export const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <Suspense fallback={<LoadingText />}>
        <LoginView />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<LoadingText />}>
        <SignupView />
      </Suspense>
    ),
  },
  {
    path: "/plans",
    element: (
      <Suspense fallback={<LoadingText />}>
        <PlansView />
      </Suspense>
    ),
  },
  {
    path: "/deactivated",
    element: (
      <Suspense fallback={<LoadingText />}>
        <DeactivatedView />
      </Suspense>
    ),
  },
  {
    path: "/",
    element: <SidebarLayout />,
    children: [
      {
        // The index and path options cannot both be used
        index: true,
        element: (
          <Suspense fallback={<LoadingText />}>
            <InboxView />
          </Suspense>
        ),
      },
      {
        path: "inbox",
        element: (
          <Suspense fallback={<LoadingText />}>
            <InboxView />
          </Suspense>
        ),
      },
      {
        path: "inbox/:inboxSectionId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <InboxView />
          </Suspense>
        ),
      },
      {
        path: "inbox/new",
        element: (
          <Suspense fallback={<LoadingText />}>
            <EditInboxSectionView />
          </Suspense>
        ),
      },
      {
        path: "inbox/:inboxSectionId/edit",
        element: (
          <Suspense fallback={<LoadingText />}>
            <EditInboxSectionView />
          </Suspense>
        ),
      },
      {
        path: "starred",
        element: (
          <Suspense fallback={<LoadingText />}>
            <StarredView />
          </Suspense>
        ),
      },
      {
        path: "done",
        element: (
          <Suspense fallback={<LoadingText />}>
            <DoneView />
          </Suspense>
        ),
      },
      {
        path: "sent",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SentView />
          </Suspense>
        ),
      },
      {
        path: "reminders",
        element: (
          <Suspense fallback={<LoadingText />}>
            <RemindersView />
          </Suspense>
        ),
      },
      {
        path: "search",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SearchView />
          </Suspense>
        ),
      },
      {
        path: "drafts",
        element: (
          <Suspense fallback={<LoadingText />}>
            <DraftsView />
          </Suspense>
        ),
      },
      {
        path: "groups/:tagId/subscribers",
        element: (
          <Suspense fallback={<LoadingText />}>
            <TagSubscribersView />
          </Suspense>
        ),
      },
      {
        path: "groups/:tagId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <TagView />
          </Suspense>
        ),
      },
      // {
      //   path: "tags/:tagId/subscribers",
      //   element: (
      //     <Suspense fallback={<LoadingText />}>
      //       <TagSubscribersView />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "tags/:tagId",
      //   element: (
      //     <Suspense fallback={<LoadingText />}>
      //       <TagView />
      //     </Suspense>
      //   ),
      // },
      {
        path: "channels/:channelId",
        element: <RedirectChannelUrlToGroupUrl />,
      },
      {
        path: "threads/:threadId",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ThreadView />
          </Suspense>
        ),
      },
      //     {
      //       path: "emails/:threadId",
      //       element: (
      //         <Suspense fallback={<LoadingText />}>
      //           <ThreadView />
      //         </Suspense>
      //       ),
      //     },
      {
        path: "organizations/:organizationId/members",
        element: (
          <Suspense fallback={<LoadingText />}>
            <OrganizationMembersView />
          </Suspense>
        ),
      },
      {
        path: "organizations/:organizationId/explore-groups",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ExploreGroupsView />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<LoadingText />}>
            <SettingsView />
          </Suspense>
        ),
      },
      {
        path: "referrals",
        element: (
          <Suspense fallback={<LoadingText />}>
            <ReferralsView />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

_setRouter(router);

function RedirectChannelUrlToGroupUrl() {
  const params = useParams<{ channelId: string }>();

  if (!params.channelId) {
    return <Navigate to="/inbox" replace />;
  }

  return <Navigate to={`/groups/${deriveUUID(params.channelId)}`} replace />;
}
