import { useCallback, useMemo, useState } from "react";
import { map, Observable, switchMap, throttleTime } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  ClientRecordLoaderApi,
  ClientRecordLoaderObserveQueryResult,
  ClientRecordLoaderObserveQueryResultMeta,
} from "~/environment/RecordLoader";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useAssertInvariant } from "./useAssertInvariant";
import { useLoadingObservable } from "./useLoadingObservable";
import { usePendingRequestBar } from "./usePendingRequestBar";
import { omit } from "lodash-es";
import { useAsRef } from "./useAsRef";
import { useCurrentUserId } from "./useCurrentUserId";

export interface UseRecordLoaderProps<
  T,
  Deps extends any[] = [],
  IsAuthOptional extends boolean | undefined = undefined,
  MapResult = T[],
> {
  load: (
    props: UseRecordLoaderLoadProps<Deps, IsAuthOptional>,
  ) => Observable<readonly [readonly T[], Omit<ClientRecordLoaderObserveQueryResultMeta, "recordMap">]>;
  deps?: Deps;
  depsKey?: string;
  initialLimit?: number;
  limitStep?: number;
  map?: MapResultFn<T, MapResult>;
  mapDeps?: any[];
  /**
   * By default, this hook will pull in the currentUserId using the AuthGuardContext
   * and pass it to the provided `load()` function as a prop. By setting isAuthOptional,
   * you can override this behavior and the currentUserId will instead by grabbed using
   * the useCurrentUserId() hook. In this scenerio, the currentUserId passed to the
   * `load()` function may be undefined.
   *
   * Whatever option you choose, the value of `isAuthOptional` must not change after
   * initialization. If it does an error will be thrown.
   */
  isAuthOptional?: IsAuthOptional;
}

export type UseRecordLoaderResult<T> = [
  T,
  Omit<ClientRecordLoaderObserveQueryResultMeta, "recordMap"> & {
    fetchMore: () => void;
    refetch: () => void;
  },
];

export function useRecordLoader<
  T,
  Deps extends [any, ...any[]] | [] = [],
  IsAuthOptional extends boolean | undefined = undefined,
  MapResult = T[],
>(props: UseRecordLoaderProps<T, Deps, IsAuthOptional, MapResult>): UseRecordLoaderResult<MapResult> {
  const {
    load,
    deps = [] as unknown as Deps,
    depsKey = "",
    initialLimit,
    limitStep,
    map: mapResult = DEFAULT_MAP_RESULT as MapResultFn<T, MapResult>,
    mapDeps = [],
    isAuthOptional = false,
  } = props;

  useAssertInvariant(isAuthOptional, "useRecordLoader: attempted to change isAuthOptional argument");

  const [limit, setLimit] = useState(initialLimit);
  const { recordLoader } = useClientEnvironment();
  const [refetch, reloadId] = useTriggerReload();

  // We already check to make sure that the isAuthOptional argument never changes
  // (above) so this condutional use of hooks is safe.
  const currentUserId = isAuthOptional
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useCurrentUserId()
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useAuthGuardContext().currentUserId;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapFn = useCallback(mapResult, mapDeps);

  type UseLoadingObservableResult = [MapResult, Omit<ClientRecordLoaderObserveQueryResultMeta, "recordMap">];

  const defaultValue = useMemo(() => [mapFn([]), DEFAULT_VALUE[1]] as UseLoadingObservableResult, [mapFn]);

  const result = useLoadingObservable({
    initialValue: defaultValue,
    deps: [recordLoader, limit, currentUserId, mapFn, reloadId, ...deps],
    depsKey: depsKey + limit + currentUserId + reloadId,
    fn(inputs$) {
      return inputs$.pipe(
        switchMap(([loader, limit, currentUserId, mapFn, _reloadId, ...deps]) =>
          load({
            loader,
            limit,
            currentUserId: currentUserId as never,
            deps,
          }).pipe(
            throttleTime(100, undefined, { leading: true, trailing: true }),
            map(([records, meta]): UseLoadingObservableResult => [mapFn(records), omit(meta, "recordMap")]),
          ),
        ),
      );
    },
  });

  const [records, meta] = result;
  usePendingRequestBar(meta.isLoading);

  const isLoadingRef = useAsRef(meta.isLoading);
  const errorRef = useAsRef(meta.error);
  const limitRef = useAsRef(limit);
  const limitStepRef = useAsRef(limitStep);

  const fetchMore = useCallback(() => {
    const limitStep = limitStepRef.current;
    const isLimitDefined = limitRef.current !== undefined && !!limitStep;
    const isLoading = isLoadingRef.current;
    const hasError = !!errorRef.current;

    if (!isLimitDefined || isLoading || hasError) return;

    setLimit((limit) => limit && limit + limitStep);
  }, []);

  return [records, { ...meta, refetch, fetchMore }];
}

const DEFAULT_MAP_RESULT = (r: any) => r;

const DEFAULT_VALUE = Object.freeze([
  Object.freeze([]) as [],
  Object.freeze({
    nextId: null,
    limit: null,
    isLoading: true,
    recordMap: Object.freeze({}),
  }),
]) as unknown as ClientRecordLoaderObserveQueryResult;

type MapResultFn<T, MapResult> = (records: readonly T[]) => MapResult;

export interface UseRecordLoaderLoadProps<Deps extends any[], IsAuthOptional extends boolean | undefined = undefined> {
  loader: ClientRecordLoaderApi;
  limit: number | undefined;
  currentUserId: true extends IsAuthOptional ? string | undefined : string;
  deps: Deps;
}

function useTriggerReload() {
  const [reloadId, setReloadId] = useState(0);

  const triggerFn = useCallback(() => {
    setReloadId((id) => id + 1);
  }, [setReloadId]);

  return [triggerFn, reloadId] as const;
}
