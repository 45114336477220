import { getPointer, TagSubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getAndAssertCurrentUserId, getAndAssertCurrentUserOwnerOrganizationId } from "~/environment/user.service";
import { GetOptions } from "~/environment/RecordLoader";

export async function updateThreadSubscription(
  environment: ClientEnvironment,
  props: {
    threadId: string;
    preference: Extract<TagSubscriptionPreference, "all" | "involved">;
  },
  options?: GetOptions,
) {
  const currentUserId = getAndAssertCurrentUserId();
  const ownerOrganizationId = getAndAssertCurrentUserOwnerOrganizationId();

  const subscriptionPointer = getPointer("thread_subscription", {
    thread_id: props.threadId,
    user_id: currentUserId,
  });

  const participationPointer = getPointer("thread_user_participant", {
    thread_id: props.threadId,
    user_id: currentUserId,
  });

  const [[subscriptionBeforeUpdate], [participationBeforeUpdate]] = await Promise.all([
    environment.recordLoader.getRecord(subscriptionPointer, options),
    environment.recordLoader.getRecord(participationPointer, options),
  ]);

  return runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "updateThreadSubscription",
    tx: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: props.preference === "all" ? `Error subscribing to thread.` : `Error unsubscribing to thread.`,
          });

          return;
        }

        toast("vanilla", {
          subject: props.preference === "all" ? `Subscribed.` : `Unsubscribed.`,
          description:
            props.preference === "all"
              ? `You will receive notifications for every reply
               to this thread.`
              : `You won't receive notifications unless a reply is addressed
               to you or @mentions you.`,
        });
      };

      transaction.operations.push(
        op.set("thread_subscription", {
          id: subscriptionPointer.id,
          owner_organization_id: ownerOrganizationId,
          preference: props.preference,
          thread_id: props.threadId,
          user_id: currentUserId,
        }),
        // It's important that this upsert comes after setting the `thread_subscription`
        // so that the subscription preference is set already. This way the "upsert_on_update"
        // operation's `where` clause will be evaluated against the new subscription
        // preference.
        op.upsert(subscriptionPointer, {
          onUpdate: [
            {
              type: "upsert_on_update",
              where: { preference: { eq: "involved" } },
              operations: [op.delete(participationPointer)],
            },
          ],
        }),
      );
    },
    undo: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject:
              props.preference === "all"
                ? `Error undoing subscribing to thread.`
                : `Error undoing unsubscribing to thread.`,
          });

          return;
        }

        if (subscriptionBeforeUpdate) {
          toast("vanilla", {
            subject: subscriptionBeforeUpdate.preference === "all" ? `Subscribed.` : `Unsubscribed.`,
            description:
              subscriptionBeforeUpdate.preference === "all"
                ? `You will receive notifications for every reply
               to this thread.`
                : `You won't receive notifications unless a reply is addressed
               to you or @mentions you.`,
          });
        } else {
          toast("vanilla", {
            subject: "Removed subscription to thread.",
          });
        }
      };

      if (participationBeforeUpdate) {
        transaction.operations.push(
          op.set("thread_user_participant", {
            id: participationBeforeUpdate.id,
            thread_id: participationBeforeUpdate.thread_id,
            user_id: participationBeforeUpdate.user_id,
            owner_organization_id: participationBeforeUpdate.owner_organization_id,
          }),
        );
      } else {
        transaction.operations.push(op.delete(participationPointer));
      }

      if (subscriptionBeforeUpdate) {
        transaction.operations.push(
          op.set("thread_subscription", {
            id: subscriptionBeforeUpdate.id,
            owner_organization_id: subscriptionBeforeUpdate.owner_organization_id,
            preference: subscriptionBeforeUpdate.preference,
            thread_id: subscriptionBeforeUpdate.thread_id,
            user_id: subscriptionBeforeUpdate.user_id,
          }),
        );
      } else {
        transaction.operations.push(op.delete(subscriptionPointer));
      }
    },
  });
}
