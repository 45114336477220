import { ComponentType, RefObject } from "react";
import { useDraftIdsForThread } from "~/hooks/useDraftIdsForThread";
import { ComposeThreadReply } from "./ComposeThreadReply";
import { IListRef } from "~/components/list";
import { ComposeReplyHint } from "~/components/ComposeReplyBase";
import { useThread } from "~/hooks/useThread";
import { TThreadTimelineEntry } from "~/components/thread-timeline-entry/util";
import { IRichTextEditorRef } from "~/components/forms/message-editor";

export const ThreadDrafts: ComponentType<{
  listRef: RefObject<IListRef<TThreadTimelineEntry>>;
  editorRefStore: Map<string, IRichTextEditorRef>;
  threadId: string;
  focusOnInit: boolean;
}> = (props) => {
  const [thread] = useThread(props.threadId);
  const [draftIds] = useDraftIdsForThread({ threadId: props.threadId });

  if (!thread) return null;

  return (
    <div>
      {draftIds.map((draftId) => (
        <ComposeThreadReply
          key={draftId}
          listRef={props.listRef}
          editorRefStore={props.editorRefStore}
          draftId={draftId}
          threadId={props.threadId}
          threadType={thread.type}
          threadVisibility={thread.visibility}
          threadSubject={thread.subject}
          isFirstDraft={draftIds[0] === draftId}
          focusOnInit={draftIds[0] === draftId && props.focusOnInit}
        />
      ))}

      {draftIds.length === 0 && (
        <div className="mt-8 mx-10 prose text-slate-9 sm-max-w:hidden">
          <p>
            <strong>Hint:</strong> press <kbd>r</kbd> to reply.
          </p>
        </div>
      )}

      {draftIds.length > 0 && <ComposeReplyHint />}
    </div>
  );
};

/* -----------------------------------------------------------------------------------------------*/
