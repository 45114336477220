import { onlyCallFnOnceWhilePreviousCallIsPending, promiseTimeout, wait } from "libs/promise-utils";
import React from "react";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { PersistedDatabaseServiceProvider } from "~/environment/PersistedDatabaseService";

/**
 * A view that is shown when an unexpected error occurs.
 */
export function GlobalErrorView({ message }: { message: string }) {
  const refresh = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    reloadAllTabsAndReinstallCommsDatabase();
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="max-w-sm">
        <h1 className="font-semibold text-xl mb-2">We found an unexpected error.</h1>
        <p className="text-slate-9">{message}</p>
        <p className="mt-2">
          <a href="#" onClick={refresh} className="text-blue-10">
            Click here
          </a>{" "}
          to try again. If the problem persist contact us at team@comms.day.
        </p>
      </div>
    </div>
  );
}

const reloadAllTabsAndReinstallCommsDatabase = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async () => {
    try {
      await promiseTimeout(2000, PersistedDatabaseServiceProvider.reloadAllTabsAndReinstallCommsDatabase());
    } finally {
      // Wait to give time for Comms to handle the call to reloadAllTabsAndReinstallCommsDatabase.
      await wait(6000);

      // In case the call to `reloadAllTabsAndReinstallCommsDatabase` doesn't work as expected, we
      // fallback to manually reloading the page.
      window.location.reload();
    }
  }),
);
