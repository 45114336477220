import { ComponentType, Dispatch, memo, useEffect, useRef, useState } from "react";
import { DialogState, withModalDialog } from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { createFormControl, createFormGroup, useControl } from "solid-forms-react";
import { handleSubmit, useControlState } from "~/components/forms/utils";
import { useRegisterCommands, withNewCommandContext } from "~/environment/command.service";
import { cx } from "@emotion/css";
import { isEqual } from "libs/predicates";
import { Tooltip } from "~/components/Tooltip";
import { toast } from "~/environment/toast-service";
import { setMessageReactions } from "~/actions/message";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { MdMoreHoriz } from "react-icons/md";

export type IMessageReactionPickerData =
  | {
      messageId: string;
      messageType: "COMMS" | "EMAIL";
      currentUsersReactions?: string[];
    }
  | undefined;

export type IMessageReactionPickerDialogReturnData = {
  success: boolean;
} | void;

export const MessageReactionPickerDialogState = new DialogState<
  IMessageReactionPickerData,
  IMessageReactionPickerDialogReturnData
>();

interface IFormValue {
  id: string;
  type: "COMMS" | "EMAIL";
  reactions: string[];
}

function createForm(data: NonNullable<IMessageReactionPickerData>) {
  return createFormGroup({
    id: createFormControl(data.messageId),
    type: createFormControl(data.messageType),
    reactions: createFormControl(data.currentUsersReactions || []),
  });
}

type IPostReactionPickerControl = ReturnType<typeof createForm>;

export const MessageReactionPickerDialog = withModalDialog({
  dialogState: MessageReactionPickerDialogState,
  Component: (props) => {
    const data = props.data;

    if (!data) {
      throw new Error("Oops! You must provide data to PostReactionPickerDialog");
    }

    const control = useControl(() => createForm(data));

    const [pickerOpened, setPickerOpened] = useState(false);

    return (
      <>
        <div
          className="PostReactionPickerContainer flex flex-col items-center"
          onClick={(e) => {
            const wasClickOnContainerEl =
              e.target instanceof HTMLDivElement && e.target.classList.contains("PostReactionPickerContainer");

            if (!wasClickOnContainerEl) return;

            MessageReactionPickerDialogState.close();
          }}
        >
          <div className="flex">
            <div className="bg-blackA-11 text-white rounded-full px-4 py-1 text-lg">React to post</div>
          </div>

          {pickerOpened ? (
            <FullEmojiPickerDialog control={control} closeFullPicker={() => setPickerOpened(false)} />
          ) : (
            <SimpleEmojiPickerDialog control={control} openFullPicker={() => setPickerOpened(true)} />
          )}
        </div>
      </>
    );
  },
});

const FullEmojiPickerDialog = withNewCommandContext<{
  control: IPostReactionPickerControl;
  closeFullPicker: () => void;
}>({
  updateStrategy: "replace",
  Component: (props) => {
    const { closeFullPicker: close, control } = props;

    const environment = useClientEnvironment();

    useRegisterCommands({
      commands: () => [
        {
          label: "Close emoji picker",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            close();
          },
        },
      ],
      deps: [close],
    });

    return (
      <EmojiPicker
        open
        onEmojiClick={(emojiData: EmojiClickData) => {
          if (control.rawValue.reactions.includes(emojiData.emoji)) {
            control.patchValue({
              reactions: control.rawValue.reactions.filter((emoji) => emoji !== emojiData.emoji),
            });
          } else {
            control.patchValue({
              reactions: [...control.rawValue.reactions, emojiData.emoji],
            });
          }

          handleSubmit({ control: control, environment, submit });
        }}
        className="m-4"
      />
    );
  },
});

const SimpleEmojiPickerDialog: ComponentType<{
  control: IPostReactionPickerControl;
  openFullPicker: () => void;
}> = (props) => {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const reactions = useControlState(() => props.control.rawValue.reactions, [props.control]);

  useRegisterMessageReactionCommands({
    focusedIndex,
    setFocusedIndex,
    openFullPicker: props.openFullPicker,
    control: props.control,
  });

  const ref = useRef<HTMLDivElement>(null);

  // On mount we focus the wrapper element. We do this because if the user switches from the
  // full emoji picker to this simple one, the previously focused element (an emoji in the full
  // picker) will still be focused but will be removed from the dom. Keyboard events are emitted
  // from the focused element and if the focused element is unattached to the window, then these
  // events won't bubble up to our command service listening on the window.
  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div ref={ref} tabIndex={-1} className="flex flex-wrap m-4 rounded-md px-6 bg-slate-1 outline-none">
      {DEFAULT_EMOJIS.map((emoji, index) => (
        <ReactionOption
          key={emoji}
          emoji={emoji}
          control={props.control}
          index={index}
          isFocused={focusedIndex === index}
          reactions={reactions}
        />
      ))}

      <div
        className={cx("py-2 mx-1 flex flex-col justify-center items-center", "relative hover:cursor-pointer")}
        onClick={props.openFullPicker}
      >
        <div
          className={cx(
            "p-2 rounded-md leading-none text-2xl",
            focusedIndex === DEFAULT_EMOJIS.length ? "border-blue-11 bg-blue-3" : "bg-slate-1",
          )}
        >
          <MdMoreHoriz />
        </div>

        <div className={cx("text-xs font-medium")}>#0</div>
      </div>
    </div>
  );
};

const DEFAULT_EMOJIS = ["👍", "👀", "🙌", "😂", "❤️", "😮", "👎", "✅"];

const ReactionOption: ComponentType<{
  emoji: string;
  index: number;
  control: IPostReactionPickerControl;
  reactions: string[];
  isFocused: boolean;
}> = memo((props) => {
  const environment = useClientEnvironment();

  const isSelected = props.reactions.some((r) => r === props.emoji);

  return (
    <Tooltip side="bottom" content={`Press "${props.index + 1}" to select or use the arrow keys + "Enter" to toggle.`}>
      <div
        className={cx("py-2 mx-1 flex flex-col justify-center items-center", "relative hover:cursor-pointer")}
        onClick={() => {
          if (props.control.rawValue.reactions.includes(props.emoji)) {
            props.control.patchValue({
              reactions: props.control.rawValue.reactions.filter((emoji) => emoji !== props.emoji),
            });
          } else {
            props.control.patchValue({
              reactions: [...props.control.rawValue.reactions, props.emoji],
            });
          }

          handleSubmit({ control: props.control, environment, submit });
        }}
      >
        <div
          className={cx(
            "p-2 rounded-md leading-none text-2xl",
            props.isFocused ? "border-blue-11 bg-blue-3" : "bg-slate-1",
          )}
        >
          {props.emoji}
        </div>

        <div className={cx("text-xs font-medium")}>#{props.index + 1}</div>

        {isSelected && (
          <div
            className={cx(
              "flex justify-center items-center absolute -top-2 -right-1",
              "w-5 h-5 text-white text-xs rounded-full",
              props.isFocused ? "bg-blue-11" : "bg-black",
            )}
          >
            ✓
          </div>
        )}
      </div>
    </Tooltip>
  );
}, isEqual);

const submit = onlyCallFnOnceWhilePreviousCallIsPending(async (environment: ClientEnvironment, values: IFormValue) => {
  console.log("submitting...", values);

  setMessageReactions(environment, {
    messageId: values.id,
    reactions: values.reactions.slice(),
  })
    .then(() => console.debug(`Reaction submitted for post ${values.id}`, values.reactions))
    .catch((e) => console.error(`Failed to submit reaction for post ${values.id}`, e));

  if (values.type === "EMAIL") {
    warnThatEmailUsersWontSeeReaction();
  }

  MessageReactionPickerDialogState.close({
    success: true,
  });
});

export function warnThatEmailUsersWontSeeReaction() {
  toast("vanilla", {
    subject: "Reactions are only visible to Comms users",
    description: `
      Folks receiving this message via email will never see
      this reaction.
    `,
    durationMs: 5000,
  });
}

function useRegisterMessageReactionCommands(props: {
  focusedIndex: number;
  setFocusedIndex: Dispatch<React.SetStateAction<number>>;
  openFullPicker: () => void;
  control: IPostReactionPickerControl;
}) {
  const { focusedIndex, setFocusedIndex, openFullPicker: onOpenPicker, control } = props;

  const environment = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      // for each emoji, get its index + 1 in a new array
      const emojiHotkeys = Array.from({ length: DEFAULT_EMOJIS.length }, (_, i) => `${i + 1}`);

      return [
        {
          label: "Close dialog",
          hotkeys: ["Escape"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            MessageReactionPickerDialogState.close();
          },
        },
        {
          label: "Submit form",
          hotkeys: ["$mod+Enter"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            console.log("attempting submit");
            handleSubmit({ control, environment, submit });
          },
        },
        {
          label: "Open emoji picker",
          hotkeys: ["0"],
          triggerHotkeysWhenInputFocused: true,
          callback: () => {
            onOpenPicker();
          },
        },
        {
          label: "Select emoji",
          hotkeys: ["Enter", ...emojiHotkeys],
          showInKBar: false,
          callback: (e) => {
            if (!e) {
              console.warn("Select emoji command expects to be called via hotkey");

              return;
            }

            if (e.key === "Enter" && focusedIndex === DEFAULT_EMOJIS.length) {
              onOpenPicker();
              return;
            }

            const selectedEmoji =
              e.key === "Enter"
                ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DEFAULT_EMOJIS[focusedIndex]!
                : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  DEFAULT_EMOJIS[Number(e.key) - 1]!;

            if (control.rawValue.reactions.includes(selectedEmoji)) {
              control.patchValue({
                reactions: control.rawValue.reactions.filter((emoji) => emoji !== selectedEmoji),
              });
            } else {
              control.patchValue({
                reactions: [...control.rawValue.reactions, selectedEmoji],
              });
            }

            if (e.key !== "Enter") {
              console.log("attempting submit");
              handleSubmit({ control, environment, submit });
            }
          },
        },
        {
          label: "Focus next emoji",
          hotkeys: ["ArrowRight"],
          callback: () => {
            if (focusedIndex === DEFAULT_EMOJIS.length) return;
            setFocusedIndex((i) => i + 1);
          },
        },
        {
          label: "Focus previous emoji",
          hotkeys: ["ArrowLeft"],
          callback: () => {
            if (focusedIndex === 0) return;
            setFocusedIndex((i) => i - 1);
          },
        },
      ];
    },
    deps: [focusedIndex, environment],
  });
}
