import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { toast } from "~/environment/toast-service";
import { runTransaction, withTxLogger } from "./write";
import { CreateRecord, op } from "libs/transaction";
import { omit } from "lodash-es";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { InboxSectionTagRecord, InboxSubsectionTagRecord, generateRecordId, getPointer } from "libs/schema";
import { isInboxSectionTagRecord } from "libs/schema/predicates";

export async function deleteInboxSection(
  environment: ClientEnvironment,
  props: {
    inboxSectionId: string;
    afterUndo?: () => Promise<void> | void;
  },
) {
  const { recordLoader } = environment;

  const [[inboxSection], [subsections]] = await Promise.all([
    recordLoader.getRecord("tag", props.inboxSectionId),
    recordLoader.getInboxSubsections({
      currentUserId: getAndAssertCurrentUserId(),
      inboxSectionId: props.inboxSectionId,
    }),
  ]);

  if (!isInboxSectionTagRecord(inboxSection)) return;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "deleteInboxSection",
    tx: async (transaction) => {
      transaction.operations.push(
        op.delete("tag", {
          id: props.inboxSectionId,
        }),
      );

      for (const subsection of subsections) {
        transaction.operations.push(op.delete("tag", subsection));
      }
    },
    undo: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: "Failed to restore inbox section",
            description: "There was an error.",
          });

          return;
        }

        props.afterUndo?.();

        toast("vanilla", {
          subject: "Inbox section restored",
        });
      };

      transaction.operations.push(
        op.set("tag", omit(inboxSection, "version")),
        ...subsections.map((subsection) => op.set("tag", omit(subsection, "version"))),
      );
    },
  });
}

export async function createInboxSection(
  environment: ClientEnvironment,
  props: {
    inboxSection: CreateRecord<InboxSectionTagRecord>;
    inboxSubsections: CreateRecord<InboxSubsectionTagRecord>[];
    afterUndo?: () => Promise<void> | void;
  },
) {
  if (!environment.network.isOnline()) {
    alert(`You must be online to create/update an inbox section`);
    return;
  }

  const currentUserId = getAndAssertCurrentUserId();

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "createInboxSection",
    tx: async (transaction) => {
      const pointer = getPointer("tag", props.inboxSection.id);

      transaction.operations.push(
        op.upsert(pointer, {
          onCreate: [
            op.set("tag", {
              ...props.inboxSection,
              data: {
                ...props.inboxSection.data,
                is_reindexing: true,
              },
            }),
            op.set("tag_user_member", {
              id: generateRecordId("tag_user_member", {
                tag_id: props.inboxSection.id,
                user_id: currentUserId,
              }),
              tag_id: props.inboxSection.id,
              user_id: currentUserId,
              creator_user_id: currentUserId,
              owner_organization_id: props.inboxSection.owner_organization_id,
            }),
            ...props.inboxSubsections.flatMap((subsection) => [
              op.set("tag", subsection),
              op.set("tag_user_member", {
                id: generateRecordId("tag_user_member", {
                  tag_id: subsection.id,
                  user_id: currentUserId,
                }),
                tag_id: subsection.id,
                user_id: currentUserId,
                creator_user_id: currentUserId,
                owner_organization_id: subsection.owner_organization_id,
              }),
            ]),
          ],
        }),
      );
    },
  });

  // After creating our inbox section, we need to reindex the notifications for it
  await environment.api.reindexInboxSections({
    notificationDeliveredAt: {
      onOrBefore: new Date().toISOString(),
    },
  });
}

export async function updateInboxSection(
  environment: ClientEnvironment,
  props: {
    inboxSection: CreateRecord<InboxSectionTagRecord>;
    inboxSubsections: CreateRecord<InboxSubsectionTagRecord>[];
    afterUndo?: () => Promise<void> | void;
  },
) {
  if (!environment.network.isOnline()) {
    alert(`You must be online to create/update an inbox section`);
    return;
  }

  const { recordLoader } = environment;

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "updateInboxSection",
    tx: async (transaction) => {
      const pointer = getPointer("tag", props.inboxSection.id);

      const [_prevSubsections] = await recordLoader.getInboxSubsections({
        currentUserId: getAndAssertCurrentUserId(),
        inboxSectionId: props.inboxSection.id,
      });

      const prevSubsections = _prevSubsections as InboxSubsectionTagRecord[];

      const deleteSubsections = prevSubsections.filter(
        (prevSubsection) => !props.inboxSubsections.some((subsection) => prevSubsection.id === subsection.id),
      );

      transaction.operations.push(
        op.upsert(pointer, {
          onUpdate: [
            op.set("tag", {
              ...omit(props.inboxSection, "version"),
              data: {
                ...props.inboxSection.data,
                is_reindexing: true,
              },
            }),
            ...props.inboxSubsections.flatMap((subsection) => [
              op.set("tag", omit(subsection, "version")),
              op.set("tag_user_member", {
                id: generateRecordId("tag_user_member", {
                  tag_id: subsection.id,
                  user_id: subsection.data.user_id,
                }),
                tag_id: subsection.id,
                user_id: subsection.data.user_id,
                creator_user_id: subsection.data.user_id,
                owner_organization_id: subsection.owner_organization_id,
              }),
            ]),
            ...deleteSubsections.map((subsection) => op.delete("tag", subsection)),
          ],
        }),
      );
    },
  });

  // After updating our inbox section, we need to reindex the notifications for it
  await environment.api.reindexInboxSections({
    notificationDeliveredAt: {
      onOrBefore: new Date().toISOString(),
    },
  });
}

export const reorderInboxSections = withPendingRequestBar(async function (
  environment: ClientEnvironment,
  props: {
    newOrder: Array<{ id: string }>;
  },
) {
  const { recordLoader } = environment;
  const currentUserId = getAndAssertCurrentUserId();

  const [oldInboxSectionOrder] = await recordLoader.getInboxSections({
    currentUserId: currentUserId,
  });

  await runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "reorderInboxSections",
    tx: async (transaction) => {
      props.newOrder.forEach(({ id }, index) => {
        transaction.operations.push(op.update({ table: "tag", id }, "data", { order: index }));
      });
    },
    undo: async (transaction) => {
      transaction.onServerResponse = ({ error }) => {
        if (error) {
          toast("vanilla", {
            subject: "Failed to reorder inbox sections",
            description: "There was an error.",
          });

          return;
        }

        toast("vanilla", {
          subject: "Inbox section order restored",
        });
      };

      (oldInboxSectionOrder as InboxSectionTagRecord[]).forEach(({ id, data: { order } }) => {
        transaction.operations.push(op.update({ table: "tag", id }, "data", { order }));
      });
    },
  });
});
