import { GetOptions } from "~/environment/RecordLoader";
import { getMentionableUsers } from "./getMentionableUsers";
import { MentionableUser } from "~/observables/observeMentionableUserRecords";
import { getMentionableUserRecord } from "./getMentionableUserRecord";
import { isNonNullable } from "libs/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type GetMentionableUserRecordsResult = {
  users: MentionableUser[];
};

export async function getMentionableUserRecords(
  environment: Pick<ClientEnvironment, "recordLoader">,
  options?: GetOptions,
): Promise<GetMentionableUserRecordsResult> {
  const { userIds } = await getMentionableUsers(environment, options);

  const results = await Promise.all(
    userIds.map((userId) => getMentionableUserRecord(environment, { id: userId }, options)),
  );

  return { users: results.map(([r]) => r).filter(isNonNullable) };
}
