import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useRecord } from "./useRecord";
import { useMemo } from "react";
import { generateRecordId } from "libs/schema";

export function useThreadSeenReceipt(props: { threadId?: string | null }) {
  const { currentUserId } = useAuthGuardContext();

  const id = useMemo(() => {
    if (!props.threadId) return null;

    return generateRecordId("thread_seen_receipt", {
      thread_id: props.threadId,
      user_id: currentUserId,
    });
  }, [props.threadId, currentUserId]);

  return useRecord("thread_seen_receipt", id);
}
