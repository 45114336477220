import { RecordValue } from "libs/schema";
import { cacheReplayForTime } from "libs/rxjs-operators";
import { Observable, combineLatest, map } from "rxjs";
import { observeGroupsUserHasAccessTo } from "./observeGroupsUserHasAccessTo";
import { isNonNullable } from "libs/predicates";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { observeCurrentUserSettings } from "~/observables/observeCurrentUserSettings";
import { ObserveOptions } from "~/environment/RecordLoader";

export type ObserveUserGroupsWithFolderAncestorRecordsResult = [
  groups: Array<{
    group: RecordValue<"tag">;
    folderPaths: RecordValue<"tag">[][];
  }>,
  meta: { isLoading: boolean },
];

export function observeUsersGroupsWithFolderAncestorRecords(
  environment: Pick<ClientEnvironment, "recordLoader" | "subscriptionManager" | "logger">,
  props: {
    userId: string;
  },
  options?: ObserveOptions,
): Observable<ObserveUserGroupsWithFolderAncestorRecordsResult> {
  const cacheKey = props.userId + options?.fetchStrategy + options?.isLoading;

  const query = groupsWithFoldersQueryCache.get(cacheKey);

  if (query) return query;

  const observable = combineLatest([
    observeGroupsUserHasAccessTo(environment, { userId: props.userId }, options),
    environment.recordLoader.observeGetTagFolderMembersUserHasAccessTo({ currentUserId: props.userId }, options),
    observeCurrentUserSettings(environment, { userId: props.userId }, options),
  ]).pipe(
    map(
      ([
        [userGroups, { isLoading: isUserGroupsLoading }],
        [groupFolders, { isLoading: isGroupFoldersLoading }],
        { settings },
      ]): ObserveUserGroupsWithFolderAncestorRecordsResult => {
        const archivedFilterGroups = settings?.show_archived_groups
          ? userGroups
          : userGroups.filter((group) => !group.archived_at);

        const groups = archivedFilterGroups.map((group) => {
          return {
            group,
            folderPaths: folderPathsForTag(group.id, groupFolders).map((folderIds) =>
              folderIds.map((folderId) => userGroups.find((group) => group.id === folderId)).filter(isNonNullable),
            ),
          };
        });

        return [groups, { isLoading: isUserGroupsLoading || isGroupFoldersLoading }];
      },
    ),
    cacheReplayForTime({
      timeMs: 5000,
      onInit() {
        groupsWithFoldersQueryCache.set(cacheKey, observable);
      },
      onCleanup() {
        groupsWithFoldersQueryCache.delete(cacheKey);
      },
    }),
  );

  return observable;
}

const groupsWithFoldersQueryCache = new Map<string, Observable<ObserveUserGroupsWithFolderAncestorRecordsResult>>();

function folderPathsForTag(tagId: string, folders: RecordValue<"tag_folder_member">[]): string[][] {
  return folders
    .filter((folder) => folder.tag_id === tagId)
    .flatMap((folder) => {
      const ancestorPaths = folderPathsForTag(folder.folder_id, folders);

      if (ancestorPaths.length === 0) return [[folder.folder_id]];

      return ancestorPaths.map((paths) => [folder.folder_id, ...paths]);
    });
}
