import { ApiInput } from "libs/ApiTypes";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { RecordValue } from "libs/schema";
import { op } from "libs/transaction";
import { runTransaction, withTxLogger } from "./write";

export type CreateUserParams = ApiInput<"createUser">;

/**
 * Note that this is not an "optimistic" operation. We wait for it
 * to be confirmed by the server. Also, we don't support creating
 * users while offline.
 */
export async function createUser(environment: ClientEnvironment, params: CreateUserParams) {
  const { status, body } = await environment.api.createUser(params);

  if (status !== 200) return;
  if (!body.recordMap) return;

  console.log("createUser response", body.recordMap);

  environment.db.writeRecordMap(body.recordMap);
}

export function updateUser(
  environment: ClientEnvironment,
  props: {
    userId: string;
    profile?: Partial<RecordValue<"user_profile">>;
    contactInfo?: Partial<RecordValue<"user_contact_info">>;
  },
) {
  const { userId, profile, contactInfo } = props;

  return runTransaction({
    environment: withTxLogger(environment, { data: props }),
    label: "updateUser",
    tx: async (transaction) => {
      if (profile) {
        transaction.operations.push(op.update({ table: "user_profile", id: userId }, profile));
      }

      if (contactInfo) {
        transaction.operations.push(op.update({ table: "user_contact_info", id: userId }, contactInfo));
      }
    },
  });
}
